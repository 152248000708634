import {
  getFileNameFromPath,
  getMimeAndExtensionFromName,
  isSupportedMimeType,
} from './utils'

const RESIZE_IMAGE_URL = 'https://images.buffer.com/dynamic-internal/'

export type RemoteFile = {
  remoteUrl: string
  name?: string
  useResizer?: boolean
}

/**
 * fileFromUrl
 *
 * Get the file data fetched from the remoteUrl.Some urls could contain
 * the name and extension of the file but others don't so a optional name param is provided
 * so we can set a name when needed.
 *
 * @param
 *   name => should contain the extension, for example name='picture-1.jpg'
 * CASES:
 *   param name is provided => name and extension are used.
 *   parm name is not provided +
 *       url contain filename and extension => the name and extension in the url are used
 *       url contain only filename          => image/png type will be used
 */
export const fileFromUrl = async ({
  remoteUrl,
  name,
  useResizer = false,
}: RemoteFile): Promise<File> => {
  let fetchUrl = remoteUrl

  let fileName = name || getFileNameFromPath(remoteUrl)
  let { mimeType } = getMimeAndExtensionFromName(fileName)

  // the mimeType found in the filename/path is trusted
  // if the mimeType is not supported, we convert to png via safeimage service
  if (!isSupportedMimeType(mimeType)) {
    useResizer = true
    mimeType = 'image/png'
    fileName = fileName ? `${fileName}.png` : `img-default-${Date.now()}.png`
    fetchUrl = `filters:format(png)/${remoteUrl}`
  }

  const response = await fetch(
    `${useResizer ? RESIZE_IMAGE_URL : ''}${fetchUrl}`,
  )
  const blob = await response.blob()

  return new File([blob], fileName, { type: mimeType })
}
