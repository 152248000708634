import { FileUploadConfig } from '../constants'

export type UploaderRestrictions = {
  maxNumberOfFiles: number
  allowedFileTypes: null | string[]
  mixedMediaError?: string
  uploadConfig: FileUploadConfig
}

/**
 * Allowed in the backend
 * @see https://github.com/bufferapp/buffer-web/blob/main/shared/helpers/utils_helper.php#L983
 */
const allowedFileTypes = [
  '.m4v',
  '.mov',
  '.mp4',
  '.avi',
  '.webm',
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.webp',
]

export const UploaderRestrictions = {
  new(
    fileRestrictions: Partial<UploaderRestrictions> = {},
  ): UploaderRestrictions {
    return {
      ...defaultRestrictions,
      ...fileRestrictions,
    }
  },
}

export const defaultRestrictions: UploaderRestrictions = {
  maxNumberOfFiles: 10,
  allowedFileTypes,
  uploadConfig: FileUploadConfig.MEDIA,
}
