import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useSplitEnabled } from '@buffer-mono/features'
import { Flex, Link } from '@buffer-mono/popcorn'

import { useUser } from '../../common/context/User'
import { isFreePlan } from '../../common/hooks/utils/segmentTraitGetters'
import BufferLogoWithWords from './components/BufferLogoWithWords'
import NavBarProducts, {
  type ProductNames,
} from './components/NavBarProducts/NavBarProducts'
import UpgradeCTA from './components/UpgradeCTA'

import { getHelpDropdownItems } from './utils/dropdown-items'

import {
  useGetToggleLauncher,
  useHasSeenLauncherInHelpMenu,
} from '../Launcher/hooks'
import { LAUNCHER_IN_HELP_OBJECT_ID } from '../Launcher'
import { DISMISS_OBJECT } from '../../common/graphql/account'

import GlobalAction from './components/GlobalAction/GlobalAction'
import AppsMenu from './components/AppsMenu/AppsMenu'
import HelpMenu from './components/HelpMenu/HelpMenu'
import AccountMenu from './components/AccountMenu/AccountMenu'
import { isOneBufferOrganization } from '../../common/utils/user'

import styles from './NavBar.module.css'

type NavBarProps = {
  activeProduct: ProductNames | null
  onLogout?: () => void
  displaySkipLink?: boolean
  onOrganizationSelected?: (organizationId: string) => void
}

const NavBar = React.memo(function NavBar(props: NavBarProps) {
  const { activeProduct, onLogout, displaySkipLink, onOrganizationSelected } =
    props

  const user = useUser()

  const toggleLauncher = useGetToggleLauncher()

  const [helpMenuDropdownWasOpenedBefore, setHelpMenuDropdownWasOpened] =
    useState(false)
  const [dismissObject, { called: dismissedNewLabel }] =
    useMutation(DISMISS_OBJECT)
  const launcherIsANewItemInHelpMenu = useHasSeenLauncherInHelpMenu()
  const [quickNavigatorHasNewLabel, setQuickNavigatorNewLabel] = useState(false)

  const { isEnabled: isGlobalActionButtonEnabled } = useSplitEnabled(
    'GROWTH-global-action-button',
  )

  /* This is a PERMANENT split used by Advocacy for volume Spikes when they need to "hide" the Beacon icon. */
  const { isEnabled: showBeaconInHelpMenu } = useSplitEnabled(
    'move-beacon-to-help-menu',
  )

  useEffect(() => {
    setQuickNavigatorNewLabel(launcherIsANewItemInHelpMenu)
  }, [launcherIsANewItemInHelpMenu])

  const isFree = isFreePlan(user)
  let canStartTrial = false

  if (user.currentOrganization?.billing) {
    canStartTrial = user?.currentOrganization?.billing.canStartTrial ?? false
  }

  const shouldShowLegacyTopNavCTA = !isOneBufferOrganization(user)

  async function hideNewTag(): Promise<void> {
    if (launcherIsANewItemInHelpMenu && !dismissedNewLabel) {
      await dismissObject({
        variables: {
          organizationId: user.currentOrganization?.id,
          dismissedObject: {
            id: LAUNCHER_IN_HELP_OBJECT_ID,
          },
        },
      })
      setHelpMenuDropdownWasOpened(true)
    } else if (helpMenuDropdownWasOpenedBefore) {
      // remove *new* label from quick navigator in help menu
      setQuickNavigatorNewLabel(false)
    }
  }

  if (!user?.currentOrganization?.id) {
    return null
  }

  const helpMenuItems = getHelpDropdownItems({
    hasVerifiedEmail: user.hasVerifiedEmail ?? false,
    toggleLauncher,
    launcherIsANewItemInHelpMenu: quickNavigatorHasNewLabel,
    showBeaconInHelpMenu,
  })

  return (
    <nav aria-label="Main menu" className={styles.navBar}>
      <Flex align="center">
        {/**
         * A11Y feature: A skip to main content link appears when a user is on a screen reader
         * and the link is in focus. To work properly, each page will need to have an element with the id main
         * example: <main id="main"></main> This feature is optional
         */}

        {displaySkipLink && (
          <Link href="#main" className={styles.skipToMainLink}>
            Skip to main content
          </Link>
        )}
        <BufferLogoWithWords />
        <NavBarProducts
          activeProduct={activeProduct}
          currentOrganization={user?.currentOrganization}
          user={user}
          canStartTrial={canStartTrial}
          isFreeUser={isFree}
        />
      </Flex>
      <Flex gap="2xs" align="center">
        <UpgradeCTA
          shouldShowLegacyCTA={shouldShowLegacyTopNavCTA}
          user={user}
        />

        {isGlobalActionButtonEnabled && <GlobalAction />}

        <HelpMenu
          hideNewTag={hideNewTag}
          showNewBadge={launcherIsANewItemInHelpMenu && !dismissedNewLabel}
          items={helpMenuItems}
        />

        <AppsMenu />

        <AccountMenu
          user={user}
          onOrganizationSelected={onOrganizationSelected}
          onLogout={onLogout || undefined}
        />
      </Flex>
    </nav>
  )
})

export default NavBar
