const DEFAULT_SIZE_IMAGE = 10 * 1024 * 1024
const DEFAULT_SIZE_GIF = 15 * 1024 * 1024
const DEFAULT_SIZE_VIDEO = 1024 * 1024 * 1024
const DEFAULT_FILE_SIZE_PDF = 100 * 1024 * 1024

export const FileType = {
  WEBM: 'WEBM',
  JPG: 'JPG',
  JPEG: 'JPEG',
  PNG: 'PNG',
  HEIC: 'HEIC',
  GIF: 'GIF',
  AVI: 'AVI',
  MP4: 'MP4',
  M4V: 'M4V',
  MOV: 'MOV',
  WEBP: 'WEBP',
  MPG: 'MPG',
  MPEG: 'MPEG',
  PDF: 'PDF',
} as const

const JPG = [FileType.JPG, { maxSize: DEFAULT_SIZE_IMAGE }] as const

const JPEG = [FileType.JPEG, { maxSize: DEFAULT_SIZE_IMAGE }] as const

const GIF = [FileType.GIF, { maxSize: DEFAULT_SIZE_GIF }] as const

const PNG = [FileType.PNG, { maxSize: DEFAULT_SIZE_IMAGE }] as const

const WEBP = [FileType.WEBP, { maxSize: DEFAULT_SIZE_IMAGE }] as const

const HEIC = [FileType.HEIC, { maxSize: DEFAULT_SIZE_IMAGE }] as const

const PDF = [FileType.PDF, { maxSize: DEFAULT_FILE_SIZE_PDF }] as const

const WEBM = [FileType.WEBM, { maxSize: DEFAULT_SIZE_VIDEO }] as const

const MP4 = [FileType.MP4, { maxSize: DEFAULT_SIZE_VIDEO }] as const

const M4V = [FileType.M4V, { maxSize: DEFAULT_SIZE_VIDEO }] as const

const MOV = [FileType.MOV, { maxSize: DEFAULT_SIZE_VIDEO }] as const

const AVI = [FileType.AVI, { maxSize: DEFAULT_SIZE_VIDEO }] as const

const MPG = [FileType.MPG, { maxSize: DEFAULT_SIZE_VIDEO }] as const

const MPEG = [FileType.MPEG, { maxSize: DEFAULT_SIZE_VIDEO }] as const

/**
 * This is a map of maps, allowing to quickly access a content type group's
 * allowed formats as keys, and that format's specifics in an object literal:
 *
 * FileUploadFormatsConfigs = {
 *   IMAGE: Map {
 *     'JPG' => { maxSize: 10 * 1024 * 1024 },
 *     'JPEG' => { maxSize: 10 * 1024 * 1024 },
 *     'PNG' => { maxSize: 10 * 1024 * 1024 }
 *   },
 *   ...
 * }
 */
export type FileUploadConfig = Map<string, { maxSize: number }>

const MEDIA = new Map([
  JPG,
  JPEG,
  GIF,
  PNG,
  WEBP,
  MOV,
  MP4,
  M4V,
  AVI,
  WEBM,
  HEIC,
])

export const FileUploadConfig: Record<string, FileUploadConfig> = {
  IMAGE: new Map([JPG, JPEG, PNG, WEBP, HEIC]),
  VIDEO: new Map([MOV, MP4, M4V, AVI, WEBM]),
  GIF: new Map([GIF]),
  MEDIA,
  LINKEDIN: new Map([...MEDIA, PDF]),
  OMNI: new Map([...MEDIA, PDF]),
  IDEAS: new Map([JPG, JPEG, GIF, PNG, WEBP, MOV, MP4, M4V, WEBM, HEIC, PDF]),
  STORIES: new Map([JPG, JPEG, GIF, PNG, MOV, MP4, M4V, AVI, HEIC]),
  REELS: new Map([MOV, MP4, M4V, AVI]),
  FACEBOOK_REELS: new Map([MOV, MP4, M4V, AVI]),
  FACEBOOK_STORIES: new Map([JPG, JPEG, GIF, PNG, MOV, MP4, M4V, AVI]),
  SHORTS: new Map([MOV, MP4, MPG, MPEG, AVI, WEBM]),
} as const
