import { useEffect, useState } from 'react'
import { type ApolloError, useMutation } from '@apollo/client'
import { ORGANIZATION_ACTIONS } from '../events/orgEvents'
import { START_TRIAL } from '../graphql/billing'
import { QUERY_ACCOUNT } from '../graphql/account'
import type { Account } from '../types'

export type UserStartTrialParams = {
  user?: Account
  plan?: {
    planId: string
    planInterval: string
  }
  attribution?: {
    cta: string
    upgradePathName: string
  }
}

export type UserStartTrialReturn = {
  startTrial: () => void
  trial: {
    billingStartTrial: {
      success?: boolean
      userFriendlyMessage?: string
      cause?: string
    }
  } | null
  error: ApolloError | { message: string } | null
  processing: boolean
}

const useStartTrial = ({
  user,
  plan,
  attribution,
}: UserStartTrialParams): UserStartTrialReturn => {
  const [startTrial, { data: trial, error: mutationError }] = useMutation(
    START_TRIAL,
    {
      refetchQueries: [{ query: QUERY_ACCOUNT }],
      awaitRefetchQueries: true,
      onCompleted: () => {
        ORGANIZATION_ACTIONS.billingUpdated({ user })
      },
    },
  )
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState<ApolloError | { message: string } | null>(
    null,
  )

  useEffect(() => {
    if (processing && user && plan) {
      startTrial({
        variables: {
          organizationId: user.currentOrganization?.id,
          plan: plan.planId,
          interval: plan.planInterval,
          attribution,
        },
      }).catch((e) => {
        console.error(e) // eslint-disable-line no-console
      })
    }
  }, [processing])

  useEffect(() => {
    if (mutationError) {
      setError(mutationError)
      setProcessing(false)
    } else if (trial?.billingStartTrial.userFriendlyMessage) {
      setError({ message: trial?.billingStartTrial.userFriendlyMessage })
      setProcessing(false)
    }
  }, [trial, mutationError])

  return {
    startTrial: (): void => {
      setProcessing(true)
    },
    trial: trial ? { ...trial } : null,
    error,
    processing,
  }
}

export default useStartTrial
