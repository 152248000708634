import React, { useContext, useEffect } from 'react'

import {
  SuccessDialog,
  Button,
  ChannelIcon,
  BufferIcon,
  RepostIcon,
} from '@buffer-mono/popcorn'
import Loader from '@bufferapp/ui/Loader'
import { useSplitEnabled } from '@buffer-mono/features'

import { getPublishComposerURL } from '../../../../common/utils/urls'

import {
  getChannelFromList,
  getChannelServiceNameFromChannel,
} from '../../../../common/utils/channels'
import { getUserChannelList } from '../../../../common/utils/user'

import { UserContext } from '../../../../common/context/User'
import type { Service } from '../../../../gql/graphql'

import trackChannelConnectionModalViewed from '../../../../tracking/ChannelConnectionModal/trackChannelConnectionModalViewed'
import { cleanupUrlParams } from '../../utils'
import { useModalManager } from '../../../ModalManager/hooks/useModalManager'

import NewChannelRefreshSuccessDialog from './components/NewChannelRefreshSuccess'

import styles from './ChannelRefreshSuccess.module.css'

function handleCreatePostCTA({
  socialChannelId,
  handleOnDismissModal,
}: {
  socialChannelId: string
  handleOnDismissModal: () => void
}): void {
  const cta = 'channelRefreshSuccessModal-createAPostCta'
  handleOnDismissModal()
  window.location.href = getPublishComposerURL({ socialChannelId, cta })
}

const ChannelRefreshSuccessModal = (): JSX.Element => {
  const user = useContext(UserContext)
  const { modalData, dismissModal: closeAction } = useModalManager()
  const { isEnabled: isInstagramLoginEnabled } =
    useSplitEnabled('instagram-login')

  const CTACopy = 'Create a Post'

  const trackingPayload = {
    ctaButton: modalData?.ctaButton || '',
    ctaView: modalData?.ctaView || '',
    ctaLocation: modalData?.ctaLocation || '',
    ctaVersion: modalData?.ctaVersion,
    upgradePathName: modalData?.upgradePathName || '',
  }

  useEffect(() => {
    trackChannelConnectionModalViewed({
      payload: trackingPayload,
      user,
      isReconnecting: true,
    })
    cleanupUrlParams()
  }, [])

  const handleOnDismissModal = (): void => {
    closeAction()
  }

  if (
    !user?.currentOrganization?.billing ||
    !user?.currentOrganization?.id ||
    !modalData?.channelIds
  ) {
    return (
      <SuccessDialog open={true} onOpenChange={handleOnDismissModal}>
        <SuccessDialog.Content className={styles.dialog}>
          <Loader />
        </SuccessDialog.Content>
      </SuccessDialog>
    )
  }

  const socialChannelId = modalData?.channelIds[0]
  const usersChannelList = getUserChannelList(user)
  const channelData = getChannelFromList(socialChannelId, usersChannelList)
  const socialChannelName = getChannelServiceNameFromChannel(channelData)

  const showNewChannelRefreshSuccessDialog =
    channelData?.service === 'instagram' && isInstagramLoginEnabled

  if (showNewChannelRefreshSuccessDialog) {
    // Only for Instagram at the moment. Need to add logic to display icons
    // and brand colors for other services, then will be able to migrate
    // all channel refreshes to this component.
    return (
      <NewChannelRefreshSuccessDialog
        handleOnDismissModal={handleOnDismissModal}
        channelData={channelData}
        socialChannelId={socialChannelId}
        CTACopy={CTACopy}
        handleCreatePostCTA={handleCreatePostCTA}
      />
    )
  }

  return (
    <SuccessDialog open={true} onOpenChange={handleOnDismissModal}>
      <SuccessDialog.Content className={styles.dialog}>
        <div className={styles.header}>
          <BufferIcon size="medium" />
          <RepostIcon size="medium" color="subtle" />
          <ChannelIcon
            color="branded"
            size="medium"
            type={socialChannelName as Service}
          />
        </div>
        <SuccessDialog.Title>
          {' '}
          Great! You&apos;ve refreshed your Channel
        </SuccessDialog.Title>
        <SuccessDialog.Description>
          {' '}
          You&apos;ve successfully refreshed your channel to Buffer. Now you can
          continue scheduling posts to it.{' '}
        </SuccessDialog.Description>
        <SuccessDialog.Actions>
          <Button
            id="create_a_post"
            variant="primary"
            size="large"
            onClick={(): void =>
              handleCreatePostCTA({ socialChannelId, handleOnDismissModal })
            }
          >
            {CTACopy}
          </Button>
        </SuccessDialog.Actions>
      </SuccessDialog.Content>
    </SuccessDialog>
  )
}

export default ChannelRefreshSuccessModal
