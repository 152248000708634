import {
  BufferTrackerReact as BufferTracker,
  Client,
} from '@buffer-mono/tracking-plan'

import { shouldPerformTrackingEvent } from '../utils'
import { getActiveProductFromPath } from '../../common/utils/getProduct'
import { getUsersCurrentPlan } from '../../common/utils/user'
import type { TrackChannelConnectionModalArguments } from './types'

export default function trackChannelConnectionModalViewed({
  payload,
  user,
  isReconnecting = false,
}: TrackChannelConnectionModalArguments): void {
  const canPerformTrackingEvent = shouldPerformTrackingEvent({
    user,
  })

  if (!canPerformTrackingEvent) {
    return
  }

  const { ctaView, ctaLocation, ctaButton, ctaVersion, upgradePathName } =
    payload

  const version = ctaVersion || '1'

  const organization = user.currentOrganization
  const product = getActiveProductFromPath()
  const currentPlanData = getUsersCurrentPlan(user)

  const cta = `${product}-${ctaView}-${ctaLocation}-${ctaButton}-${version}`

  const eventData = {
    organizationId: organization?.id || '',
    currentPlan: currentPlanData?.id || '',
    cta,
    ctaApp: product,
    ctaView,
    ctaLocation,
    ctaButton,
    ctaVersion,
    clientName: Client.PublishWeb,
    product,
    upgradePathName,
  }

  isReconnecting
    ? BufferTracker.channelRefreshSuccessModalViewed(eventData)
    : BufferTracker.channelConnectionOnboardingModalViewed(eventData)
}
