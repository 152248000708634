export const MASTODON_SERVER_GENERIC = 'mastodon-generic'

export const MASTODON_SERVERS = [
  'mastodon.social',
  'mas.to',
  'mastodon.world',
  'mastodon.online',
  'techhub.social',
  MASTODON_SERVER_GENERIC,
]
