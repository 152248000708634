import React from 'react'

export const AppleIcon = (): JSX.Element => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9983 13.4383C9.22293 14.1771 8.37636 14.0604 7.56144 13.7105C6.69905 13.3528 5.90786 13.3372 4.99799 13.7105C3.85868 14.1927 3.25738 14.0527 2.57696 13.4383C-1.28404 9.52658 -0.71438 3.56955 3.6688 3.3518C4.7369 3.40624 5.48062 3.92728 6.10565 3.97394C7.03926 3.7873 7.9333 3.2507 8.93019 3.32069C10.1249 3.41401 11.0268 3.88062 11.6202 4.72052C9.15173 6.17478 9.7372 9.37104 12 10.2654C11.549 11.4319 10.9635 12.5906 9.99039 13.4461L9.9983 13.4383ZM6.02654 3.30514C5.90786 1.57091 7.33991 0.139982 8.98558 0C9.21502 2.00641 7.1342 3.49956 6.02654 3.30514Z"
        fill="#636363"
      />
    </svg>
  )
}
