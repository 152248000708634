import React from 'react'

import {
  MailIcon,
  DollarIcon,
  SettingsIcon,
  LockIcon,
  AllChannelsIcon,
} from '@buffer-mono/popcorn'

import {
  HELLONEXT_URL,
  HELLONEXT_LOGIN_SERVICE_URL,
} from '../../../common/utils/constants'

import {
  getBillingUrl,
  getManageChannelsURL,
  getPublishUrl,
} from '../../../common/utils/urls'

type NavDropdownItem = {
  id: string
  title: string
  url?: string
  onItemClick?: () => void
  tag?: JSX.Element
  icon?: JSX.Element
  hasDivider?: boolean
  className?: string
  badge?: JSX.Element
  new?: boolean
  shortcuts?: string[]
}

/* TODO: Refactor to replace onItemClick by a Link as we have done in the Account Menu */
function getHelpDropdownItems(args: {
  hasVerifiedEmail: boolean
  toggleLauncher?: (() => void) | undefined
  launcherIsANewItemInHelpMenu?: boolean
  showBeaconInHelpMenu?: boolean
}): Array<NavDropdownItem> {
  const {
    hasVerifiedEmail,
    launcherIsANewItemInHelpMenu,
    showBeaconInHelpMenu,
  } = args
  const hellonextUrl = hasVerifiedEmail
    ? `${HELLONEXT_LOGIN_SERVICE_URL}&redirect=${HELLONEXT_URL}`
    : HELLONEXT_URL
  const utmParams = 'utm_source=app&utm_medium=appshell&utm_campaign=appshell'

  const items: NavDropdownItem[] = [
    {
      id: 'Help Center',
      title: 'Help Center',
      onItemClick: (): void => {
        window.open(`https://support.buffer.com/?${utmParams}`, '_blank')
      },
    },
    {
      id: 'Plans & Pricing',
      title: 'Plans & Pricing',
      onItemClick: (): void => {
        window.open(`https://buffer.com/pricing?${utmParams}`, '_blank')
      },
    },
    {
      id: 'Request a Feature',
      title: 'Suggest a Feature',
      onItemClick: (): void => {
        window.open(hellonextUrl, '_blank')
      },
    },
    {
      id: "What's New",
      title: "What's New",
      onItemClick: (): void => {
        window.open(`https://buffer.com/whats-new?${utmParams}`, '_blank')
      },
    },
    {
      id: 'Status',
      title: 'Status',
      onItemClick: (): void => {
        window.open(`https://status.buffer.com/?${utmParams}`, '_blank')
      },
    },
  ]

  if (showBeaconInHelpMenu) {
    items.splice(1, 0, {
      id: 'Get in Touch',
      title: 'Get in Touch',
      onItemClick: (): void => {
        if (window.Beacon) {
          window.Beacon('open')
        } else {
          window.open(
            `https://support.buffer.com/?${utmParams}&openContact=true`,
            '_blank',
          )
        }
      },
    })
  }

  if (args.toggleLauncher) {
    items.push({
      id: 'Quick Navigator',
      title: 'Quick Navigator',
      new: launcherIsANewItemInHelpMenu,
      shortcuts: [getModifierKey(), 'k'],
      onItemClick: (): void => {
        if (args.toggleLauncher) {
          args.toggleLauncher()
        }
      },
    })
  }

  return items
}

function getModifierKey(): string {
  if (navigator.userAgent.indexOf('Mac') > -1) {
    return 'cmd'
  }
  return 'ctrl'
}

/* TODO: Refactor to replace onItemClick by a Link as we have done in the Account Menu */
function getAppsDropdownItems(): Array<NavDropdownItem> {
  return [
    {
      id: 'Buffer-for-iOS',
      title: 'Buffer for iOS',
      onItemClick: (): void => {
        window.open(
          'https://apps.apple.com/app/apple-store/id490474324?pt=936146&ct=TopBarAppsDropdown&mt=8',
          '_blank',
        )
      },
    },
    {
      id: 'Buffer-for-Android',
      title: 'Buffer for Android',
      onItemClick: (): void => {
        window.open(
          'https://play.google.com/store/apps/details?id=org.buffer.android',
          '_blank',
        )
      },
    },
    {
      id: 'Buffer-Extensions',
      title: 'Browser Extensions',
      onItemClick: (): void => {
        window.open('http://buffer.com/extensions', '_blank')
      },
    },
    {
      id: 'Zapier-for-Buffer',
      title: 'Zapier Integration',
      onItemClick: (): void => {
        window.open('https://zapier.com/apps/buffer/integrations', '_blank')
      },
    },
  ]
}

function getAccountDropdownItems(): Array<NavDropdownItem> {
  return [
    {
      id: 'preferences',
      title: 'Preferences',
      icon: <SettingsIcon size="small" />,
      url: window.location.href.match(/publish/)
        ? '/preferences/general'
        : getPublishUrl('preferences/general'),
    },
    {
      id: 'security',
      title: 'Security',
      icon: <LockIcon size="small" />,
      url: window.location.href.match(/publish/)
        ? '/preferences/security'
        : getPublishUrl('preferences/security'),
    },

    {
      id: 'notifications',
      title: 'Notification Settings',
      icon: <MailIcon size="small" />,
      url: window.location.href.match(/publish/)
        ? '/preferences/notifications'
        : getPublishUrl('preferences/notifications'),
    },

    {
      id: 'billing',
      title: 'Billing',
      icon: <DollarIcon size="small" />,
      url: getBillingUrl(),
    },

    {
      id: 'channels',
      title: 'Channels',
      hasDivider: true,
      icon: <AllChannelsIcon size="small" />,
      url: getManageChannelsURL(),
    },
  ]
}

export {
  NavDropdownItem,
  getHelpDropdownItems,
  getAppsDropdownItems,
  getAccountDropdownItems,
}
