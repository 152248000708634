import React from 'react'
import styled from 'styled-components'

const ShortcutsWrapper = styled.section`
  display: flex;
  gap: var(--space-3xs);
  margin-left: auto;
`

export const Shortcut = styled.span`
  color: var(--color-text-neutral);
  align-self: center;
  padding: 0 var(--space-2xs);
  height: 17px;
  min-width: 17px;
  border-radius: 4px;
  background: var(--color-bg-neutral-subtle);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
`

interface ShortcutsProps {
  shortcuts: string[]
  variant?: 'simple' | 'default'
}

export function Tab(): JSX.Element {
  return <Shortcut>Tab</Shortcut>
}

const Shortcuts = ({
  shortcuts,
  variant = 'default',
}: ShortcutsProps): JSX.Element => (
  <ShortcutsWrapper>
    {shortcuts
      .map((s) => <Shortcut key={`key-${s}`}>{s}</Shortcut>)
      .reduce<JSX.Element[]>((list, elem, i) => {
        list.push(elem)
        if (i < shortcuts.length - 1 && variant === 'default') {
          list.push(<span key={'then'}>then</span>)
        }
        return list
      }, [])}
  </ShortcutsWrapper>
)

export default Shortcuts
