import React from 'react'
import { Label, VisuallyHidden } from '@buffer-mono/popcorn'

import styles from './ChipInput.module.css'

interface CheckboxChipProps {
  label: string
  value: string
  checked: boolean
  name: string
  onChange: (checked: boolean) => void
  inputType?: 'checkbox' | 'radio'
}

function ChipInput({
  label,
  value,
  name,
  checked,
  onChange,
  inputType = 'checkbox',
}: CheckboxChipProps): JSX.Element {
  function handleOnKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      onChange(!checked)
    }
  }

  return (
    <Label
      size="medium"
      htmlFor={value}
      aria-checked={checked}
      className={`${styles.chipInput} ${checked ? styles.checked : ''}`}
    >
      <VisuallyHidden>
        {inputType === 'checkbox' ? (
          <input
            id={value}
            name={name}
            type={inputType}
            value={value}
            checked={checked}
            onChange={(event): void => onChange(event.target.checked)}
            onKeyDown={(event): void => {
              handleOnKeyDown(event)
            }}
          />
        ) : (
          <input
            id={value}
            type="radio"
            value={value}
            name={name}
            checked={checked}
            onClick={(): void => onChange(true)}
            onChange={(event): void => onChange(event.target.checked)}
            onKeyDown={(event): void => {
              handleOnKeyDown(event)
            }}
          />
        )}
      </VisuallyHidden>
      {label}
    </Label>
  )
}

export default ChipInput
