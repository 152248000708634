import type { Account } from '../../common/types'
import { getActiveProductFromPath } from '../../common/utils/getProduct'
import { getOrganization, isAtPlanChannelLimit } from '../../common/utils/user'
import { getCommonTrackingPropertiesFromAccount } from '../../tracking/utils'
import type {
  TrialPathsTrackingPayload,
  TrackingTrialPathsEventData,
} from './TrialPathsTrackingTypes'

export function createEventDataStructureForTrialPath({
  user,
  payload,
  defaultUpgradePathName,
}: {
  user: Account
  payload: TrialPathsTrackingPayload
  defaultUpgradePathName: string
}): TrackingTrialPathsEventData {
  const { cta, ctaView, ctaLocation, ctaButton, ctaVersion, upgradePathName } =
    payload

  const version = ctaVersion || '1'

  const organization = getOrganization(user)
  const product = getActiveProductFromPath()
  const compoundCta =
    cta || `${product}-${ctaView}-${ctaLocation}-${ctaButton}-${version}`

  const isAtPlanLimit = isAtPlanChannelLimit(user)

  const commonTrackingProperties = getCommonTrackingPropertiesFromAccount(user)

  const eventData: TrackingTrialPathsEventData = {
    organizationId: organization?.id || null,
    cta: compoundCta,
    upgradePathName: upgradePathName || defaultUpgradePathName,
    clientName: 'publishWeb',
    product,
    isAtPlanLimit,
    ...commonTrackingProperties,
  }

  return eventData
}
