export enum Service {
  facebook = 'facebook',
  instagram = 'instagram',
  instagram_login_api = 'instagram_login_api',
  linkedin = 'linkedin',
  pinterest = 'pinterest',
  shopify = 'shopify',
  twitter = 'twitter',
  tiktok = 'tiktok',
  googlebusiness = 'googlebusiness',
  mastodon = 'mastodon',
  youtube = 'youtube',
  threads = 'threads',
  bluesky = 'bluesky',
}

export enum InstagramAuthMethod {
  instagramLogin = 'instagramLogin',
  facebookLogin = 'facebookLogin',
  notificationsOnly = 'notificationsOnly',
}

export type AuthMethods = {
  [Service.instagram]: InstagramAuthMethod
  [Service.instagram_login_api]: InstagramAuthMethod.instagramLogin
  [Service.shopify]: never
  [Service.mastodon]: never
  [Service.facebook]: never
  [Service.twitter]: never
  [Service.linkedin]: never
  [Service.pinterest]: never
  [Service.googlebusiness]: never
  [Service.youtube]: never
  [Service.tiktok]: never
  [Service.threads]: never
  [Service.bluesky]: never
}

export enum ChannelAccountType {
  page = 'page',
  group = 'group',
  profile = 'profile',
}

export type onRedirectParams = {
  redirectUrl: string
  authMethod?: InstagramAuthMethod
}
