import type { Account } from '../types'
import React from 'react'

export const UserContext = React.createContext({
  name: '...',
  email: '...',
  hasVerifiedEmail: false,
  helpScoutBeaconSignature: null,
  products: [],
  featureFlips: [],
  organizations: [],
  currentOrganization: {},
  isImpersonation: false,
  isAutomaticTaxEnabled: null,
} as Account)

export function useUser(): Account {
  const user = React.useContext(UserContext)
  if (user === undefined) {
    throw new Error('useUser must be used within a UserContext.Provider')
  }

  return user
}
