import eventDispatcher from '../utils/eventDispatcher'
import { AppshellEventKeys, OrganizationActionKeys } from './types'

export const ACCOUNT_ACTIONS = {
  refetchAccount(): void {
    eventDispatcher(AppshellEventKeys.ACCOUNT_EVENT_KEY, {
      action: OrganizationActionKeys.refetchAccount,
    })
  },
}

export type ACCOUNT_ACTIONS_TYPE = typeof ACCOUNT_ACTIONS
