import {
  BufferTrackerReact as BufferTracker,
  Client,
} from '@buffer-mono/tracking-plan'
import type { InstagramAuthMethod, Service } from './types'
import type { Account } from '../../../common/types'
import { getActiveProductFromPath } from '../../../common/utils/getProduct'
import {
  getUsersCurrentPlan,
  isAtPlanChannelLimit,
} from '../../../common/utils/user'
import {
  getCommonTrackingPropertiesFromAccount,
  setApiVersion,
} from '../../../tracking/utils'
import trackChannelConnectionStarted from '../../../tracking/trackChannelConnectionStarted'

export enum TrackingChannelEvent {
  connection = 'connection',
  refresh = 'refresh',
}

export function trackProfileTypeSelectionViewed({
  account,
  service,
}: {
  account: Account
  service: Service
}): void {
  const commonTrackingProperties =
    getCommonTrackingPropertiesFromAccount(account)
  const organizationId = account?.currentOrganization?.id || ''
  const currentChannelQuantity = account?.channels?.length
  const isAtChannelLimit = isAtPlanChannelLimit(account)
  const product = getActiveProductFromPath()

  return BufferTracker.channelConnectionProfileTypeSelectionViewed({
    organizationId,
    channel: service,
    currentChannelQuantity,
    product,
    isAtPlanLimit: isAtChannelLimit,
    clientName: Client.PublishWeb,
    ...commonTrackingProperties,
  })
}
export function trackChannelConnectionAborted({
  account,
  service,
}: {
  account: Account
  service: Service
}): void {
  const organizationId = account?.currentOrganization?.id || ''
  const product = getActiveProductFromPath()

  return BufferTracker.channelConnectionAborted({
    organizationId,
    channel: service,
    product,
    clientName: Client.PublishWeb,
    apiVersion: setApiVersion(service),
  })
}

export function trackChannelRefreshStarted({
  account,
  service,
}: {
  account: Account
  service: Service
}): void {
  const commonTrackingProperties =
    getCommonTrackingPropertiesFromAccount(account)
  const organizationId = account?.currentOrganization?.id || ''
  const product = getActiveProductFromPath()
  const currentChannelQuantity = account?.channels?.length
  const isAtChannelLimit = isAtPlanChannelLimit(account)

  return BufferTracker.channelRefreshStarted({
    organizationId,
    channel: service,
    product,
    currentChannelQuantity,
    clientName: Client.PublishWeb,
    isAtPlanLimit: isAtChannelLimit,
    apiVersion: setApiVersion(service),
    ...commonTrackingProperties,
  })
}

export function triggerTrackingEventBasedOnAction({
  account,
  event,
  service,
  cta,
  authMethod,
}: {
  account: Account
  event: TrackingChannelEvent
  service: Service
  cta?: string
  authMethod?: InstagramAuthMethod
}): void {
  // Track Channel Conenction Started
  if (event === TrackingChannelEvent.connection) {
    trackChannelConnectionStarted({
      payload: { service, cta, authMethod },
      user: account,
    })
  }

  // Track Channel Refresh Started
  if (event === TrackingChannelEvent.refresh) {
    trackChannelRefreshStarted({
      account,
      service,
    })
  }
}

export function trackCustomChannelSetupViewed({
  account,
  service,
}: {
  account: Account
  service: Service
}): void {
  const commonTrackingProperties =
    getCommonTrackingPropertiesFromAccount(account)
  const organizationId = account?.currentOrganization?.id || ''
  const currentChannelQuantity = account?.channels?.length
  const isAtChannelLimit = isAtPlanChannelLimit(account)
  const product = getActiveProductFromPath()

  return BufferTracker.customChannelSetupViewed({
    organizationId,
    channel: service,
    currentChannelQuantity,
    product,
    isAtPlanLimit: isAtChannelLimit,
    clientName: Client.PublishWeb,
    ...commonTrackingProperties,
  })
}

export function trackChannelConnectionModalViewed({
  account,
  isRefreshing = false,
}: {
  account: Account
  isRefreshing?: boolean
}): void {
  const organizationId = account?.currentOrganization?.id || ''
  const currentPlanData = getUsersCurrentPlan(account)
  const product = getActiveProductFromPath()

  const version = '1'
  const cta = `${product}-connectionCelebrationModal-connectionCelebrationModal-connectChannel-${version}`

  const eventData = {
    organizationId,
    currentPlan: currentPlanData?.id || '',
    cta,
    ctaApp: product,
    clientName: Client.PublishWeb,
    product,
  }

  isRefreshing
    ? BufferTracker.channelRefreshSuccessModalViewed(eventData)
    : BufferTracker.channelConnectionOnboardingModalViewed(eventData)
}
