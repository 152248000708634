import React from 'react'
import Text from '@bufferapp/ui/Text'
import Link from '@bufferapp/ui/Link'
import type { GetCopyArgs, SuccessModalContentData } from './types'

function capitalizeFirstLetter(word: string | undefined): string {
  if (!word) return ''
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const SUCCESS_CTA = "Great, Let's Go!"
const getCopy = ({
  planName,
  startedTrial,
  stayedOnSamePlan,
  isMigrationFromMP,
  isDowngradingToFreePlan,
  planEndDate,
  organizationId,
  env,
  scheduledUpdate,
  currentPlanId,
  currentPlanChannelQuantity,
}: GetCopyArgs): SuccessModalContentData => {
  // TODO: This function should get simplified
  // There are too many conditions/return statements in this file
  // Also the default return statement is not generic but instead specific to a scenario

  if (scheduledUpdate) {
    const formattedDate = new Date(
      scheduledUpdate.effectiveDate,
    ).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
    return {
      label: 'Your plan change is confirmed',
      description: `You’ve successfully changed to the ${
        scheduledUpdate.newPlan
      } Plan with ${
        scheduledUpdate.newQuantity
      } channels. Your plan change will take effect on ${formattedDate}. You'll have access to your ${capitalizeFirstLetter(
        currentPlanId,
      )} Plan with ${currentPlanChannelQuantity} channels until then.`,
      buttonCopy: SUCCESS_CTA,
      imageUrl: 'https://buffer-ui.s3.amazonaws.com/illustration-celebrate.png',
    }
  }

  if (isMigrationFromMP) {
    return {
      label: 'Congratulations & Welcome!',
      description: `You’ve upgraded to the latest version of Buffer! Start using your ${planName} plan features. `,
      buttonCopy: "Let's Go!",
      imageUrl: 'https://buffer-ui.s3.amazonaws.com/illustration-celebrate.png',
    }
  }

  if (startedTrial) {
    return {
      label: "Your trial's begun 🎉 time to explore.",
      description:
        'Let’s make the most of your 14-day trial. Jump in and start exploring, here are some key features you can start with:',
      featuresList: [
        {
          label: 'Review audience & post analytics',
          link: `https://analyze.${env}buffer.com/`,
          cta: 'trialConfirmationModal-analytics-1',
        },
        {
          label: 'Engage with Facebook & Instagram comments',
          link: `https://engage.${env}buffer.com/`,
          cta: 'trialConfirmationModal-engagement-1',
        },
        {
          label: 'Invite a team member or client',
          link: `https://${env}buffer.com/manage/${organizationId}/team-members`,
          cta: 'trialConfirmationModal-inviteTeamMember-1',
        },
        {
          label: 'Create some more posts',
          link: `https://publish.${env}buffer.com/`,
          cta: 'trialConfirmationModal-createPost-1',
        },
      ],
      imageUrl:
        'https://buffer-ui.s3.amazonaws.com/upgrade-paths/start-trial/trial-prompt-modal.jpg',
    }
  }

  if (isDowngradingToFreePlan) {
    return {
      label: 'Sorry to see you go',
      description: `You’ve chosen to downgrade to our Free plan. You will continue to have access to paid features until ${planEndDate}`,
      buttonCopy: SUCCESS_CTA,
      imageUrl: 'https://buffer-ui.s3.amazonaws.com/illustration-celebrate.png',
    }
  }

  if (planName === 'Free') {
    return {
      label: 'Congrats! You are now on the Free plan',
      description: `You've successfully changed your plan. Start using your Free plan today.`,
      buttonCopy: SUCCESS_CTA,
      imageUrl: 'https://buffer-ui.s3.amazonaws.com/illustration-celebrate.png',
    }
  }

  if (planName && !stayedOnSamePlan) {
    return {
      label: `Congrats! Welcome to the ${planName} plan`,
      description: `You've successfully saved your payment details! Start using your ${planName} plan features.`,
      buttonCopy: SUCCESS_CTA,
      imageUrl: 'https://buffer-ui.s3.amazonaws.com/illustration-celebrate.png',
      footer: (
        <Text type="p">
          You can always access your invoices and billing information{' '}
          <Link href="https://account.buffer.com/billing">here</Link>.
        </Text>
      ),
    }
  }

  // Just quantity change
  return {
    label: 'You’re all set!',
    description:
      'You have successfully adjusted the channel count for your plan.',
    buttonCopy: 'Great! Now Take Me Back',
    imageUrl: 'https://buffer-ui.s3.amazonaws.com/illustration-highfive.png',
    footer: (
      <Text type="p">
        You can always access your invoices and billing information{' '}
        <Link href="https://account.buffer.com/billing">here</Link>.
      </Text>
    ),
  }
}

export default getCopy
