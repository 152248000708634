import type { Channel } from '../../common/types'
import type { ActionImpl } from 'kbar'

export interface Action extends ActionImpl {
  channel?: Channel
}

export type KBarItem = Action | string

abstract class LauncherItem {
  abstract get id(): string
  abstract get name(): string
  abstract get isParent(): boolean
  abstract get isHeading(): boolean
  abstract get isActive(): boolean
  abstract get isChannel(): boolean
  abstract get channel(): Channel | undefined
  abstract get shortcuts(): string[]
}

export class ItemFactory {
  static new(action: KBarItem, active = false): LauncherItem {
    if (typeof action === 'string') {
      return new LauncherHeading(action)
    }
    return new LauncherOption(action, active)
  }
}

class LauncherHeading implements LauncherItem {
  private action: string
  constructor(action: string) {
    this.action = action
  }

  public get isParent(): boolean {
    return false
  }

  public get isActive(): boolean {
    return false
  }

  public get isHeading(): boolean {
    return true
  }

  public get id(): string {
    return this.action
  }

  public get name(): string {
    return this.action
  }

  public get isChannel(): boolean {
    return false
  }

  public get channel(): undefined {
    return undefined
  }

  public get shortcuts(): string[] {
    return []
  }
}

class LauncherOption implements LauncherItem {
  private action: Action
  private active: boolean

  constructor(action: Action, active = false) {
    this.action = action
    this.active = active
  }

  public get id(): string {
    return this.action.id
  }

  public get name(): string {
    return this.action.name
  }

  public get isParent(): boolean {
    return this.action?.children.length > 0
  }

  public get isActive(): boolean {
    return this.active
  }

  public get isHeading(): boolean {
    return false
  }

  public get isChannel(): boolean {
    return this.action.channel !== undefined
  }

  public get channel(): Channel | undefined {
    return this.action.channel
  }

  public get shortcuts(): string[] {
    return this.action.shortcut || []
  }
}

type RootActionId = string | undefined | null
export class LauncherResults {
  private results: KBarItem[]
  constructor(results: KBarItem[]) {
    this.results = results
  }

  public get(index: number): LauncherItem {
    return ItemFactory.new(this.results[index])
  }

  private addTrackingToActions(
    actions: KBarItem[],
    onActionSelected: (a: KBarItem) => Promise<void>,
  ): KBarItem[] {
    const newActions = actions.map((a: KBarItem): KBarItem => {
      if (typeof a !== 'string') {
        return {
          ...a,
          command: {
            perform: (): void => {
              onActionSelected(a).finally(() => {
                a.command?.perform()
              })
            },
          },
        } as unknown as KBarItem
      }
      return a
    })
    return newActions
  }

  public toKBarItems(
    rootActionId: RootActionId,
    onActionSelected: (a: KBarItem) => Promise<void>,
  ): KBarItem[] {
    if (!rootActionId) {
      const rootActionsOnly = this.results.filter((result) => {
        return typeof result === 'string' || result.ancestors.length === 0
      })
      const areAllHeaders = rootActionsOnly.every(
        (action) => typeof action === 'string',
      )
      return areAllHeaders
        ? []
        : this.addTrackingToActions(rootActionsOnly, onActionSelected)
    }
    return this.addTrackingToActions(this.results, onActionSelected)
  }
}
