import type {
  Billing,
  ChannelSlotDetails,
  Plan,
  PlanInterval,
  PricingTier,
  Subscription,
} from '../types'

export function getSubscriptionData(
  billingData: Billing,
): Subscription | undefined {
  return billingData.subscription
}

export function getSubscriptionPlanData(
  billingData?: Billing,
): Plan | undefined {
  return billingData?.subscription?.plan
}

export function getSubscriptionPlanId(
  billingData: Billing,
): string | undefined {
  return billingData?.subscription?.plan?.id
}

export function getSubscriptionInterval(
  billingData?: Billing,
): PlanInterval | undefined {
  return billingData?.subscription?.interval
}

export function getSubscriptionPeriodEnd(billingData: Billing): string | null {
  return billingData?.subscription?.periodEnd || null
}

export function getBillingChannelSlotDetails(
  billingData?: Billing,
): ChannelSlotDetails | undefined {
  return billingData?.channelSlotDetails
}

export function getPricingTiers(
  billingData: Billing,
): Array<PricingTier> | undefined {
  return billingData?.pricingTiers
}

export function isOnNonAgencyTieredPricing(billingData?: Billing): boolean {
  if (!billingData || !billingData.pricingTiers) {
    return false
  }
  // Non-agency tiered plans are the only ones with more than 2 pricing tiers
  return billingData.pricingTiers.length > 2
}
