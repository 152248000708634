import styled, { type FlattenSimpleInterpolation, css } from 'styled-components'
import { blue, gray, grayDarker } from '@bufferapp/ui/style/colors'

export const CTAContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  gap: 1rem;
  flex-direction: row;
  justify-content: right;
`

export const FieldSet = styled.div`
  width: 100%;
  border: none;

  legend {
    display: none;
  }
`

export const ListItem = styled.li<{
  isDisabled: boolean
}>`
  display: flex;
  flex-direction: row;
  margin: 14px 0;
  width: 100%;

  list-style: none;

  opacity: ${({ isDisabled }): number => (isDisabled ? 0.5 : 1)};
  cursor: ${({ isDisabled }): string => (isDisabled ? 'default' : 'pointer')};

  label,
  input {
    cursor: ${({ isDisabled }): string => (isDisabled ? 'default' : 'pointer')};
  }
`

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
`

export const Selection = styled.span`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const TextGroup = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;

  span {
    font-size: 14px;
    color: ${grayDarker};
  }
`

export const RadioWrapper = styled.span`
  display: flex;

  input {
    appearance: none;
    font: inherit;
    color: ${gray};
    width: 16px;
    height: 16px;
    border: 0.15em solid ${gray};
    border-radius: 100px;
    display: grid;
    place-content: center;

    &::before {
      box-sizing: border-box;
      margin: 0;
      padding: 0;

      content: '';
      width: 16px;
      height: 16px;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      border: 6px solid ${blue};
    }

    :checked::before {
      transform: scale(1);
    }
  }
`

export const InputWrapper = styled.div<{
  isVisible: boolean
  hasError: boolean
}>`
  width: 100%;
  position: relative;

  input[type='text'] {
    margin: 0 !important;
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
    ${(props): false | FlattenSimpleInterpolation =>
      props.isVisible &&
      css`
        opacity: 1;
        visibility: visible;
      `}
  }

  div[class*='HelpTextWrapper'] {
    position: absolute;
    padding-top: 4px;
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
    display: none;
    ${(props): false | FlattenSimpleInterpolation =>
      props.isVisible &&
      props.hasError &&
      css`
        display: block;
        opacity: 1;
        visibility: visible;
      `}
  }
`

export const FollowBufferWrapper = styled(FieldSet)`
  display: flex;
  padding: 16px 16px 0;

  font-size: 14px;
  color: ${grayDarker};

  label {
    cursor: pointer;
  }
`
