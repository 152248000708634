import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

export const trackButtonClick = (
  buttonName: string,
  organizationId: string | null,
  placement?: string,
  clientName = 'publishWeb',
): void => {
  BufferTracker.remindersSetupButtonClicked({
    clientName,
    organizationId,
    buttonName,
    placement,
  })
}

type StepViewedProps = {
  stepNumber: number
  organizationId: string | null
  placement?: string
  clientName: string
}

export const trackStepViewed = ({
  stepNumber,
  organizationId,
  placement,
  clientName = 'appShell',
}: StepViewedProps): void => {
  BufferTracker.remindersSetupStepViewed({
    clientName,
    organizationId,
    stepNumber,
    placement,
  })
}
