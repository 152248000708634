import styled, { css, type FlattenSimpleInterpolation } from 'styled-components'
import { fontSizeSmall } from '@bufferapp/ui/style/fonts'

interface ITitle {
  isInline: boolean
}

export const ChannelsContainer = styled.div`
  display: flex;
  flex-direction: column;

  font-size: ${fontSizeSmall};
`

export const Title = styled.div<ITitle>`
  display: flex;
  flex-direction: ${(props): string => (props.isInline ? 'row' : 'column')};
  margin-bottom: 20px;
  justify-content: space-between;
  width: 100%;
`

interface IIcons {
  isInline: boolean
}

export const Icons = styled.div<IIcons>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  gap: 6px;

  ${(props): false | FlattenSimpleInterpolation =>
    props.isInline &&
    css`
      margin-top: 0;
      margin-left: 6px;
    `}

  svg {
    &:last-of-type {
      margin-right: 0;
    }
  }
`

export const ChannelsInputContainer = styled.div`
  width: 140px;
`
