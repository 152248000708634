import React, { useEffect } from 'react'

import {
  Badge,
  Button,
  Card,
  Dialog,
  List,
  Heading,
  Strong,
  Text,
} from '@buffer-mono/popcorn'

import styles from '../../InstagramSelectAccountType.module.css'

import useAuthorizationInfo from '../../../../hooks/useAuthorizationInfo/useAuthorizationInfo'
import {
  InstagramAuthMethod,
  type onRedirectParams,
  Service,
} from '../../../../types'

export function ProfessionalCard({
  onRedirect,
}: {
  onRedirect: (args: onRedirectParams) => void
}): JSX.Element {
  const { handleGetAuthorizationInfo, url, isAuthorizationInfoLoading } =
    useAuthorizationInfo()

  useEffect(() => {
    handleGetAuthorizationInfo({
      service: Service.instagram,
      authMethod: InstagramAuthMethod.instagramLogin,
      isRefreshingConnection: false,
    })
  }, [handleGetAuthorizationInfo])

  return (
    <Card className={styles.card}>
      <Heading className={styles.heading}>
        Professional{' '}
        <Badge className={styles.badge} size="xsmall">
          Recommended
        </Badge>
      </Heading>
      <Text>Business or Creator Accounts</Text>
      <List>
        <List.Item>
          <Text>
            Notification-based publishing: Receive a mobile notification to post
            yourself
          </Text>
        </List.Item>
        <List.Item>
          <Text>
            <span className={styles.underlinedText}>
              Automatic publishing
              <svg
                width="117"
                height="4"
                viewBox="0 0 117 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.underline}
              >
                <path
                  d="M116 2.05613C77.5486 0.610412 39.3129 0.405243 1 3"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </span>
            : Schedule and we&apos;ll publish for you
          </Text>
        </List.Item>
        <List.Item>
          <Text>Analytics & engagement (paid plans)</Text>
        </List.Item>
      </List>

      <Button
        onClick={(): void => {
          if (url) {
            onRedirect({
              redirectUrl: url,
              authMethod: InstagramAuthMethod.instagramLogin,
            })
          }
        }}
        disabled={isAuthorizationInfoLoading}
        loading={isAuthorizationInfoLoading}
        size="large"
        variant="primary"
        className={styles.action}
      >
        Connect to Instagram
      </Button>
      <Dialog.Separator className={styles.separator} />
      <Text>
        <svg
          className={styles.warningEmoji}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 36 36"
        >
          <path
            fill="#FFCC4D"
            d="M2.653 35C.811 35-.001 33.662.847 32.027L16.456 1.972c.849-1.635 2.238-1.635 3.087 0l15.609 30.056c.85 1.634.037 2.972-1.805 2.972H2.653z"
          />
          <path
            fill="#231F20"
            d="M15.583 28.953c0-1.333 1.085-2.418 2.419-2.418 1.333 0 2.418 1.085 2.418 2.418 0 1.334-1.086 2.419-2.418 2.419-1.334 0-2.419-1.085-2.419-2.419zm.186-18.293c0-1.302.961-2.108 2.232-2.108 1.241 0 2.233.837 2.233 2.108v11.938c0 1.271-.992 2.108-2.233 2.108-1.271 0-2.232-.807-2.232-2.108V10.66z"
          />
        </svg>{' '}
        If your account type isn&apos;t Professional yet,{' '}
        <Strong>Instagram will prompt you to change it</Strong> with a few
        simple steps.
      </Text>
    </Card>
  )
}
