import {
  BufferTrackerReact as BufferTracker,
  Client,
} from '@buffer-mono/tracking-plan'

import type { Account } from '../../common/types'
import {
  getCommonTrackingPropertiesFromAccount,
  shouldPerformTrackingEvent,
} from '../utils'
import { getActiveProductFromPath } from '../../common/utils/getProduct'
import { getUsersCurrentPlan } from '../../common/utils/user'

export type TrackChannelStorefrontViewedPayload = {
  cta?: string
}

export type TrackChannelStorefrontViewedArguments = {
  payload: TrackChannelStorefrontViewedPayload
  user: Account
}

export default function trackChannelStorefrontViewedViewed({
  payload,
  user,
}: TrackChannelStorefrontViewedArguments): void {
  const canPerformTrackingEvent = shouldPerformTrackingEvent({
    user,
  })

  if (!canPerformTrackingEvent) {
    return
  }

  const { cta } = payload

  const organization = user.currentOrganization
  const product = getActiveProductFromPath()
  const currentPlanData = getUsersCurrentPlan(user)

  const commonTrackingProperties = getCommonTrackingPropertiesFromAccount(user)

  const eventData = {
    organizationId: organization?.id || '',
    currentPlan: currentPlanData?.id || '',
    cta,
    clientName: Client.PublishWeb,
    product,
    ...commonTrackingProperties,
  }

  BufferTracker.channelStorefrontViewed(eventData)
}
