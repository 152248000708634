import React from 'react'

import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'
import type { ErrorState } from '../../hooks/Subscription/types'

import { StyledError, ErrorContainer } from './style'

type ErrorProps = {
  isInline?: boolean
  error: ErrorState
}

export const Error = ({ isInline = false, error }: ErrorProps): JSX.Element => (
  <>
    {error && (
      <ErrorContainer>
        <StyledError isInline={isInline} aria-live="polite">
          <WarningIcon />
          {error.message}
        </StyledError>
      </ErrorContainer>
    )}
  </>
)
