import React from 'react'

import { Button, Card, Heading, Text, List } from '@buffer-mono/popcorn'

import styles from '../../InstagramSelectAccountType.module.css'

export function PersonalProfileCard({
  onPersonalSelect,
}: {
  onPersonalSelect: () => void
}): JSX.Element {
  return (
    <Card className={styles.card}>
      <Heading className={styles.heading}>Personal Profile</Heading>
      <Text>Most used to share to family and friends</Text>
      <List>
        <List.Item>
          <Text>
            Notification-based publishing: Receive a mobile notification to post
            yourself
          </Text>
        </List.Item>
      </List>

      <Button
        onClick={(): void => onPersonalSelect()}
        size="large"
        variant="secondary"
        className={styles.action}
      >
        Connect a Personal Account
      </Button>
    </Card>
  )
}
