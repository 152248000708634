import type { RenderComponentParams } from '../../../common/events/types'

type ActionReturnType = {
  type: string
  payload: RenderComponentParams
}

export const showComponent = ({
  componentKey,
  containerId,
  options,
}: RenderComponentParams): ActionReturnType => ({
  type: 'SHOW_COMPONENT',
  payload: { componentKey, containerId, options },
})
