import { MODAL_ACTIONS } from '../../../common/events/modalEvents'
import { ORCHESTRATOR_ACTIONS } from '../../../common/events/orchestratorEvents'
import { MODALS } from '../../../common/types'
import { Service } from '../../Orchestrator/channelConnections/types'

export function triggerChannelConnectBasedOnUrl(): void {
  const { hash } = window.location
  if (hash.startsWith('#connectChannel=')) {
    const channel = hash.replace('#connectChannel=', '')
    if (Object.keys(Service).includes(channel as Service)) {
      ORCHESTRATOR_ACTIONS.connectChannel({
        selectedService: channel as Service,
        cta: 'directLink-directLink-directLink-1',
      })
    }
  }
}

export const openModalFromUrl = () => {
  const { hash } = window.location
  const matchingModal = Object.keys(MODALS).find(
    (k) => k === hash.replace('#', ''),
  )

  if (matchingModal) {
    const modalKey = matchingModal
    const modalData = {
      cta: 'directLink-directLink-directLink-1',
      upgradePathName: 'directLink-upgrade',
    }

    MODAL_ACTIONS.openModal(modalKey, modalData)
  }
}

export const handleOrchestratorActionsOnload = () => {
  triggerChannelConnectBasedOnUrl()
  openModalFromUrl()
}
