import type { PricingTier } from '../../../../common/types'

export const calculateTieredPrice = (args: {
  channelCount: number
  pricingTiers: Array<PricingTier>
}): number => {
  const { channelCount, pricingTiers } = args

  if (pricingTiers.length === 0) {
    throw new Error('No pricing tiers found')
  }

  let price = 0

  // Find the maximum tier reached by the channel count
  const maxTierReachedIndex = pricingTiers.findIndex(
    (tier) => tier.upperBound >= channelCount,
  )
  const maxTier = pricingTiers[maxTierReachedIndex]

  // We only reached the first tier, so the calculation is simple
  if (maxTierReachedIndex === 0) {
    price = maxTier.flatFee + maxTier.unitPrice * channelCount
    return price
  }

  // Calculate price at the maximum tier reached
  const previousTier = pricingTiers[maxTierReachedIndex - 1]
  const quantityAtMaxTier = channelCount - previousTier.upperBound

  price += maxTier.flatFee + maxTier.unitPrice * quantityAtMaxTier

  // Calculate prices for all remaining tiers
  for (let i = 0; i < maxTierReachedIndex; i++) {
    const tier = pricingTiers[i]
    // If this is the first tier, calculate price for the entire tier
    if (i === 0) {
      price += tier.flatFee + tier.unitPrice * tier.upperBound
      // Otherwise, calculate price for the quantity difference between this tier and the previous tier
    } else {
      price +=
        tier.flatFee +
        tier.unitPrice * (tier.upperBound - pricingTiers[i - 1].upperBound)
    }
  }

  return price
}
