import React from 'react'
import { type AuthMethods, InstagramAuthMethod, Service } from '../../types'
import upperFirst from 'lodash/upperFirst'

import {
  Button,
  Heading,
  Image,
  List,
  Text,
  UpgradeDialog,
} from '@buffer-mono/popcorn'

export type PaywallService = Extract<
  Service,
  | Service.threads
  | Service.mastodon
  | Service.youtube
  | Service.bluesky
  | Service.tiktok
  | Service.instagram
>

const imageMap: Record<PaywallService, string> = {
  [Service.threads]:
    'https://buffer-publish.s3.amazonaws.com/images/upgrade-dialog-threads.webp',
  [Service.mastodon]:
    'https://buffer-publish.s3.amazonaws.com/images/upgrade-dialog-mastodon.webp',
  [Service.youtube]:
    'https://buffer-publish.s3.amazonaws.com/images/upgrade-dialog-youtube.webp',
  [Service.bluesky]:
    'https://buffer-publish.s3.amazonaws.com/images/upgrade-dialog-bluesky.webp',
  [Service.tiktok]:
    'https://buffer-publish.s3.amazonaws.com/images/upgrade-dialog-tiktok.webp',
  [Service.instagram]:
    'https://buffer-publish.s3.amazonaws.com/images/upgrade-dialog-instagram.webp',
}

function getDisplayName(
  service: PaywallService,
  authMethod?: AuthMethods[PaywallService],
): string {
  if (
    service === Service.instagram &&
    authMethod === InstagramAuthMethod.notificationsOnly
  ) {
    return 'Instagram Personal Accounts'
  }

  return upperFirst(service)
}

const PaywallPromptThreads = ({
  service,
  authMethod,
  handleOnClick,
  closeModal,
}: {
  service: PaywallService
  authMethod?: AuthMethods[PaywallService]
  handleOnClick: () => void
  closeModal: () => void
}): React.JSX.Element => {
  const displayName = getDisplayName(service, authMethod)

  return (
    <UpgradeDialog
      open
      onOpenChange={(): void => {
        closeModal()
      }}
    >
      <UpgradeDialog.Content>
        <UpgradeDialog.TextContent>
          <Heading size="medium">Unlock {displayName}</Heading>
          <Text as="p">
            You&apos;re on a <Text weight="bold">legacy Buffer plan</Text>.
            Upgrade to the new Buffer to unlock {displayName}, plus several
            other features:
          </Text>
          <List>
            <List.Item>No charge for channels you don&apos;t use</List.Item>
            <List.Item>New channels like Mastodon and YouTube</List.Item>
            <List.Item>Analytics access on all plans</List.Item>
            <List.Item>Capture and organize your content ideas</List.Item>
            <List.Item>Unlimited team members (on Team plan)</List.Item>
            <List.Item>Guaranteed access to all new features</List.Item>
            <List.Item>Access to Buffer Open Beta Program</List.Item>
          </List>

          <Button size="large" variant="primary" onClick={handleOnClick}>
            See Upgrade Options
          </Button>
        </UpgradeDialog.TextContent>
        <UpgradeDialog.Image>
          <Image
            alt={`${displayName} upgrade dialog`}
            src={imageMap[service]}
          />
        </UpgradeDialog.Image>
      </UpgradeDialog.Content>
    </UpgradeDialog>
  )
}

export default PaywallPromptThreads
