import { getActiveProductFromPath } from './getProduct'

/**
 * Prefixes the product name to the CTA string if it is not already prefixed with it
 * @param {string} cta cta to prefix with the product name.
 * @return {string} cta with the product name prefixed.
 */
export function prefixWithProduct(cta: string | undefined): string {
  const activeProduct = getActiveProductFromPath()

  if (!cta) {
    return `${activeProduct}`
  }
  // Check if 'cta' already starts with 'activeProduct'
  if (cta && cta.startsWith(activeProduct)) {
    return cta
  }

  return `${activeProduct}-${cta}`
}
