// This are the extensions we support, no need to have any other for now
//
// ___Where to take new extensions from?
//    https://github.com/jshttp/mime-db/blob/master/db.json
import { MediaType } from '../constants'
import { isSupportedMimeType } from './isSupportedMimeType'

export function getMediaTypeFromMime(mimeType: string | undefined): MediaType {
  if (!mimeType || !isSupportedMimeType(mimeType)) {
    return MediaType.unsupported
  }

  if (mimeType === 'image/gif') {
    return MediaType.gif
  }

  if (mimeType.startsWith('video')) {
    return MediaType.video
  }

  if (mimeType === 'application/pdf') {
    return MediaType.document
  }

  return MediaType.image
}
