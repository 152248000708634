export const maxChannelsAllowedPerPurchaseViaStripe = 8333

export function isValidChannelCountForPlan(
  planId: any,
  channelsCount: any,
  minimumQuantity: any,
) {
  if (channelsCount >= maxChannelsAllowedPerPurchaseViaStripe) {
    console.warn(
      'isValidChannelCountForPlan: The chosen channel quantity is too large for Stripe to process',
    )
  }

  return (
    channelsCount >= minimumQuantity &&
    channelsCount < maxChannelsAllowedPerPurchaseViaStripe
  )
}
