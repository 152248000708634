import React, { useMemo } from 'react'

import { Header } from '../Header/Header'
import { Footer } from '../Footer/Footer'
import {
  Button,
  ChevronDownIcon,
  Dialog,
  DropdownMenu,
  Flex,
  Heading,
  Text,
} from '@buffer-mono/popcorn'

import styles from './Notifications.module.css'
import { useQuery } from '@apollo/client'
import { QUERY_ACCOUNT } from '../../../../../../../common/graphql/account'
import { MODAL_ACTIONS } from '../../../../../../../common/events/modalEvents'
import { MODALS } from '../../../../../../../common/types'

import instagramSelectAccountTypeStyles from '../../InstagramSelectAccountType.module.css'
import footerStyles from '../Footer/Footer.module.css'
import clsx from 'clsx'

function NotificationCard({
  title,
  description,
  imageClassName,
}: {
  title: string
  description: string
  imageClassName: string
}): JSX.Element {
  return (
    <Flex direction="column" gap="md" className={styles.card}>
      <div className={imageClassName} title="notification steps example" />
      <Text weight="bold">{title}</Text>
      <Text color="subtle">{description}</Text>
    </Flex>
  )
}

export function Notifications({
  onContinue,
}: {
  onContinue: () => void
}): JSX.Element {
  const { data } = useQuery(QUERY_ACCOUNT)

  const hasNotificationsSetUp = useMemo(() => {
    if (data.account) {
      return data.account.currentOrganization.channels.some(
        (channel: { hasActiveMemberDevice: boolean }) =>
          channel.hasActiveMemberDevice,
      )
    }

    return false
  }, [data])

  return (
    <>
      <Header />
      <Dialog.Body className={instagramSelectAccountTypeStyles.body}>
        <div className={instagramSelectAccountTypeStyles.titleSubtitle}>
          <Heading
            size="large"
            align="center"
            className={instagramSelectAccountTypeStyles.heading}
          >
            Schedule Posts With Notifications
          </Heading>
          <Text>
            Buffer facilitates scheduling posts, reels and stories with mobile
            notifications, you just need to set it up on your phone. Here's how
            it works:
          </Text>
        </div>
        <Flex gap="lg" justify="center" className={styles.cards}>
          <NotificationCard
            imageClassName={clsx(styles.imageWrapper, styles.image1)}
            title="Create a post with 'Notify Me' On"
            description="We'll send a notification to the Buffer mobile app when it's time to post."
          />
          <NotificationCard
            imageClassName={clsx(styles.imageWrapper, styles.image2)}
            title="Open the Buffer App notification"
            description="Easily copy your post's content from the Buffer app to your social app."
          />
          <NotificationCard
            imageClassName={clsx(styles.imageWrapper, styles.image3)}
            title="Tap 'Open in...' and finish your post"
            description="Add any finishing touches using native social features, and publish your post."
          />
        </Flex>
      </Dialog.Body>

      <Footer>
        <DropdownMenu
          align="start"
          className={footerStyles.menu}
          trigger={
            <Button
              variant="tertiary"
              size="large"
              className={footerStyles.helpButton}
            >
              Help
              <ChevronDownIcon />
            </Button>
          }
        >
          <DropdownMenu.Item asChild>
            <a
              className={footerStyles.helpLink}
              href="https://support.buffer.com/article/658-using-notification-publishing#h_01H410D6B71NKQSJKVBAH2SBF6?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
              target="_blank"
              rel="noopener noreferrer"
            >
              Using Notification Publishing
            </a>
          </DropdownMenu.Item>
        </DropdownMenu>
        {hasNotificationsSetUp ? (
          <Button onClick={onContinue} size="large">
            Got It
          </Button>
        ) : (
          <Flex gap="xs">
            <Button onClick={onContinue} variant="tertiary" size="large">
              Remind Me Later
            </Button>
            <Button
              onClick={(): void => {
                MODAL_ACTIONS.openModal(MODALS.setupNotifications, {
                  onContinue,
                  onDismiss: onContinue,
                })
              }}
              size="large"
            >
              Set Up Notifications
            </Button>
          </Flex>
        )}
      </Footer>
    </>
  )
}
