import React from 'react'
import {
  Card,
  Flex,
  ChevronRightIcon,
  UnstyledButton,
  Text,
} from '@buffer-mono/popcorn'

import type { FeatureType } from '../types'
import { useUser } from '../../../../../common/context/User'
import UpgradePathsTracking from '../../../../../tracking/UpgradePaths'

import styles from '../Confirmation.module.css'

export const ConfirmationFeaturesList = ({
  featuresList,
}: {
  featuresList?: FeatureType[]
}): JSX.Element => {
  const user = useUser()
  const { trackCTAClicked } = UpgradePathsTracking(user)

  /**
   *  Tracks the CTA Clicked event and go to the feature link
   */
  const handleClickOnFeature = (feature: FeatureType): void => {
    trackCTAClicked({
      cta: feature.cta,
      upgradePathName: 'trialWelcomeModal',
    })

    window.location.assign(feature.link)
  }

  return (
    <Flex
      direction="column"
      gap="sm"
      align="stretch"
      justify="center"
      className={styles.featuresContainer}
      data-testid="confirmation-features-list"
    >
      {featuresList &&
        featuresList.map((feature: FeatureType, index: number) => (
          <Card key={index} className={styles.featureCard}>
            <UnstyledButton
              className={styles.cardButton}
              onClick={(): void => handleClickOnFeature(feature)}
            >
              <Text as="span">{feature.label}</Text>
              <ChevronRightIcon size="small" />
            </UnstyledButton>
          </Card>
        ))}
    </Flex>
  )
}
