export * from './FileUploadConfig'

export enum MediaType {
  image = 'image',
  video = 'video',
  gif = 'gif',
  document = 'document',
  link = 'link',
  unsupported = 'unsupported',
}

export enum UploadStatus {
  Added = 'Added',
  Uploading = 'Uploading',
  UploadedToS3 = 'UploadedToS3',
  Completed = 'Completed',
  Failed = 'Failed',
  Preloaded = 'Preloaded',
}
