import { useState } from 'react'
import { type ApolloError, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useUser } from '../context/User'
import { env } from '../../env'

export function getEnvironment(): string {
  const getEnvironment = (env: string): string =>
    ({
      development: 'local',
      staging: 'staging',
      production: 'production',
    }[env] || 'production') // Provide a default value of 'production' when env is undefined
  return getEnvironment(env.NODE_ENV)
}

export const getProductPort = (product: string): string => {
  if (product === 'publish') {
    return '8888'
  }
  if (product === 'analyze') {
    return '8081'
  }
  if (product === 'start-page') {
    return '9999'
  }

  return '8080'
}

export const getProductUrl = (product: string): string => {
  const environment = getEnvironment()

  if (environment === 'production') {
    return `https://${product}.buffer.com`
  } else if (environment === 'staging') {
    return `https://${product}.dev.buffer.com`
  } else {
    return `https://${product}.local.buffer.com:${getProductPort(product)}`
  }
}

export const CREATE_CUSTOMER_PORTAL_SESSION = gql`
  mutation createCustomerPortalSession(
    $organizationId: String!
    $returnURL: String!
  ) {
    billingCreateCustomerPortalSession(
      organizationId: $organizationId
      returnURL: $returnURL
    ) {
      ... on BillingCreateCustomerPortalSessionResponse {
        customerPortalSessionUrl
      }
      ... on BillingError {
        userFriendlyMessage
      }
    }
  }
`
export type UseGetPortalSessionResponse = {
  openBillingPortal: (ctaClicked: boolean) => void
  mutationError: ApolloError | undefined
  errorMessage: string | null
  loading: boolean
}

export default function (): UseGetPortalSessionResponse {
  const account = useUser()
  const productURL = getProductUrl('account')
  const openBillingPortal = (ctaClicked: boolean): void => {
    if (!ctaClicked) {
      return
    }
    getCustomerPortalSession({
      variables: {
        organizationId: account.currentOrganization?.id,
        returnURL: `${productURL}/billing`,
      },
    })
  }
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [getCustomerPortalSession, { error: mutationError, loading }] =
    useMutation(CREATE_CUSTOMER_PORTAL_SESSION, {
      onCompleted(data) {
        if (data?.billingCreateCustomerPortalSession.userFriendlyMessage) {
          setErrorMessage(
            data?.billingCreateCustomerPortalSession.userFriendlyMessage,
          )
        } else {
          window.open(
            `${data.billingCreateCustomerPortalSession.customerPortalSessionUrl}/customer/update`,
            '_self',
          )
          setErrorMessage(null)
        }
      },
      onError(e) {
        setErrorMessage(
          `Whoops! Looks like there was a problem processing your request. Please try again. If you continue experiencing this problem, please contact us.`,
        )
        throw new Error(
          `There was a problem fetching the customer portal session: ${e.message}.`,
        )
      },
    })

  return {
    openBillingPortal,
    mutationError,
    errorMessage,
    loading,
  }
}
