import React, { useEffect } from 'react'
import { toast } from '@buffer-mono/popcorn'

import { useUser } from '../../common/context/User'
import useChannels from '../Modal/hooks/useChannels'
import { usePostCounts } from '../Modal/hooks/usePostCounts'
import {
  cleanupUrlParams,
  shouldPrefillComposerDataAfterConnection,
  shouldShowChannelConnectionPrompt,
  shouldShowDownloadAppModal,
  shouldShowFreeUserStartTrialPrompt,
  shouldShowPaywallModal,
  shouldShowTeamMemberDowngradeModal,
  shouldShowTrialExpiredModal,
  shouldSkipCelebrationModals,
  shouldTriggerChannelOnboardingModalFromUrl,
  shouldTriggerChannelRefreshModalFromUrl,
  shouldTriggerSuccessModalFromUrl,
  userHasCreatedAPostEver,
  shouldShowTrialSignupWelcomeModal,
} from '../Modal/utils'
import type { Service } from '../../exports/Orchestrator/channelConnections/types'
import {
  handleChannelConnectionOnboardingViaURL,
  handleChannelRefreshViaURL,
} from './ModalManagerUtils'
import { getServiceName } from '../../exports/Orchestrator/channelConnections/utils'
import { MODALS } from '../../common/types'
import { ModalContent } from '../Modal/ModalContent'
import { useModalManager } from './hooks/useModalManager'

export const ModalManager = (): JSX.Element => {
  const { modalKey: modal, modalData: data, openModal } = useModalManager()
  const user = useUser()
  const { connectedChannels = [], connectedChannelIds = [] } = useChannels()
  const organizationId = user?.currentOrganization?.id ?? ''
  const { counts, loading: postCountsLoading } = usePostCounts({
    organizationId,
  })

  /**
   * We are running a new experiment to show Trial prompt
   * just after a user has created his first post in Publish
   * or when he has created one or more post from app-shell
   *
   */
  const shouldShowTrialPromptModal = (): boolean => {
    return (
      userHasCreatedAPostEver(counts) &&
      shouldShowFreeUserStartTrialPrompt(user)
    )
  }

  useEffect(() => {
    if (!postCountsLoading) {
      if (shouldShowTrialPromptModal())
        openModal({
          key: MODALS.startTrialNoClick,
          data: {
            ctaButton: 'startTrial',
            ctaView: 'noclick',
            ctaLocation: 'startTrialModal',
            cta: 'noclick-startTrialModal-startTrial-1',
            upgradePathName: 'startTrial',
          },
        })
    }
  }, [postCountsLoading])

  useEffect(() => {
    /* This modal must have the highest priority to be shown up to mobile users and avoid
     they see another modal which they cannot interact with */
    if (shouldShowDownloadAppModal()) {
      openModal({
        key: MODALS.downloadApp,
      })
    }

    // Show Team Member Downgrade Modal
    if (shouldShowTeamMemberDowngradeModal(user)) {
      openModal({
        key: MODALS.teamMemberDowngrade,
      })
    }

    // Show Trial Expired Modal
    if (shouldShowTrialExpiredModal(user)) {
      openModal({
        key: MODALS.trialExpired,
        data: {
          ctaButton: 'noclick',
          upgradePathName: 'noclickTrialExpiredModal-upgrade',
          ctaView: 'trialExpired',
          ctaLocation: ' noclick',
        },
      })
    }

    if (shouldTriggerChannelOnboardingModalFromUrl()) {
      handleChannelConnectionOnboardingViaURL({ openModal })
    }

    if (shouldTriggerChannelRefreshModalFromUrl()) {
      handleChannelRefreshViaURL({ openModal })
    }

    if (shouldTriggerSuccessModalFromUrl()) {
      openModal({
        key: MODALS.success,
        data: {
          ctaButton: 'noclick',
          upgradePathName: 'noclickCheckoutSuccess-upgrade',
          ctaView: 'successModal',
          ctaLocation: ' noclickCheckoutSuccess',
        },
      })
      cleanupUrlParams()
    }

    // Show Channel Connection prompt
    if (shouldShowChannelConnectionPrompt(user)) {
      openModal({
        key: MODALS.channelConnectionPrompt,
      })
    }

    // Show Trial Signup Welcome Modal
    if (shouldShowTrialSignupWelcomeModal(user)) {
      openModal({
        key: MODALS.trialSignupWelcomeModal,
      })
    }

    // Show Paywall Modal
    if (shouldShowPaywallModal(user)) {
      openModal({
        key: MODALS.paywall,
      })
    }
  }, [user.currentOrganization])

  useEffect(() => {
    // Open Composer with Prefilled Data
    if (connectedChannels && shouldPrefillComposerDataAfterConnection()) {
      if (window.__openComposer) {
        const cta = 'connectChannelOnboarding-previewMode'
        window.__openComposer(
          {
            channels: connectedChannelIds,
            cta: `publish-modal-${cta}-1`,
          },
          {
            prefillFromLocalStorage: true,
          },
        )
        if (shouldSkipCelebrationModals()) {
          const service = connectedChannels[0].service
          toast.success(
            `Great! your ${getServiceName(service as Service)} channel${
              connectedChannelIds.length > 1 ? 's have' : ' has'
            } been Connected 🎉`,
          )
        }
        cleanupUrlParams()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedChannels, window.__openComposer])

  return <ModalContent />
}

export default React.memo(ModalManager)
