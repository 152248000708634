export const getSendProcessLabel = (
  originalLabel: string,
  isLoading: boolean,
  sent: boolean,
  t: (key: string) => string,
): string | undefined => {
  if (isLoading) {
    return t('remindersSetupWizard.buttonLabelSending')
  }

  if (sent) {
    return t('remindersSetupWizard.buttonLabelSent')
  }

  return t(originalLabel)
}
