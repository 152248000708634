import { useUser } from '../../../common/context/User'
import type {
  ModalData,
  OrchestratorRootState,
} from '../../../common/events/types'
import { trackModalDismissed } from '../../../common/hooks/useSegmentTracking'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../exports/Orchestrator/store/orchestratorStore'
import {
  showModal,
  closeModal,
} from '../../../exports/Orchestrator/store/modalsReducer'

type OpenModalProps = {
  key: string
  data?: ModalData
}

type DismissModalProps = {
  shouldUseTracking: boolean
}

export const useModalManager = () => {
  const user = useUser()
  const dispatch = useAppDispatch()

  // Access the modal state from the store
  const { modal: modalKey, data: modalData } = useAppSelector(
    (state: OrchestratorRootState) => state.modals,
  )

  // Method to open a modal
  const openModal = ({ key, data }: OpenModalProps): void => {
    if (!key) {
      return
    }

    /* Send this tag to Clarity to track modal open */
    if (window.clarity) {
      /* if there is a modal already open, this modal couldn't be open */
      /* TODO: move this to a middleware */
      window.clarity('set', 'new_modal_management', key)

      if (modalKey) {
        window.clarity(
          'set',
          'modal_collision',
          `modal_on: ${modalKey} new_modal: ${key}`,
        )
      }
    }

    dispatch(showModal({ modalKey: key, modalData: data }))
  }

  // Method to close the currently active modal
  const dismissModal = (
    options: DismissModalProps = { shouldUseTracking: true },
  ): void => {
    dispatch(closeModal({}))

    if (options.shouldUseTracking) {
      trackModalDismissed({
        payload: {
          modalName: modalKey,
          method: null,
        },
        user,
      })
    }
  }

  return {
    modalKey,
    modalData,
    openModal,
    dismissModal,
  }
}
