import React, { type ReactElement } from 'react'

import { SearchIcon, ChevronRightIcon } from '@buffer-mono/popcorn'
import { gray, grayDarker } from '@bufferapp/ui/style/colors'
import {
  type Action,
  KBarPortal,
  KBarPositioner,
  KBarProvider,
  KBarResults,
  KBarSearch,
  useMatches,
  useRegisterActions,
} from 'kbar'
import actions from './actions'
import {
  useChannels,
  useGetParentAction,
  useHasLauncherEnabled,
  useResults,
  useTabNavigation,
  useTrackOpened,
  useTrackSelectedHandler,
} from './hooks'
import { ItemFactory, type KBarItem, LauncherResults } from './entities'
import styled from 'styled-components'
import Avatar from '@bufferapp/ui/Avatar'
import DisabledAnimator from './components/DisabledAnimator'
import {
  type ChannelActionOverrides,
  useGlobalContextActions,
} from './useGlobalContextActions'
import Shortcuts, { Tab } from './components/Shortcuts'

export const LAUNCHER_IN_HELP_OBJECT_ID = 'launcher-in-help-dropdown'

const Animator = styled(DisabledAnimator)`
  font-size: 14px;
  color: #636363;
  line-height: 20px;
  max-width: 600px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
  background: white;
  padding: 8px 0;
  min-height: 60px;

  & > div {
    width: 100%;
  }
`

const Search = styled(KBarSearch)`
  font-size: 14px;
  width: 100%;
  background: white;
  border: none;
  outline: none;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${gray};
  }
  :-ms-input-placeholder {
    color: ${gray};
  }
`

const ResultListWrapper = styled.section`
  width: 100%;
  padding: 8px;
`

const ResultList = KBarResults

const Result = styled('section')<{ active?: boolean; fullWidth?: boolean }>`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  cursor: pointer;
  width: ${(props): string => (props.fullWidth ? '100%' : 'auto')};
  border-radius: 6px;
  background-color: ${(props): string =>
    props.active ? '#f5f5f5' : 'transparent'};
`

const Heading = styled(Result)`
  text-transform: capitalize;
  font-size: 10px;
  line-height: 12px;
  padding: 8px 8px 4px;
`

const SearchSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: ${grayDarker};
  gap: 8px;
  padding: 14px 16px;
  height: 44px;
`

const Positioner = styled(KBarPositioner)`
  z-index: 10000;
  & * {
    box-sizing: border-box;
  }
`

const Provider: React.FC<{
  children: React.ReactNode
  actions: Action[]
}> = ({ children, actions }) => {
  return <KBarProvider actions={actions}>{children}</KBarProvider>
}

function SearchInput(): React.JSX.Element {
  const parentAction = useGetParentAction()
  return (
    <SearchSection>
      <SearchIcon size="medium" />
      {parentAction && (
        <>
          <Result active>
            <AvatarAndName>
              <Avatar
                src={parentAction?.channel?.avatar || ''}
                alt={parentAction?.channel?.name || ''}
                size="xsmall"
                type="social"
                network={parentAction?.channel?.service || ''}
              />
              <Name>{parentAction.name}</Name>
            </AvatarAndName>
          </Result>
          <ChevronRightIcon size="large" />
        </>
      )}
      <Search defaultPlaceholder="Search channels and pages" />
    </SearchSection>
  )
}

export default function Launcher({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const isEnabled = useHasLauncherEnabled()

  const { contextActions, channelActionOverrides } = useGlobalContextActions({
    isEnabled,
  })

  if (isEnabled) {
    return (
      <Provider actions={actions}>
        <KBarPortal>
          <Positioner>
            <Animator>
              <SearchInput />
              <Results
                contextActions={contextActions}
                channelActionOverrides={channelActionOverrides}
              />
            </Animator>
          </Positioner>
        </KBarPortal>
        {children}
      </Provider>
    )
  }
  return <>{children}</>
}

function Results({
  contextActions,
  channelActionOverrides,
}: {
  contextActions: Action[]
  channelActionOverrides: ChannelActionOverrides
}): ReactElement {
  const { results } = useResults()
  const { rootActionId } = useMatches()
  useTabNavigation({ results })
  const { channelActions } = useChannels({ channelActionOverrides })

  useTrackOpened()
  const onSelectAction = useTrackSelectedHandler()

  useRegisterActions(
    [...channelActions, ...contextActions],
    [channelActionOverrides],
  )

  const items = React.useMemo(() => {
    const launcherResults = new LauncherResults(results)
    return launcherResults.toKBarItems(rootActionId, onSelectAction)
  }, [rootActionId, results, onSelectAction])

  if (results.length === 0) {
    return <></>
  }

  return (
    <ResultListWrapper>
      <ResultList items={items} onRender={Row} />
    </ResultListWrapper>
  )
}

const AvatarAndName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Name = styled.span`
  margin-left: 10px;
`

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  color: ${gray};
`

function Row({
  item,
  active,
}: {
  item: KBarItem
  active: boolean
}): ReactElement {
  const action = ItemFactory.new(item, active)
  const shortcuts = action.shortcuts

  if (action.isHeading) {
    return <Heading key={action.id}>{action.name}</Heading>
  } else {
    if (action.isChannel) {
      return (
        <Result key={action.id} active={action.isActive} fullWidth>
          <AvatarAndName>
            <Avatar
              src={action.channel?.avatar || ''}
              alt={action.channel?.name || ''}
              size="xsmall"
              type="social"
              network={action.channel?.service || ''}
            />
            <Name>{action.name}</Name>
            <TabWrapper>
              <Tab />
              <ChevronRightIcon size="medium" />
            </TabWrapper>
          </AvatarAndName>
          <Shortcuts shortcuts={shortcuts} />
        </Result>
      )
    }
    return (
      <Result key={action.id} active={action.isActive}>
        <span>{action.name}</span>
        <Shortcuts shortcuts={shortcuts} />
      </Result>
    )
  }
}
