import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@bufferapp/ui'
import InfoIcon from '@bufferapp/ui/Icon/Icons/Info'
import Tooltip from '@bufferapp/ui/Tooltip'
import { useSplitEnabled } from '@buffer-mono/features'

import Channels from '../../../../../common/components/Channels/Channels'
import { maxChannelsAllowedPerPurchaseViaStripe } from '../../../../../common/utils/validation'

import {
  UpdatedPlanInfoContainer,
  PlanName,
  ChannelsCount,
  UsersCount,
  CurrentPaymentContainer,
  Row,
  Section,
  CancellationInfo,
  Loader,
  LoaderWrapper,
  TotalAmountInfo,
  FinalCost,
  TotalDueLoading,
} from './UpdatedPlanInfo.style'

import type { MessageStatus } from '../../../../../common/components/UXMessaging/UXMessaging'

type UpdatedPlanInfoProps = {
  planName: string
  planCycle: string
  numberOfUsers: string
  channelsCount: number
  increaseCounter: () => void
  decreaseCounter: () => void
  setChannelsCounterValue: (value: number) => void
  newPrice: number
  isNewInvoiceLoading: boolean
  amountDueToday: number
  hasPlanCounterChanged: boolean
  isOnTrial: boolean
  channelCounterMessageStatus: MessageStatus
  nextBillingDate: string
  isScheduledChange: boolean
  isStripeCheckout: boolean
}

const CancelTrialMessage = (): JSX.Element => {
  return (
    <CancellationInfo>
      You are ending your trial and starting your subscription now.
    </CancellationInfo>
  )
}

const UpdatedPlanInfo = (props: UpdatedPlanInfoProps): JSX.Element => {
  const {
    planName,
    planCycle,
    numberOfUsers,
    channelsCount,
    increaseCounter,
    decreaseCounter,
    setChannelsCounterValue,
    newPrice,
    channelCounterMessageStatus,
    isNewInvoiceLoading,
    amountDueToday,
    hasPlanCounterChanged,
    isOnTrial,
    nextBillingDate,
    isScheduledChange,
    isStripeCheckout,
  } = props
  const totalDueHeader = isScheduledChange
    ? 'Total due on ' + nextBillingDate
    : 'Total due today'

  const shouldIncludeScheduledChangeCopy =
    isScheduledChange && !isStripeCheckout
  return (
    <UpdatedPlanInfoContainer>
      <Channels
        channelsCount={channelsCount}
        onIncreaseCounter={(): void => increaseCounter()}
        onDecreaseCounter={(): void => decreaseCounter()}
        setChannelsCounterValue={(value: number): void =>
          setChannelsCounterValue(value)
        }
        channelCounterMessageStatus={channelCounterMessageStatus}
        isSmallLayout={false}
      />
      {hasPlanCounterChanged && (
        <div>
          <Section>
            Your updated plan:
            <PlanName role="textbox" aria-label="New plan">
              {planName}
            </PlanName>
            <Row>
              {channelsCount < maxChannelsAllowedPerPurchaseViaStripe && (
                <>
                  <ChannelsCount>
                    <span>{channelsCount}</span>
                    channels
                  </ChannelsCount>
                  <UsersCount>
                    <span>{numberOfUsers}</span>
                  </UsersCount>
                </>
              )}
            </Row>
          </Section>
          <CurrentPaymentContainer>
            {isNewInvoiceLoading ? (
              <TotalDueLoading />
            ) : (
              <div>{totalDueHeader}</div>
            )}

            <TotalAmountInfo>
              <LoaderWrapper>{isNewInvoiceLoading && <Loader />}</LoaderWrapper>

              <FinalCost>
                {isNewInvoiceLoading ? null : (
                  <>
                    {/* Although it's called `amountDueToday` this may actually
                     be the future invoice amount, if this is a scheduled downgrade */}
                    <Text type="p">${amountDueToday}</Text>
                    <Tooltip
                      label="This total factors in any existing discount and credit in your account"
                      opacity={0.8}
                      position="top"
                    >
                      <InfoIcon size="medium" />
                    </Tooltip>
                  </>
                )}
              </FinalCost>
            </TotalAmountInfo>
          </CurrentPaymentContainer>
          {isOnTrial && <CancelTrialMessage />}
          <CancellationInfo>
            <span>
              {shouldIncludeScheduledChangeCopy
                ? `Starting on ${nextBillingDate}, `
                : ''}
              ${newPrice} + Applicable taxes
            </span>{' '}
            will be billed every {planCycle} until canceled.
          </CancellationInfo>
        </div>
      )}
    </UpdatedPlanInfoContainer>
  )
}

UpdatedPlanInfo.propTypes = {
  planName: PropTypes.string.isRequired,
  planCycle: PropTypes.string.isRequired,
  numberOfUsers: PropTypes.string.isRequired,
  channelsCount: PropTypes.number.isRequired,
  increaseCounter: PropTypes.func.isRequired,
  decreaseCounter: PropTypes.func.isRequired,
  setChannelsCounterValue: PropTypes.func.isRequired,
  newPrice: PropTypes.number.isRequired,
  isNewInvoiceLoading: PropTypes.bool,
  amountDueToday: PropTypes.number,
  hasPlanCounterChanged: PropTypes.bool,
  isOnTrial: PropTypes.bool,
  channelCounterMessageStatus: PropTypes.shape({
    messageStatus: PropTypes.string,
    message: PropTypes.string,
  }),
}

UpdatedPlanInfo.defaultProps = {
  channelCounterMessageStatus: null,
  isNewInvoiceLoading: false,
  hasPlanCounterChanged: false,
  isOnTrial: false,
}

export default UpdatedPlanInfo
