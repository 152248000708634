import React from 'react'
import {
  Button,
  Card,
  ChevronDownIcon,
  Dialog,
  DropdownMenu,
  Heading,
  InstagramIcon,
  Link,
  OrderedList,
  Paragraph,
  Strong,
  Text,
} from '@buffer-mono/popcorn'

import { useUser } from '../../../../../../common/context/User'

import styles from './UnlockMoreFeatures.module.css'
import { useModalManager } from '../../../../../ModalManager/hooks/useModalManager'

export function UnlockMoreFeatures({
  setCurrentScreen,
}: {
  setCurrentScreen: (screen: string) => void
}): JSX.Element {
  const { modalData: data } = useModalManager()
  const user = useUser()

  const currentChannel = user?.currentOrganization?.channels?.find(
    (channel) => channel.id === data?.channelId,
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleGroup}>
        <Dialog.Header className={styles.header}>
          <div className={styles.iconUsernameWrapper}>
            <div className={styles.instagramIconWrapper}>
              <InstagramIcon />
            </div>
            <Text className={styles.username}>
              <strong>{currentChannel?.name}</strong>
            </Text>
          </div>
          <Dialog.CloseButton />
        </Dialog.Header>
        <Dialog.Body className={styles.body}>
          <div className={styles.titleGroup}>
            <Heading align="center" className={styles.heading}>
              Unlock More Instagram Features
            </Heading>
            <Paragraph align="center">
              Meta requires linking your Instagram with a Facebook Page to
              access features like Engage and in-depth analytics. Follow these
              steps to get set up:
            </Paragraph>
          </div>

          <Card padding="large" className={styles.card}>
            <OrderedList>
              <OrderedList.Item>
                <Paragraph>
                  <Strong>On your desktop browser, log into</Strong>{' '}
                  <Link
                    href="https://www.facebook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook.com
                  </Link>
                  .
                </Paragraph>
                <Paragraph>
                  Click your avatar in the top right corner and switch to your
                  Page. Need to create a Page? Create one{' '}
                  <Link
                    href="https://www.facebook.com/pages/creation"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </Link>{' '}
                  first, then continue.
                </Paragraph>
              </OrderedList.Item>
              <OrderedList.Item>
                <Paragraph>
                  <Strong>Head to</Strong>{' '}
                  <Link
                    href="https://www.facebook.com/settings/?tab=linked_instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    this screen
                  </Link>{' '}
                  <Strong>
                    to link your Instagram account. Click "Connect Account".
                  </Strong>
                </Paragraph>
                <Paragraph>
                  Log into the Instagram account you want to link to this
                  Facebook Page.
                </Paragraph>
              </OrderedList.Item>
              <OrderedList.Item>
                <Paragraph>
                  <Strong>
                    When they are linked, come back here and click "I'm Ready".
                  </Strong>
                </Paragraph>
              </OrderedList.Item>
            </OrderedList>
          </Card>
        </Dialog.Body>
      </div>
      <div className={styles.footerGroup}>
        <Dialog.Separator />
        <Dialog.Footer className={styles.footer}>
          <DropdownMenu
            align="start"
            className={styles.menu}
            trigger={
              <Button variant="tertiary" size="large">
                Help
                <ChevronDownIcon />
              </Button>
            }
          >
            <DropdownMenu.Item asChild>
              <a
                href="https://support.buffer.com/article/869-differences-between-facebook-pages-and-profiles?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
                target="_blank"
                rel="noopener noreferrer"
              >
                I can't find my page
              </a>
            </DropdownMenu.Item>
            <DropdownMenu.Item asChild>
              <a
                href="https://support.buffer.com/article/565-troubleshooting-instagram-connections#Checking-the-link-between-Facebook-and-Instagram-wPHUz?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
                target="_blank"
                rel="noopener noreferrer"
              >
                I can't link my Instagram to the Page
              </a>
            </DropdownMenu.Item>
          </DropdownMenu>
          <Button
            size="large"
            onClick={() => {
              setCurrentScreen('redirectingToFacebook')
            }}
          >
            I'm Ready, Login to Facebook
          </Button>
        </Dialog.Footer>
      </div>
    </div>
  )
}
