import React, { useState } from 'react'
import ChipInput from './ChipInput'

import styles from './ChipInputGgroup.module.css'
import { Text } from '@buffer-mono/popcorn'

type Option = {
  label: string
  value: string
}

interface CheckboxChipGroupProps {
  name: string
  options: Option[]
  legend: string
  defaultSelected?: string[]
  onChange: (options: string[]) => void
  inputType?: 'checkbox' | 'radio'
}

function ChipInputGroup({
  options,
  onChange,
  name,
  legend,
  defaultSelected,
  inputType = 'checkbox',
}: CheckboxChipGroupProps): JSX.Element {
  const defaultValue = defaultSelected || []
  const [selectedOptions, setSelectedOptions] = useState<string[]>(defaultValue)

  function handleOnChange(option: string, checked: boolean): void {
    const updatedOptions = checked
      ? [...selectedOptions, option]
      : selectedOptions.filter((item) => item !== option)

    setSelectedOptions(updatedOptions)
    onChange(updatedOptions)
  }

  function handleOnChangeRadio(option: string, checked: boolean): void {
    const updatedOptions = checked ? [option] : []

    setSelectedOptions(updatedOptions)
    onChange(updatedOptions)
  }

  return (
    <div className={styles.chipInputGroup}>
      <fieldset>
        <Text as="legend">{legend}</Text>
        {options.map((option) => (
          <ChipInput
            name={name}
            inputType={inputType}
            key={option.value}
            label={option.label}
            value={option.value}
            checked={selectedOptions.includes(option.value)}
            onChange={(checked: boolean): void =>
              inputType === 'radio'
                ? handleOnChangeRadio(option.value, checked)
                : handleOnChange(option.value, checked)
            }
          />
        ))}
      </fieldset>
    </div>
  )
}

export default ChipInputGroup
