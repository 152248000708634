import { useContext } from 'react'
import { UserContext } from '../../../../../common/context/User'
import {
  GatewayPlatform,
  PlanInterval,
  PricingTier,
} from '../../../../../common/types'
import {
  getBillingChannelSlotDetails,
  getPricingTiers,
  getSubscriptionInterval,
  getSubscriptionPlanData,
} from '../../../../../common/utils/billing'
import {
  getUserBillingData,
  isFreeUser,
  isOnActiveTrial,
  isPaidRevenueCatBillingGateway,
} from '../../../../../common/utils/user'
import type { BillingType } from '../types'

type UsePlanInformationReturn = {
  planInterval: PlanInterval
  quantity: number
  pricePerQuantity: number
  planId: string
  billingType: BillingType
  isPaidRevenueCat: boolean
  gatewayPlatformDeviceName: string
  gatewayPlatformStoreName: string
  pricingTiers?: Array<PricingTier>
}

const getGatewayPlatformDeviceName = (args: {
  gatewayPlaform?: GatewayPlatform
}): string => {
  const { gatewayPlaform = GatewayPlatform.web } = args

  if (gatewayPlaform === GatewayPlatform.apple) {
    return 'iOS'
  }

  if (gatewayPlaform === GatewayPlatform.android) {
    return 'Android'
  }

  return 'Web'
}

const getGatewayPlatformStoreName = (args: {
  gatewayPlaform?: GatewayPlatform
}): string => {
  const { gatewayPlaform = GatewayPlatform.web } = args

  if (gatewayPlaform === GatewayPlatform.apple) {
    return 'App Store'
  }

  if (gatewayPlaform === GatewayPlatform.android) {
    return 'Play Store'
  }

  return 'Web'
}

const usePlanInformation = (): UsePlanInformationReturn => {
  const user = useContext(UserContext)
  const isFree = isFreeUser(user)
  const isOnTrial = isOnActiveTrial(user)
  const billingData = getUserBillingData(user)
  const currentPlan = getSubscriptionPlanData(billingData)
  const planInterval =
    getSubscriptionInterval(billingData) || PlanInterval.month
  const channelSlotDetails = getBillingChannelSlotDetails(billingData)
  const isPaidRevenueCat = isPaidRevenueCatBillingGateway(user)
  const gatewayPlaform =
    user.currentOrganization?.billing?.gateway?.gatewayPlatform

  const billingType = isFree ? 'free' : isOnTrial ? 'trial' : 'paid'

  return {
    planInterval,
    planId: currentPlan?.id || 'team',
    quantity: channelSlotDetails?.currentQuantity || 0,
    pricePerQuantity: channelSlotDetails?.pricePerQuantity || 0,
    billingType,
    isPaidRevenueCat,
    gatewayPlatformDeviceName: getGatewayPlatformDeviceName({ gatewayPlaform }),
    gatewayPlatformStoreName: getGatewayPlatformStoreName({ gatewayPlaform }),
    pricingTiers: billingData ? getPricingTiers(billingData) : undefined,
  }
}

export default usePlanInformation
