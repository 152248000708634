import styled from 'styled-components'
import { gray, redDark, white } from '@bufferapp/ui/style/colors'

export const UploadWrapper = styled.div`
  background: ${white};
  border: 1px solid ${gray};
  border-radius: 4px;
  padding: 16px;
  margin-top: 8px;
`

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;

  label {
    flex: 1;
  }
`

export const AvatarInputWrapper = styled.div`
  width: 385px;
`

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border-radius: 100%;
  background: #dfecfd;
  margin-right: 12px;
`

export const LabelIconWrapper = styled.span`
  display: flex;

  .label-name {
    margin-right: 4px;
  }
`

export const ErrorWrapper = styled.span`
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
  }

  p {
    margin: 0;

    strong {
      color: ${redDark};
    }
  }
`
