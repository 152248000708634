import { grayDark, grayDarker } from '@bufferapp/ui/style/colors'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 503px;
  height: 323px;
`

export const ConfettiContainer = styled.div`
  position: absolute;
  pointer-events: none;
  width: 1px;
  height: 1px;
  top: 90%;
  left: 50%;
`

export const ImageWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 8px;
  > mask {
    mask-type: alpha;
  }
`

export const TextWrapper = styled.div`
  text-align: center;
  > h2 {
    font-size: 18px;
    color: ${grayDarker};
    margin: 0;
  }
  > p {
    color: ${grayDark};
    margin: 0;
    margin-top: 4px;
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 16px;
`
