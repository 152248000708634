import type { Account } from '../../../common/types'
import { BannerTypes } from '../../../common/hooks/useDismissBanner'

export function shouldShowFacebookGroupsNotificationsBanner(
  user: Account,
): boolean {
  if (
    !user ||
    !user.currentOrganization ||
    !user.currentOrganization.channels
  ) {
    return false
  }

  const hasFacebookGroup =
    user.currentOrganization.channels.filter(
      (channel) => channel.service === 'facebook' && channel.type === 'group',
    ).length > 0

  const hasNotDismissedBanner = !user?.dismissedBanners?.includes(
    BannerTypes.facebookGroupNotifications,
  )

  return hasFacebookGroup && hasNotDismissedBanner
}
