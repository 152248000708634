import React, {
  type ChangeEvent,
  type FormEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useMutation } from '@apollo/client'
import { Button, Input, Link, Notice, Text, Tooltip } from '@bufferapp/ui'
import { UserContext } from '../../../../../common/context/User'
import {
  CREATE_CUSTOM_CHANNELS,
  UPDATE_CUSTOM_CHANNEL,
} from '../../../../../common/graphql/channels'
import * as Styles from '../AccountTypeSelection/styles'
import { trackCustomChannelSetupViewed } from '../../tracking'
import { useSelector } from 'react-redux'
import type { OrchestratorRootState } from '../../../../../common/events/types'
import { ensureValidHandle, InvalidHandleError } from '@atproto/syntax'
import { Checkbox } from '@buffer-mono/popcorn'
import {
  Form,
  HandleLabel,
  NoticeWrapper,
  CheckboxText,
  SelectWrapper,
  AppPasswordText,
  PasswordLink,
  HelpIcon,
} from './styles'
import { setConnectionSuccessQueryParamsForCustomChannel } from '../../utils'

const handleErrorsMap = {
  'Disallowed characters in handle (ASCII letters, digits, dashes and periods only)':
    'Special characters are not allowed. Use only letters, numbers, dashes, and periods.',
  'Handle is too long (253  chars max)':
    'The handle is too long. The maximum length is 253 characters.',
  'Handle domain needs at least two parts':
    'The handle domain needs at least two parts (for example: one.two).',
  'Handle part too long (max 63 chars)':
    'Each part of the handle can be up to 63 characters long.',
  'Handle parts can not start or end with hyphens':
    "Handle parts can't start or end with hyphens.",
  'Handle final component (TLD) must start with ASCII letter':
    'The last part of the handle (TLD) must start with a letter. ',
} as Record<string, string>

function isValidHandle(handle: string): {
  isValid: boolean
  errorMessage: string
} {
  try {
    ensureValidHandle(handle)

    return {
      isValid: true,
      errorMessage: '',
    }
  } catch (error) {
    if (error instanceof InvalidHandleError) {
      return {
        isValid: false,
        errorMessage: handleErrorsMap[error.message] || error.message,
      }
    }

    return {
      isValid: false,
      errorMessage: 'Handle is invalid',
    }
  }
}

function isValidPassword(
  password: string,
  isRefreshingChannelConnection: boolean,
): {
  isValid: boolean
  errorMessage: string
} {
  if (!isRefreshingChannelConnection && password.length === 0) {
    return {
      isValid: false,
      errorMessage: 'App Password is required',
    }
  }

  // check if password match xxxx-xxxx-xxxx-xxxx pattern
  if (
    password &&
    password.match(/^[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}$/i) ===
      null
  ) {
    return {
      isValid: false,
      errorMessage: 'App Password should match xxxx-xxxx-xxxx-xxxx pattern',
    }
  }

  return {
    isValid: true,
    errorMessage: '',
  }
}

export default function BlueskyAuth({
  onClose,
  isRefreshingChannelConnection,
  name,
  channelId,
}: {
  onClose: () => void
  isRefreshingChannelConnection: boolean
  name?: string
  channelId?: string
}): React.JSX.Element {
  const { selectedService } = useSelector(
    (state: OrchestratorRootState) => state.channelConnections,
  )
  const [blueskyData, setBlueskyData] = useState({
    handle: name || '',
    appPassword: '',
    server: 'bsky.social',
    followBuffer: true,
  })
  const [errorData, setErrorData] = useState({
    handleError: '',
    appPasswordError: '',
    serverError: '',
    responseError: '',
  })

  const user = useContext(UserContext)

  const [upsertCustomChannels, { data, loading, error }] = useMutation(
    isRefreshingChannelConnection
      ? UPDATE_CUSTOM_CHANNEL
      : CREATE_CUSTOM_CHANNELS,
  )
  const message =
    data?.createCustomChannels?.message || data?.updateCustomChannel?.message

  useEffect(() => {
    if (data) {
      const newChannelId = data.createCustomChannels?.channels?.[0]?.id

      if (newChannelId) {
        // Redirect to '/channels' and trigger channel connection success modal
        const newURLWithSuccessParams =
          setConnectionSuccessQueryParamsForCustomChannel(newChannelId)
        window.location.href = newURLWithSuccessParams.toString()
      } else if (data.updateCustomChannel?.channel?.id) {
        onClose()
      } else if (message) {
        setErrorData((prevErrorData) => ({
          ...prevErrorData,
          responseError: message,
        }))
      }
    }
  }, [data, message, onClose, isRefreshingChannelConnection])

  useEffect(() => {
    if (error) {
      setErrorData((prevErrorData) => ({
        ...prevErrorData,
        responseError: error.message,
      }))
    }
  }, [error])

  useEffect(() => {
    if (selectedService) {
      trackCustomChannelSetupViewed({ account: user, service: selectedService })
    }
  }, [selectedService, user])

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      const isValidHandleData = isValidHandle(blueskyData.handle)
      const isValidAppPassword = isValidPassword(
        blueskyData.appPassword,
        isRefreshingChannelConnection,
      )

      if (!isValidHandleData.isValid) {
        setErrorData((prevErrorData) => ({
          ...prevErrorData,
          handleError: isValidHandleData.errorMessage,
        }))
      }

      if (!isValidAppPassword.isValid) {
        setErrorData((prevErrorData) => ({
          ...prevErrorData,
          appPasswordError: isValidAppPassword.errorMessage,
        }))
      }

      if (!isValidHandleData.isValid || !isValidAppPassword.isValid) {
        return
      }

      upsertCustomChannels({
        variables: {
          input: isRefreshingChannelConnection
            ? {
                channelId,
                customChannelMetadata: {
                  blueskyMetadata: {
                    handle: blueskyData.handle,
                    appPassword: blueskyData.appPassword
                      ? blueskyData.appPassword
                      : undefined,
                  },
                },
              }
            : {
                channels: [
                  {
                    organizationId: user?.currentOrganization?.id,
                    service: 'bluesky',
                    type: 'profile',
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    customChannelMetadata: {
                      blueskyMetadata: {
                        handle: blueskyData.handle,
                        server: blueskyData.server,
                        appPassword: blueskyData.appPassword,
                        followBuffer: blueskyData.followBuffer,
                      },
                    },
                  },
                ],
              },
        },
      })
    },
    [
      blueskyData,
      channelId,
      upsertCustomChannels,
      user,
      isRefreshingChannelConnection,
    ],
  )

  return (
    <Styles.Container>
      <Styles.Header>
        <Text type="h3">
          {isRefreshingChannelConnection
            ? 'Refresh connection to Bluesky'
            : 'Connect to Bluesky'}
        </Text>
      </Styles.Header>
      <Form onSubmit={handleSubmit}>
        <Styles.Body>
          {errorData.responseError && (
            <NoticeWrapper>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-expect-error TS(2740) */}
              <Notice type="alert" disableAnimation>
                {errorData.responseError}
              </Notice>
            </NoticeWrapper>
          )}
          <Styles.FormGroup>
            <Text htmlFor="handle" type="label">
              <HandleLabel>
                Handle
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Tooltip
                  label="On Bluesky, click on your avatar. Your handle is below your display name and looks like this: username.bsky.social."
                  position="right"
                >
                  <HelpIcon />
                </Tooltip>
              </HandleLabel>
              <Input
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error TS(2322) FIXME: Type '{ onChange: (ev: any) => void; disabled: boo... Remove this comment to see the full error message
                type="input"
                value={blueskyData.handle}
                onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                  setErrorData({
                    ...errorData,
                    handleError: '',
                    responseError: '',
                  })
                  setBlueskyData({
                    ...blueskyData,
                    handle: event.target.value.replace(/^@/, ''),
                  })
                }}
                id="handle"
                name="handle"
                placeholder="e.g. yourname.bsky.social"
                hasError={errorData.handleError !== ''}
                help={errorData.handleError}
              />
            </Text>
          </Styles.FormGroup>
          <Styles.FormGroup>
            <Text htmlFor="apppassword" type="label">
              Bluesky App Password
              <AppPasswordText>
                Use an app password to connect safely with Buffer without giving
                full access to your account. This is not your account password.{' '}
                <PasswordLink
                  href="https://bsky.app/settings/app-passwords"
                  newTab
                >
                  Generate app password in Bluesky
                </PasswordLink>
              </AppPasswordText>
              <Input
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error TS(2322) FIXME: Type '{ onChange: (ev: any) => void; disabled: boo... Remove this comment to see the full error message
                type="password"
                value={blueskyData.appPassword}
                onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                  setErrorData({
                    ...errorData,
                    appPasswordError: '',
                    responseError: '',
                  })
                  setBlueskyData({
                    ...blueskyData,
                    appPassword: event.target.value,
                  })
                }}
                id="apppassword"
                name="apppassword"
                placeholder="xxxx-xxxx-xxxx-xxxx"
                hasError={errorData.appPasswordError !== ''}
                help={errorData.appPasswordError}
              />
            </Text>
          </Styles.FormGroup>
          {!isRefreshingChannelConnection && (
            <Styles.FormGroup>
              <CheckboxText htmlFor="followbuffer" type="label">
                <Checkbox
                  id="followbuffer"
                  checked={blueskyData.followBuffer}
                  onChange={(checked): void => {
                    setBlueskyData({
                      ...blueskyData,
                      followBuffer: checked === true,
                    })
                  }}
                />
                Follow Buffer on Bluesky
              </CheckboxText>
            </Styles.FormGroup>
          )}
        </Styles.Body>
        <Styles.Footer>
          <SelectWrapper>
            <Link
              href="https://support.buffer.com/article/855-using-bluesky-with-buffer"
              newTab
            >
              Need Help?
            </Link>
          </SelectWrapper>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error TS(2740) FIXME: Type '{ label: string; type: string; size: string... Remove this comment to see the full error message */}
          <Button type="button" onClick={onClose} label="Cancel" />
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error TS(2740) FIXME: Type '{ label: string; type: string; size: string... Remove this comment to see the full error message */}
          <Button
            type="primary"
            label={loading ? 'Connecting Channel' : 'Next'}
            disabled={loading}
          />
        </Styles.Footer>
      </Form>
    </Styles.Container>
  )
}
