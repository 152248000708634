import React, { useState, useEffect, useContext } from 'react'

import {
  ArrowLeftIcon,
  Button,
  ChevronDownIcon,
  Dialog,
  DropdownMenu,
  Flex,
  Heading,
  IconButton,
  Text,
} from '@buffer-mono/popcorn'

import { ProfessionalCard } from './components/ProfessionalCard'
import { PersonalProfile } from './components/PersonalProfile/PersonalProfile'
import { PersonalProfileCard } from './components/PersonalProfileCard'
import PaywallModal from '../PaywallPrompt/PaywallPrompt'
import { Header } from './components/Header/Header'
import { Footer } from './components/Footer/Footer'

import { trackProfileTypeSelectionViewed } from '../../tracking'
import {
  InstagramAuthMethod,
  type onRedirectParams,
  Service,
} from '../../types'
import { UserContext, useUser } from '../../../../../common/context/User'
import { isOneBufferOrganization } from '../../../../../common/utils/user'
import { Notifications } from './components/Notifications/Notifications'
import { setConnectionSuccessQueryParamsForCustomChannel } from '../../utils'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetChannelConnectionsState,
  setShowChannelStorefront,
} from '../../../store/channelConnectionsReducer'
import type {
  AccountType,
  OrchestratorRootState,
} from '../../../../../common/events/types'
import { useSplitEnabled } from '@buffer-mono/features'

import styles from './InstagramSelectAccountType.module.css'
import footerStyles from './components/Footer/Footer.module.css'
import { useModalManager } from '../../../../../components/ModalManager/hooks/useModalManager'

function LeftArrow(): JSX.Element {
  const { isEnabled: isChannelStorefrontEnabled } = useSplitEnabled(
    'growth-new-channel-store-front',
  )

  const dispatch = useDispatch()
  const { cta } = useSelector(
    (state: OrchestratorRootState) => state.channelConnections,
  )

  function backToChannelStorefront(): void {
    dispatch(setShowChannelStorefront({ cta }))
  }

  // TODO: This check can be removed after the Account up gets a visual refresh
  // The channel storefront page will be made obsolete.
  // We are checking this now to avoid showing the channel stroefront modal ontop of the old page
  const isOnChannelStorefront =
    window.location.href.includes('/channels/connect') ||
    !isChannelStorefrontEnabled
  return isOnChannelStorefront ? (
    <Dialog.Close>
      <IconButton label="Close" variant="tertiary" size="small">
        <ArrowLeftIcon />
      </IconButton>
    </Dialog.Close>
  ) : (
    <IconButton
      onClick={backToChannelStorefront}
      label="Back to channel storefront"
      variant="tertiary"
      size="small"
    >
      <ArrowLeftIcon />
    </IconButton>
  )
}

enum Steps {
  accountType = 'accountType',
  notifications = 'notifications',
  personal = 'personal',
}

function openPublishSuccessScreen(channelId: string): void {
  window.location.href =
    setConnectionSuccessQueryParamsForCustomChannel(channelId).toString()
}

export default function InstagramSelectAccountType({
  onRedirect,
}: {
  onRedirect: (args: onRedirectParams) => void
}): JSX.Element {
  const dispatch = useDispatch()

  const user = useContext(UserContext)
  const { modalData: data } = useModalManager()

  const [showPayWallModal, setShowPayWallModal] = useState(false)
  const [selectedAccountType, setSelectedAccountType] = useState<
    AccountType | Steps
  >(data?.accountType || Steps.accountType)
  const [channelId, setChannelId] = useState<string | null>(null)
  const isOneBufferOrg = isOneBufferOrganization(user)

  if (showPayWallModal) {
    return (
      <PaywallModal
        service={Service.instagram}
        authMethod={InstagramAuthMethod.notificationsOnly}
        onClose={(): void => setShowPayWallModal(false)}
      />
    )
  }

  const stepMap = {
    personal: (
      <PersonalProfile
        onBack={(): void => setSelectedAccountType(Steps.accountType)}
        onContinue={(channelId): void => {
          setChannelId(channelId)
          setSelectedAccountType(Steps.notifications)
        }}
      />
    ),
    notifications: (
      <Notifications
        onContinue={(): void => {
          // Redirect to '/channels' and trigger channel connection success modal
          if (channelId) {
            openPublishSuccessScreen(channelId)
            dispatch(resetChannelConnectionsState({}))
          }
        }}
      />
    ),
    accountType: (
      <SelectAccountType
        onPersonalSelect={(): void => {
          if (isOneBufferOrg) {
            setSelectedAccountType(Steps.personal)
          } else {
            setShowPayWallModal(true)
          }
        }}
        onRedirect={onRedirect}
      />
    ),
  }

  return stepMap[selectedAccountType as Steps]
}

function SelectAccountType({
  onPersonalSelect,
  onRedirect,
}: {
  onPersonalSelect: () => void
  onRedirect: (args: onRedirectParams) => void
}): JSX.Element {
  const account = useUser()

  useEffect(() => {
    trackProfileTypeSelectionViewed({
      account,
      service: Service.instagram,
    })
  }, [account])

  return (
    <>
      <Header leftArrow={<LeftArrow />} />

      <Dialog.Body className={styles.body}>
        <div className={styles.titleSubtitle}>
          <Heading size="large" align="center">
            Which type of Instagram account would you like to connect?
          </Heading>
          <Text className={styles.subtitle}>
            The account type you choose will determine the features available to
            you.
          </Text>
        </div>
        <Flex justify="center" gap="xl">
          <PersonalProfileCard onPersonalSelect={onPersonalSelect} />
          <ProfessionalCard onRedirect={onRedirect} />
        </Flex>
      </Dialog.Body>
      <Footer>
        <DropdownMenu
          align="start"
          className={footerStyles.menu}
          trigger={
            <Button
              variant="tertiary"
              size="large"
              className={footerStyles.helpButton}
            >
              Help
              <ChevronDownIcon />
            </Button>
          }
        >
          <DropdownMenu.Item asChild>
            <a
              className={footerStyles.helpLink}
              href="https://support.buffer.com/article/554-using-instagram-with-buffer#Finding-your-Instagram-account-type-x-zBM?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
              target="_blank"
              rel="noopener noreferrer"
            >
              Finding your Instagram account type
            </a>
          </DropdownMenu.Item>
          <DropdownMenu.Item asChild>
            <a
              className={footerStyles.helpLink}
              href="https://support.buffer.com/article/554-using-instagram-with-buffer#Converting-your-Instagram-account-to-a-Professional-account-Creator-o-pCMTs?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
              target="_blank"
              rel="noopener noreferrer"
            >
              Converting your Instagram account to a Professional account
            </a>
          </DropdownMenu.Item>
        </DropdownMenu>
      </Footer>
    </>
  )
}
