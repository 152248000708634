import { blue, blueDark } from '@bufferapp/ui/style/colors'
import { fontFamily } from '@bufferapp/ui/style/fonts'
import styled from 'styled-components'

export const TextButton = styled.button`
  cursor: pointer;
  border: 0;
  background: none;
  color: ${blue};
  margin-right: -2px;
  padding-left: 0px;
  font-family: ${fontFamily};
  font-size: 14px;
  &:hover {
    color: ${blueDark};
  }
  &:focus {
    outline: none;
  }
`
