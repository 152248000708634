import { useContext, useEffect } from 'react'
import { UserContext } from '../../../../../common/context/User'
import { trackUpgradePathViewed } from '../../../../../common/hooks/useSegmentTracking'
import {
  isFreeUser,
  isOnActiveTrial,
  userCanStartFreeTrial,
} from '../../../../../common/utils/user'
import trackCTAViewed from '../../../../../tracking/UpgradePaths/trackCTAViewed'

interface UseTrackingReturn {
  cta: string
  upgradePathName: string
}

const useTracking = (): UseTrackingReturn => {
  const user = useContext(UserContext)
  const canStartTrial = userCanStartFreeTrial(user)
  const isFree = isFreeUser(user)
  const isTrialling = isOnActiveTrial(user)
  const cta = 'appshell-connectChannelUpgradeModal-1'
  const upgradePathName = canStartTrial
    ? 'addChannels-trial'
    : 'addChannels-upgrade'

  // Trigger Modal Viewed
  useEffect(() => {
    if (user) {
      const props = {
        payload: {
          upgradePathName,
          cta,
        },
        user,
      }
      trackCTAViewed(props)
      if (isFree || isTrialling) {
        trackUpgradePathViewed(props)
      }
    }
  }, [isFree, isTrialling, upgradePathName, user])

  return {
    cta,
    upgradePathName,
  }
}

export default useTracking
