import { PlanInterval } from '../types'

export const freePlan = {
  planId: 'free',
  planInterval: PlanInterval.month,
  planName: 'Free',
  isCurrentPlan: true,
  summary: { details: [] },
  currency: '$',
  totalPrice: 0,
}
