import React from 'react'

export default function FacebookGroupDescription(): JSX.Element {
  return (
    <>
      Connect to a Facebook Group as a <b>member</b> or an <b>admin</b>.
      <br />
      <br />
      Direct publishing to Facebook Groups is no longer supported by Meta.
      Buffer seamlessly facilitates scheduling your posts with mobile-based
      notifications.
      <br />
      <a
        href="https://support.buffer.com/article/570-connecting-facebook-groups-and-troubleshooting-connections"
        target="_blank"
        rel="noreferrer"
      >
        Learn more
      </a>
    </>
  )
}
