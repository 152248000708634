import React from 'react'
import {
  Card,
  ChannelIcon,
  Heading,
  IconButton,
  InfoIcon,
  NewBadge,
  Text,
} from '@buffer-mono/popcorn'
import type { ConnectableService, Service } from '../../../../../gql/graphql'

import styles from './ChannelStoreFrontItem.module.css'

function ChannelStoreFrontItem({
  connectableService,
  handleOpenServiceDetails,
  handleConnectService,
}: {
  connectableService: ConnectableService
  handleOpenServiceDetails: (connectableService: ConnectableService) => void
  handleConnectService: (connectableService: Service) => void
}): JSX.Element {
  const isNew = connectableService.serviceStatus.label === 'new'

  function onKeyDownCard(event: React.KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      handleConnectService(connectableService.service.toLowerCase() as Service)
    }
  }

  return (
    <Card
      aria-label={`Connect ${connectableService.title}`}
      role="button"
      tabIndex={0}
      className={styles.card}
      onClick={(): void => {
        handleConnectService(
          connectableService.service.toLowerCase() as Service,
        )
      }}
      onKeyDown={onKeyDownCard}
    >
      <ChannelIcon
        className={styles.channelIcon}
        color="inverted"
        type={connectableService.service}
        size="medium"
      />
      <div className={styles.wrapper}>
        <IconButton
          className={styles.infoIcon}
          data-testid={`info-${connectableService.service}`}
          label="More Details"
          onClick={(event): void => {
            event.stopPropagation()
            handleOpenServiceDetails(connectableService)
          }}
          tooltip="More Details"
          variant="tertiary"
        >
          <InfoIcon size="small" />
        </IconButton>

        <Heading size="small" align="center">
          {connectableService.title}
          {isNew && <NewBadge className={styles.newBadge} />}
        </Heading>
        <div className={styles.textWrapper}>
          <Text className={styles.subtitle} align="center" color="subtle">
            {connectableService.subtitle}
          </Text>
        </div>
      </div>
    </Card>
  )
}

export default ChannelStoreFrontItem
