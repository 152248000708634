import React, { useEffect, useState } from 'react'
import { Text } from '@buffer-mono/popcorn'
import { useQuery } from '@apollo/client'
import { QUERY_START_PAGES } from '../../../../common/graphql/startPage'

import { useUser } from '../../../../common/context/User'
import {
  getUserChannelCount,
  isFreeUser,
  isFreeUserMP,
} from '../../../../common/utils/user'
import type { Account } from '../../../../common/types'

// We need to append an id to display a pendo modal for users across
// buffer to notify them of the start page as a channel change
// the following are the groups:
//
// Group 1:
// Free & paid users not at their plan limit
//
// Group 2
// Free users at their plan limit
//
// Group 3
// Mobile Users at their plan limit
//
// Group 4
// Multi-product users at their plan limit
//
// Group 5
// Paid users at their plan limit

// This logic doesn't need to live forever, a few weeks and then we'll clean up!

export type StartPageForNavbar = {
  id?: string
  migrationAsAChannel: {
    success: boolean
    wasAtLimit: boolean
    wasMobile: boolean
    wasBilling: string
  }
  isPublished: boolean
}

export const getPendoId = (
  startPages: StartPageForNavbar[],
): string | undefined => {
  const migratedSPs = startPages.filter(
    (page) => page?.migrationAsAChannel?.success,
  )
  if (migratedSPs.length === 0) return '' // no SPs were migrated so they don't need a message

  const pagesAtPlanLimit = migratedSPs.filter(
    (page) => page.migrationAsAChannel.wasAtLimit,
  )

  if (pagesAtPlanLimit.length === 0) {
    return 'noImpact'
  }
  const mobileBillingPages = pagesAtPlanLimit.filter(
    (page) => page.migrationAsAChannel.wasMobile,
  )
  if (mobileBillingPages.length > 0) {
    return 'mobileBilling'
  }
  let id
  pagesAtPlanLimit.forEach((page) => {
    switch (page.migrationAsAChannel.wasBilling) {
      case 'free':
        id = 'freeOrg'
        break
      case 'paid-ob':
        id = 'oneBufferOrg'
        break
      case 'paid-mp':
        id = 'multiProductOrg'
        break
      default:
        id = ''
        break
    }
  })
  return id
}

// a week before the launch, we want to let free users at their
// limits know what's going to happen to their SP via a message in Pendo
// i also added a task in the release plan to remove this at launch so they can get
// the correct pendo modal then
// https://paper.dropbox.com/doc/Start-Page-As-A-Channel-Implementation-plan-No-Legacy-ing--BkbQL_KcAzURbWLv23R5Qv_RAg-By8T1RKJhQttRHRneO3pW#:uid=059762319064679039822417&h2=3)-Public-release
export const getFreeOrgWarning = ({ user }: { user: Account }): string => {
  const isOneBuffer = user?.currentOrganization?.isOneBufferOrganization

  const isFree = isOneBuffer ? isFreeUser(user) : isFreeUserMP(user)
  const isAtFreePlanChannelLimit = (getUserChannelCount(user) || 0) >= 3

  if (isFree && isAtFreePlanChannelLimit) {
    return 'freeOrg-warning'
  }
  return ''
}

export const StartPageLink = (): JSX.Element => {
  const user = useUser()
  const orgId = user?.currentOrganization?.id
  const { data } = useQuery(QUERY_START_PAGES, {
    variables: {
      organizationId: orgId,
    },
  })
  const [id, setId] = useState<string | undefined>('')
  const [warningClass, setWarningClass] = useState(() => '')

  useEffect(() => {
    const startPages = data?.startPages?.startPages as StartPageForNavbar[]
    if (startPages) {
      const pendoId = getPendoId(startPages)
      setId(pendoId)

      const hasPublishedPages = startPages.filter((page) => page.isPublished)
      const hasBeenSuccessfullyMigrated = startPages.filter(
        (page) => page?.migrationAsAChannel?.success,
      )
      if (
        hasPublishedPages.length > 0 &&
        hasBeenSuccessfullyMigrated.length === 0
      ) {
        setWarningClass(getFreeOrgWarning({ user }))
      }
    }
  }, [data, user])

  return (
    <Text as="span" id={id} size="md" weight="bold">
      Start Page
    </Text>
  )
}
