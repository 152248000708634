import React, { type ReactNode } from 'react'
import { Container } from './style'

interface StickyModalProps {
  children: ReactNode
  closeAction?: () => void
}

const StickyModal: React.FC<StickyModalProps> = ({ children }): JSX.Element => {
  return <Container>{children}</Container>
}

export default StickyModal
