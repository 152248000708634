import { useEffect, useState } from 'react'
import type { History } from 'history'

declare global {
  interface Window {
    __history: History<unknown> | undefined
  }
}

export function useProductHistory(): {
  history: History
  navigate: (href: string) => void
} {
  const [history, setHistory] = useState<History | null>(null)

  useEffect(() => {
    if (window.__history) {
      setHistory(window.__history)
    } else {
      const interval = setInterval(() => {
        if (window.__history) {
          setHistory(window.__history)
          clearInterval(interval)
        }
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [])

  const navigate = (href: string): void => {
    const url = new URL(href)
    const origin = url.origin
    const pathname = url.pathname

    if (window.__history && window.location.origin === origin) {
      try {
        return window.__history?.push(pathname)
      } catch (error) {
        // Do nothing, will fallback to window.location.assign
      }
    }
    window.location.assign(href)
  }

  return { history: history as History, navigate }
}
