import { getActiveProductFromPath } from '../../../../common/utils/getProduct'
import { getPublishUrl, isInPublish } from '../../../../common/utils/urls'
import { useProductHistory } from '../../../../exports/Navigator/utils/useProductHistory'

export default function useCreateIdeaRedirect(): {
  createIdeaRedirect: () => void
} {
  const { history } = useProductHistory()
  const isInPublishApp = isInPublish()

  function createIdeaRedirect(): void {
    const cta =
      `${getActiveProductFromPath()}-navBar-globalPlus-newIdea-1` as const
    const createIdeaRoute = `create/ideas/new?source=${cta}`
    if (isInPublishApp && history) {
      history.push(`/${createIdeaRoute}`)
    } else {
      const redirectUrl = getPublishUrl(createIdeaRoute)
      window.location.assign(redirectUrl)
    }
  }

  return { createIdeaRedirect }
}
