import { gql } from '@apollo/client'

export const BILLING_FIELDS = gql`
  fragment BillingFields on Billing {
    id
    ... on MPBilling {
      subscriptions {
        plan
        product
        trial {
          isActive
        }
      }
    }
    ... on OBBilling {
      hasNeverBeenPayingCustomer
      canStartTrial
      subscription {
        trial {
          isActive
        }
        plan {
          id
          name
        }
      }
    }
  }
`

export const ACCOUNT_ORGANIZATION_FIELDS = gql`
  fragment AccountOrganizationFields on AccountOrganization {
    id
    isOneBufferOrganization
    billing {
      ...BillingFields
    }
  }
  ${BILLING_FIELDS}
`

export const ORG_DATA_QUERY = gql`
  query OrgDataQuery {
    account {
      id
      currentOrganization {
        ...AccountOrganizationFields
      }
      organizations {
        ...AccountOrganizationFields
      }
    }
  }
  ${ACCOUNT_ORGANIZATION_FIELDS}
`
