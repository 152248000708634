import React, { useCallback, useContext, useMemo } from 'react'
import clsx from 'clsx'
import {
  AudienceIcon,
  Badge,
  CloseIcon,
  DropdownMenu,
  FlaskConicalIcon,
  IconButton,
  LogOutIcon,
  MessageCircleHeartIcon,
  StatusDot,
  type Theme,
  useTheme,
  UserIcon,
  UserRoundPlusIcon,
  WarningIcon,
  ZapFilledIcon,
  THEMES,
  AppearanceIcon,
  SettingsIcon,
  AlphaBadge,
} from '@buffer-mono/popcorn'
import { useSplitEnabled } from '@buffer-mono/features'

import {
  canHaveTeamMembers,
  hasEditPermission,
  isFreeUser,
  isOneBufferOrganization,
  userCanStartFreeTrial,
} from '../../../../common/utils/user'
import type { Account } from '../../../../common/types'
import UpgradePathsTracking from '../../../../tracking/UpgradePaths'
import { trackCTAsViewedInDropdown } from '../../utils/ctaTracking'
import {
  getAccountDropdownItems,
  type NavDropdownItem,
} from '../../utils/dropdown-items'

import {
  handleLogoutClick,
  handleStartTrialClick,
  handleTeamClick,
  handleUpgradeClick,
} from './callbacksAccountMenu'
import { OrganizationsSwitcher } from './components/OrganizationsSwitcher'
import AccountMenuAvatar from './components/AccountMenuAvatar'

import {
  getAccountUrl,
  getMigrationHubUrl,
  getNewSettingsUrl,
  getStopImpersonationUrl,
} from '../../../../common/utils/urls'

import { VerifyEmailModal } from './components/VerifyEmailModal'
import { useModalManager } from '../../../ModalManager/hooks/useModalManager'
import { useProductHistory } from '../../../../exports/Navigator/utils/useProductHistory'

import styles from './AccountMenu.module.css'


type AccountMenuProps = {
  user: Account
  onLogout?: () => void
  onOrganizationSelected?: (organizationId: string) => void
}

export const AccountMenu = ({
  user,
  onLogout,
  onOrganizationSelected,
}: AccountMenuProps): JSX.Element => {
  const { trackCTAClicked } = UpgradePathsTracking(user)
  const { openModal } = useModalManager()
  const [openMenu, setOpenMenu] = React.useState(false)

  const isFree = isFreeUser(user)
  const canEdit = hasEditPermission(user)
  const canStartTrial = userCanStartFreeTrial(user)
  const isOneBufferOrg = isOneBufferOrganization(user)
  const freeUserCanNotStartATrial = isFree && isOneBufferOrg && !canStartTrial
  const freeUserCanStartATrial = isFree && isOneBufferOrg && canStartTrial
  const shouldShowTeamMemberUpgradePath =
    isOneBufferOrg && !canHaveTeamMembers(user)
  const isBetaEnabled = user?.currentOrganization?.isBetaEnabled
  const isImpersonation = user.isImpersonation
  const hasVerifiedEmail = user.hasVerifiedEmail
  const avatar = user.avatar

  const { isEnabled: isThemeSwitcherEnabled } = useSplitEnabled(
    'buffer-test-theme-switcher',
  )

  const { isEnabled: isNewSettingsEnabled } = useSplitEnabled(
    'account-settings-refresh',
  )

  const upgradePathConditionals = useMemo(
    () => ({
      shouldTrackUpgradeCTAViewed: freeUserCanNotStartATrial,
      shouldTrackStartTrialViewed: freeUserCanStartATrial,
      shouldShowTeamMemberUpgradePath,
    }),
    [
      freeUserCanNotStartATrial,
      freeUserCanStartATrial,
      shouldShowTeamMemberUpgradePath,
    ],
  )

  /* CALLBACKS */
  const handleOpenAccountMenu = useCallback(
    (open: boolean) => {
      if (open) trackCTAsViewedInDropdown(user, upgradePathConditionals)
      setOpenMenu(open)
    },
    [user, upgradePathConditionals],
  )

  const { navigate } = useProductHistory()

  /* MENU ITEMS */
  const menuItems: NavDropdownItem[] = useMemo(() => {
    const baseItems = [
      isNewSettingsEnabled && {
        id: 'new-settings',
        title: 'Settings',
        icon: <SettingsIcon size="small" />,
        onItemClick: (): void => {
          navigate(getNewSettingsUrl())
        },
        hasDivider: true,
        badge: (
          <AlphaBadge size="small" className={styles.badgeNewSettingsAlpha} />
        ),
      },
      {
        id: 'account',
        title: 'Account',
        icon: <UserIcon size="small" />,
        hasDivider: true,
        url: getAccountUrl(),
      },
      ...getAccountDropdownItems(),
      canEdit && {
        id: 'openTeam',
        title: shouldShowTeamMemberUpgradePath
          ? 'Invite Your Team'
          : 'Manage Your Team',
        icon: shouldShowTeamMemberUpgradePath ? (
          <UserRoundPlusIcon color="brand" size="small" />
        ) : (
          <AudienceIcon size="small" />
        ),
        className: shouldShowTeamMemberUpgradePath
          ? 'colorBrand'
          : 'colorNeutral',
        onItemClick: handleTeamClick(
          shouldShowTeamMemberUpgradePath,
          openModal,
          trackCTAClicked,
          user,
        ),
      },
      !isOneBufferOrg && {
        id: 'upgrade',
        title: 'Explore New Features',
        icon: <ZapFilledIcon color="brand" size="small" />,
        className: 'colorBrand',
        hasDivider: true,
        url: getMigrationHubUrl('topNavMenu-migration-cta'),
      },
      freeUserCanNotStartATrial && {
        id: 'upgrade',
        title: 'Upgrade',
        icon: <ZapFilledIcon color="brand" size="small" />,
        className: 'colorBrand',
        hasDivider: true,
        onItemClick: handleUpgradeClick(openModal, trackCTAClicked),
      },
      freeUserCanStartATrial && {
        id: 'start trial',
        title: 'Start a free trial',
        icon: <ZapFilledIcon color="brand" size="small" />,
        className: 'colorBrand',
        hasDivider: true,
        onItemClick: handleStartTrialClick(openModal, trackCTAClicked),
      },
      {
        id: 'referral',
        title: 'Refer a Friend',
        icon: <MessageCircleHeartIcon size="small" />,
        hasDivider: false,
        url: getAccountUrl('referrals'),
      },
      {
        id: 'beta',
        title: 'Beta Features',
        icon: <FlaskConicalIcon data-testid="beta-enabled-icon" size="small" />,
        hasDivider: false,
        className: 'colorNeutral',
        url: getAccountUrl('beta'),
        badge: isBetaEnabled ? (
          <Badge className={styles.badgeON} size="small">
            ON
          </Badge>
        ) : null,
      },
      user.isImpersonation
        ? {
            id: 'Stop Impersonation',
            title: 'Stop Impersonation',
            icon: <CloseIcon size="small" />,
            hasDivider: true,
            url: getStopImpersonationUrl(),
          }
        : {
            id: 'logout',
            title: 'Logout',
            icon: <LogOutIcon size="small" />,
            hasDivider: true,
            onItemClick: handleLogoutClick(onLogout),
          },
    ]
    return baseItems.filter(Boolean) as NavDropdownItem[]
  }, [
    user,
    onLogout,
    canStartTrial,
    isFree,
    isOneBufferOrg,
    shouldShowTeamMemberUpgradePath,
  ])

  return (
    <DropdownMenu
      modal={false}
      open={openMenu}
      onOpenChange={handleOpenAccountMenu}
      trigger={
        <IconButton
          variant="tertiary"
          label="User Menu"
          size="large"
          className={clsx(styles.triggerButton, {
            [styles.isImpersonation]: isImpersonation,
          })}
        >
          <>
            <AccountMenuAvatar
              avatar={avatar}
              isImpersonation={isImpersonation}
            />
            {!hasVerifiedEmail && (
              <StatusDot
                data-testid="email-not-verified-badge"
                className={styles.avatarBadge}
              />
            )}
          </>
        </IconButton>
      }
      className={styles.accountMenu}
    >
      {/* email */}
      {!user.hasVerifiedEmail ? (
        <VerifyEmailModal onCloseMenu={setOpenMenu}>
          <DropdownMenu.Item
            key={'user'}
            id={'user'}
            onSelect={(e: Event): void => {
              e.preventDefault()
            }}
          >
            <span className={styles.userEmail}>{user.email}</span>
            <WarningIcon
              data-testid="email-not-verified-icon"
              color="warning"
            />
          </DropdownMenu.Item>
        </VerifyEmailModal>
      ) : (
        <DropdownMenu.Item key={'user'} id={'user'} disabled>
          <span className={styles.userEmail}>{user.email}</span>
        </DropdownMenu.Item>
      )}

      {/* organizations switcher */}
      <OrganizationsSwitcher
        user={user}
        onOrganizationSelected={onOrganizationSelected}
      />

      {menuItems.map((item) => (
        <React.Fragment key={item.id}>
          {item.hasDivider && (
            <DropdownMenu.Separator key={`separator-${item.id}`} />
          )}
          <DropdownMenu.Item
            key={item.id}
            id={item.id}
            onSelect={item.onItemClick && item.onItemClick}
            className={
              item.className ? styles[item.className] : styles.colorNeutral
            }
            {...(item.url ? { asChild: true } : {})}
          >
            {/* We are using anchor elements when item is a navigation link
            as they are more accessible */}
            {item.url ? (
              <a href={item.url}>{renderItemContent(item)}</a>
            ) : (
              renderItemContent(item)
            )}
          </DropdownMenu.Item>
        </React.Fragment>
      ))}
      {isThemeSwitcherEnabled && <ThemeSwitcher />}
    </DropdownMenu>
  )
}

const renderItemContent = (item: NavDropdownItem): JSX.Element => (
  <>
    {item.icon}
    {item.title}
    {item.badge}
  </>
)

export default AccountMenu

export const ThemeSwitcher = (): JSX.Element => {
  const { theme, setTheme } = useTheme()

  const themes = Object.entries(THEMES) as [Theme, string][]

  return (
    <>
      <DropdownMenu.Separator />
      <DropdownMenu.Sub>
        <DropdownMenu.SubTrigger>
          <AppearanceIcon />
          Theme
        </DropdownMenu.SubTrigger>
        <DropdownMenu.SubMenu>
          <DropdownMenu.RadioGroup
            aria-label="Choose theme"
            value={theme}
            onChange={(value: string): void => setTheme(value as Theme)}
          >
            {themes.map(([key, title]) => (
              <DropdownMenu.RadioItem key={key} value={key}>
                {title}
              </DropdownMenu.RadioItem>
            ))}
          </DropdownMenu.RadioGroup>
        </DropdownMenu.SubMenu>
      </DropdownMenu.Sub>
    </>
  )
}
