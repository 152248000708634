import React, { useEffect, useState } from 'react'
import { Badge } from '@buffer-mono/popcorn'
import clsx from 'clsx'

import { useFetchUnreadEngageComments } from './useFetchUnreadEngageComments'
import style from './UnreadEngageCommentsCounter.module.css'

const FIVE_MINUTES_IN_MS = 5 * 60 * 1000

const UnreadEngageCommentsCounter = (args: {
  pollInterval?: number
}): JSX.Element | null => {
  const { pollInterval = FIVE_MINUTES_IN_MS } = args
  const [isVisible, setIsVisible] = useState(false)

  const { totalPendingComments } = useFetchUnreadEngageComments({
    pollInterval,
  })

  useEffect(() => {
    if (totalPendingComments && totalPendingComments > 0) setIsVisible(true)
  }, [totalPendingComments])

  // Don't display badge if there are no pending comments
  if (totalPendingComments === 0) return null

  const valueToShow = totalPendingComments > 9 ? '9+' : totalPendingComments

  return (
    <Badge className={clsx(style.badge, isVisible && style.popIn)}>
      {valueToShow}
    </Badge>
  )
}

export default UnreadEngageCommentsCounter
