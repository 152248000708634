import { useMutation } from '@apollo/client'
import { ACCOUNT_INITIATE_EMAIL_VERIFICATION } from '../graphql/account'

type BannerOptions = {
  text?: string
  actionButton?: {
    action?: () => void
    label?: string
  }
}

type UseEmailVerificationReturn = {
  bannerOptions?: BannerOptions
  renderCustomHTML?: boolean
}

const useEmailVerification = (): UseEmailVerificationReturn => {
  const [initiateEmailVerification, { data, error }] = useMutation(
    ACCOUNT_INITIATE_EMAIL_VERIFICATION,
  )

  let bannerOptions
  let renderCustomHTML

  if (data && data.accountInitiateEmailVerification) {
    if (data.accountInitiateEmailVerification.success) {
      // Success after re-send verification email attempt
      bannerOptions = {
        text: 'We just sent you an email! Please check your inbox to complete verification steps.',
        actionButton: {},
      }
    } else {
      // rate limit error
      bannerOptions = {
        text: data.accountInitiateEmailVerification?.userFriendlyMessage,
        actionButton: {},
      }
    }
  } else if (error) {
    // Error during the re-send verification email attempt
    bannerOptions = {
      text: error.message,
      actionButton: {},
    }
  } else {
    // Email verification needed
    bannerOptions = {
      actionButton: {
        action: initiateEmailVerification,
        label: 'Re-send verification email',
      },
    }
    renderCustomHTML = true
  }

  return {
    bannerOptions,
    renderCustomHTML,
  }
}

export default useEmailVerification
