import React, { useCallback, useEffect } from 'react'
import { Text, Button } from '@bufferapp/ui'

import { isValidChannelCountForPlan } from '../../../../../common/utils/validation'

import { PlanContext } from '../../../../../common/context/Plan'
import useChannelsCounter from '../../../../../common/hooks/ChannelsCounter/useChannelsCounter'
import useUpdateSubscriptionQuantity from '../../../../../common/hooks/Subscription/useUpdateSubscriptionQuantity'
import Channels from '../../../../../common/components/Channels/Channels'
import useGetTotalChargeAmount from '../../../../../common/hooks/ChargeAmount/useGetTotalChargeAmount'
import { calculateTotalSlotsPrice } from '../../../utils'
import {
  trackQuantityUpdateModalViewed,
  formatCTAString,
} from '../../../../../common/hooks/useSegmentTracking'

import type { CardBodyProps } from './CardBodyTypes'
import { isBillingResponse } from '../../../../../common/types/graphQL/Billing'
import type { ModalData } from '../../../../../common/events/types'
import { MODALS } from '../../../../../common/types'
import { useModalManager } from '../../../../ModalManager/hooks/useModalManager'

import {
  Header,
  SectionContainer,
  Section,
  ButtonWrapper,
  InnerContainer,
  ChannelsWrapper,
  Summary,
  LoaderWrapper,
  Loader,
  TotalCost,
} from './style'

const CardBody = ({
  planName,
  quantity,
  channelFee,
  pricePerQuantity,
  minimumQuantity,
  planId,
  planCycle,
  user,
  trialInfo,
}: CardBodyProps): JSX.Element => {
  const {
    channelsCount,
    increaseCounter,
    decreaseCounter,
    channelCountMessageStatus,
    setChannelsCounterValue,
  } = useChannelsCounter(planId, quantity, minimumQuantity)

  const {
    modalData: data,
    openModal,
    dismissModal: closeModal,
  } = useModalManager()
  const cta = data?.cta || ''
  const upgradePathName = data?.upgradePathName || ''

  const newPrice = calculateTotalSlotsPrice(
    planId,
    channelsCount,
    pricePerQuantity,
    minimumQuantity,
    channelFee,
  )

  const currentPrice = calculateTotalSlotsPrice(
    planId,
    quantity,
    pricePerQuantity,
    minimumQuantity,
    channelFee,
  )

  const hasCounterChanged = channelsCount !== quantity

  const { loading, totalAmountDue, nextBillingDate, isScheduledChange } =
    useGetTotalChargeAmount(
      user,
      planId,
      planCycle,
      channelsCount,
      planId,
      newPrice,
      trialInfo?.isActive || false,
      hasCounterChanged,
    )

  const totalDueHeader = isScheduledChange
    ? 'Total due on ' + nextBillingDate
    : 'Total due today'

  const ctaButtonText = isScheduledChange
    ? 'Confirm Plan Change'
    : 'Confirm and Pay'

  const {
    updateSubscriptionQuantity,
    data: updateQuantityData,
    isProcessing,
    error,
  } = useUpdateSubscriptionQuantity({
    user,
    channelsQuantity: channelsCount,
    cta,
    upgradePathName,
  })

  const isValidChannelsCount = isValidChannelCountForPlan(
    planId,
    channelsCount,
    minimumQuantity,
  )

  const disableSumbitButton =
    !hasCounterChanged || isProcessing || !isValidChannelsCount

  const planInfoContext = {
    selectedPlanId: planId,
    selectedPlanName: planName,
    selectedPlanMinimumQuantity: minimumQuantity,
    currentPlanChannelQuantity: quantity,
  }

  const openSuccessModal = useCallback(
    (newData: ModalData): void => {
      closeModal()
      openModal({ key: MODALS.success, data: newData })
    },
    [openModal],
  )

  useEffect(() => {
    trackQuantityUpdateModalViewed({
      payload: {
        currentPlan: formatCTAString(`${planId} ${planCycle}`),
        cta,
        ctaButton: 'confimAndPay',
        ctaView: 'quantityUpdateModal',
        ctaLocation: 'quantityUpdateModal',
        upgradePathName: 'quantityUpdateModal-upgrade',
      },
      user,
    })
  }, [])

  useEffect(() => {
    if (
      isBillingResponse(
        updateQuantityData?.billingUpdateSubscriptionQuantity,
      ) &&
      updateQuantityData?.billingUpdateSubscriptionQuantity?.success
    ) {
      openSuccessModal({
        selectedPlan: planName,
        stayedOnSamePlan: true,
        isDowngradingToFreePlan: false,
        scheduledUpdate:
          updateQuantityData.billingUpdateSubscriptionQuantity.scheduledUpdate,
      })
    }
  }, [updateQuantityData, openSuccessModal, planName])

  const modalData = {
    cta: 'quantityUpdateModal-paragraph-changeplan-1',
    ctaButton: 'changeplan',
    ctaView: 'quantityUpdateModal',
    ctaLocation: 'quantityUpdateModal-paragraph',
    upgradePathName: 'quantityUpdateModal-changeplan',
  }

  return (
    <PlanContext.Provider value={planInfoContext}>
      <>
        <Header>
          <Text type="h2">Add or Remove Channels from Plan</Text>
          <Text type="p">
            You&apos;re currently on the <strong>{planName}</strong> plan and
            you&apos;re paying{' '}
            <strong>
              ${pricePerQuantity}/{planCycle} per channel
            </strong>{' '}
            for {quantity} channel
            {quantity !== 1 ? 's' : ''}.{' '}
            <button
              data-testid="quantityupdate-changeplan"
              type="button"
              onClick={(): void => {
                closeModal()
                openModal({ key: MODALS.planSelector, data: modalData })
              }}
            >
              Change Plan
            </button>
          </Text>
          {error && (
            <Text type="help" hasError>
              {error.message}
            </Text>
          )}
        </Header>
        <SectionContainer>
          <Section>
            <InnerContainer>
              <ChannelsWrapper>
                <Channels
                  channelsCount={channelsCount}
                  onIncreaseCounter={(): void => increaseCounter()}
                  onDecreaseCounter={(): void => decreaseCounter()}
                  channelCounterMessageStatus={channelCountMessageStatus}
                  setChannelsCounterValue={(value: number): void =>
                    setChannelsCounterValue(value)
                  }
                  isSmallLayout={true}
                />
              </ChannelsWrapper>

              <Summary>
                <Text type="p">Currently paying: ${currentPrice}</Text>
                {hasCounterChanged ? (
                  <>
                    <TotalCost>
                      <Text type="p">
                        {!loading && (
                          <>
                            <strong>{totalDueHeader}:</strong> ${totalAmountDue}
                          </>
                        )}
                      </Text>

                      {loading && (
                        <LoaderWrapper>
                          {' '}
                          <Loader />
                        </LoaderWrapper>
                      )}
                    </TotalCost>
                    {!loading && (
                      /* @ts-expect-error TS(2741) FIXME:  Property 'type' is missing in type '{ children: an... Remove this comment to see the full error message */
                      <Text>
                        <span>
                          {isScheduledChange
                            ? `Starting on ${nextBillingDate}, `
                            : ''}
                          ${newPrice} + Applicable taxes
                        </span>{' '}
                        will be billed every {planCycle} until canceled.
                      </Text>
                    )}
                  </>
                ) : (
                  <Text type="p">
                    <strong>+ ${pricePerQuantity}</strong> per channel
                  </Text>
                )}
              </Summary>
            </InnerContainer>
          </Section>
        </SectionContainer>

        <ButtonWrapper>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: () => void; label: ... Remove this comment to see the full error message */}
          <Button type="text" onClick={closeModal} label="Cancel" />
          {/* @ts-expect-error TS(2740) FIXME: Type '{ id: string; type: string; onClick: () => v... Remove this comment to see the full error message */}
          <Button
            id="confirm_and_pay"
            type="primary"
            onClick={(): void => {
              updateSubscriptionQuantity()
            }}
            label={isProcessing ? 'Processing...' : ctaButtonText}
            disabled={disableSumbitButton}
          />
        </ButtonWrapper>
      </>
    </PlanContext.Provider>
  )
}

export default CardBody
