import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import type { OrchestratorRootState } from '../../../../../common/events/types'
import { getMastodonServers } from './utils'

import { Avatar, Button, Input, Text } from '@bufferapp/ui'
import { MASTODON_SERVER_GENERIC } from './constants'
import useAuthorizationInfo from '../../hooks/useAuthorizationInfo/useAuthorizationInfo'
import Bugsnag from '@bugsnag/browser'

import * as StyledParentComponents from '../InstagramPreAuthenticate/InstagramPreAuthentication.styles'
import * as Styled from './styles'
import type { onRedirectParams } from '../../types'

type ServerSelectionProps = {
  onRedirect: (args: onRedirectParams) => void
  cancelConnection: () => void
}

function ServerSelection({
  onRedirect,
  cancelConnection,
}: ServerSelectionProps): JSX.Element | null {
  const inputRef = useRef()
  const [selectedServer, setSelectedServer] = useState<string | null>(null)
  const [customServer, setCustomServer] = useState<string>('')
  const [followBuffer, setFollowBuffer] = useState<boolean>(true)

  const { selectedService, isRefreshingChannelConnection } = useSelector(
    (state: OrchestratorRootState) => state.channelConnections,
  )

  const {
    handleGetAuthorizationInfo,
    url: redirectUrl,
    isAuthorizationInfoLoading: isLoading,
    authorizationInfoErrorMessage,
  } = useAuthorizationInfo()

  const isValidServer =
    (selectedServer === 'mastodon-generic' && customServer.length > 0) ||
    (selectedServer && selectedServer !== 'mastodon-generic')

  function onConfirmServer(): void {
    if (selectedService && selectedServer) {
      const serverValue =
        selectedServer === 'mastodon-generic' ? customServer : selectedServer

      handleGetAuthorizationInfo({
        service: selectedService,
        isRefreshingConnection: isRefreshingChannelConnection,
        server: serverValue,
        followBuffer,
      })
    } else {
      Bugsnag.notify(
        new Error(
          `onConfirmServer: handleGetAuthorizationInfo was not triggered because there is an issue with either the selected service: ${selectedService} or the selected server ${selectedServer}`,
        ),
      )
    }
  }

  const availableServers = getMastodonServers()

  useEffect(() => {
    if (redirectUrl) {
      onRedirect({ redirectUrl })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectUrl])

  return (
    <>
      <StyledParentComponents.Container>
        <StyledParentComponents.TitleSpacing>
          <Text type="h3">Choose your Mastodon server</Text>
        </StyledParentComponents.TitleSpacing>
        <StyledParentComponents.Body>
          <Styled.FieldSet>
            <legend>
              <h3>Which server would you like to connect to?</h3>
            </legend>
            {availableServers.map((server) => {
              return (
                <Styled.ListItem key={server.text} isDisabled={false}>
                  <Styled.Selection>
                    <Styled.Label htmlFor={server.text}>
                      <Styled.RadioWrapper>
                        <input
                          type="radio"
                          id={server.text}
                          name="mastodon-server"
                          value={server.url}
                          onChange={(event): void => {
                            setSelectedServer(event.target.value)
                          }}
                        />
                      </Styled.RadioWrapper>
                      <Avatar
                        src={server.image_url}
                        alt={`server image for ${server.text}`}
                      />
                      <Styled.TextGroup>
                        <span>{server.text}</span>
                      </Styled.TextGroup>
                      {server.url === MASTODON_SERVER_GENERIC && (
                        <Styled.InputWrapper
                          isVisible={selectedServer === 'mastodon-generic'}
                          hasError={Boolean(authorizationInfoErrorMessage)}
                          data-testid="custom-server-input"
                        >
                          <Input
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-expect-error TS(2322) FIXME: Type '{ onChange: (e: any) => void; name: string; ... Remove this comment to see the full error message
                            onChange={(e): void =>
                              setCustomServer(e.target.value.toLowerCase())
                            }
                            name="other"
                            ref={inputRef}
                            placeholder="example.server"
                            id="custom-server-input"
                            data-testid="custom-server-input"
                            help="Mastodon server doesn't exist or is unreachable"
                            hasError={Boolean(authorizationInfoErrorMessage)}
                          />
                        </Styled.InputWrapper>
                      )}
                    </Styled.Label>
                  </Styled.Selection>
                </Styled.ListItem>
              )
            })}
          </Styled.FieldSet>
        </StyledParentComponents.Body>
        <Styled.FollowBufferWrapper>
          <Styled.Label htmlFor={'follow-buffer'}>
            <legend>
              <h3>Follow Buffer on Mastodon</h3>
            </legend>
            <input
              type="checkbox"
              id="follow-buffer"
              name="mastodon-server-follow-buffer"
              onChange={(): void => {
                setFollowBuffer(!followBuffer)
              }}
              checked={followBuffer}
            />
            <Styled.TextGroup>
              <strong>Follow Buffer on Mastodon</strong>
            </Styled.TextGroup>
          </Styled.Label>
        </Styled.FollowBufferWrapper>
        <StyledParentComponents.CTAContainer>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error TS(2740) FIXME: Type '{ id: string; type: string; onClick: () => v... Remove this comment to see the full error message */}
          <Button
            id="cancel-ig-auth"
            type="text"
            onClick={(): void => cancelConnection()}
            label="Cancel"
          />
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error TS(2740) FIXME: Type '{ id: string; type: string; onClick: () => v... Remove this comment to see the full error message */}
          <Button
            id="go-to-service"
            type="primary"
            onClick={(): void => {
              onConfirmServer()
            }}
            label={isLoading ? 'Redirecting...' : 'Continue'}
            disabled={!isValidServer || isLoading}
          />
        </StyledParentComponents.CTAContainer>
      </StyledParentComponents.Container>
    </>
  )
}

export default ServerSelection
