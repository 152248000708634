// FIXME: Ignoring ban-ts-comment
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { ORGANIZATION_ACTIONS } from '../../events/orgEvents'
import { UPDATE_SUBSCRIPTION_PLAN } from '../../graphql/billing'
import { QUERY_ACCOUNT } from '../../graphql/account'
import { prefixWithProduct } from '../../utils/prefixWithProduct'

import type { BillingUpdateSubscriptionPlanPayload } from '../../types/graphQL/Billing'
import type {
  UseUpdateSubscriptionPlan,
  UseUpdateSubscriptionPlanResponse,
  ErrorState,
} from './types'

const useUpdateSubscriptionPlan = ({
  cta = '',
  user,
  plan,
  hasPaymentMethod,
  alreadyProcessing,
  channelsQuantity,
  upgradePathName = '',
}: UseUpdateSubscriptionPlan): UseUpdateSubscriptionPlanResponse => {
  const [processing, setProcessing] = useState<boolean | undefined>(
    alreadyProcessing,
  )
  const [error, setError] = useState<ErrorState>(null)

  const [updateSubscriptionPlan, { data, error: mutationError }] =
    useMutation<BillingUpdateSubscriptionPlanPayload>(
      UPDATE_SUBSCRIPTION_PLAN,
      {
        refetchQueries: [{ query: QUERY_ACCOUNT }],
        awaitRefetchQueries: true,
        onCompleted: () => {
          ORGANIZATION_ACTIONS.billingUpdated({ user })
        },
      },
    )

  useEffect(() => {
    if (!user || !plan) {
      // eslint-disable-next-line no-console
      console.error(
        'Could not update plan because either: user or plan are undefined',
      )
    }
  }, [hasPaymentMethod, user, plan, updateSubscriptionPlan, channelsQuantity])

  function updateSubscription(): void {
    setProcessing(true)
    updateSubscriptionPlan({
      variables: {
        organizationId: user?.currentOrganization?.id,
        plan: plan.planId,
        interval: plan.planInterval,
        quantity: channelsQuantity,
        attribution: { cta: prefixWithProduct(cta), upgradePathName },
      },
    }).catch((e) => {
      console.error(e) // eslint-disable-line no-console
    })
  }

  useEffect(() => {
    if (mutationError) {
      setError(mutationError)
      // @ts-expect-error TS(2339) FIXME: Property 'userFriendlyMessage' does not exist on t... Remove this comment to see the full error message
    } else if (data?.billingUpdateSubscriptionPlan?.userFriendlyMessage) {
      setError({
        // @ts-expect-error TS(2339) FIXME: Property 'userFriendlyMessage' does not exist on t... Remove this comment to see the full error message
        message: data.billingUpdateSubscriptionPlan?.userFriendlyMessage,
      })
      setProcessing(false)
    }
  }, [mutationError, data])

  return {
    updateSubscriptionPlan: () => updateSubscription(),
    data,
    error,
    processing,
  }
}

export default useUpdateSubscriptionPlan
