import React, { useCallback, useMemo } from 'react'
import {
  DropdownMenu,
  IconButton,
  HelpIcon,
  StatusDot,
} from '@buffer-mono/popcorn'
import { NavDropdownItem } from '../../utils/dropdown-items'

import styles from './HelpMenu.module.css'

type cb = () => void

type HandlersMap = {
  [key: string]: (e: Event) => void
}

type HelpMenuProps = {
  hideNewTag: () => Promise<void>
  showNewBadge: boolean
  items: Array<NavDropdownItem>
}

const HelpMenu = ({
  hideNewTag,
  showNewBadge,
  items,
}: HelpMenuProps): JSX.Element => {
  const handleOpenChange = (open: boolean) => {
    if (open) {
      hideNewTag()
    }
  }

  /* using useCallback and useMemo to avoid unnecessary re-renders */
  const handleItemClick = useCallback(async (e: Event, onItemClick?: cb) => {
    e.preventDefault()
    if (onItemClick) onItemClick()
  }, [])

  const itemClickHandlers = useMemo(() => {
    return items.reduce<HandlersMap>((acc, item) => {
      acc[item.id] = (e: Event) => handleItemClick(e, item?.onItemClick)
      return acc
    }, {})
  }, [items, handleItemClick])

  return (
    <DropdownMenu
      modal={false}
      onOpenChange={handleOpenChange}
      trigger={
        <IconButton
          variant="tertiary"
          label="Help Menu"
          size="large"
          className={styles.triggerButton}
        >
          <>
            <HelpIcon />
            {showNewBadge && (
              <StatusDot data-testid="new-badge" className={styles.statusDot} />
            )}
          </>
        </IconButton>
      }
    >
      {items.map((item) => (
        <DropdownMenu.Item
          key={item.id}
          id={item.id}
          onSelect={itemClickHandlers[item.id]}
          shortcut={item?.shortcuts?.join(' ').toUpperCase()}
        >
          {item.title}
          {item.new && (
            <StatusDot
              className={styles.statusDotInMenuItem}
              data-testid="help-menu-item-new-badge"
            />
          )}
        </DropdownMenu.Item>
      ))}
    </DropdownMenu>
  )
}

export default HelpMenu
