import React, { useEffect } from 'react'
import type { Action } from 'kbar'
import type { Channel } from '../../common/types'

export type ChannelActionOverrides = Record<
  string,
  (a: Action | { channel?: Channel }) => void
>

export function useGlobalContextActions({
  isEnabled,
}: {
  isEnabled?: boolean
}): {
  contextActions: Action[]
  channelActionOverrides: ChannelActionOverrides
} {
  const [contextActions, setContextActions] = React.useState<Action[]>([])
  const [channelActionOverrides, setChannelActionOverrides] =
    React.useState<ChannelActionOverrides>({})
  useEffect(() => {
    if (isEnabled && !window.bufferQuickNavigator) {
      window.bufferQuickNavigator = {
        registerActions: (actions: Action[]): void => {
          setContextActions(actions)
        },
        registerChannelActionOverrides: (
          overrides: ChannelActionOverrides,
        ): void => {
          setChannelActionOverrides(overrides)
        },
      }
    }
  }, [isEnabled, setContextActions])

  // useRegisterActions(contextActions, [contextActions])
  return { contextActions, channelActionOverrides }
}
