import styled from 'styled-components'

export const ImagePlaceholder = styled.div`
  width: 100%;
  height: 215px;
  border-radius: 4px;
  margin-bottom: 36px;
  background-image: url('https://buffer-publish.s3.amazonaws.com/images/facebook-group-reminders-banner.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #d5dbff;
`
