import { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import eventDispatcher from '../utils/eventDispatcher'
import { AppshellEventKeys, OrganizationActionKeys } from '../events/types'
import { QUERY_ACCOUNT, SET_CURRENT_ORGANIZATION } from '../graphql/account'
import { getActiveProductFromPath } from '../utils/getProduct'

function useOrgSwitcher() {
  const [setCurrentOrganization] = useMutation(SET_CURRENT_ORGANIZATION)
  function updateCache(organizationId: any, client: any) {
    const previousData = client.readQuery({ query: QUERY_ACCOUNT }) || {}
    const organizationSelected = previousData?.account?.organizations?.filter(
      (organization: any) => organization.id === organizationId,
    )[0]
    const updatedData = {
      account: {
        ...previousData.account,
        currentOrganization: organizationSelected,
      },
    }

    client.writeQuery({
      query: QUERY_ACCOUNT,
      data: updatedData,
    })
  }

  async function updateOrganization(organizationId: any, options = {}) {
    await setCurrentOrganization({
      variables: {
        organizationId,
      },
      update: (client) => updateCache(organizationId, client),
    })

    eventDispatcher(AppshellEventKeys.ORGANIZATION_EVENT_KEY, {
      organizationId,
    })

    BufferTracker.organizationSwitched({
      organizationId,
      product: getActiveProductFromPath(),
    })

    // Needed, as the onCompleted is not triggered when passed as an option in the mutate function.
    // @ts-expect-error TS(2339) FIXME: Property 'onCompleted' does not exist on type '{}'... Remove this comment to see the full error message
    if (options.onCompleted) {
      // @ts-expect-error TS(2339) FIXME: Property 'onCompleted' does not exist on type '{}'... Remove this comment to see the full error message
      options.onCompleted(organizationId)
    }
  }

  // set organization from event
  function handleUpdateOrganization({ detail }: any) {
    const { action, organizationId: orgId, options: updateOptions } = detail

    switch (action) {
      case OrganizationActionKeys.setCurrentOrganization:
        updateOrganization(orgId, updateOptions)
        break
      default:
        break
    }
  }
  useEffect(() => {
    window.addEventListener(
      AppshellEventKeys.ORGANIZATION_EVENT_KEY,
      handleUpdateOrganization,
    )

    return function cleanup() {
      window.removeEventListener(
        AppshellEventKeys.ORGANIZATION_EVENT_KEY,
        handleUpdateOrganization,
      )
    }
  }, [])

  return updateOrganization
}

export default useOrgSwitcher
