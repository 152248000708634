import React from 'react'
import { Flag, People, Person } from '@bufferapp/ui/Icon'

import { ChannelAccountType } from '../../types'
import FacebookGroupDescription from './FacebookGroupDescription'

export const HC_UTM_PARAMS =
  'utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more'

export const ACCOUNT_OPTIONS_FOR_CHANNELS = {
  linkedin: [
    {
      accountType: ChannelAccountType.page,
      header: 'LinkedIn Page',
      icon: <Flag />,
      description: 'A company profile that LinkedIn users can follow',
    },
    {
      accountType: ChannelAccountType.profile,
      header: 'LinkedIn Profile',
      icon: <Person />,
      description:
        'A profile that showcases an individualU+2019s professional experience',
    },
  ],
  facebook: [
    {
      accountType: ChannelAccountType.page,
      header: 'Facebook Page',
      icon: <Flag />,
      description: 'A public profile that Facebook users “like”',
    },
    {
      accountType: ChannelAccountType.group,
      header: 'Facebook Group',
      icon: <People />,
      description: <FacebookGroupDescription />,
    },
  ],
}
