import React, { useEffect } from 'react'
import { format } from 'date-fns'

import {
  Button,
  Heading,
  Image,
  Paragraph,
  UpgradeDialog,
} from '@buffer-mono/popcorn'

import { useUser } from '../../../../common/context/User'
import getCopy from './getCopy'
import trackTrialWelcomeModalViewed from '../../../../tracking/TrialPaths/trackTrialWelcomeModalViewed'
import { getSubscriptionPeriodEnd } from '../../../../common/utils/billing'
import { MODALS } from '../../../../common/types'
import { shouldShowChannelConnectionPrompt } from '../../utils'
import { ConfirmationFeaturesList } from './components/ConfirmationFeaturesList'
import { getUrlEnvModifier } from '../../../../common/utils/urls'
import {
  getOrganizationId,
  getUserBillingData,
} from '../../../../common/utils/user'

import { useModalManager } from '../../../ModalManager/hooks/useModalManager'

const Confirmation = (): JSX.Element | null => {
  const user = useUser()
  const { modalData, openModal, dismissModal: closeAction } = useModalManager()

  if (!modalData || !modalData?.selectedPlan) {
    return null
  }

  const {
    selectedPlan,
    startedTrial,
    stayedOnSamePlan,
    isMigrationFromMP,
    isDowngradingToFreePlan,
    scheduledUpdate,
    currentPlanId,
    currentPlanChannelQuantity,
    cta,
  } = modalData

  const planName = selectedPlan?.planName || ''
  const billingData = getUserBillingData(user)
  const subscriptionEndDate =
    billingData && getSubscriptionPeriodEnd(billingData)
  const planEndDate = subscriptionEndDate
    ? format(new Date(subscriptionEndDate), 'dd/MM/yyyy')
    : format(new Date(), 'dd/MM/yyyy') // Todays date
  const organizationId = getOrganizationId(user)
  const env = getUrlEnvModifier()

  const { label, description, buttonCopy, imageUrl, footer, featuresList } =
    getCopy({
      planName,
      startedTrial,
      stayedOnSamePlan,
      isMigrationFromMP,
      isDowngradingToFreePlan,
      planEndDate,
      organizationId,
      env,
      scheduledUpdate,
      currentPlanId,
      currentPlanChannelQuantity,
    })

  const onCloseHandle = (): void => {
    /* when the modal is closed, we check if we should open the channelconnection prompt */
    closeAction()
    if (shouldShowChannelConnectionPrompt(user)) {
      openModal({ key: MODALS.channelConnectionPrompt })
    }
  }

  useEffect(() => {
    const upgradePathName = 'trialWelcomeModal'
    const payload = {
      ctaButton: 'trialStarted',
      upgradePathName,
      ctaView: 'trialWelcomeModal',
      ctaLocation: 'trialWelcomeModal',
    }
    if (startedTrial) {
      trackTrialWelcomeModalViewed({
        payload,
        user,
      })
    }
  }, [user, startedTrial])

  return (
    <UpgradeDialog open={true} onOpenChange={onCloseHandle}>
      <UpgradeDialog.Content>
        <UpgradeDialog.TextContent>
          <Heading size="medium">{label}</Heading>
          <Paragraph>{description}</Paragraph>
          {startedTrial ? (
            <ConfirmationFeaturesList featuresList={featuresList} />
          ) : (
            <Button
              id="confirmation"
              variant="primary"
              data-testid="confirmation-button"
              aria-label={buttonCopy}
              onClick={onCloseHandle}
            >
              {buttonCopy}
            </Button>
          )}
          {footer && footer}
        </UpgradeDialog.TextContent>
        <UpgradeDialog.Image>
          <Image alt="Start Trial" src={imageUrl} />
        </UpgradeDialog.Image>
      </UpgradeDialog.Content>
    </UpgradeDialog>
  )
}

export default Confirmation
