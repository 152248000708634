import { Link, Paragraph } from '@buffer-mono/popcorn'
import React from 'react'
import {
  HC_UTM_PARAMS,
  TRIAL_CHANNELS_LIMIT,
} from '../../../../../common/utils/constants'
import usePlanInformation from '../hooks/usePlanInformation'
import { calculateAdditionalChannelsPrice } from '../calculateNextChannelPrice'

const addOrRemovingChannelSlotLink = `https://support.buffer.com/article/539-adding-or-removing-a-channel-slot-from-buffer?${HC_UTM_PARAMS}`

export const ConnectChannelUpgradeMessage = (props: {
  totalChannelsCount: number
  lockedChannelIds: string[]
  isOnTieredPricing: boolean
}): JSX.Element => {
  const {
    quantity: channelLimit,
    billingType,
    pricePerQuantity,
    planInterval,
    pricingTiers,
  } = usePlanInformation()
  const { totalChannelsCount, lockedChannelIds, isOnTieredPricing } = props

  const priceToDisplay =
    isOnTieredPricing && pricingTiers
      ? calculateAdditionalChannelsPrice({
          existingChannelCount: channelLimit,
          channelsToAdd: lockedChannelIds.length,
          pricingTiers,
        })
      : pricePerQuantity * lockedChannelIds.length

  if (billingType === 'free') {
    return (
      <>
        <Paragraph>
          Your free Buffer plan includes up to 3 social channels. By connecting
          each of these channels, you&apos;ve gone past the limit.
        </Paragraph>
        <Paragraph>
          Not to worry! If you&apos;d like to use all these channels with
          Buffer, please consider upgrading to one of our paid plans. If you
          need time to review your other connected channels, no problem! We will
          keep any extra channels you&apos;ve connected locked in the meantime.
        </Paragraph>
      </>
    )
  }
  if (billingType === 'trial') {
    return (
      <>
        <Paragraph>
          You’ve reached the maximum number of channel slots available on your
          free trial
          {' ('}
          <strong>{TRIAL_CHANNELS_LIMIT} slots</strong>
          {')'}. To use all of your {totalChannelsCount} connected channels,
          please subscribe to a paid Buffer plan.
        </Paragraph>
      </>
    )
  }
  return (
    <>
      <Paragraph>
        You’ve reached the maximum number of channel slots available {'('}
        <strong>
          {channelLimit} slot{channelLimit > 1 ? 's' : ''}
        </strong>
        {')'}. You can start using this channel today by{' '}
        <Link href={addOrRemovingChannelSlotLink}>
          purchasing another channel slot{' '}
        </Link>
        - by doing so, you will be charged a pro-rated amount today and your
        subscription plan will be increased by{' '}
        <strong>
          ${priceToDisplay} per {planInterval}
        </strong>{' '}
        moving forward.
      </Paragraph>
      <Paragraph>
        If you need time to review your other connected channels to make space
        on your current plan, no problem! We will keep{' '}
        {lockedChannelIds.length > 1 ? 'these channels' : 'this channel'} locked
        in the meantime.
      </Paragraph>
    </>
  )
}
