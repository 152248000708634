import React from 'react'
import PropTypes from 'prop-types'

import {
  InstagramIcon,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  TiktokIcon,
  BlueskyIcon,
  MastodonIcon,
  YoutubeIcon,
  ThreadsIcon,
  TwitterIcon,
} from '@buffer-mono/popcorn'

import Counter from '../Counter/Counter'
import UXMessaging, { type MessageStatus } from '../UXMessaging/UXMessaging'

import {
  ChannelsContainer,
  Icons,
  Title,
  ChannelsInputContainer,
} from './Channels.style'

type ChannelsProps = {
  channelsCount: number
  onDecreaseCounter: () => void
  onIncreaseCounter: () => void
  setChannelsCounterValue: (value: number) => void
  channelCounterMessageStatus: MessageStatus | null
  isSmallLayout: boolean
}

const Channels = ({
  channelsCount,
  onDecreaseCounter,
  onIncreaseCounter,
  setChannelsCounterValue,
  channelCounterMessageStatus,
  isSmallLayout,
}: ChannelsProps): JSX.Element => {
  return (
    <ChannelsContainer>
      <Title isInline={isSmallLayout}>
        <span>Channels</span>
        <Icons isInline={isSmallLayout}>
          <InstagramIcon size="xsmall" color="subtle" />
          <FacebookIcon size="xsmall" color="subtle" />
          <TwitterIcon size="xsmall" color="subtle" />
          <PinterestIcon size="xsmall" color="subtle" />
          <LinkedinIcon size="xsmall" color="subtle" />
          <TiktokIcon size="xsmall" color="subtle" />
          <MastodonIcon size="xsmall" color="subtle" />
          <YoutubeIcon size="xsmall" color="subtle" />
          <ThreadsIcon size="xsmall" color="subtle" />
          <BlueskyIcon size="small" color="subtle" />
          <span>+2</span>
        </Icons>
      </Title>
      <ChannelsInputContainer>
        <Counter
          channelsCount={channelsCount}
          onIncreaseCounter={(): void => onIncreaseCounter()}
          onDecreaseCounter={(): void => onDecreaseCounter()}
          setChannelsCounterValue={(value): void =>
            setChannelsCounterValue(value)
          }
        />
      </ChannelsInputContainer>
      {channelCounterMessageStatus && (
        <UXMessaging
          messageStatus={channelCounterMessageStatus.messageStatus}
          message={channelCounterMessageStatus.message}
        />
      )}
    </ChannelsContainer>
  )
}

Channels.propTypes = {
  channelsCount: PropTypes.number,
  onDecreaseCounter: PropTypes.func.isRequired,
  onIncreaseCounter: PropTypes.func.isRequired,
  setChannelsCounterValue: PropTypes.func.isRequired,
  channelCounterMessageStatus: PropTypes.shape({
    message: PropTypes.string.isRequired,
    messageStatus: PropTypes.string,
  }),
  isSmallLayout: PropTypes.bool,
}

Channels.defaultProps = {
  channelsCount: 0,
  channelCounterMessageStatus: null,
  isSmallLayout: false,
}

export default Channels
