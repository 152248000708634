import {
  BufferTrackerReact as BufferTracker,
  Client,
} from '@buffer-mono/tracking-plan'
import type { Account } from '../common/types'
import { getActiveProductFromPath } from '../common/utils/getProduct'
import { getOrganization } from '../common/utils/user'
import type {
  ChannelAccountType,
  InstagramAuthMethod,
  Service,
} from '../exports/Orchestrator/channelConnections/types'
import {
  getCommonTrackingPropertiesFromAccount,
  shouldPerformTrackingEvent,
} from './utils'
import { getGlobalActionCTAFromQueryParams } from '../common/utils/urls'

export interface ChannelConnectionStartedTrackingPayload {
  service: Service
  cta?: string
  channelType?: ChannelAccountType
  authMethod?: InstagramAuthMethod
}

export interface ChannelConnectionStartedTrackingArguments {
  payload: ChannelConnectionStartedTrackingPayload
  user: Account
}

export default function trackChannelConnectionStarted({
  payload,
  user,
}: ChannelConnectionStartedTrackingArguments): void {
  const canPerformTrackingEvent = shouldPerformTrackingEvent({
    user,
  })

  if (!canPerformTrackingEvent) {
    return
  }

  const globalActionCTA = getGlobalActionCTAFromQueryParams()

  const { service, cta, channelType, authMethod } = payload

  const organization = getOrganization(user)
  const product = getActiveProductFromPath()

  const ctaValue = globalActionCTA || cta

  const commonTrackingProperties = getCommonTrackingPropertiesFromAccount(user)

  if (organization?.id) {
    const eventData = {
      organizationId: organization?.id,
      cta: ctaValue,
      clientName: Client.PublishWeb,
      product,
      channel: service,
      upgradePathName: null,
      channelType,
      apiVersion: authMethod,
      ...commonTrackingProperties,
    }
    BufferTracker.channelConnectionStarted(eventData)
  }
}
