export const getMixedMediaError = ({
  supportsMultipleImages,
  supportsGif,
}: {
  supportsMultipleImages: boolean
  supportsGif: boolean
}): string => {
  let message = 'We can only attach one type of file at the same time: either '
  message += supportsMultipleImages ? 'images' : 'an image'
  message += supportsGif ? ', or a gif,' : ''
  message += ' or a video.<br/>'
  message += 'Could you try with only one of those?'

  return message
}
