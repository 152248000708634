import gql from 'graphql-tag'

export const CREATE_CUSTOM_CHANNELS = gql`
  mutation CreateCustomChannels($input: CreateCustomChannelsInput!) {
    createCustomChannels(input: $input) {
      ... on CreateCustomChannelsResponse {
        channels {
          id
        }
      }
      ... on CustomChannelError {
        message
      }
    }
  }
`

export const UPDATE_CUSTOM_CHANNEL = gql`
  mutation UpdateCustomChannel($input: UpdateCustomChannelInput!) {
    updateCustomChannel(input: $input) {
      ... on UpdateCustomChannelResponse {
        channel {
          id
        }
      }
      ... on CustomChannelError {
        message
      }
    }
  }
`
