import styled from 'styled-components'
import { white, gray, blue, grayDarker } from '@bufferapp/ui/style/colors'

export const Content = styled.div`
  max-width: 1092px;
  height: 468px;
  background: ${white};
  padding: 64px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    margin: 0 0 16px;
  }

  p {
    margin: 0 0 18px;
    color: ${grayDarker};
  }

  li {
    color: ${grayDarker};
  }
`

export const Video = styled.div`
  @media screen and (max-width: 1092px) {
    display: none;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 520px;
  margin-left: 64px;

  .react-player-wrapper {
    > div:first-of-type {
      border-radius: 8px;
    }
  }

  label {
    margin-top: 12px;
    font-size: 12px;
    font-weight: 400;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  max-width: 378px;
`

export const List = styled.ul`
  margin: 0 0 10px;
  padding: 0;
  list-style: none;
  align-items: center;

  li {
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: 12px;

    span {
      font-size: 14px;
    }
  }
`

export const Check = styled.span`
  display: flex;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  margin-right: 11px;
  border-radius: 36px;

  svg {
    color: ${blue};
  }
`

export const CTAs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;

  a {
    margin-left: 32px;
    font-weight: 700;
  }

  span {
    margin-top: 8px;
    margin-left: 8px;
    font-size: 14px;
    color: ${gray};
  }

  > * {
    flex-shrink: 0;
  }
`
