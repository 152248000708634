import React from 'react'

import {
  Avatar,
  BufferIcon,
  Button,
  Card,
  ChannelIcon,
  Heading,
  SuccessDialog,
  Text,
} from '@buffer-mono/popcorn'

import type { Service } from '../../../../../../gql/graphql'

import styles from './NewChannelRefreshSuccess.module.css'

type NewChannelRefreshSuccessDialogProps = {
  handleOnDismissModal: () => void
  channelData: {
    avatar: string
    name: string
    service: string
  }
  socialChannelId: string
  CTACopy: string
  handleCreatePostCTA: (args: {
    socialChannelId: string
    handleOnDismissModal: () => void
  }) => void
}

const NewChannelRefreshSuccessDialog = ({
  handleOnDismissModal,
  channelData,
  socialChannelId,
  CTACopy,
  handleCreatePostCTA,
}: NewChannelRefreshSuccessDialogProps): JSX.Element => {
  const DEFAULT_AVATAR = 'https://s3.amazonaws.com/buffer-ui/Default+Avatar.png'

  return (
    <SuccessDialog open={true} onOpenChange={handleOnDismissModal}>
      <SuccessDialog.Content className={styles.content}>
        <div className={styles.logoAvatarWrapper}>
          <Card className={styles.logoWrapper}>
            <BufferIcon size="large" color="brand" />
          </Card>
          <div className={styles.avatarChannelIconWrapper}>
            <Avatar
              src={channelData.avatar || DEFAULT_AVATAR}
              alt={channelData.name}
              size="large"
            />
            <div className={styles.channelIconWrapper}>
              <ChannelIcon
                color="inverted"
                size="medium"
                type={channelData.service as Service}
              />
            </div>
          </div>
        </div>
        <SuccessDialog.Title className={styles.title}>
          <Heading size="large">Great! You've refreshed your Channel</Heading>
        </SuccessDialog.Title>
        <SuccessDialog.Description className={styles.description}>
          <Text>
            You've successfully refreshed your channel to Buffer. Now you can
            continue scheduling posts to it.
          </Text>
        </SuccessDialog.Description>
        <SuccessDialog.Actions>
          <Button
            id="create_a_post"
            className={styles.button}
            variant="primary"
            size="large"
            onClick={(): void =>
              handleCreatePostCTA({ socialChannelId, handleOnDismissModal })
            }
          >
            {CTACopy}
          </Button>
        </SuccessDialog.Actions>
      </SuccessDialog.Content>
    </SuccessDialog>
  )
}

export default NewChannelRefreshSuccessDialog
