export type BillingResponse = {
  success: boolean
}

export type BillingError = {
  userFriendlyMessage: string
}

export type ScheduledSubscriptionUpdate = {
  effectiveDate: string
  newInterval: string
  newPlan: string
  newQuantity: number
}

type BillingUpdateSubscriptionPlanResponse = {
  success: boolean
  scheduledUpdate?: ScheduledSubscriptionUpdate
}

export type BillingUpdateSubscriptionPlanPayload = {
  billingUpdateSubscriptionPlan:
    | BillingUpdateSubscriptionPlanResponse
    | BillingError
}

export type BillingUpdateSubscriptionQuantityPayload = {
  billingUpdateSubscriptionQuantity:
    | BillingUpdateSubscriptionPlanResponse
    | BillingError
}

// https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
export function isBillingResponse(
  response: BillingError | BillingUpdateSubscriptionPlanResponse | undefined,
): response is BillingUpdateSubscriptionPlanResponse {
  return (
    (response as BillingUpdateSubscriptionPlanResponse)?.success !== undefined
  )
}
