import i18next from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import enTranslation from './translations/en-us.json'

const resources = {
  'en-US': {
    translation: enTranslation,
  },
}
const i18nInstance = i18next.createInstance()

i18nInstance
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en-US',
    debug: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: true,
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
    },
  })

export { useTranslation, i18nInstance as i18n }
