import {
  BufferTrackerReact as BufferTracker,
  Client,
} from '@buffer-mono/tracking-plan'

import type { Account } from '../../common/types'
import { shouldPerformTrackingEvent } from '../utils'
import { getActiveProductFromPath } from '../../common/utils/getProduct'
import { getUsersCurrentPlan } from '../../common/utils/user'
import type { ChannelType, DayOfWeek, Service } from '../../gql/graphql'

export type TrackPostingPlanCreatedPayload = {
  channel: Service
  channelType?: ChannelType
  channelUsername: string
  days: DayOfWeek[]
  timesPerDay: number
}

export type TrackPostingPlanCreatedArguments = {
  payload: TrackPostingPlanCreatedPayload
  user: Account
}

export default function trackPostingPlanCreated({
  payload,
  user,
}: TrackPostingPlanCreatedArguments): void {
  const canPerformTrackingEvent = shouldPerformTrackingEvent({
    user,
  })

  if (!canPerformTrackingEvent) {
    return
  }

  const { channel, channelType, channelUsername, days, timesPerDay } = payload

  const organization = user.currentOrganization
  const product = getActiveProductFromPath()
  const currentPlanData = getUsersCurrentPlan(user)

  const eventData = {
    organizationId: organization?.id || '',
    currentPlan: currentPlanData?.id || '',
    clientName: Client.PublishWeb,
    product,
    channel,
    channelType,
    channelUsername,
    days,
    timesPerDay,
  }

  BufferTracker.postingPlanCreated(eventData)
}
