import type { PricingTier } from '../../../../common/types'

export const calculateAdditionalChannelsPrice = (args: {
  existingChannelCount: number
  channelsToAdd: number
  pricingTiers: Array<PricingTier>
}): number => {
  const { existingChannelCount, channelsToAdd, pricingTiers } = args

  let price = 0
  for (let i = 0; i < channelsToAdd; i++) {
    price += calculateNextChannelPrice({
      existingChannelCount: existingChannelCount + i,
      pricingTiers,
    })
  }

  return price
}

export const calculateNextChannelPrice = (args: {
  existingChannelCount: number
  pricingTiers: Array<PricingTier>
}): number => {
  const { existingChannelCount, pricingTiers } = args

  if (pricingTiers.length === 0) {
    throw new Error('No pricing tiers found')
  }

  // Find the maximum tier reached by the existing channel count + 1
  const maxTierReachedIndex = pricingTiers.findIndex(
    (tier) => tier.upperBound >= existingChannelCount + 1,
  )
  const maxTier = pricingTiers[maxTierReachedIndex]

  return maxTier.unitPrice
}
