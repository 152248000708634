import React, { useEffect, useState } from 'react'
import { Dialog } from '@buffer-mono/popcorn'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import { CreatorLimitations } from './components/CreatorLimitations'
import { UnlockMoreFeatures } from './components/UnlockMoreFeatures'
import { RedirectingToFacebook } from './components/RedirectingToFacebook'
import { useUser } from '../../../../common/context/User'

import { useModalManager } from '../../../ModalManager/hooks/useModalManager'

export function InstagramFacebookReAuth(): JSX.Element {
  const { modalData: data, dismissModal: closeAction } = useModalManager()
  const isCreatorChannel = data?.channelType === 'creator'

  const [currentScreen, setCurrentScreen] = useState(
    isCreatorChannel ? 'creatorLimitations' : 'unlockMoreFeatures',
  )

  const user = useUser()

  useEffect(() => {
    BufferTracker.channelLinkToFacebookPageDialogViewed({
      organizationId: user?.currentOrganization?.id || '',
      channel: 'instagram',
      channelId: data?.channelId || '',
      clientName: 'publishWeb',
    })
  }, [])

  return (
    <Dialog
      open
      onOpenChange={() => {
        BufferTracker.channelLinkToFacebookPageAborted({
          organizationId: user?.currentOrganization?.id || '',
          channel: 'instagram',
          channelId: data?.channelId || '',
          clientName: 'publishWeb',
        })

        closeAction()
      }}
    >
      <Dialog.Content size="xlarge">
        {currentScreen === 'creatorLimitations' && isCreatorChannel && (
          <CreatorLimitations
            setCurrentScreen={setCurrentScreen}
            closeAction={closeAction}
          />
        )}
        {currentScreen === 'unlockMoreFeatures' && (
          <UnlockMoreFeatures setCurrentScreen={setCurrentScreen} />
        )}
        {currentScreen === 'redirectingToFacebook' && (
          <RedirectingToFacebook
            setCurrentScreen={setCurrentScreen}
            channelId={data?.channelId || ''}
          />
        )}
      </Dialog.Content>
    </Dialog>
  )
}
