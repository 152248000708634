import React, { useEffect, useRef } from 'react'
import clsx from 'clsx'

import { Button, Dialog, Flex, Heading, Text } from '@buffer-mono/popcorn'

import { getAppLink, getDeviceType } from '../../utils'
import { getActiveProductFromPath } from '../../../../common/utils/getProduct'

import styles from './DownloadAppModal.module.css'

type ModalProps = {
  closeAction: () => void
}

type ModalContentProps = {
  closeAction: () => void
  productIsResponsive: boolean
}

const DownloadAppContent = ({
  closeAction,
  productIsResponsive,
}: ModalContentProps): JSX.Element => {
  const ctaButtonRef = useRef<HTMLAnchorElement>(null)
  const deviceUsed = getDeviceType()

  useEffect(() => {
    if (ctaButtonRef.current) {
      ctaButtonRef.current.focus() // Set focus to the cta button
    }
  }, [])

  return (
    <Flex justify="center" direction="column" align="center" gap="lg">
      <Dialog.Body>
        <Flex justify="center" direction="column" align="center" gap="xs">
          <Heading
            as="h3"
            className={
              productIsResponsive ? styles.headingResponsive : styles.heading
            }
          >
            Welcome to Buffer 👋
          </Heading>
          <Text
            align="center"
            className={
              productIsResponsive
                ? styles.descriptiongResponsive
                : styles.description
            }
          >
            To enjoy the full Buffer experience, please download the app on your
            device.
          </Text>
        </Flex>
      </Dialog.Body>
      <Dialog.Footer>
        <Button
          as="a"
          href={getAppLink(deviceUsed)}
          target="_blank"
          ref={ctaButtonRef}
          size="large"
          variant="primary"
          onClick={closeAction}
          className={
            productIsResponsive ? styles.ctaButtonResponsive : styles.ctaButton
          }
        >
          Download {deviceUsed} App
        </Button>
      </Dialog.Footer>
    </Flex>
  )
}

const DownloadAppModal = ({ closeAction }: ModalProps): JSX.Element => {
  const activeProduct = getActiveProductFromPath()
  const productIsResponsive = activeProduct !== 'publish'

  return (
    <Dialog open={true} onOpenChange={closeAction}>
      <Dialog.Content
        className={clsx(
          styles.wrapper,
          productIsResponsive && styles.wrapperResponsive,
        )}
      >
        <DownloadAppContent
          closeAction={closeAction}
          productIsResponsive={productIsResponsive}
        />
        <Dialog.CloseButton
          className={
            productIsResponsive
              ? styles.closeButtonResponsive
              : styles.closeButton
          }
        />
      </Dialog.Content>
    </Dialog>
  )
}

export default DownloadAppModal
