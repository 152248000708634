import React from 'react'
import Text from '@bufferapp/ui/Text'

import { findPlanUserDetails } from '../../../../common/utils/product'
import useGetTotalChargeAmount from '../../../../common/hooks/ChargeAmount/useGetTotalChargeAmount'
import {
  isPaidRevenueCatBillingGateway,
  isOnActiveTrial,
} from '../../../../common/utils/user'

import CurrentPlanInfo from './components/CurrentPlanInfo'
import UpdatedPlanInfo from './components/UpdatedPlanInfo'
import PaymentPlanInfo from './components/PaymentPlanInfo'

import { Body, SummaryContainer } from './style'

const Summary = ({
  planOptions,
  selectedPlan,
  channelsCount,
  increaseCounter,
  decreaseCounter,
  newPrice,
  channelCounterMessageStatus,
  currentChannelQuantity,
  isPaymentMethodSummary,
  user,
  setChannelsCounterValue,
  trialInfo,
  isStripeCheckout,
}: any) => {
  const isPaidRevenueCat = isPaidRevenueCatBillingGateway(user)
  const shouldShowUpdatedPlanInfo = !isPaidRevenueCat

  const isOnTrial = isOnActiveTrial(user)

  const currentPlan = planOptions.find((option: any) => option.isCurrentPlan)

  const {
    planName: currentPlanName,
    totalPrice: currentPlanPricing,
    planInterval: currentPlanInterval,
    summary: currentPlanSummary,
    planId: currentPlanId,
  } = currentPlan
  const currentPlanUsersText = findPlanUserDetails(currentPlanSummary.details)
  const {
    planName: selectedPlanName,
    planInterval: selectedPlanInterval,
    summary: selectePlanSummary,
    planId: selectedPlanId,
    isCurrentPlan,
  } = selectedPlan
  const selectedPlanUsersText = findPlanUserDetails(selectePlanSummary.details)

  const hasPlanCounterChanged =
    !isCurrentPlan || currentChannelQuantity !== channelsCount

  const { loading, totalAmountDue, nextBillingDate, isScheduledChange } =
    useGetTotalChargeAmount(
      user,
      selectedPlanId,
      selectedPlanInterval,
      channelsCount,
      currentPlanId,
      newPrice,
      trialInfo?.isActive,
      hasPlanCounterChanged,
    )

  const paymentInfo = isPaymentMethodSummary ? (
    <PaymentPlanInfo
      planName={selectedPlanName}
      planCycle={selectedPlanInterval}
      numberOfUsers={selectedPlanUsersText}
      channelsCount={channelsCount}
      newPrice={newPrice}
      // @ts-expect-error TS(2322) FIXME: Type '{ planName: any; planCycle: any; numberOfUse... Remove this comment to see the full error message
      channelCounterMessageStatus={channelCounterMessageStatus}
    />
  ) : (
    shouldShowUpdatedPlanInfo && (
      <UpdatedPlanInfo
        planName={selectedPlanName}
        planCycle={selectedPlanInterval}
        numberOfUsers={selectedPlanUsersText}
        channelsCount={channelsCount}
        increaseCounter={(): void => increaseCounter()}
        decreaseCounter={(): void => decreaseCounter()}
        newPrice={newPrice}
        channelCounterMessageStatus={channelCounterMessageStatus}
        isNewInvoiceLoading={loading}
        amountDueToday={totalAmountDue || 0}
        hasPlanCounterChanged={hasPlanCounterChanged}
        setChannelsCounterValue={(value): void =>
          setChannelsCounterValue(value)
        }
        isOnTrial={isOnTrial}
        nextBillingDate={nextBillingDate}
        isScheduledChange={isScheduledChange}
        isStripeCheckout={isStripeCheckout}
      />
    )
  )

  return (
    <SummaryContainer>
      <Body>
        <Text type="h2">Summary</Text>

        <CurrentPlanInfo
          planName={currentPlanName}
          planPrice={currentPlanPricing}
          planCycle={currentPlanInterval}
          numberOfChannels={currentChannelQuantity}
          numberOfUsers={currentPlanUsersText}
        />
        {paymentInfo}
      </Body>
    </SummaryContainer>
  )
}

export default Summary
