import styled from 'styled-components'
import { Link, Text } from '@bufferapp/ui'
import { grayDark } from '@bufferapp/ui/style/colors'
import HelpIconUnstyled from '@bufferapp/ui/Icon/Icons/Help'

export const Form = styled.form`
  width: 100%;
  margin: 0;
`

export const HandleLabel = styled.div`
  display: flex;
  gap: 4px;
`

export const HelpIcon = styled(HelpIconUnstyled)`
  color: ${grayDark};
`

export const NoticeWrapper = styled.div`
  margin-bottom: 16px;
`

export const CheckboxText = styled(Text)`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
`

export const SelectWrapper = styled.div`
  flex: 1;
`

export const AppPasswordText = styled.div`
  color: ${grayDark};
  text-wrap: wrap;
  margin: 12px 0;
  font-weight: 400;
`

export const PasswordLink = styled(Link)`
  font-weight: 400 !important;
  text-decoration: underline !important;
`
