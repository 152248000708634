import React, { useEffect } from 'react'
import Banner from '../../../../components/Banner'
import Text from '@bufferapp/ui/Text'
import Button from '@bufferapp/ui/Button'
import { ButtonWrapper } from './styles'
import { orangeDarker } from '@bufferapp/ui/style/colors'
import UpgradePathsTracking from '../../../../tracking/UpgradePaths'
import { useUser } from '../../../../common/context/User'
import { getBillingPageUrl } from '../../../../common/utils/urls'

const trackingPayload = {
  ctaButton: 'updatePaymentDetails',
  ctaView: 'banner',
  ctaLocation: 'navBar',
  ctaVersion: '1',
  upgradePathName: 'pastDue-upgrade',
}

const PaymentPastDueBanner = (): JSX.Element => {
  const user = useUser()
  const { trackCTAClicked, trackCTAViewed } = UpgradePathsTracking(user)

  useEffect(() => {
    trackCTAViewed({
      ...trackingPayload,
    })
  }, [trackCTAViewed])

  return (
    <Banner
      // @ts-expect-error TS(2322) FIXME: Type '{ customHTML: Element; themeColor: string; d... Remove this comment to see the full error message
      customHTML={
        <>
          <Text type="p" color={orangeDarker}>
            <strong>ACTION REQUIRED:</strong> We had a problem charging your
            last payment. Please update your payment details to prevent losing
            access to your features.
          </Text>
          <ButtonWrapper>
            <Button
              type="orange"
              onClick={(): void => {
                trackCTAClicked({
                  ...trackingPayload,
                })
                window.location.assign(
                  getBillingPageUrl('update-payment-method'),
                )
              }}
              label={'Update Payment Details'}
              size="small"
            />
          </ButtonWrapper>
        </>
      }
      themeColor="orange"
      dismissible={false}
      testId="payments-past-due-banner"
    />
  )
}

export default PaymentPastDueBanner
