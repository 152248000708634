import type { ApolloClient } from '@apollo/client'
import { PREVIEW_CUSTOM_UPCOMING_INVOICE } from '../../graphql/billing'
import type { PreviewUpcomingInvoiceReturn } from '../../types'

export const calculateTotalAmountDue = (
  selectedPlanId: string,
  currentPlanId: string,
  newPrice: number,
  data: PreviewUpcomingInvoiceReturn | null,
  isActiveTrial: boolean,
): number | undefined => {
  if (selectedPlanId === 'free') {
    return 0
  }

  if (isActiveTrial || currentPlanId === 'free') {
    return newPrice
  }

  return data?.previewCustomUpcomingInvoice?.chargeAmount
}

export const getCachedDataFromClient = ({
  client,
  variables,
}: {
  client: ApolloClient<any> | undefined
  variables: {
    organizationId: string | undefined
    plan: string
    interval: string
    quantity: number
  }
}): PreviewUpcomingInvoiceReturn | null => {
  if (!client) return null

  return client.readQuery({
    query: PREVIEW_CUSTOM_UPCOMING_INVOICE,
    variables,
  })
}
