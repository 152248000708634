import type { Account } from '../types'
import { getEnvironmentFromUrl } from './constants'

export function getUrlEnvModifier(): string {
  const [, envModifier] = window.location.hostname.match(
    /\w+\.(\w+\.)buffer\.com/,
  ) || [null, '']
  return envModifier
}

const productPorts = {
  publish: ':8888',
  analyze: ':8081',
  create: ':8888',
  'start-page': ':9999',
  // If no port is specified, we assume the product is running on port 8080
}

export function getPortForProduct(product: string): string {
  const envModifier = getUrlEnvModifier()
  if (envModifier !== 'local.') return ''
  return productPorts[product as keyof typeof productPorts] || ':8080'
}

export type ComposerUrlArgs = {
  socialChannelId: string
  cta: string
}

export function getPublishComposerURL({
  socialChannelId,
  cta,
}: ComposerUrlArgs): string {
  const envModifier = getUrlEnvModifier()

  return `https://publish.${envModifier}buffer.com/profile/${socialChannelId}/tab/queue?auto_open_composer=true&profile_id=${socialChannelId}&cta=${cta}`
}

export function getPublishComposerDraftURL({
  socialChannelId,
  cta,
}: ComposerUrlArgs): string {
  const envModifier = getUrlEnvModifier()

  return `https://publish.${envModifier}buffer.com/profile/${socialChannelId}/tab/queue?auto_open_composer_as_draft=true&profile_id=${socialChannelId}&cta=${cta}`
}

export function getPostingScheduleURL(args: { profileId: string }): string {
  const { profileId } = args
  const port = getPortForProduct('publish')
  const envModifier = getUrlEnvModifier()
  return `https://publish.${envModifier}buffer.com${port}/channels/${profileId}/settings?tab=posting-schedule`
}

export function getChannelsConnectionURL(): string {
  const envModifier = getUrlEnvModifier()
  return `https://account.${envModifier}buffer.com/channels/connect`
}

export function getMigrationHubUrl(cta: string | null): string {
  const port = getPortForProduct('account')
  const envModifier = getUrlEnvModifier()
  const ctaParam = cta ? `/?cta=${cta}` : ``

  return `https://account.${envModifier}buffer.com${port}/new-plans${ctaParam}`
}

export function getBillingPageUrl(cta: string | null): string {
  const envModifier = getUrlEnvModifier()
  const ctaParam = cta ? `/?cta=${cta}` : ``
  return `https://account.${envModifier}buffer.com/billing${ctaParam}`
}

export function getBillingPaymentMethodUrl(): string {
  const envModifier = getUrlEnvModifier()
  return `https://account.${envModifier}buffer.com/billing/#payment-method`
}

export function getAccountBaseUrl(): string {
  const envModifier = getUrlEnvModifier()
  return `https://account.${envModifier}buffer.com`
}

export function getAccountUrl(subpath = ''): string {
  const envModifier = getUrlEnvModifier()
  const port = getPortForProduct('account')
  return `https://account.${envModifier || ''}buffer.com${port}/${subpath}`
}

export function getNewSettingsUrl(subpath = ''): string {
  const envModifier = getUrlEnvModifier()
  const port = getPortForProduct('publish')
  return `https://publish.${
    envModifier || ''
  }buffer.com${port}/settings/${subpath}`
}

export function redirectToUrl(redirectURI: string): void {
  window.location.assign(redirectURI)
}

export function getStartPageUrl(subpath = ''): string {
  const envModifier = getUrlEnvModifier()
  const port = getPortForProduct('publish')
  return `https://start-page.${envModifier || ''}buffer.com${port}/${subpath}`
}

export function isInPublish(): boolean {
  const hostname = window.location.hostname

  return hostname.includes('publish')
}

export function isOnPublishChannelsRoute(): boolean {
  const isPublishProduct = isInPublish()

  if (!isPublishProduct) return false

  const pathname = window.location.pathname
  return pathname.includes('/channels')
}

export function isOnPublishProfileRoute(): boolean {
  const isPublishProduct = isInPublish()

  if (!isPublishProduct) return false

  const pathname = window.location.pathname
  return pathname.includes('/profile')
}

export function isOnPublishAllChannelsRoute(): boolean {
  const isPublishProduct = isInPublish()

  if (!isPublishProduct) return false

  const pathname = window.location.pathname
  return pathname.includes('all-channels')
}

export function isOnPublishCalendarRoute(): boolean {
  const isPublishProduct = isInPublish()

  if (!isPublishProduct) return false

  const pathname = window.location.pathname
  return pathname.includes('calendar')
}

export function getSelectedChannelIdFromProfileRoute(): string {
  const pathname = window.location.pathname
  return pathname.split('/')[2]
}

export function getSelectedChannelIdFromChannelsRoute(): string {
  const pathWithoutQueryString = window.location.pathname.split('?')[0]
  return pathWithoutQueryString.split('/')[2]
}

export function getSelectedChannelIdFromAllChannelsRoute(): string[] {
  const urlParams = new URLSearchParams(window.location.search)

  return urlParams.getAll('channels[]')
}

export function getSelectedChannelsFromPublishingRoute(): string[] {
  const isProfileRoute = isOnPublishProfileRoute()
  const isChannelsRoute = isOnPublishChannelsRoute()
  const isAllChannelsRoute = isOnPublishAllChannelsRoute()
  const isCalendarRoute = isOnPublishCalendarRoute()
  const shouldAttempttoGetProfileIdFromURL =
    isProfileRoute || isChannelsRoute || isAllChannelsRoute || isCalendarRoute

  if (!shouldAttempttoGetProfileIdFromURL) return []

  if (isProfileRoute) {
    const selectedChannelId = getSelectedChannelIdFromProfileRoute()
    return selectedChannelId ? [selectedChannelId] : []
  }

  if (isChannelsRoute) {
    const selectedChannelId = getSelectedChannelIdFromChannelsRoute()
    return selectedChannelId ? [selectedChannelId] : []
  }

  if (isAllChannelsRoute || isCalendarRoute) {
    const selectedChannelIds = getSelectedChannelIdFromAllChannelsRoute()
    return selectedChannelIds
  }

  return []
}

export function isInAnalyze(): boolean {
  const hostname = window.location.hostname

  return hostname.includes('analyze')
}

export function isOnAnalyzeOverviewRoute(): boolean {
  const isInAnalyzeProduct = isInAnalyze()

  if (!isInAnalyzeProduct) return false

  const pathname = window.location.pathname
  return pathname.includes('overview')
}

export function getSelectedChannelIdFromOverviewRoute(): string {
  const pathname = window.location.pathname
  return pathname.split('/')[3]
}

export function getSelectedChannelsFromAnalyzeRoute(): string[] {
  const isOverviewRoute = isOnAnalyzeOverviewRoute()

  if (!isOverviewRoute) return []

  const selectedChannelId = getSelectedChannelIdFromOverviewRoute()

  return selectedChannelId ? [selectedChannelId] : []
}

export function isInStartPage(): boolean {
  const hostname = window.location.hostname

  return hostname.includes('start-page')
}

export function getSelectedChannelIdFromStartPageRoute(): string | null {
  const pathname = window.location.pathname
  const channelId = pathname.split('/')[1]

  // Onboarding is the only other value which can be found in the first position of the path
  if (channelId === 'onboarding') return null

  return channelId
}

export function getSelectedChannelsFromStartPageRoute(): string[] {
  const isStartPageRoute = isInStartPage()

  if (!isStartPageRoute) return []

  const selectedChannelId = getSelectedChannelIdFromStartPageRoute()

  return selectedChannelId ? [selectedChannelId] : []
}

export function isInEngage(): boolean {
  const hostname = window.location.hostname

  return hostname.includes('engage')
}

export function getSelectedChannelIdFromEngageRoute(): string {
  const pathname = window.location.pathname
  return pathname.split('/')[1]
}

export function getSelectedChannelsFromEngageRoute(): string[] {
  const isInEngageProduct = isInEngage()

  if (!isInEngageProduct) return []

  const selectedChannelId = getSelectedChannelIdFromEngageRoute()

  return selectedChannelId ? [selectedChannelId] : []
}

export function getPortFromURL(baseUrl: string): string {
  const port = baseUrl.match(/(?<port>:\d+)/)?.groups?.port
  return port || ''
}

export function getQueryParameters(baseUrl: string): string {
  const query = baseUrl.match(/\?(?<query>.*)$/)?.groups?.query
  // NOTE: returned with a starting "&", because it is always used after the
  // initial query parameter, "?redirect="
  return query ? `?${encodeURI(query)}` : ''
}

export function getProductPath(baseUrl: string): string {
  const result = baseUrl.match(/https*:\/\/(.+)\.buffer\.com/)
  let productPath = baseUrl
  if (result instanceof Array) {
    ;[, productPath] = result
  }
  return productPath
}

function getRedirectUrl(baseUrl: string): string {
  const productPath = getProductPath(baseUrl)
  const port = getPortFromURL(baseUrl)

  return `https://${productPath}.buffer.com${port}`
}

function getRedirectUrlWithParams(baseUrl: string): string {
  return `${getRedirectUrl(baseUrl)}${
    window.location.pathname
  }${getQueryParameters(baseUrl)}${encodeURI(window.location.hash)}`
}

function getLoginOrLogoutUrl(baseUrl = '', loginOrLogoutPath: string): string {
  const result = `https://login${getEnvironmentFromUrl(
    baseUrl,
  )}.buffer.com/${loginOrLogoutPath}?redirect=${getRedirectUrlWithParams(
    baseUrl,
  )}`
  return result
}

export function getLogoutUrl(baseUrl: string): string {
  return getLoginOrLogoutUrl(baseUrl, 'logout')
}

export function getLoginUrl(baseUrl: string): string {
  return getLoginOrLogoutUrl(baseUrl, 'login')
}

export function getTeamManageUrl(user: Account, subpath = ''): string {
  const envModifier = getUrlEnvModifier()
  const orgId = user.currentOrganization?.id
  return orgId
    ? `https://${
        envModifier || ''
      }buffer.com/manage/${orgId}/team-members/${subpath}`
    : `https://${envModifier || ''}buffer.com`
}

export function getTeamInviteUrl(user: Account): string {
  return getTeamManageUrl(user, 'invite')
}

export function getPublishUrl(subpath = ''): string {
  const envModifier = getUrlEnvModifier()
  const port = getPortForProduct('publish')
  return `https://publish.${envModifier || ''}buffer.com${port}/${subpath}`
}

export function getBillingUrl(): string {
  return getAccountUrl('billing')
}

export function getManageChannelsURL(): string {
  return getAccountUrl('channels')
}

export function getChannelsQueueUrl({
  channelId,
}: {
  channelId: string
}): string {
  return getPublishUrl(`channels/${channelId}?tab=queue`)
}

export function getStopImpersonationUrl(): string | null {
  const { hostname } = window.location

  if (!hostname.endsWith('buffer.com') && hostname !== 'localhost') {
    return null
  }

  return `https://admin${
    hostname.includes('local') ? '-next.local' : ''
  }.buffer.com/clearImpersonation`
}

export function getGlobalActionCTAFromQueryParams(): string | null {
  const urlParams = new URLSearchParams(window.location.search)

  return urlParams.get('globalActionCTA')
}
