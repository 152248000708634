import styled from 'styled-components'
import { redDark, redLightest, redDarker } from '@bufferapp/ui/style/colors'

export const ErrorContainer = styled.div`
  background-color: ${redLightest};
  border: 1px solid ${redDark};
  border-radius: 4px;
  padding: 4px;
  margin-top: 18px;
`

export const StyledError = styled.p`
  margin: 0px !important;
  display: inline-block;
  align-items: center;
  width: 100%;
  overflow: ${({ isInline }: { isInline: boolean }): string =>
    isInline ? 'hidden' : ''};
  text-overflow: ellipsis;
  font-size: 14px;

  span {
    color: ${redDarker};
  }

  svg {
    color: ${redDarker};
    height: 12px;
    margin-right: 4px;
    position: relative;
    top: 1px;
  }
`
