import type { Action } from 'kbar'
import type { Channel } from '../../common/types'
import type { ChannelActionOverrides } from './useGlobalContextActions'

function goToChannel(channel: Channel, url: string): void {
  const [, ...domain] = window.location.hostname.split('.')
  let newUrl: string
  if (channel.service === 'startPage') {
    newUrl = `https://start-page.${domain.join('.')}/${channel.id}/${url}`
  } else {
    newUrl = `https://publish.${domain.join('.')}/profile/${channel.id}/${url}`
  }
  return window.location.assign(newUrl)
}

export function selectChannel(channel: Channel): void {
  goToChannel(channel, '')
}

const START_PAGE_ACTIONS = [
  {
    name: 'Appearance',
    path: 'appearance',
  },
  {
    name: 'Layout',
    path: 'layout',
  },
  {
    name: 'Statistics',
    path: 'statistics',
  },
  {
    name: 'Settings',
    path: 'settings',
  },
]

const DEFAULT_ACTIONS = [
  {
    name: 'Queue',
    path: 'tab/queue',
  },
  {
    name: 'Sent',
    path: 'tab/sentPosts',
  },
  {
    name: 'Approvals',
    path: 'tab/awaitingApproval',
  },
  {
    name: 'Drafts',
    path: 'tab/drafts',
  },
  {
    name: 'Settings',
    path: 'tab/settings',
  },
]

const toChannelAction = ({
  channel,
  name,
  path,
  channelActionOverrides,
}: {
  channel: Channel
  name: string
  path: string
  channelActionOverrides: ChannelActionOverrides
}): Action => ({
  id: `channel-${channel.id}-${path}`,
  parent: `channel-${channel.id}`,
  name,
  perform: (): void => {
    if (channelActionOverrides[path]) {
      channelActionOverrides[path]({ channel })
    } else {
      goToChannel(channel, path)
    }
  },
})

export function makeChannelActions(
  channel: Channel,
  channelActionOverrides: ChannelActionOverrides,
): Action[] {
  const actionList =
    channel.service === 'startPage' ? START_PAGE_ACTIONS : DEFAULT_ACTIONS
  return actionList.map(({ name, path }) =>
    toChannelAction({ channel, name, path, channelActionOverrides }),
  )
}
