import React, { useState } from 'react'
import { useMutation, FetchResult } from '@apollo/client'

import { Dialog, Button, Link, toast } from '@buffer-mono/popcorn'

import { ACCOUNT_INITIATE_EMAIL_VERIFICATION } from '../../../../../common/graphql/account'

import styles from '../AccountMenu.module.css'

type EmailVerificationResponseType = {
  accountInitiateEmailVerification: {
    success?: boolean
    userFriendlyMessage?: string
  }
}

export function VerifyEmailModal({
  onCloseMenu,
  children,
}: {
  onCloseMenu: (open: boolean) => void
  children: React.ReactNode
}): JSX.Element | null {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [initiateEmailVerification] = useMutation(
    ACCOUNT_INITIATE_EMAIL_VERIFICATION,
  )

  const onVerifyEmail = () => {
    /* To be able to show the Toast notification, we need to close the modal and the DropdownMenu */
    onCloseMenu(false)
    setDialogOpen(false)
    initiateEmailVerification()
      .then((response: FetchResult<EmailVerificationResponseType>) => {
        if (
          response.data &&
          response.data?.accountInitiateEmailVerification?.success
        ) {
          toast.success(
            'We just sent you an email! Please check your inbox to complete verification steps.',
          )
        } else {
          toast.error(
            response.data?.accountInitiateEmailVerification
              ?.userFriendlyMessage ||
              'Something went wrong. Please try again later.',
          )
        }
      })
      .catch(() => {
        toast.error('Something went wrong. Please try again later.')
      })
  }

  const handleSelectItem = (e: Event): void => {
    e.preventDefault()
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <Dialog.Trigger>{children}</Dialog.Trigger>
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>Verify your Email</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <Dialog.Description>
            An email has been sent to your inbox to verify your email address.
            You can visit our{' '}
            <Link
              external
              href="https://support.buffer.com/article/504-verifying-your-buffer-email-address"
            >
              help guide
            </Link>{' '}
            for more info.
          </Dialog.Description>
        </Dialog.Body>
        <Dialog.Separator />
        <Dialog.Footer>
          <Button variant="secondary" onClick={onVerifyEmail}>
            Resend Verification Email
          </Button>
        </Dialog.Footer>
        <Dialog.CloseButton className={styles.dialogCloseButton} />
      </Dialog.Content>
    </Dialog>
  )
}
