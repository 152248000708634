import React, { useEffect } from 'react'

import { Button, Link, Text } from '@bufferapp/ui'

import * as Styled from './InstagramPreAuthentication.styles'
import useAuthorizationInfo from '../../hooks/useAuthorizationInfo/useAuthorizationInfo'
import { type onRedirectParams, Service } from '../../types'
import { useSelector } from 'react-redux'
import type { OrchestratorRootState } from '../../../../../common/events/types'

type InstagramPreAuthenticationContentProps = {
  onRedirect: (args: onRedirectParams) => void
  cancelConnection: () => void
}

export default function InstagramPreAuthenticationContent({
  onRedirect,
  cancelConnection,
}: InstagramPreAuthenticationContentProps): JSX.Element {
  const buttonLabel = `Authenticate with Facebook`
  const linkToIGHelpGuide =
    'https://support.buffer.com/article/568-connecting-your-instagram-business-or-creator-account-to-buffer?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more'

  const { authRedirectUrl } = useSelector(
    (state: OrchestratorRootState) => state.channelConnections,
  )
  const { handleGetAuthorizationInfo } = useAuthorizationInfo()

  useEffect(() => {
    handleGetAuthorizationInfo({
      service: Service.instagram,
      isRefreshingConnection: false,
    })
  }, [handleGetAuthorizationInfo])

  return (
    <>
      <Styled.Container>
        <Styled.TitleSpacing>
          <Text type="h3">Authenticate with Facebook</Text>
        </Styled.TitleSpacing>
        <Styled.Body>
          <Text type="p">
            <strong>Wait...why do I have to authenticate with Facebook?</strong>
          </Text>
          <Text type="p">
            Facebook requires Instagram Business or Creator accounts to be
            connected to a Facebook Page. For Buffer to work as intended, we
            need to authenticate through Facebook.
          </Text>
          <Styled.Section>
            <Styled.SubSection>
              <Text type="p">
                <strong>What happens next?</strong>
              </Text>
              <Text type="p">
                Once we transfer you,{' '}
                <span className="underline">
                  sign in to the Facebook profile that has Admin permissions for
                  the Facebook Page
                </span>{' '}
                connected with your Instagram account.
              </Text>
              <Text type="p">
                Check out our{' '}
                <Link href={linkToIGHelpGuide} newTab>
                  Instagram connection guide
                </Link>{' '}
                for more.
              </Text>
            </Styled.SubSection>
            <Styled.Doodle />
          </Styled.Section>
        </Styled.Body>
        <Styled.CTAContainer>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error TS(2740) FIXME: Type '{ id: string; type: string; onClick: () => v... Remove this comment to see the full error message */}
          <Button
            id="cancel-ig-auth"
            type="text"
            onClick={(): void => cancelConnection()}
            label="Cancel"
          />
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error TS(2740) FIXME: Type '{ id: string; type: string; onClick: () => v... Remove this comment to see the full error message */}
          <Button
            id="go-to-service"
            type="primary"
            disabled={!authRedirectUrl}
            onClick={(): void => {
              if (authRedirectUrl) {
                onRedirect({ redirectUrl: authRedirectUrl })
              }
            }}
            label={buttonLabel}
          />
        </Styled.CTAContainer>
      </Styled.Container>
    </>
  )
}
