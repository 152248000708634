import eventDispatcher from '../utils/eventDispatcher'
import { AppshellEventKeys, type ModalData } from './types'

export const MODAL_ACTIONS = {
  openModal(modalKey: string | null, modalData?: ModalData): void {
    eventDispatcher(AppshellEventKeys.MODAL_EVENT_KEY, {
      modal: modalKey,
      data: modalData,
    })
  },
}

export type MODAL_ACTIONS_TYPE = typeof MODAL_ACTIONS
