import { useMutation } from '@apollo/client'
import { DISMISS_BANNER } from '../graphql/account'

export const BannerTypes = {
  emailVerification: 'emailVerification',
  facebookGroupNotifications: 'facebookGroupNotifications',
  instagramPersonalProfilesAnnouncementDialog:
    'instagramPersonalProfilesAnnouncementDialog',
}

type UseDismissBannerReturn = {
  onDismissBanner: () => void
}

const useDismissBanner = ({
  banner,
}: {
  banner: string
}): UseDismissBannerReturn => {
  const [dismissBanner] = useMutation(DISMISS_BANNER)

  const onDismissBanner = (): void => {
    if (!banner || !Object.values(BannerTypes).includes(banner)) {
      return
    }

    dismissBanner({ variables: { banner } })
  }

  return { onDismissBanner }
}

export default useDismissBanner
