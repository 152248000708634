import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import {
  getCommonTrackingPropertiesFromAccount,
  shouldPerformTrackingEvent,
} from '../utils'
import { getActiveProductFromPath } from '../../common/utils/getProduct'
import type { Account } from '../../common/types'

export type TrackGlobalPlusButtonOpenedPayload = {
  ctaButton: string
  ctaView: string
  ctaLocation: string
  ctaVersion?: string
}

type TrackGlobalPlusButtonOpenedArguments = {
  payload: TrackGlobalPlusButtonOpenedPayload
  user: Account
}

export default function trackGlobalPlusButtonOpened({
  payload,
  user,
}: TrackGlobalPlusButtonOpenedArguments): void {
  const canPerformTrackingEvent = shouldPerformTrackingEvent({
    user,
  })

  if (!canPerformTrackingEvent) {
    return
  }

  const { ctaView, ctaLocation, ctaButton, ctaVersion } = payload

  const version = ctaVersion || '1'

  const organization = user.currentOrganization
  const product = getActiveProductFromPath()
  const cta = `${product}-${ctaView}-${ctaLocation}-${ctaButton}-${version}`
  const currentChannelQuantity = user?.channels?.length

  const commonTrackingProperties = getCommonTrackingPropertiesFromAccount(user)

  const eventData = {
    organizationId: organization?.id || '',
    cta,
    clientName: 'publishWeb',
    product,
    currentChannelQuantity,
    ...commonTrackingProperties,
  }

  BufferTracker.globalPlusButtonOpened(eventData)
}
