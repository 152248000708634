import type { Account } from '../../../../common/types'
import {
  isAtChannelLimit,
  isFreeUser,
  isOneBufferOrganization,
} from '../../../../common/utils/user'

export function shouldShowConnectChannelUpgradePath(user: Account): boolean {
  const hasReachedChannelLimit = isAtChannelLimit(user)
  const isOnFreePlan = isFreeUser(user)
  const isMPUser = !isOneBufferOrganization(user)

  return (
    (hasReachedChannelLimit && isOnFreePlan) ||
    (hasReachedChannelLimit && isMPUser)
  )
}
