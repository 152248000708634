export const ENGAGE_HEADING_EMPTY = 'Turn your followers into your fans'
export const ENGAGE_HEADING_SINGULAR = 'You have 1 unanswered comment!'
export const ENGAGE_HEADING_PLURAL = 'You have %UNREAD% unanswered comments!'

export const ENGAGE_DESCRIPTION =
  'See all of your comments, stay on top of important interactions, and turn followers into fans using Buffer.'

export const ENGAGE_BULLET_POINTS = [
  'View unanswered comments in a simple dashboard',
  'Machine-learning-powered comment alerts',
  'Reply faster with hotkeys and a smart emoji picker',
]

export const ANALYZE_HEADING =
  'One dashboard for your social media analytics and reporting'

export const ANALYZE_DESCRIPTION =
  'Access in-depth insights on how to grow your following, reach more people, and boost your engagement.'

export const ANALYZE_BULLET_POINTS = [
  'Get recommendations to grow your presence',
  'Measure social media performance',
  'Create gorgeous reports',
]
