import React from 'react'
import Banner from '../../../../components/Banner'
import Text from '@bufferapp/ui/Text'
import Button from '@bufferapp/ui/Button'
import { ButtonWrapper } from './styles'
import { orangeDarker } from '@bufferapp/ui/style/colors'
import { useUser } from '../../../../common/context/User'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { getActiveProductFromPath } from '../../../../common/utils/getProduct'
import { isCanceledAtPeriodEnd } from '../../../../common/utils/user'
import useGetPortalSession from '../../../../common/hooks/useGetPortalSession'

const MissingBillingAddress = (): JSX.Element => {
  const user = useUser()
  const { openBillingPortal } = useGetPortalSession()

  const copy = isCanceledAtPeriodEnd(user)
    ? 'You do not have a billing address on file. Please add your info.'
    : 'You do not have a billing address on file. Please add your info to ensure your subscription remains in good standing.'

  return (
    <Banner
      // @ts-expect-error TS(2322) FIXME: Type '{ customHTML: Element; themeColor: string; d... Remove this comment to see the full error message
      customHTML={
        <>
          <Text type="p" color={orangeDarker}>
            <strong>ACTION REQUIRED:</strong> {copy}
          </Text>
          <ButtonWrapper>
            <Button
              type="orange"
              onClick={(): void => {
                BufferTracker.billingPortalSessionCreated({
                  organizationId: user.currentOrganization?.id || null,
                  customerId:
                    user.currentOrganization?.billing?.gateway?.gatewayId,
                  ctaApp: getActiveProductFromPath(),
                  ctaView: 'banner',
                  ctaLocation: 'banner',
                  ctaButton: 'addBillingAddress',
                  ctaVersion: '1',
                })
                openBillingPortal(true)
              }}
              label={'Add Billing Address'}
              size="small"
            />
          </ButtonWrapper>
        </>
      }
      themeColor="orange"
      dismissible={false}
    />
  )
}

export default MissingBillingAddress
