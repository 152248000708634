import React, { useEffect } from 'react'
import { Button, Heading, SuccessDialog, Text } from '@buffer-mono/popcorn'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import useDismissBanner, {
  BannerTypes,
} from '../../../../common/hooks/useDismissBanner'
import styles from './InstagramPersonalProfilesAnnouncement.module.css'

import { useUser } from '../../../../common/context/User'

import { getMigrationHubURL } from './utils'
import { AccountType } from '../../../../common/events/types'

import { useModalManager } from '../../../ModalManager/hooks/useModalManager'

export function InstagramPersonalProfilesAnnouncement(): JSX.Element {
  const account = useUser()
  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null

  useEffect(() => {
    BufferTracker.dialogViewed({
      dialogName: 'instagramPersonalProfilesAnnouncementDialog',
      clientName: 'publishWeb',
      organizationId: account?.currentOrganization?.id || '',
      product: 'publish',
      ...commonTrackingProperties,
    })
  }, [])

  const isOneBuffer = account?.currentOrganization?.isOneBufferOrganization
  const { dismissModal: closeAction } = useModalManager()

  const { onDismissBanner } = useDismissBanner({
    banner: BannerTypes.instagramPersonalProfilesAnnouncementDialog,
  })

  const onDismiss = () => {
    onDismissBanner()
    closeAction()
  }

  const { MODALS, actions } = window?.appshell || {}

  const contentMap = {
    oneBuffer: {
      title: 'Connect Instagram personal accounts',
      description:
        'While automatic publishing to Instagram personal accounts is not supported by Meta, Buffer seamlessly facilitates scheduling your posts with mobile notifications.',
      listItems: [
        'Create and schedule your post in Buffer. At the scheduled time, we will send you a mobile notification to the Buffer app.',
        "Tap the notification and we'll take you to Instagram. Add the finishing touches and publish your post.",
      ],
      ctaCopy: 'Connect a Personal Account',
      onCtaClick: () => {
        BufferTracker.cTAClicked({
          clientName: 'publishWeb',
          organizationId: account?.currentOrganization?.id || '',
          cta: 'appShell-publish-instagramPersonalProfilesAnnouncementDialog-connectAPersonalAccount-1',
          product: 'publish',
          upgradePathName: null,
          ...commonTrackingProperties,
        })

        onDismiss()
        actions.openModal(MODALS.instagramSelectAccountType, {
          accountType: AccountType.personal,
        })
      },
    },
    legacy: {
      title: 'Unlock Instagram Personal Accounts',
      description: (
        <Text>
          You're on a <strong>legacy plan</strong>. Upgrade to the new Buffer to
          unlock Instagram Personal Accounts, plus several other features like
        </Text>
      ),
      listItems: [
        "New channels like Threads', Bluesky and YouTube",
        'Capture and organize your content ideas',
        "No charge for channels you don't use and more!",
      ],
      ctaCopy: 'See Upgrade Options',
      onCtaClick: () => {
        BufferTracker.cTAClicked({
          clientName: 'publishWeb',
          organizationId: account?.currentOrganization?.id || '',
          cta: 'appShell-publish-instagramPersonalProfilesAnnouncementDialog-seeUpgradeOptions-1',
          product: 'publish',
          upgradePathName: null,
          ...commonTrackingProperties,
        })

        onDismiss()
        const migrationHubUrl = getMigrationHubURL()
        window.location.assign(migrationHubUrl)
      },
    },
  }

  return (
    <SuccessDialog open onOpenChange={onDismiss}>
      <SuccessDialog.Content className={styles.dialog}>
        <SuccessDialog.Image
          alt="Success Dialog Image"
          data-testid="image-successdialog"
          src="https://buffer-publish.s3.us-east-1.amazonaws.com/images/personal-profiles-announcement.png"
        />
        <Heading size="large">
          {isOneBuffer ? contentMap.oneBuffer.title : contentMap.legacy.title}
        </Heading>
        <SuccessDialog.Description>
          <Text align="left">
            {isOneBuffer
              ? contentMap.oneBuffer.description
              : contentMap.legacy.description}
          </Text>
          {isOneBuffer ? (
            <ol className={styles.list}>
              {contentMap.oneBuffer.listItems.map((item) => (
                <li>
                  <Text align="left">{item}</Text>
                </li>
              ))}
            </ol>
          ) : (
            <ul className={styles.list}>
              {contentMap.legacy.listItems.map((item) => (
                <li>
                  <Text align="left">{item}</Text>
                </li>
              ))}
            </ul>
          )}
        </SuccessDialog.Description>
        <SuccessDialog.Actions>
          <Button
            variant="primary"
            className={styles.button}
            size="large"
            onClick={
              isOneBuffer
                ? contentMap.oneBuffer.onCtaClick
                : contentMap.legacy.onCtaClick
            }
          >
            {isOneBuffer
              ? contentMap.oneBuffer.ctaCopy
              : contentMap.legacy.ctaCopy}
          </Button>
        </SuccessDialog.Actions>
      </SuccessDialog.Content>
    </SuccessDialog>
  )
}
