import type { Account, CommonTrackingProperties } from '../common/types'
import {
  Service,
  InstagramAuthMethod,
} from '../exports/Orchestrator/channelConnections/types'

export type ShouldPerformTrackingEventArguments = {
  user: Account
}

export function shouldPerformTrackingEvent({
  user,
}: ShouldPerformTrackingEventArguments): boolean {
  if (!user) {
    return false
  }

  if (user.isImpersonation) {
    return false
  }

  const organization = user.currentOrganization
  if (!organization || !organization.id) {
    return false
  }

  return true
}

export function getCommonTrackingPropertiesFromAccount(
  account: Account,
): CommonTrackingProperties | null {
  return account?.currentOrganization?.commonTrackingProperties || null
}

export function setApiVersion(
  service: Service,
): InstagramAuthMethod | undefined {
  switch (service) {
    case Service.instagram:
      return InstagramAuthMethod.facebookLogin
    case Service.instagram_login_api:
      return InstagramAuthMethod.instagramLogin
    default:
      return undefined
  }
}
