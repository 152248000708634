import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'

import { useUser } from '../../../../../common/context/User'
import { CREATE_CHECKOUT_SESSION } from '../../../../../common/graphql/billing'
import {
  useStripeCheckoutSessionCreated,
  formatCTAString,
} from '../../../../../common/hooks/useSegmentTracking'

import type { ErrorState } from '../../../../../common/types/graphQL/Error'
import type { UseCheckoutSession, UseCheckoutSessionResponse } from './types'
import { prefixWithProduct } from '../../../../../common/utils/prefixWithProduct'

function useCheckoutSession({
  organizationId,
  planName,
  interval,
  quantity,
  cta,
  upgradePathName,
  initialQuantity,
  initialSelectedPlan,
  initialInterval,
}: UseCheckoutSession): UseCheckoutSessionResponse {
  const [createCheckoutSession, { data, error: mutationError }] = useMutation(
    CREATE_CHECKOUT_SESSION,
  )
  const [error, setError] = useState<ErrorState>(null)
  const user = useUser()

  function checkoutSession(): void {
    if (!organizationId) {
      return
    }

    if (organizationId) {
      createCheckoutSession({
        variables: {
          organizationId,
          plan: planName,
          interval,
          quantity,
          currentURL: window.location.href,
          shouldCollectAutomaticTax: true,
          attribution: {
            cta: prefixWithProduct(cta),
            upgradePathName,
          },
        },
      }).catch((e) => {
        console.error(e) // eslint-disable-line no-console
      })
    }
  }

  useEffect(() => {
    if (mutationError) {
      setError(mutationError)
    } else if (data?.billingCreateCheckoutSession.userFriendlyMessage) {
      setError({
        message: data.billingCreateCheckoutSession.userFriendlyMessage,
      })
    }

    if (data?.billingCreateCheckoutSession?.redirectUrl) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useStripeCheckoutSessionCreated({
        payload: {
          selectedPlan: formatCTAString(`${planName} ${interval}`),
          channelQuantity: quantity,
          isStripeCheckout: true,
          cta,
          initialChannelQuantity: initialQuantity,
          initialSelectedPlan,
          initialCycle: initialInterval,
        },
        user,
      })
      window.location.assign(data?.billingCreateCheckoutSession?.redirectUrl)
    }
  }, [mutationError, data])

  return {
    checkoutSession,
    error,
  }
}

export default useCheckoutSession
