import React from 'react'
import clsx from 'clsx'

import styles from './DescriptionList.module.css'

type DescriptionListProps = React.ComponentPropsWithoutRef<'dl'> & {
  /**
   * Size of the description list
   */
  size?: 'small' | 'medium'
}

const DescriptionListRoot = React.forwardRef<
  React.ElementRef<'dl'>,
  DescriptionListProps
>(({ size = 'medium', ...props }: DescriptionListProps, forwardedRef) => {
  return (
    <dl
      {...props}
      className={clsx(styles.base, styles[size], props.className)}
      ref={forwardedRef}
    />
  )
})

DescriptionListRoot.displayName = 'DescriptionList'

const DescriptionListTitle = React.forwardRef<
  React.ElementRef<'dt'>,
  React.ComponentPropsWithoutRef<'dt'>
>((props, forwardedRef) => {
  return (
    <dt
      {...props}
      ref={forwardedRef}
      className={clsx(styles.title, props.className)}
    />
  )
})

DescriptionListTitle.displayName = 'DescriptionList.Title'

const DescriptionListDescription = React.forwardRef<
  React.ElementRef<'dd'>,
  React.ComponentPropsWithoutRef<'dd'>
>((props, forwardedRef) => {
  return (
    <dd
      {...props}
      ref={forwardedRef}
      className={clsx(styles.description, props.className)}
    />
  )
})

DescriptionListDescription.displayName = 'DescriptionList.Description'

const DescriptionList = Object.assign(DescriptionListRoot, {
  Title: DescriptionListTitle,
  Description: DescriptionListDescription,
})

export { DescriptionList }
export type { DescriptionListProps }
