import React from 'react'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'
import type { OrchestratorRootState } from '../../common/events/types'

import { StartTrialButton } from './components/StartTrialButton'

export const COMPONENTS = {
  startTrialButton: 'startTrialButton',
}

export const Orchestrator = (): JSX.Element | null => {
  const { showComponent, componentParams } = useSelector(
    (state: OrchestratorRootState) => state.components,
  )

  if (!showComponent || !componentParams) {
    return null
  }
  const { componentKey, containerId, options } = componentParams
  const container = document.getElementById(containerId)

  if (container && componentKey === COMPONENTS.startTrialButton) {
    // we need to render the component out of the current components react tree
    return createPortal(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <StartTrialButton options={options as any} />,
      container,
    )
  }

  return null
}
