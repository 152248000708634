import { gql, type TypedDocumentNode } from '@apollo/client'
import type {
  Mutation,
  SetPostingScheduleForChannelInput,
} from '../../../../gql/graphql'

type CreatePostingPlanMutation = TypedDocumentNode<
  Mutation['setPostingScheduleForChannel'],
  { input: SetPostingScheduleForChannelInput }
>

export const CREATE_POSTING_PLAN_MUTATION: CreatePostingPlanMutation =
  gql(/* GraphQL */ `
    mutation SetPostingScheduleForChannel(
      $input: SetPostingScheduleForChannelInput!
    ) {
      setPostingScheduleForChannel(input: $input) {
        ... on SetPostingScheduleForChannelResponse {
          postingSchedule {
            day
            paused
            times
          }
        }
        ... on MutationError {
          message
        }
      }
    }
  `) as CreatePostingPlanMutation
