/**
 * Different posting time frames.
 * - Keys represent posting amount per day
 * - Values represent the start and end hours for the posting times
 */
export const POSTING_TIME_FRAMES: Record<
  number,
  { startHour: number; endHour: number }
> = {
  1: {
    startHour: 9,
    endHour: 11,
  },
  2: {
    startHour: 9,
    endHour: 15,
  },
  3: {
    startHour: 9,
    endHour: 21,
  },
  4: {
    startHour: 8,
    endHour: 20,
  },
  5: {
    startHour: 7,
    endHour: 22,
  },
  8: {
    startHour: 7,
    endHour: 22,
  },
  10: {
    startHour: 6,
    endHour: 22,
  },
}
