// import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import {
  getOrganization,
  getUserUnlockedChannelCount,
  isAtPlanChannelLimit,
} from '../../common/utils/user'
import { getActiveProductFromPath } from '../../common/utils/getProduct'

import {
  getCommonTrackingPropertiesFromAccount,
  shouldPerformTrackingEvent,
} from '../utils'
import type { TrackCTAViewedArguments } from './UpgradePathsTrackingTypes'

export default function trackCTAViewed({
  payload,
  user,
}: TrackCTAViewedArguments): void {
  const canPerformTrackingEvent = shouldPerformTrackingEvent({
    user,
  })

  if (!canPerformTrackingEvent) {
    return
  }

  const {
    ctaView,
    cta,
    product = getActiveProductFromPath(),
    ctaLocation,
    ctaButton,
    ctaVersion,
    upgradePathName,
  } = payload

  const version = ctaVersion || '1'

  const organization = getOrganization(user)
  const currentCta =
    cta || `${product}-${ctaView}-${ctaLocation}-${ctaButton}-${version}`

  const isAtPlanLimit = isAtPlanChannelLimit(user)
  const currentChannelCount = getUserUnlockedChannelCount(user)

  const commonTrackingProperties = getCommonTrackingPropertiesFromAccount(user)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const eventData = {
    organizationId: organization?.id || null,
    cta: currentCta,
    upgradePathName,
    clientName: 'publishWeb',
    product,
    currentChannelQuantity: currentChannelCount,
    isAtPlanLimit,
    ...commonTrackingProperties,
  }

  // Disable CTA viewed tracking for now - see https://buffer.atlassian.net/browse/GROWTH-1081
  // BufferTracker.cTAViewed(eventData)
}
