import * as React from 'react'
import clsx from 'clsx'
import { Slot } from '@radix-ui/react-slot'

import { Label, LabelProps } from '../Label'

import styles from './Form.module.css'
import useId from '../../helpers/useId'

/**
 * Form
 */
type FormProps = React.ComponentPropsWithoutRef<'form'> & {
  /**
   * Name of the form
   */
  name?: string
  children: React.ReactNode
  /**
   * Func which will be trigged when the form is submitted
   */
  onSubmit?: React.FormEventHandler<HTMLFormElement>
}

/**
 * At this stage this component is laying the groundwork for future form components.
 * To handle Errors this is done by the consumer via the `aria-invalid` attribute added to the Input component provided.
 */
const Form = React.forwardRef<React.ElementRef<'form'>, FormProps>(
  ({ name, children, onSubmit, ...props }: FormProps, ref) => {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      onSubmit?.(event)
    }

    return (
      <form name={name} onSubmit={handleSubmit} ref={ref} {...props}>
        {children}
      </form>
    )
  },
)

Form.displayName = 'Form'

/**
 * Form Field Context
 */
interface FormFieldContextValue {
  id: string
  name: string
}

const FormFieldContext = React.createContext<FormFieldContextValue | null>(null)

const useFormField = () => {
  const context = React.useContext(FormFieldContext)

  if (!context) {
    throw new Error('useFormField must be used within a Form.Field')
  }

  return context
}

/**
 * Form Field
 */
type FieldProps = React.ComponentPropsWithoutRef<'div'> & {
  /**
   * Name of the form field
   */
  name: string
  className?: string
}

const FormField = React.forwardRef<React.ElementRef<'div'>, FieldProps>(
  ({ className, name, ...props }, ref) => {
    const id = useId()

    return (
      <FormFieldContext.Provider value={{ id, name }}>
        <div ref={ref} className={clsx(styles.field, className)} {...props} />
      </FormFieldContext.Provider>
    )
  },
)

FormField.displayName = 'Form.Field'

/**
 * Form Label
 */
const FormLabel = React.forwardRef<React.ElementRef<'label'>, LabelProps>(
  ({ ...props }, ref) => {
    const { id } = useFormField()

    return <Label ref={ref} htmlFor={id} {...props} />
  },
)

FormLabel.displayName = 'Form.Label'

type FormControlProps = React.ComponentPropsWithoutRef<typeof Slot> & {
  name?: string
}

/**
 * Form Control
 */
const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  FormControlProps
>(({ children, ...props }, ref) => {
  const { id, name } = useFormField()

  return (
    <Slot ref={ref} id={id} name={name} {...props}>
      {children}
    </Slot>
  )
})

FormControl.displayName = 'Form.Control'

const FormObject = Object.assign(Form, {
  Field: FormField,
  Label: FormLabel,
  Control: FormControl,
})

export { FormObject as Form }
export type { FormProps }
