/* eslint-disable no-shadow, no-else-return */
import { useState } from 'react'

import { formatCTAString } from '../../../../../common/hooks/useSegmentTracking'

import type { UseButtonOptions, UseButtonOptionsResponse } from './types'

/**
 *
 * @param selectedPlan: new plan selected in Pricing modal, selectedPlan.planId contains free/essentials/team/agency
 * @param currentPlan: contains the identifier for the current plan
 * @param updatePlan: function to update plan, we update the plan sending mutation UPDATE_SUBSCRIPTION_PLA to backend
 * @param hasPaymentDetails: boolean, true if user has payment details
 * @param isActiveTrial: boolean, true if user is in trial
 * @param currentChannelQuantity: number, current number of channels
 * @param updatedChannelQuantity: number, new number of channels
 * @param checkoutSession: function to redirect to checkout session, muttaion CREATE_CHECKOUT_SESSION
 * @param openChurnSurveyModal: function to open churn survey modal
 *
 */

const useButtonOptions = ({
  currentPlanId,
  selectedPlan,
  updatePlan,
  hasPaymentDetails,
  isActiveTrial,
  currentChannelQuantity,
  updatedChannelQuantity,
  checkoutSession,
  openChurnSurveyModal,
  isChurnSurveyModalEnabled = false,
}: UseButtonOptions): UseButtonOptionsResponse => {
  /**
   * getLabel
   *
   * @returns string, label for button in pricing modal
   */
  const getLabel = (): string => {
    /* If user is on any paid plan (trialling or not) and chooses Free */
    if (selectedPlan.planId === 'free' && !selectedPlan.isCurrentPlan) {
      return 'Confirm Cancellation'
    }

    /* If user’s current plan is Free and is upgrading to any paid plan: “Confirm & Pay” */
    if (
      (currentPlanId === 'free' && selectedPlan.planId !== 'free') ||
      isActiveTrial
    ) {
      return 'Confirm and Pay'
    }

    /* Otherwise */
    return 'Confirm Plan Change'
  }

  const [label, setLabel] = useState<string>(getLabel())

  const paymentType = checkoutSession

  const buttonFunction = (): (() => void) | null => {
    // If Free is selected and is not the current plan (no payment method modal needed)
    if (selectedPlan.planId === 'free' && !selectedPlan.isCurrentPlan) {
      // Open the churn survey modal instead of cancelling immediately
      return isChurnSurveyModalEnabled ? openChurnSurveyModal : updatePlan
    }

    // If trialing
    if (isActiveTrial) {
      return hasPaymentDetails ? updatePlan : paymentType
    }

    // If not not trialing, no plan changed and quantity has not changed.
    if (
      selectedPlan.isCurrentPlan &&
      currentChannelQuantity === updatedChannelQuantity
    ) {
      return null
    }

    // If there are no payment details we redirect to stripe checkout
    // otherwise we update the plan directly.
    return hasPaymentDetails ? updatePlan : paymentType
  }

  const [action, setAction] = useState(() => buttonFunction())

  const updateButton = (): void => {
    setLabel(getLabel())
    setAction(() => buttonFunction())
  }

  return {
    label,
    action,
    updateButton,
    ctaButton: formatCTAString(label),
  }
}

export default useButtonOptions
