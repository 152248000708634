import { grayDark, blue, white } from '@bufferapp/ui/style/colors'
import styled from 'styled-components'

const buttonColor = '#E9ECFC'

export const ChannelsCounterContainer = styled.div`
  display: flex;
  height: 48px;

  border: 1px ${blue} solid;
  border-radius: 3px;
`

export const ChannelsCounterButton = styled.button`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  background: ${buttonColor};
  color: ${grayDark};
  border-radius: 3px;
  border: 0;

  cursor: pointer;
`

export const ChannelsCounterCountDisplay = styled.div`
  display: flex;
  flex-grow: 1.5;
  max-width: 50px;

  justify-content: center;
  align-items: center;
  background: ${white};
  border-left: 1px ${blue} solid;
  border-right: 1px ${blue} solid;

  input {
    height: 48px;
    border-radius: 0;
    text-align: center;

    /* Disable number arrows */
    &::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    -moz-appearance: textfield;
  }
`
