import trackCTAClicked from './trackCTAClicked'
import trackCTAViewed from './trackCTAViewed'

import type { Account } from '../../common/types'
import type {
  UpgradePathsTrackingEvents,
  TrackCTAViewedPayload,
  TrackCTAClickedPayload,
} from './UpgradePathsTrackingTypes'

export default function UpgradePathsTracking(
  user: Account,
): UpgradePathsTrackingEvents {
  return {
    trackCTAViewed: ({
      ctaButton,
      upgradePathName,
      ctaView,
      ctaLocation,
    }: TrackCTAClickedPayload): void =>
      trackCTAViewed({
        payload: {
          ctaButton,
          upgradePathName,
          ctaView,
          ctaLocation,
        },
        user,
      }),
    trackCTAClicked: ({
      cta,
      ctaButton,
      upgradePathName,
      ctaView,
      ctaLocation,
    }: TrackCTAViewedPayload): void =>
      trackCTAClicked({
        payload: {
          cta,
          ctaButton,
          upgradePathName,
          ctaView,
          ctaLocation,
        },
        user,
      }),
  }
}
