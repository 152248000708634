import { useSplitEnabled } from '@buffer-mono/features'
import { useState } from 'react'
import { getCookie, setCookie } from '../../../../common/utils/cookies'

const getTodaysDateString = (): string => {
  const today = new Date()
  return today.toISOString().slice(0, 10).replace(/-/g, '')
}

interface CoachMarkConfig {
  [key: string]: {
    title: string
    content: string
    link?: string
  }
}

export default function useCreatorCampCoachMark(): {
  isCoachMarkEnabled: boolean
  coachMarkTitle: string
  coachMarkContent: string
  coachMarkLink?: string
  onCoachMarkDismiss: () => void
} {
  // Creator Camp CoachMark
  const { isEnabled: isCoachMarkSplitEnabled, config: coachMarkConfig } =
    useSplitEnabled<CoachMarkConfig>('creator-camp-v3-tips')

  const isCoachMarkCookiePresent = Boolean(
    getCookie({ key: `coachMarkV3TipDismissed${getTodaysDateString()}` }),
  )
  const [isCoachMarkVisible, setIsCoachMarkVisible] = useState(
    !isCoachMarkCookiePresent,
  )

  const todayConfig = (coachMarkConfig as CoachMarkConfig)[
    getTodaysDateString()
  ]
  const coachMarkTitle = todayConfig?.title
  const coachMarkContent = todayConfig?.content
  const coachMarkLink = todayConfig?.link
  const coachMarkHasContent = Boolean(coachMarkTitle && coachMarkContent)

  const onCoachMarkDismiss = (): void => {
    const now = new Date()
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000)
    const key = `coachMarkV3TipDismissed${getTodaysDateString()}`
    const value = 'true'
    setCookie({ key, value, expires })
    setIsCoachMarkVisible(false)
  }

  // The CoachMark is enabled if the split is enabled, the title and content are present,
  // and the CoachMark hasn't been dismissed for today
  return {
    isCoachMarkEnabled:
      isCoachMarkSplitEnabled && coachMarkHasContent && isCoachMarkVisible,
    coachMarkTitle,
    coachMarkContent,
    coachMarkLink,
    onCoachMarkDismiss,
  }
}
