// FIXME: Ignoring explicit-module-boundary-types
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// FIXME: Ignoring explicit-function-return-type
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type { Account, CreditCardDetails, DismissedObject } from '../types'

import { getSubscriptionPlanData } from '../utils/billing'
import { isPast, differenceInDays } from 'date-fns'
import { TRIAL_CHANNELS_LIMIT, FREE_CHANNELS_LIMIT } from './constants'
import type { Service } from '../../exports/Orchestrator/channelConnections/types'

export function isFreeUser(user: Account) {
  return user?.currentOrganization?.billing?.subscription?.plan?.id === 'free'
}

export function isFreeUserMP(user: Account) {
  if (user?.currentOrganization?.billing?.subscriptions) {
    return user?.currentOrganization?.billing?.subscriptions[0]?.plan === 'free'
  }

  return false
}

export function isAgencyUser(user: Account) {
  if (!user) {
    // eslint-disable-next-line no-console
    console.warn(
      'Warning: isAgencyUser - user was undefined. This could lead to unexpected behaviour.',
    )
  }

  return user?.currentOrganization?.billing?.subscription?.plan?.id === 'agency'
}

export function isTeamUser(user: Account) {
  return user?.currentOrganization?.billing?.subscription?.plan?.id === 'team'
}

export function isEssentialsUser(user: Account) {
  return (
    user?.currentOrganization?.billing?.subscription?.plan?.id === 'essentials'
  )
}

export function userCanStartFreeTrial(user: Account) {
  return Boolean(user?.currentOrganization?.billing?.canStartTrial)
}

export function isOnActiveTrial(user: Account) {
  return (
    user?.currentOrganization?.billing?.subscription?.trial?.isActive || false
  )
}

export function isOnAgencyTrial(user: Account) {
  if (!user) {
    // eslint-disable-next-line no-console
    console.warn(
      'Warning: isOnAgencyTrial - user was undefined. This could lead to unexpected behaviour.',
    )
  }

  const isOnAgencyPlan = isAgencyUser(user)
  if (isOnAgencyPlan) {
    return isOnActiveTrial(user)
  }

  return false
}

export function hasTeamMembers(user: Account): boolean {
  return (user?.currentOrganization?.members?.totalCount ?? 0) > 1
}

// Channel slots are only available for NB customers
export function getUsersCurrentChannelSlotDetails(user: Account) {
  return user?.currentOrganization?.billing?.channelSlotDetails
}

export function getUserChannelSlots(user: Account) {
  // Agencies channelSlotDetails.chargableQuantity will stay at 0
  // until they increase their channel quantity above 10
  // as the flatPrice includes 10 channels.
  // So we will always want to return the currentQuantity to get their slot details.
  if (isAgencyUser(user)) {
    return getUsersCurrentChannelSlotDetails(user)?.currentQuantity
  }

  return getUsersCurrentChannelSlotDetails(user)?.chargableQuantity
}

export function hasPaymentDetails(user: Account) {
  return user?.currentOrganization?.billing?.paymentDetails?.hasPaymentDetails
}

export function getUserBillingData(user: Account) {
  return user?.currentOrganization?.billing
}

export function getUsersCurrentPlan(user: Account) {
  const billingData = getUserBillingData(user)

  if (!billingData) return

  return getSubscriptionPlanData(billingData)
}

export function hasPermissionToManageBilling(user: Account) {
  return user?.currentOrganization?.privileges?.canManageBilling
}

export function hasEditPermission(user: Account) {
  return user?.currentOrganization?.canEdit
}

export function canHaveTeamMembers(user: Account) {
  return isAgencyUser(user) || isTeamUser(user)
}

export function getUserChannelCount(user: Account) {
  return user?.currentOrganization?.channels?.length
}

export function getUserUnlockedChannelCount(user: Account) {
  const unlockedChannels =
    user?.currentOrganization?.channels?.filter(
      (channel) => !channel?.isLocked,
    ) || []

  return unlockedChannels.length
}

export function getUserUnlockedChannels(user: Account) {
  return (
    user?.currentOrganization?.channels?.filter(
      (channel) => !channel?.isLocked,
    ) || []
  )
}

export function getUserLockedChannels(user: Account) {
  return (
    user?.currentOrganization?.channels?.filter(
      (channel) => channel?.isLocked,
    ) || []
  )
}

export function isRevenueCatBillingGateway(user: Account) {
  return (
    user?.currentOrganization?.billing?.gateway?.gatewayType === 'revenuecat'
  )
}

// checks if RevenueCat (mobile) is a paying customer
export function isPaidRevenueCatBillingGateway(user: Account) {
  return (
    user?.currentOrganization?.billing?.gateway?.gatewayType === 'revenuecat' &&
    user?.currentOrganization?.billing?.subscription?.plan?.id !== 'free'
  )
}

export function getBillingGateway(user: Account) {
  return user?.currentOrganization?.billing?.gateway?.gatewayType
}

// FIXME DUPLICATED CODE: from  account-frontend

export function isPayingStripeCustomer(user: Account) {
  return (
    user?.currentOrganization?.billing?.gateway?.gatewayType === 'stripe' &&
    user?.currentOrganization?.billing?.subscription?.plan?.id !== 'free' &&
    !isOnActiveTrial(user)
  )
}

export function isCustomerTaxExempt(user: Account) {
  return user?.currentOrganization?.billing?.taxExempt === 'exempt'
}

export function isUnrecognizedTaxLocationValid(user: Account) {
  return (
    user?.currentOrganization?.billing?.taxDetails?.automaticTax ===
    'unrecognized_location'
  )
}

export function isCanceledAtPeriodEnd(user: Account) {
  return user?.currentOrganization?.billing?.subscription?.isCanceledAtPeriodEnd
}

export function canManageBilling(user: Account) {
  return user?.currentOrganization?.privileges?.canManageBilling || false
}

export function getOrganization(user: Account) {
  return user?.currentOrganization
}

export function getOrganizationId(user: Account) {
  return user?.currentOrganization?.id
}

export function getUserChannelList(user: Account) {
  return user?.currentOrganization?.channels
}

export function isAtPlanChannelLimit(user: Account) {
  const unlockedChannels = getUserUnlockedChannelCount(user)
  const channelLimit = getUserChannelSlots(user) || 0
  return unlockedChannels >= channelLimit
}

export function isPaymentPastDue(user: Account) {
  if (user?.currentOrganization?.isOneBufferOrganization === false) {
    return false
  }
  if (user?.currentOrganization?.billing?.gateway?.gatewayType !== 'stripe') {
    return false
  }
  if (
    user?.currentOrganization?.billing?.subscription?.isPaymentPastDue === true
  ) {
    return true
  }
  return false
}

export function isObjectDismissed(user: Account, target: string) {
  return (
    user?.currentOrganization?.dismissedObjects?.some(
      (dismissedObject: DismissedObject) => dismissedObject.id === target,
    ) || false
  )
}

export function formatCreditCardExpiryToDate(
  cardDetails: CreditCardDetails,
): Date {
  return new Date(`
  ${Number(cardDetails?.expYear) || 2023},
  ${Number(cardDetails?.expMonth) || 1},
  30, 23:59:00`)
}

export function getUserCreditCardDetails(
  user: Account,
): CreditCardDetails | null {
  return user?.currentOrganization?.billing?.paymentDetails?.creditCard || null
}

export function calculateDaysTillUserCreditCardExpires(user: Account): number {
  const cardDetails = getUserCreditCardDetails(user)
  if (!cardDetails?.expYear || !cardDetails?.expMonth) return 0

  // We set the expiry to the 30th of the provided card month at midnight
  // Note - we use the specified time in hours to include that day in the counted days
  const cardExpiryDate = formatCreditCardExpiryToDate(cardDetails)

  const isCardAlreadyExpired = isUserCreditCardExpired(user)
  const todaysDate = new Date()

  const remainingDaysTillExpiry = isCardAlreadyExpired
    ? 0
    : differenceInDays(cardExpiryDate, todaysDate)

  return remainingDaysTillExpiry
}

export function isUserCreditCardExpired(user: Account): boolean {
  const cardDetails = getUserCreditCardDetails(user)

  if (!cardDetails?.expYear || !cardDetails?.expMonth) return true

  const cardExpiryDate = formatCreditCardExpiryToDate(cardDetails)

  const isCardAlreadyExpired = isPast(cardExpiryDate)

  return isCardAlreadyExpired
}

export function isUserCreditCardExpiring(user: Account): boolean {
  const remainingDaysTillExpiry = calculateDaysTillUserCreditCardExpires(user)
  const isCardExpiringInNext30Days = remainingDaysTillExpiry <= 30

  return isCardExpiringInNext30Days
}

export function hasExpiredOrExpiringCreditCard(user: Account): boolean {
  return isUserCreditCardExpired(user) || isUserCreditCardExpiring(user)
}

export function isOneBufferOrganization(user: Account) {
  return user?.currentOrganization?.isOneBufferOrganization === true
}

export function getRemainingTrialDays(user: Account): number {
  return (
    user?.currentOrganization?.billing?.subscription?.trial?.remainingDays || 0
  )
}

export function getUserChannelLimit(user: Account) {
  return user?.currentOrganization?.channelLimit
}

// TODO: This function may beable to replace isAtPlanChannelLimit
export function isAtChannelLimit(user: Account) {
  const unlockedChannels = getUserUnlockedChannelCount(user)
  const channelLimit = getUserChannelLimit(user) || 0
  return unlockedChannels >= channelLimit
}

export function isAtTrialChannelLimit(user: Account, service: Service | null) {
  if (!service) return false

  const lifetimeConnectedChannels =
    user?.currentOrganization?.lifetimeConnectedChannelsCount || []

  const totalServicesConnected = (
    lifetimeConnectedChannels.find((acc) => acc.service === service)
      ?.serviceIds ?? []
  ).length

  return (
    (isOnActiveTrial(user) &&
      !isAgencyUser(user) &&
      totalServicesConnected >= TRIAL_CHANNELS_LIMIT) ||
    (isFreeUser(user) && totalServicesConnected >= FREE_CHANNELS_LIMIT)
  )
}

export function getChangePlanOptions(user: Account) {
  return user?.currentOrganization?.billing?.changePlanOptions
}

export function canAccessEngagement(user: Account) {
  return Boolean(user?.currentOrganization?.billing?.canAccessEngagement)
}

export function isPaymentMethodBank(account: Account): boolean {
  return (
    account?.currentOrganization?.billing?.paymentDetails?.paymentMethod ===
      'bank' || false
  )
}
