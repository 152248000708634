import React, { useEffect, useState, useContext } from 'react'
import { useSplitEnabled } from '@buffer-mono/features'

import Text from '@bufferapp/ui/Text'
import FacebookIcon from '@bufferapp/ui/Icon/Icons/Facebook'
import InstagramIcon from '@bufferapp/ui/Icon/Icons/Instagram'
import TwitterIcon from '@bufferapp/ui/Icon/Icons/Twitter'
import LinkedInIcon from '@bufferapp/ui/Icon/Icons/LinkedIn'
import PinterestIcon from '@bufferapp/ui/Icon/Icons/Pinterest'
import ShopifyIcon from '@bufferapp/ui/Icon/Icons/Shopify'
import TiktokIcon from '@bufferapp/ui/Icon/Icons/Tiktok'
import GbpIcon from '@bufferapp/ui/Icon/Icons/Gbp'
import Button from '@bufferapp/ui/Button'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'

import { getActiveProductFromPath } from '../../../../common/utils/getProduct'
import { useTrackPageViewed } from '../../../../common/hooks/useSegmentTracking'
import { UserContext } from '../../../../common/context/User'

import {
  facebook,
  instagram,
  twitter,
  linkedin,
  shopify,
  pinterest,
  tiktok,
  googlebusiness,
  white,
} from '@bufferapp/ui/style/colors'

import { Content, Icons, CloseBtnWrapper, ConnectButtons } from './style'
import { Service } from '../../../../exports/Orchestrator/channelConnections/types'
import { getChannelsConnectionURL } from '../../../../common/utils/urls'
import { ORCHESTRATOR_ACTIONS } from '../../../../common/events/orchestratorEvents'

export const SUPPORTED_PRODUCTS = [
  'analyze',
  'engage',
  'publish',
  'appshell',
] /* appshell has been added for testing purposes */
const CHANNELS_CONNECTION_URL = `${getChannelsConnectionURL()}?utm_source=channel_connection_prompt`

function renderCloseBtn(onClose: () => void): JSX.Element {
  return (
    <CloseBtnWrapper>
      <Button
        type="text"
        hasIconOnly
        onClick={(): void => onClose()}
        label="Close"
        icon={<CrossIcon size="medium" />}
      />
    </CloseBtnWrapper>
  )
}

const CTASection = ({
  label = 'Connect a Channel',
}: {
  label?: string
}): JSX.Element => {
  const { isEnabled: isChannelStorefrontEnabled } = useSplitEnabled(
    'growth-new-channel-store-front',
  )

  function handleCTAClick(): void {
    if (isChannelStorefrontEnabled) {
      ORCHESTRATOR_ACTIONS.openChannelStorefront({
        cta: `analyze-connect-channel-prompt-cta`,
      })
    } else {
      window.location.href = CHANNELS_CONNECTION_URL
    }
  }
  return <Button type="primary" onClick={handleCTAClick} label={label} />
}

const Analytics = (args: { onClose: () => void }): JSX.Element => (
  <>
    {renderCloseBtn(args.onClose)}
    <Text type="h3">
      Connect a channel <br /> to get started with Analytics
    </Text>
    <Icons>
      <FacebookIcon color={facebook} size="large" />
      <InstagramIcon color={instagram} size="large" />
      <TwitterIcon color={twitter} size="large" />
      <LinkedInIcon color={linkedin} size="large" />
      <ShopifyIcon color={shopify} size="large" />
    </Icons>
    <img
      src="https://buffer-ui.s3.amazonaws.com/onboarding/connection-modal-analytics_%40x2.jpg"
      alt="A preview of Buffer's Analytics app."
      width="313px"
    />
    <ConnectButtons>
      <CTASection />
    </ConnectButtons>
  </>
)

const Engagement = (args: { onClose: () => void }): JSX.Element => {
  return (
    <>
      {renderCloseBtn(args.onClose)}
      <Text type="h3">
        Connect a channel <br /> to get started with Engagement
      </Text>
      <Icons>
        <FacebookIcon color={facebook} size="large" />
        <InstagramIcon color={instagram} size="large" />
      </Icons>
      <img
        src="https://buffer-ui.s3.amazonaws.com/onboarding/connection-modal-engagement_%40x2.jpg"
        alt="A preview of Buffer's Engagement app."
        width="313px"
      />
      <ConnectButtons>
        <Button
          type="primary"
          icon={<FacebookIcon color={white} size="large" />}
          iconEnd
          onClick={(): void => {
            if (window.appshell?.actions) {
              const { actions } = window.appshell || {}
              actions.connectChannel({
                selectedService: Service.facebook,
                cta: `engange-channelPrompt-channelConnect-facebook-1`,
              })
            }
          }}
          label={'Connect Facebook'}
        />
        <Button
          type="primary"
          icon={<InstagramIcon color={white} size="large" />}
          iconEnd
          onClick={(): void => {
            if (window.appshell?.actions) {
              const { actions } = window.appshell || {}
              actions.connectChannel({
                selectedService: Service.instagram,
                cta: `engange-channelPrompt-channelConnect-instagram-1`,
              })
            }
          }}
          label={'Connect Instagram'}
        />
      </ConnectButtons>
    </>
  )
}

const Publish = (args: { onClose: () => void }): JSX.Element => (
  <>
    {renderCloseBtn(args.onClose)}
    <Text type="h3">
      Connect a channel <br /> to get started with Publishing
    </Text>
    <Icons>
      <TiktokIcon color={tiktok} size="large" />
      <GbpIcon color={googlebusiness} size="large" />
      <FacebookIcon color={facebook} size="large" />
      <InstagramIcon color={instagram} size="large" />
      <TwitterIcon color={twitter} size="large" />
      <LinkedInIcon color={linkedin} size="large" />
      <PinterestIcon color={pinterest} size="large" />
    </Icons>
    <img
      src="https://buffer-ui.s3.amazonaws.com/connection_modal_publishing.png"
      alt="A preview of BUffer's Publishing app."
      width="313px"
    />
    <ConnectButtons>
      <CTASection />
    </ConnectButtons>
  </>
)

const ChannelConnectionPrompt = (args: {
  closeAction: () => void
}): JSX.Element => {
  const [product, setProduct] = useState('')
  const user = useContext(UserContext)
  const { closeAction } = args

  useEffect(() => {
    const activeProduct = getActiveProductFromPath()
    setProduct(activeProduct)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTrackPageViewed({
      payload: {
        name: 'Connect Channel Prompt',
        title: 'Connect a Channel',
        product: activeProduct,
      },
      user,
    })
  }, [])

  return (
    <Content id="channel-connection-prompt">
      {product === 'analyze' && <Analytics onClose={closeAction} />}
      {product === 'engage' && <Engagement onClose={closeAction} />}
      {product !== 'analyze' && product !== 'engage' && (
        <Publish onClose={closeAction} />
      )}
    </Content>
  )
}

export default ChannelConnectionPrompt
