import eventDispatcher from '../utils/eventDispatcher'
import { AppshellEventKeys, OrganizationActionKeys } from './types'

export const ENGAGE_ACTIONS = {
  refetchEngageComments(): void {
    eventDispatcher(AppshellEventKeys.ENGAGE_COMMENT_EVENT_KEY, {
      action: OrganizationActionKeys.refetchEngageComments,
    })
  },
}

export type ENGAGE_ACTIONS_TYPE = typeof ENGAGE_ACTIONS
