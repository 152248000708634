import {
  Products,
  type ProductNames,
} from '../../../components/NavBar/components/NavBarProducts/NavBarProducts'

export function getActiveProductFromUrl(): ProductNames | null {
  const subdomain = window.location.hostname.split('.')[0].toLowerCase()
  const products = Object.values(Products)

  const matchingProducts = products.filter(
    ({ subdomain: productSubdomain }) => productSubdomain === subdomain,
  )
  const pathname = window.location.pathname.split('/')[1]

  const productId =
    matchingProducts.find(
      ({ pathname: productPathname, activeOnMatch }) =>
        activeOnMatch?.test(pathname) ?? productPathname === pathname,
    )?.id ?? matchingProducts.find(({ pathname }) => pathname === '')?.id

  return productId ?? null
}

export function isOnboardingPage(): boolean {
  return (
    getActiveProductFromUrl()?.toString() === 'account' &&
    window.location.pathname.includes('onboarding')
  )
}
