import React from 'react'
import { useDispatch } from 'react-redux'

import Button from '@bufferapp/ui/Button'
import SimpleModal from '@bufferapp/ui/SimpleModal'
import Text from '@bufferapp/ui/Text'

import { useUser } from '../../../../../common/context/User'
import { MODALS } from '../../../../../common/types'
import { getMigrationHubUrl } from '../../../../../common/utils/urls'
import {
  isFreeUser,
  isOnActiveTrial,
  isOneBufferOrganization,
} from '../../../../../common/utils/user'
import { resetChannelConnectionsState } from '../../../store/channelConnectionsReducer'
import * as Styled from './styles'
import { type AuthMethods, InstagramAuthMethod, Service } from '../../types'
import { RESTRICTED_SERVICES_FOR_MP_USERS } from '../../../../../common/constants/channels'
import PaywallPromptChannels, {
  type PaywallService,
} from './PaywallPromptChannels'
import { useModalManager } from '../../../../../components/ModalManager/hooks/useModalManager'

const redirectToMigrationHub = (): void => {
  window.location.href = getMigrationHubUrl(
    'channelConnections-paywall-upgradePlan-1',
  )
}

function isMPRestricted<T extends Service>(
  isOneBufferOrg: boolean,
  service?: T,
  authMethod?: AuthMethods[T],
): boolean {
  if (isOneBufferOrg) {
    return false
  }

  if (service) {
    return !!(
      RESTRICTED_SERVICES_FOR_MP_USERS.includes(service) ||
      (service === Service.instagram &&
        authMethod === InstagramAuthMethod.notificationsOnly)
    )
  }

  return false
}

const PaywallModal = <T extends Service>({
  service,
  authMethod,
  onClose,
}: {
  service?: T
  authMethod?: AuthMethods[T]
  serviceName?: string
  onClose?: () => void
}): JSX.Element => {
  const user = useUser()
  const { openModal } = useModalManager()

  const isOneBufferOrg = isOneBufferOrganization(user)
  const isFreeNewBufferPlan = isFreeUser(user)
  const isOnTrial = isOnActiveTrial(user)
  const isMPRestrictedService = isMPRestricted(
    isOneBufferOrg,
    service,
    authMethod,
  )

  const isConnectionLimited = isOnTrial || isFreeNewBufferPlan

  const dispatch = useDispatch()

  // We display slightly different copy depending on if the user is a MP customer or
  // if they are New Buffer on the Free Plan
  const heading = isFreeNewBufferPlan
    ? 'Upgrade your Buffer plan'
    : 'Add more channels'
  const planNaming = isFreeNewBufferPlan ? 'current' : 'legacy'
  const extraCopy = isFreeNewBufferPlan
    ? 'To connect more channels, please upgrade your plan.'
    : 'To add more channels, update your plan to unlock our newest features and more channels.'

  const ctaLabel = 'Upgrade Plan'

  function closeModal(): void {
    if (onClose) {
      onClose()
    } else {
      dispatch(resetChannelConnectionsState({}))
    }
  }

  function handleOnClick(): void {
    closeModal()
    if (isOneBufferOrg) {
      openModal({
        key: MODALS.planSelector,
        data: {
          cta: 'channelConnections-paywall-upgradePlan-1',
          upgradePathName: 'channelConnectionsPaywall-upgrade',
        },
      })
    } else {
      redirectToMigrationHub()
    }
  }

  if (isMPRestrictedService && service) {
    return (
      <PaywallPromptChannels
        service={service as PaywallService}
        authMethod={authMethod}
        handleOnClick={handleOnClick}
        closeModal={closeModal}
      />
    )
  }

  return (
    <SimpleModal
      closeAction={(): void => {
        closeModal()
      }}
    >
      <Styled.ContentWrapper>
        <Styled.HeaderWrapper>
          <Text type="h2">
            {isConnectionLimited
              ? 'No more channel connections available'
              : heading}
          </Text>
        </Styled.HeaderWrapper>
        <Styled.Body>
          {isConnectionLimited && (
            <Text type="p">
              You have reached the limit on the number of times you can connect
              a channel on{' '}
              {isOnTrial ? 'your free trial' : "Buffer's free plan"}. To add
              more, please consider a paid plan which also unlocks additional
              features.
            </Text>
          )}
          {!isConnectionLimited && (
            <Text type="p">
              You&apos;ve reached the limit on the number of channels you can
              connect based on your {planNaming} plan. {extraCopy}
            </Text>
          )}
        </Styled.Body>
        <Styled.Footer>
          <Button
            type="text"
            label="Cancel"
            disabled={false}
            onClick={(): void => {
              closeModal()
            }}
          />
          <Button
            type="primary"
            label={ctaLabel}
            disabled={false}
            onClick={(): void => {
              handleOnClick()
            }}
          />
        </Styled.Footer>
      </Styled.ContentWrapper>
    </SimpleModal>
  )
}

export default PaywallModal
