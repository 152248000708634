import { getOrganization } from '../common/utils/user'

import {
  getCommonTrackingPropertiesFromAccount,
  shouldPerformTrackingEvent,
} from './utils'
import type { Account } from '../common/types'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { getActiveProductFromPath } from '../common/utils/getProduct'

export type PaywallVideoPlayedTrackingPayload = {
  progress: number
  upgradePathName: string
}

export type PaywallVideoPlayedTrackingArguments = {
  payload: PaywallVideoPlayedTrackingPayload
  user: Account
}

export default function trackCTAPaywallVideoPlayed({
  payload,
  user,
}: PaywallVideoPlayedTrackingArguments): void {
  const canPerformTrackingEvent = shouldPerformTrackingEvent({
    user,
  })

  if (!canPerformTrackingEvent) {
    return
  }

  const { progress, upgradePathName } = payload

  const organization = getOrganization(user)
  const product = getActiveProductFromPath()

  const commonTrackingProperties = getCommonTrackingPropertiesFromAccount(user)

  const eventData = {
    organizationId: organization?.id || null,
    upgradePathName,
    clientName: 'publishWeb',
    product,
    ...commonTrackingProperties,
    progress,
  }
  BufferTracker.paywallVideoPlayed(eventData)
}
