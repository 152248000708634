import React from 'react'
import PropTypes from 'prop-types'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
} from '@apollo/client'

const APIGatewayProvider = ({
  children,
}: {
  children: React.ReactNode
}): React.ReactElement => {
  const client = new ApolloClient({
    connectToDevTools: false,
    cache: new InMemoryCache({
      possibleTypes: {
        Billing: ['MPBilling', 'OBBilling'],
      },
      typePolicies: {
        OBBilling: {
          merge: true,
        },
        // @ts-expect-error (TS2559) Type 'string[][]' has no properties in common with type 'TypePolicy'.
        Channels: [['id', 'name']],
      },
    }),
    link: new HttpLink({
      uri: window.API_GATEWAY_URL,
      credentials: 'include',
      headers: {
        'x-buffer-client-id': 'webapp-app-shell',
      },
    }),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

APIGatewayProvider.propTypes = {
  children: PropTypes.any,
}

export default APIGatewayProvider
