import React, { useContext, useEffect } from 'react'
import Text from '@bufferapp/ui/Text'
import Button from '@bufferapp/ui/Button'
import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'
import PropTypes from 'prop-types'

import { trackUpgradePathViewed } from '../../../../common/hooks/useSegmentTracking'
import { UserContext, useUser } from '../../../../common/context/User'
import { Account, MODALS } from '../../../../common/types'
import useDismissObject from '../../../../common/hooks/useDismissObject'
import { addMonths } from 'date-fns'

import {
  ScreenContainer,
  SectionLeft,
  SectionRight,
  ButtonContainer,
  Details,
  Check,
} from './styles'
import trackTrialExpiredModalViewed from '../../../../tracking/TrialPaths/trackTrialExpiredModalViewed'
import trackTrialExpiredModalMainCTAClicked from '../../../../tracking/TrialPaths/trackTrialExpiredModalMainCTAClicked'
import { useModalManager } from '../../../ModalManager/hooks/useModalManager'

const bodyTextColor = 'grayDarker'

const benefitCheckPoints = [
  'Schedule up to 2,000 posts',
  'Track audience growth with Analyze',
  'Engage with your audience directly',
  'Collaborate with unlimited users',
  'Save & manage Hashtags easily',
  'Get unlimited tags',
]

export const TrialExpired = (): JSX.Element => {
  const user = useUser()
  const { modalData, openModal, dismissModal: onDismiss } = useModalManager()
  const { onDismissObject } = useDismissObject({
    organizationId: user?.currentOrganization?.id,
    dismissedObject: {
      id: 'trial_expired_modal',
      expiresAt: addMonths(new Date(), 6).toISOString(),
    },
  })

  const { ctaButton, upgradePathName, ctaView, ctaLocation } = modalData || {}

  const defaultUpgradePathName = 'trialExpiredModal-upgrade'
  const payload = {
    ctaButton: ctaButton || 'upgradeOptions',
    upgradePathName: upgradePathName || defaultUpgradePathName,
    ctaView: ctaView || 'trialExpiredModal',
    ctaLocation: ctaLocation || 'trialExpiredModal',
  }

  const onUpgrade = (): void => {
    openModal({
      key: MODALS.planSelector,
      data: {
        cta: 'navBar-trialExpiredModal-trialExpired-1',
        upgradePathName: 'trialExpired-upgrade',
      },
    })
  }

  useEffect(() => {
    trackTrialExpiredModalViewed({
      payload,
      user,
    })
    trackUpgradePathViewed({
      payload: {
        upgradePathName: defaultUpgradePathName,
      },
      user,
    })
  }, [])

  const handleMainCTAClicked = (): void => {
    trackTrialExpiredModalMainCTAClicked({
      payload,
      user,
    })
    onDismissObject()
    onUpgrade()
  }

  const imageUrl =
    'https://buffer-ui.s3.amazonaws.com/upgrade-paths/trial-expired/social-proof.png'
  const description = `Your free trial has expired and you are back on the limited free plan. Upgrade now to keep enjoying all these benefits and more...`

  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <ScreenContainer>
      <SectionLeft>
        <Text type="h2" color={bodyTextColor}>
          Your trial period is over. What’s next?
        </Text>
        <Text type="p" color={bodyTextColor}>
          {description}
        </Text>
        <Details>
          {benefitCheckPoints.map(
            (detail: any): JSX.Element => (
              <li key={detail}>
                <Check>
                  <CheckmarkIcon size="small" />
                </Check>
                <Text color={bodyTextColor}>{detail}</Text>
              </li>
            ),
          )}
        </Details>
        <ButtonContainer>
          <Button
            type="primary"
            onClick={handleMainCTAClicked}
            label="See Upgrade Options"
          />
          <Button
            type="secondary"
            onClick={(): void => {
              onDismissObject()
              onDismiss()
            }}
            label="Stay on the Free Plan"
          />
        </ButtonContainer>
      </SectionLeft>
      <SectionRight backgroundImageURL={imageUrl} />
    </ScreenContainer>
  )
}

export default TrialExpired
