import { getPortForProduct } from '../../common/utils/urls'

export default [
  {
    id: 'connect-channel',
    section: 'go to',
    name: 'Connect channel',
    shortcut: ['n', 'c'],
    keywords: 'new channel',
    perform: (): void => {
      const [, ...domain] = window.location.hostname.split('.')
      const port = getPortForProduct('account')
      const newUrl = `https://account.${domain.join(
        '.',
      )}${port}/channels/connect`
      return window.location.assign(newUrl)
    },
  },
  {
    id: 'manage-channels',
    section: 'go to',
    name: 'Manage channels',
    keywords: 'manage channels',
    perform: (): void => {
      const [, ...domain] = window.location.hostname.split('.')
      const port = getPortForProduct('account')
      const newUrl = `https://account.${domain.join('.')}${port}/channels`
      return window.location.assign(newUrl)
    },
  },
  {
    id: 'analytics',
    name: 'Analytics',
    section: 'go to',
    shortcut: ['g', 'a'],
    keywords: 'analytics, analyze',
    perform: (): void => {
      const [, ...domain] = window.location.hostname.split('.')
      const port = getPortForProduct('analyze')
      const newUrl = `https://analyze.${domain.join('.')}${port}`
      return window.location.assign(newUrl)
    },
  },
  {
    id: 'engagement',
    section: 'go to',
    name: 'Engagement',
    shortcut: ['g', 'e'],
    keywords: 'engagement',
    perform: (): void => {
      const [, ...domain] = window.location.hostname.split('.')
      const port = getPortForProduct('engage')
      const newUrl = `https://engage.${domain.join('.')}${port}`
      return window.location.assign(newUrl)
    },
  },
  {
    id: 'publishing',
    section: 'go to',
    name: 'Publishing',
    shortcut: ['g', 'p'],
    keywords: 'publish',
    perform: (): void => {
      const [, ...domain] = window.location.hostname.split('.')
      const port = getPortForProduct('publish')
      const newUrl = `https://publish.${domain.join('.')}${port}`
      return window.location.assign(newUrl)
    },
  },
  {
    id: 'start-page',
    section: 'go to',
    name: 'Start Page',
    shortcut: ['g', 's'],
    keywords: 'start page',
    perform: (): void => {
      const [, ...domain] = window.location.hostname.split('.')
      const port = getPortForProduct('start-page')
      const newUrl = `https://start-page.${domain.join('.')}${port}`
      return window.location.assign(newUrl)
    },
  },
  {
    id: 'ideas',
    section: 'go to',
    name: 'Ideas',
    shortcut: ['g', 'i'],
    keywords: 'content create',
    perform: (): void => {
      const [, ...domain] = window.location.hostname.split('.')
      const port = getPortForProduct('publish')
      const newUrl = `https://publish.${domain.join('.')}${port}/content`
      return window.location.assign(newUrl)
    },
  },
  {
    id: 'campaigns',
    section: 'go to',
    name: 'Campaigns',
    // shortcut: ['g', 'i'],
    keywords: 'campaigs tag',
    perform: (): void => {
      const [, ...domain] = window.location.hostname.split('.')
      const port = getPortForProduct('publish')
      const newUrl = `https://publish.${domain.join('.')}${port}/campaigns`
      return window.location.assign(newUrl)
    },
  },
  {
    id: 'calendar',
    section: 'go to',
    name: 'Calendar',
    shortcut: ['g', 'c'],
    keywords: 'schedule',
    perform: (): void => {
      const [, ...domain] = window.location.hostname.split('.')
      const port = getPortForProduct('publish')
      const newUrl = `https://publish.${domain.join('.')}${port}/calendar`
      return window.location.assign(newUrl)
    },
  },
  {
    id: 'account',
    section: 'go to',
    name: 'Account',
    keywords: 'account email password',
    perform: (): void => {
      const [, ...domain] = window.location.hostname.split('.')
      const port = getPortForProduct('account')
      const newUrl = `https://account.${domain.join('.')}${port}/`
      return window.location.assign(newUrl)
      // window.open(newUrl, '_blank')
    },
  },
  {
    id: 'billing',
    section: 'go to',
    name: 'Billing',
    keywords: 'invoice receipt plan payment',
    perform: (): void => {
      const [, ...domain] = window.location.hostname.split('.')
      const port = getPortForProduct('account')
      const newUrl = `https://account.${domain.join('.')}${port}/billing`
      return window.location.assign(newUrl)
    },
  },
  {
    id: 'new-post',
    section: 'go to',
    name: 'New Post',
    keywords: 'create post',
    shortcut: ['n', 'p'],
    perform: (): void => {
      const [, ...domain] = window.location.hostname.split('.')
      const port = getPortForProduct('publish')
      const newUrl = `https://publish.${domain.join('.')}${port}/post/new`
      return window.location.assign(newUrl)
    },
  },
  {
    id: 'new-idea',
    section: 'go to',
    name: 'New Idea',
    keywords: 'create idea',
    shortcut: ['n', 'i'],
    perform: (): void => {
      const [, ...domain] = window.location.hostname.split('.')
      const port = getPortForProduct('publish')
      const newUrl = `https://publish.${domain.join(
        '.',
      )}${port}/create/ideas/new`
      return window.location.assign(newUrl)
    },
  },
  {
    id: 'feature-request',
    section: 'go to',
    name: 'Submit Feature Request',
    keywords: 'feedback feature',
    // shortcut: ['n', 'i'],
    perform: (): void => {
      window.open('https://buffer.com/feature-request', '_blank')
    },
  },
  {
    id: 'help-center',
    section: 'go to',
    name: 'Help Center',
    keywords: 'help support customer',
    // shortcut: ['n', 'i'],
    perform: (): void => {
      window.open(
        'https://support.buffer.com?utm_source=buffer&utm_medium=command-launcher&utm_campaign=command-launcher',
        '_blank',
      )
    },
  },
  {
    id: 'whats-new',
    section: 'go to',
    name: "What's New",
    keywords: 'whats new updates',
    // shortcut: ['n', 'i'],
    perform: (): void => {
      window.open('https://buffer.com/whats-new', '_blank')
    },
  },
  {
    id: 'get-in-touch',
    section: 'go to',
    name: 'Get in touch',
    keywords: 'help support customer',
    // shortcut: ['n', 'i'],
    perform: (): void => {
      window.open(
        'https://support.buffer.com/?openContact=true&utm_source=buffer&utm_medium=command-launcher&utm_campaign=command-launcher',
        '_blank',
      )
    },
  },
]
