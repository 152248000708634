import React, { useCallback, useMemo } from 'react'

import { DropdownMenu, IconButton, GripIcon } from '@buffer-mono/popcorn'
import { getAppsDropdownItems } from '../../utils/dropdown-items'

import styles from './AppsMenu.module.css'

type cb = () => void

type HandlersMap = {
  [key: string]: (e: Event) => void
}

const items = getAppsDropdownItems()

const AppsMenu = (): JSX.Element => {
  /* using useCallback and useMemo to avoid unnecessary re-renders */
  const handleItemClick = useCallback(async (e: Event, onItemClick?: cb) => {
    e.preventDefault()
    if (onItemClick) onItemClick()
  }, [])

  const itemClickHandlers = useMemo(() => {
    return items.reduce<HandlersMap>((acc, item) => {
      acc[item.id] = (e: Event) => handleItemClick(e, item?.onItemClick)
      return acc
    }, {})
  }, [items, handleItemClick])

  return (
    <DropdownMenu
      modal={false}
      trigger={
        <IconButton
          variant="tertiary"
          label="Apps Menu"
          size="large"
          className={styles.triggerButton}
        >
          <GripIcon />
        </IconButton>
      }
    >
      {getAppsDropdownItems().map((item) => (
        <DropdownMenu.Item
          key={item.id}
          id={item.id}
          onSelect={itemClickHandlers[item.id]}
          className={styles.dropdownItem}
        >
          {item.title}
        </DropdownMenu.Item>
      ))}
    </DropdownMenu>
  )
}

export default AppsMenu
