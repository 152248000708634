import {
  ChannelAvatar,
  CheckCircleIcon,
  Flex,
  Heading,
  LockIcon,
  Text,
} from '@buffer-mono/popcorn'
import React from 'react'
import type { Channel } from '../../../../../common/types'
import type { Service } from '../../../../../exports/Orchestrator/channelConnections/types'
import {
  capitalizeFirstLetter,
  getServiceName,
} from '../../../../../exports/Orchestrator/channelConnections/utils'
import styles from './ChannelsList.module.css'

const ChannelsList = (args: { channels: Channel[] }): JSX.Element => {
  const { channels } = args
  return (
    <Flex className={styles.channelList} direction="column" gap="sm">
      {channels.map((channel) => (
        <Flex
          key={channel.id}
          className={styles.channelCard}
          gap="lg"
          align="center"
        >
          <ChannelAvatar
            alt={channel.name}
            channel={channel.service as Service}
            src={channel.avatar}
          />
          <Flex direction="column" className={styles.channelInfo}>
            <Heading size="small" as="h2">
              {channel.name}
            </Heading>
            <Text color="subtle">
              {getServiceName(channel.service as Service)}{' '}
              {capitalizeFirstLetter(channel.type || '')}
            </Text>
          </Flex>
          {channel.isLocked && <LockIcon size="medium" color="subtle" />}
          {!channel.isLocked && <CheckCircleIcon size="medium" />}
        </Flex>
      ))}
    </Flex>
  )
}

export default ChannelsList
