import React from 'react'
import { useUser } from '../../../../common/context/User'
import {
  Button,
  ChevronDownIcon,
  Dialog,
  EmptyState,
  Flex,
  Link,
  LockIcon,
  Paragraph,
  Select,
  Strong,
  VisuallyHidden,
} from '@buffer-mono/popcorn'
import useOrgSwitcher from '../../../../common/hooks/useOrgSwitcher'
import { getLogoutUrl } from '../../../../common/utils/urls'
import { ModalProps } from '../../../../common/types'

export const EMAIL_SUBJECT = 'Access to Buffer lost'
export const EMAIL_BODY =
  "Hey, I lost access to Buffer and can't contribute to your organisation anymore. May you upgrade to the Team plan again so that I can regain access, please?"

export const TeamMemberDowngradeModal = ({
  closeAction,
}: ModalProps): JSX.Element | null => {
  const account = useUser()
  const switchOrganization = useOrgSwitcher()
  const organization = account?.currentOrganization

  const handleSwitchOrganization = (organizationId: string): void => {
    closeAction()
    switchOrganization(organizationId)
    window.location.reload()
  }

  const handleLogout = (): void => {
    closeAction()
    window.location.assign(getLogoutUrl(window.location.href))
  }

  if (!organization) {
    return null
  }

  return (
    <Dialog open={true} onOpenChange={closeAction}>
      <Dialog.Content>
        <VisuallyHidden as="div">
          <Dialog.Title>Organization has been downgraded</Dialog.Title>
        </VisuallyHidden>

        <EmptyState variant="warning" size="large">
          <EmptyState.Icon>
            <LockIcon />
          </EmptyState.Icon>
          <EmptyState.Heading size="medium">
            Looks like you&apos;ve lost access
          </EmptyState.Heading>
          <EmptyState.Description size="sm">
            To restore access, please ask the admin{' '}
            <Link
              external
              color="inherit"
              href={`mailto:${organization.ownerEmail}?subject=${EMAIL_SUBJECT}&body=${EMAIL_BODY}`}
            >
              {organization.ownerEmail}
            </Link>{' '}
            of <Strong>{organization.name}</Strong> to upgrade to the{' '}
            <Link external color="inherit" href="https://buffer.com/pricing">
              Team plan
            </Link>
            .
          </EmptyState.Description>
          <Flex gap="md" direction="column" align="center">
            <Paragraph size="sm" color="subtle" align="center">
              Meanwhile, continue using Buffer by switching organization:
            </Paragraph>

            <Select
              value={organization.id}
              onValueChange={handleSwitchOrganization}
            >
              <Select.Trigger>
                <Button size="large">
                  Switch Organization <ChevronDownIcon />
                </Button>
              </Select.Trigger>
              <Select.Content align="end">
                {account?.organizations?.map((org) => (
                  <Select.Item
                    // HACK: in reality ID cannot be undefined, but we need to handle it here to make TS happy
                    key={org.id ?? ''}
                    value={org.id ?? ''}
                    disabled={org.id === organization.id}
                  >
                    {org.name ?? 'Unknown'}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
            <Button size="large" variant="tertiary" onClick={handleLogout}>
              Logout
            </Button>
          </Flex>
        </EmptyState>
      </Dialog.Content>
    </Dialog>
  )
}
