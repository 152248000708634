import type { Service } from '../../../../exports/Orchestrator/channelConnections/types'
import { getServiceName } from '../../../../exports/Orchestrator/channelConnections/utils'

export function constructTitleWithGrammer(numberOfChannels: number): string {
  if (numberOfChannels === 1) return `Great! You've connected a Channel`

  return `Great! You've connected ${numberOfChannels} Channels`
}

export function constructBodtTextWithGrammer(
  service: Service,
  numberOfChannels: number,
): string {
  const serviceName = getServiceName(service)
  return `You've successfully connected ${serviceName} to Buffer. Now you can begin scheduling posts to ${
    numberOfChannels > 1 ? 'them' : 'it'
  }.`
}
