type Platform = 'mac' | 'pc'

const getPlatform = (): Platform => {
  return navigator.userAgent.includes('Macintosh') ? 'mac' : 'pc'
}

export const keyMapping: Record<
  string,
  string | { [key in Platform]: string }
> = {
  mod: { pc: 'Ctrl', mac: '⌘' },
  alt: { pc: 'Alt', mac: '⌥' },
  shift: '⇧',
  tab: '⇥',
  enter: '↵',
  control: { pc: 'Ctrl', mac: '^' },
  del: '⌫',
}

export function mapKeyboardKey(key: string): string {
  const platform = getPlatform()
  const mappedKey = keyMapping[key]

  if (!mappedKey) {
    return key
  }

  if (typeof mappedKey === 'string') {
    return mappedKey
  }

  return mappedKey[platform]
}
