import { grayLighter, grayLight, grayDarker } from '@bufferapp/ui/style/colors'
import styled from 'styled-components'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 512px;
`

export const HeaderWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  color: ${grayDarker};

  h2 {
    margin: 0;
  }
`

export const Body = styled.div`
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  background-color: ${grayLighter};
  border-top: 1px solid ${grayLight};
  border-bottom: 1px solid ${grayLight};

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
    font-weight: 700;
  }
`

export const Footer = styled.footer`
  width: 100%;
  box-sizing: border-box;
  background: white;
  padding: 0 16px;
  margin-top: 16px;
  margin-bottom: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > span,
  & > p {
    margin: 0 auto 0 0;
  }

  & + * {
    display: none;
  }
`
