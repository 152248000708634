import React, { useEffect } from 'react'
import Tooltip from '@bufferapp/ui/Tooltip'
import { StyledEmoji } from './styles'
import { EMOJI_DATA } from '../../../../common/themed-emoji-icon/data'

export const ThemedEmojiIcon = (): JSX.Element | null => {
  const [emojiUrl, setEmojiUrl] = React.useState('')
  const [emojiLink, setEmojiLink] = React.useState('')
  const [emojiTooltip, setEmojiTooltip] = React.useState('')

  useEffect(() => {
    setEmojiUrl(EMOJI_DATA.emojiUrl)
    setEmojiLink(EMOJI_DATA.emojiLink)
    setEmojiTooltip(EMOJI_DATA.emojiTooltip)
  }, [])

  if (!EMOJI_DATA?.emojiDisplay === true) return null
  return (
    <Tooltip label={emojiTooltip} opacity={0.8}>
      <picture>
        <StyledEmoji
          src={emojiUrl}
          alt={emojiTooltip}
          onClick={(e): Window | null => {
            e.preventDefault()
            return window.open(emojiLink, '_blank')
          }}
          role="button"
        />
      </picture>
    </Tooltip>
  )
}
