import { useMutation } from '@apollo/client'
import { DISMISS_OBJECT } from '../graphql/account'

type UseDismissObjectReturn = {
  onDismissObject: () => void
}

const useDismissObject = ({
  organizationId,
  dismissedObject,
}: {
  organizationId?: string
  dismissedObject: { id: string; expiresAt?: string }
}): UseDismissObjectReturn => {
  const [dismissObject] = useMutation(DISMISS_OBJECT)

  const onDismissObject = (): void => {
    if (!dismissedObject || !organizationId) {
      return
    }

    dismissObject({ variables: { organizationId, dismissedObject } })
  }

  return { onDismissObject }
}

export default useDismissObject
