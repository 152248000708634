import {
  BufferTrackerReact as BufferTracker,
  Client,
} from '@buffer-mono/tracking-plan'
import { hasPaymentDetails } from '../utils/user'

import { getActiveProductFromPath } from '../utils/getProduct'
import { prefixWithProduct } from '../utils/prefixWithProduct'
import type { Account } from '../types'

export const formatCTAString = (str: string): string => {
  const result = str
    .split(/\s+/)
    .map((word: any) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join('')

  return `${result.charAt(0).toLowerCase()}${result.slice(1)}`
}

export const trackQuantityUpdateModalViewed = ({
  payload,
  user,
}: {
  payload: {
    currentPlan?: string
    cta?: string
    ctaButton?: string
    ctaView?: string
    ctaLocation?: string
    upgradePathName?: string
  }
  user: Account
}): void => {
  if (user.isImpersonation) {
    return
  }

  const organization = user.currentOrganization
  if (!organization || !organization.id) {
    return
  }

  const product = getActiveProductFromPath()
  const eventData = {
    organizationId: organization.id,
    ctaApp: product,
    ctaVersion: '1',
    currentPlan: null,
    ctaLocation: 'appShell',
    ctaButton: null, // What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`,
    ctaView: null, // What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site

    ...payload,
    cta: prefixWithProduct(payload.cta),
  }

  BufferTracker.quantityUpdateModalViewed(eventData)
}

export const useTrackPageViewed = ({
  payload,
  user,
}: {
  payload: {
    ctaButton?: string
    cta?: string
    name?: string
    title?: string
    product?: string
  }
  user: Account
}): void => {
  if (user.isImpersonation) {
    return
  }

  const organization = user.currentOrganization
  if (!organization || !organization.id) {
    return
  }

  const product = getActiveProductFromPath()
  const eventData = {
    organizationId: organization.id,
    ctaApp: product,
    ctaVersion: '1',
    product,
    url: window.location.href,
    search: window.location.search || null,
    path: window.location.pathname || null, // The path typically refers to a file or directory on the web server.
    ctaLocation: 'appShell',
    title: null,
    name: null, // Human readable name of the page (e.g., ""overview"", ""posts"", etc.)
    ctaView: null, // What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site
    channel: null, // Channel of the page, if applicable (e.g., ""facebook"", ""instagram"", etc.)
    channelId: null, // The database id for the channel document
    channelServiceId: null, // The id of the channel on the given service
    channelType: null, // What is the type of channel? ex. "page", "group"
    platform: null, // The platform on which the page view occurred (e.g. ""classic"", ""new_publish"", ""marketing"", ""ios"")
    referrer: null, // The address of the webpage which is linked to the resource being requested. By checking the referrer, the new webpage can see where the request originated.

    ...payload,
    cta: payload?.cta ? `${product}-${payload.cta}` : null, // If the user navigated to this page from a CTA on another Buffer page, which call to action was it?
    ctaButton: payload.ctaButton || null, // What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`
  }

  BufferTracker.pageViewed(eventData)
}

export const trackUpgradePathViewed = ({
  payload,
  user,
}: {
  payload: {
    upgradePathName: string
  }
  user: Account
}): void => {
  if (user.isImpersonation) {
    return
  }

  const organization = user.currentOrganization
  if (!organization || !organization.id) {
    return
  }

  const product = getActiveProductFromPath()
  const eventData = {
    upgradePathName: `${payload.upgradePathName}`,
    product,
    organizationId: organization.id,
    clientName: Client.PublishWeb,
  }

  BufferTracker.upgradePathViewed(eventData)
}

export const useStripeCheckoutSessionCreated = ({
  payload,
  user,
}: {
  payload: {
    selectedPlan?: string
    channelQuantity?: number
    isStripeCheckout?: boolean
    ctaButton?: string
    cta?: string
    initialChannelQuantity?: number
    initialSelectedPlan?: string
    initialCycle?: string
  }
  user: Account
}): void => {
  if (user.isImpersonation) {
    return
  }

  const organization = user.currentOrganization
  if (!organization || !organization.id) {
    return
  }

  const product = getActiveProductFromPath()

  const eventData = {
    organizationId: organization.id,
    ctaApp: product,

    ctaLocation: 'appShell',
    ctaVersion: '1',
    ctaView: null,
    ...payload,
    cta: payload?.cta ? `${product}-${payload.cta}` : null,
    ctaButton: payload.ctaButton || null,
    initialChannelQuantity: payload?.initialChannelQuantity || null,
    initialSelectedPlan: payload?.initialSelectedPlan || null,
    initialCycle: payload?.initialCycle || null,
  }

  BufferTracker.stripeCheckoutSessionCreated(eventData)
}

export const useChecklistCompleteModalViewed = ({
  user,
}: {
  user: Account
}): void => {
  if (user.isImpersonation) {
    return
  }

  const organization = user.currentOrganization
  if (!organization || !organization.id) {
    return
  }

  const eventData = {
    organizationId: organization.id,
  }

  BufferTracker.checklistModalViewed(eventData)
}

export const trackModalDismissed = ({
  payload,
  user,
}: {
  payload: {
    modalName: string | null
    method: string | null
  }
  user: Account
}): void => {
  if (user.isImpersonation) {
    return
  }

  const organization = user.currentOrganization
  if (!organization || !organization.id) {
    return
  }

  const commonTrackingProperties =
    organization?.commonTrackingProperties || null

  const eventData = {
    organizationId: organization.id,
    modalName: payload?.modalName || null,
    method: payload?.method || null,
    ...commonTrackingProperties,
    hasPaymentDetails: hasPaymentDetails(user) || null,
  }

  BufferTracker.modalDismissed(eventData)
}

export const trackBannerViewed = ({
  payload,
  user,
}: {
  payload: {
    bannerViewed: string[]
  }
  user: Account
}): void => {
  if (user.isImpersonation) {
    return
  }

  const organization = user.currentOrganization
  if (!organization || !organization.id) {
    return
  }

  const commonTrackingProperties =
    organization?.commonTrackingProperties || null

  if (payload?.bannerViewed.length === 0) return

  const eventData = {
    organizationId: organization.id,
    bannerViewed: payload?.bannerViewed || null,
    ...commonTrackingProperties,
  }

  BufferTracker.bannerViewed(eventData)
}
