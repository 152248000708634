import React from 'react'

import { Dialog, Button, Heading, Text } from '@buffer-mono/popcorn'

import styles from './ExitChannelConnectionDialog.module.css'

export function ExitChannelConnectionDialog({
  onOpenChange,
  setShowExitChannelConnectionDialog,
}: {
  onOpenChange: () => void
  setShowExitChannelConnectionDialog: React.Dispatch<
    React.SetStateAction<boolean>
  >
}) {
  return (
    <Dialog open onOpenChange={() => setShowExitChannelConnectionDialog(false)}>
      <Dialog.Content>
        <Dialog.Header>
          <Heading className={styles.heading}>
            Instagram Connection in Progress
          </Heading>
        </Dialog.Header>
        <Dialog.Body>
          <Text>
            Instagram is not fully connected yet. If you exit now, you will lose
            all progress.
          </Text>
        </Dialog.Body>
        <Dialog.Separator />
        <Dialog.Footer className={styles.footer}>
          <Button
            variant="tertiary"
            size="large"
            onClick={() => setShowExitChannelConnectionDialog(false)}
          >
            Continue Connection
          </Button>
          <Button
            className={styles.criticalButton}
            variant="critical"
            size="large"
            onClick={onOpenChange}
          >
            Exit Channel Connection
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}
