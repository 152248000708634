import React from 'react'

import { BufferIcon, CloseIcon, Dialog, IconButton } from '@buffer-mono/popcorn'

import styles from './Header.module.css'

export function Header({
  leftArrow,
}: {
  leftArrow?: React.ReactNode
}): JSX.Element {
  return (
    <Dialog.Header>
      <div className={styles.wrapper}>
        <div>{leftArrow}</div>
        <div>
          <BufferIcon />
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.connectIcon}
          >
            <path
              d="M9.66602 1L12.3327 3.66667M12.3327 3.66667L9.66602 6.33333M12.3327 3.66667L1.66602 3.66667M4.33268 13L1.66602 10.3333M1.66602 10.3333L4.33268 7.66667M1.66602 10.3333L12.3327 10.3333"
              stroke="#646464"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <img
            src="https://buffer-account.s3.amazonaws.com/logo/large/instagram.svg"
            className={styles.instagramLogo}
            alt="Instagram logo"
          />
        </div>
        <Dialog.Close>
          <IconButton label="Close" variant="tertiary" size="small">
            <CloseIcon />
          </IconButton>
        </Dialog.Close>
      </div>
    </Dialog.Header>
  )
}
