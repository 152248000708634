import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import type { PlanSelectorClickedTrackingArguments } from './PlanSelectorTrackingTypes'

import { hasPaymentDetails, getOrganization } from '../../common/utils/user'

import { shouldPerformTrackingEvent } from '../utils'

export default function trackPlanSelectorPlanClicked({
  payload,
  user,
}: PlanSelectorClickedTrackingArguments): void {
  const canPerformTrackingEvent = shouldPerformTrackingEvent({
    user,
  })

  if (!canPerformTrackingEvent) {
    return
  }

  const organization = getOrganization(user)
  const commonTrackingProperties =
    organization?.commonTrackingProperties || null

  const eventData = {
    organizationId: organization?.id || null,
    selectedPlanName: payload?.selectedPlanName || null,
    ...commonTrackingProperties,
    hasPaymentDetails: hasPaymentDetails(user) || null,
    channelQuantity: payload?.channelQuantity || null,
  }

  BufferTracker.planSelectorPlanClicked(eventData)
}
