import React, { useContext } from 'react'

import { UserContext } from '../../../../common/context/User'
import type { MessagingReturn } from '../../../../common/hooks/ChannelsCounter/utils'

import Summary from './Summary'

type SummaryProvider = {
  selectedPlan: string
  channelsCount: number
  increaseCounter: () => void
  decreaseCounter: () => void
  setChannelsCounterValue: (value: string) => void
  newPrice?: string
  channelCounterMessageStatus?: MessagingReturn | null
  currentChannelQuantity?: number
  isPaymentMethodSummary?: boolean
  isStripeCheckout: boolean
}

const SummaryProvider = ({
  selectedPlan,
  channelsCount,
  increaseCounter,
  decreaseCounter,
  setChannelsCounterValue,
  newPrice,
  channelCounterMessageStatus,
  currentChannelQuantity,
  isPaymentMethodSummary,
  isStripeCheckout,
}: SummaryProvider): JSX.Element => {
  const user = useContext(UserContext)

  return (
    <Summary
      user={user}
      planOptions={user?.currentOrganization?.billing?.changePlanOptions}
      trialInfo={user?.currentOrganization?.billing?.subscription?.trial}
      selectedPlan={selectedPlan}
      channelsCount={channelsCount}
      increaseCounter={(): void => increaseCounter()}
      decreaseCounter={(): void => decreaseCounter()}
      setChannelsCounterValue={(value: string): void =>
        setChannelsCounterValue(value)
      }
      newPrice={newPrice}
      channelCounterMessageStatus={channelCounterMessageStatus}
      currentChannelQuantity={currentChannelQuantity}
      isPaymentMethodSummary={isPaymentMethodSummary}
      isStripeCheckout={isStripeCheckout}
    />
  )
}

export default SummaryProvider
