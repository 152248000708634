import { useEffect, useRef } from 'react'
import { getActiveProductFromPath } from '../utils/getProduct'
import trackHelpScoutBeaconOpened from '../../tracking/HelpScoutBeacon/trackHelpScoutBeaconOpened'
import trackHelpScoutBeaconClosed from '../../tracking/HelpScoutBeacon/trackHelpScoutBeaconClosed'
import trackHelpScoutBeaconArticleViewed from '../../tracking/HelpScoutBeacon/trackHelpScoutBeaconArticleViewed'
import trackHelpScoutBeaconEmailSent from '../../tracking/HelpScoutBeacon/trackHelpScoutBeaconEmailSent'
import trackHelpScoutBeaconSearchQueryPerformed from '../../tracking/HelpScoutBeacon/trackHelpScoutBeaconSearchQueryPerformed'
import trackHelpScoutBeaconChatStarted from '../../tracking/HelpScoutBeacon/trackHelpScoutBeaconChatStarted'
const activeProduct = getActiveProductFromPath()
import { env } from '../../env'

interface BeaconInHelpMenu {
  isReady: boolean
  isEnabled: boolean
}
interface BeaconWidget {
  isReady: boolean
  isEnabled: boolean
}

export function getHorizontalPosition(activeProduct: string): number {
  switch (activeProduct) {
    case 'engage':
      return 63
    case 'start_page':
      return 80
    default:
      return 40
  }
}

function positionBeacon(activeProduct: string): void {
  if (window.Beacon) {
    window.Beacon('config', {
      display: {
        horizontalOffset: getHorizontalPosition(activeProduct),
      },
    })
  }
}

function shouldDisplayBeaconOnPage(
  showBeaconInHelpMenu: BeaconInHelpMenu,
): boolean {
  return (
    showBeaconInHelpMenu.isReady &&
    !showBeaconInHelpMenu.isEnabled &&
    !window.location.pathname.includes('/onboarding')
  )
}

export function configureBeacon(
  activeProduct: string,
  shouldEnableBeaconAiAnswers: boolean,
  showBeaconInHelpMenu: BeaconInHelpMenu,
): void {
  const shouldDisplayBeacon = shouldDisplayBeaconOnPage(showBeaconInHelpMenu)
  if (window.Beacon) {
    window.Beacon('config', {
      display: {
        style: shouldDisplayBeacon ? 'icon' : 'manual',
        horizontalOffset: getHorizontalPosition(activeProduct),
        zIndex: 9999,
      },
      messaging: {
        chatEnabled: false,
      },
      aiAnswersEnabled: shouldEnableBeaconAiAnswers,
    })
  }
}

function identifyUser(user: any): void {
  if (window.Beacon && !user.loading) {
    window.Beacon('identify', {
      email: user.email,
      signature: user.helpScoutBeaconSignature,
    })
  }
}

function setupEventListeners(user: any): void {
  if (window.Beacon) {
    window.Beacon('on', 'open', () => {
      trackHelpScoutBeaconOpened({ user })
    })

    window.Beacon('on', 'close', () => {
      trackHelpScoutBeaconClosed({ user })
    })

    window.Beacon(
      'on',
      'article-viewed',
      ({ id: articleId }: { id: string }) => {
        trackHelpScoutBeaconArticleViewed({ user, articleId })
      },
    )

    window.Beacon('on', 'search', ({ query }: { query: string }) => {
      trackHelpScoutBeaconSearchQueryPerformed({ user, searchTerm: query })
    })

    window.Beacon('on', 'email-sent', () => {
      trackHelpScoutBeaconEmailSent({ user })
    })

    window.Beacon('on', 'chat-started', ({ subject }: { subject: string }) => {
      trackHelpScoutBeaconChatStarted({ user, subject })
    })
  }
}

// prettier-ignore
/* eslint-disable */
export default function useHelpScoutBeacon(user: any, shouldEnableBeaconAiAnswers: boolean, showBeaconInHelpMenu: BeaconInHelpMenu, loadBeaconWidget: BeaconWidget) {
  const isBeaconInitialized = useRef(false);

  useEffect(() => {
    // Ensuring Beacon is loaded and user is not in loading state
    const initializeBeacon = () => {
      if (window.Beacon && !user.loading && !isBeaconInitialized.current) {
        identifyUser(user);
        positionBeacon(activeProduct);
        setupEventListeners(user);
        isBeaconInitialized.current = true;
        setTimeout(() => configureBeacon(activeProduct, shouldEnableBeaconAiAnswers, showBeaconInHelpMenu), 1000);
      }
    }

    if (!window.Beacon) {
      if(showBeaconInHelpMenu.isReady && loadBeaconWidget.isReady && loadBeaconWidget.isEnabled) {
        // @ts-ignore
        !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        window.Beacon('init', env.HELP_SCOUT_BEACON_ID);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        window.Beacon('on', 'ready', initializeBeacon);
      }
    } else {
      // Initialize immediately if Beacon is already present
      initializeBeacon();
      identifyUser(user);
      positionBeacon(activeProduct);
      setTimeout(() => configureBeacon(activeProduct, shouldEnableBeaconAiAnswers, showBeaconInHelpMenu), 1000);
    }
  }, [user, activeProduct, shouldEnableBeaconAiAnswers, showBeaconInHelpMenu, loadBeaconWidget]);
}
