import React from 'react'
import { DropdownMenu, Flex, Tooltip } from '@buffer-mono/popcorn'

import type {
  Account,
  Channel,
  Organization,
} from '../../../../../common/types'
import { getNetworkIcon } from '../../../utils/getNetworkIcon'
import useOrgSwitcher from '../../../../../common/hooks/useOrgSwitcher'

import styles from './OrganizationsSwitcher.module.css'

export const ORG_SWITCHER = 'org_switcher'
export const MAX_ITEMS_IN_TOOLTIP = 5

type SelectOrganizationFn = (organizationId: string) => void

export type ChannelTooltipItem = {
  id: string
  title: string
  network: string
  icon?: JSX.Element | null
}

type OrgSwitcherItem = {
  id?: string
  title?: string
  selected: boolean
  subItems: ChannelTooltipItem[]
  onItemClick: () => void
}

type OrganizationSwitcherProps = {
  user: Account
  onOrganizationSelected?: SelectOrganizationFn
}

type OrgSwitcher = {
  type: string
  subItems: ChannelTooltipItem[] | undefined
  defaultTooltipMessage: string | false
  id?: string
  title?: string
  selected: boolean
  onItemClick: () => void
}

function buildOrgSwitcher(
  user: Account,
  selectOrganization: SelectOrganizationFn,
): OrgSwitcher[] | undefined {
  /* Only has one organization */
  if (user.organizations?.length === 1) {
    return []
  }
  /* Gets all the channels in all the organizations in a unidimensional array */
  const channels: Channel[] =
    user?.organizations?.flatMap((o: Organization) => o.channels ?? []) || []

  /* Builds the Organization Switcher */
  const orgSwitcher = {
    title: 'Organizations',
    hideTooltips: !channels,
    menuItems: user.organizations?.map((org: Organization, index: number) => {
      const isCurrentOrganization = user.currentOrganization
        ? user.currentOrganization.id === org.id
        : index === 0
      return {
        id: org.id,
        title: org.name,
        selected: isCurrentOrganization,
        subItems:
          channels && channels.length
            ? channels
                .filter(
                  (channel: Channel) =>
                    channel?.organizationId === org.id && !channel.isLocked,
                )
                .map((channel: Channel) => ({
                  id: channel.id,
                  title: channel.name,
                  network: channel.service,
                }))
            : [],
        onItemClick: (): void => {
          if (!isCurrentOrganization) {
            if (org?.id) selectOrganization(org.id)
          }
        },
      }
    }),
  }

  return orgSwitcher?.menuItems?.map((item: OrgSwitcherItem) => {
    // Adds social icon to each channel
    let subItems: ChannelTooltipItem[] = []

    if (item.subItems.length > 0) {
      subItems = item.subItems.map((subItem: ChannelTooltipItem) => ({
        ...subItem,
        icon: getNetworkIcon(subItem),
      }))
    }

    return {
      ...item,
      type: ORG_SWITCHER,
      subItems: subItems.length ? subItems : undefined,
      defaultTooltipMessage: orgSwitcher.hideTooltips
        ? ''
        : !subItems.length && 'No channels connected yet',
    }
  })
}

const channelsTooltipContent = (
  maxItems: number,
  defaultMessage: string | false,
  items?: ChannelTooltipItem[],
): JSX.Element | null => {
  if (!items && !defaultMessage) return null

  const totalItems = items?.length || 0
  const exceedsTotal = totalItems > maxItems
  const remainingItems = totalItems - maxItems

  if (totalItems === 0) return <>{defaultMessage}</>

  return (
    <Flex gap="2xs" direction="column" className={styles.TooltipWrapper}>
      {items?.slice(0, maxItems).map((item, index) => (
        <Flex
          gap="2xs"
          direction="row"
          align="center"
          justify="start"
          key={`tooltip-item-${index}`}
          className={styles.tooltipItem}
        >
          {item.icon}
          <span className={styles.tooltipChannelName}>{item.title}</span>
        </Flex>
      ))}
      {items?.length === 0 && (
        <span className={styles.tooltipItem}>{defaultMessage}</span>
      )}
      {exceedsTotal && (
        <span
          className={styles.tooltipItem}
        >{`${remainingItems} more...`}</span>
      )}
    </Flex>
  )
}

export const OrganizationsSwitcher = ({
  user,
  onOrganizationSelected,
}: OrganizationSwitcherProps): JSX.Element | null => {
  const switchOrganization = useOrgSwitcher()

  const selectOrganization = (organizationId: string): void => {
    switchOrganization(organizationId, {
      onCompleted: () => {
        onOrganizationSelected?.(organizationId)
        window.location.href = '/'
      },
    })
  }

  const organizations = buildOrgSwitcher(user, selectOrganization)

  if (!organizations || organizations.length === 0) {
    return null
  }
  return (
    <React.Fragment key={'organizations-switcher'}>
      <DropdownMenu.Separator />

      <DropdownMenu.Group label="Organizations">
        {organizations?.map((org) => (
          <Tooltip
            key={org.id}
            content={channelsTooltipContent(
              MAX_ITEMS_IN_TOOLTIP,
              org.defaultTooltipMessage,
              org.subItems,
            )}
            side="left"
          >
            <DropdownMenu.CheckboxItem
              onSelect={org.onItemClick}
              checked={org.selected}
              className={styles.menuitemCheckbox}
            >
              <span className={styles.organizationName}>{org.title}</span>
            </DropdownMenu.CheckboxItem>
          </Tooltip>
        ))}
      </DropdownMenu.Group>
    </React.Fragment>
  )
}
