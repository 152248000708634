import { MASTODON_SERVERS, MASTODON_SERVER_GENERIC } from './constants'
import type { MastodonServer } from './types'

export function getMastodonServers(): MastodonServer[] {
  const servers = MASTODON_SERVERS.map((server) => {
    return {
      url: server,
      image_url: `https://s3.amazonaws.com/static.buffer.com/account/mastodon-server-${server}.png`,
      text: server === MASTODON_SERVER_GENERIC ? 'Other' : server,
    }
  })

  return servers
}
