import { useEffect, useMemo, useState } from 'react'
import { type ApolloError, useLazyQuery } from '@apollo/client'
import debounce from 'lodash/debounce'
import Bugsnag from '@bugsnag/browser'

import { PREVIEW_CUSTOM_UPCOMING_INVOICE } from '../../graphql/billing'
import type { Account, PreviewUpcomingInvoiceReturn } from '../../types'
import { calculateTotalAmountDue } from './utils'

type UseGetTotalChargeAmountReturn = {
  data?: PreviewUpcomingInvoiceReturn
  error: ApolloError | undefined
  loading: boolean
  totalAmountDue?: number
  nextBillingDate: string
  isScheduledChange: boolean
}

const useGetTotalChargeAmount = (
  user: Account,
  selectedPlanId: string,
  interval: string,
  quantity: number,
  currentPlanId: string,
  newPrice: number,
  isActiveTrial: boolean,
  hasPlanCounterChanged: boolean,
): UseGetTotalChargeAmountReturn => {
  const [invoiceData, setInvoiceData] =
    useState<PreviewUpcomingInvoiceReturn | null>(null)
  const [isLoading, setLoading] = useState(false)

  const [getCustomInvoice, { data, loading, error }] =
    useLazyQuery<PreviewUpcomingInvoiceReturn>(
      PREVIEW_CUSTOM_UPCOMING_INVOICE,
      {
        onCompleted: (newData) => {
          setInvoiceData(newData)
          setLoading(loading)
        },
        onError: (e) => {
          Bugsnag.notify(
            new Error(
              `useGetTotalChargeAmount: getCustomInvoice failed with error ${e.message}`,
            ),
          )
        },
      },
    )

  const skipDataQuery =
    selectedPlanId === 'free' ||
    currentPlanId === 'free' ||
    isActiveTrial ||
    !hasPlanCounterChanged

  const debouncedInvoiceData = useMemo(
    () => debounce(getCustomInvoice, 200),
    [getCustomInvoice],
  )

  const totalAmountDue = calculateTotalAmountDue(
    selectedPlanId,
    currentPlanId,
    newPrice,
    invoiceData,
    isActiveTrial,
  )

  useEffect(() => {
    if (!skipDataQuery) {
      setLoading(true)
      debouncedInvoiceData({
        variables: {
          organizationId: user?.currentOrganization?.id,
          plan: selectedPlanId,
          interval,
          quantity,
        },
      })
    }
  }, [
    selectedPlanId,
    quantity,
    interval,
    skipDataQuery,
    debouncedInvoiceData,
    user?.currentOrganization?.id,
  ])

  const nextBillingDate = data?.previewCustomUpcomingInvoice?.nextBillingDate
    ? Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(new Date(data.previewCustomUpcomingInvoice.nextBillingDate))
    : ''
  const isScheduledChange =
    data?.previewCustomUpcomingInvoice?.isScheduledChange || false

  return {
    data,
    error,
    loading: isLoading,
    nextBillingDate,
    isScheduledChange,
    totalAmountDue,
  }
}

export default useGetTotalChargeAmount
