/* eslint-disable @typescript-eslint/no-unused-vars */

/** Modified from: https://github.com/timc1/kbar/blob/main/src/KBarAnimator.tsx to remove animations */

import React from 'react'
import { VisualState, useKBar } from 'kbar'
import { useOuterClick } from 'kbar/lib/utils'

interface KBarAnimatorProps {
  style?: React.CSSProperties
  className?: string
  disableCloseOnOuterClick?: boolean
}

const DisabledAnimator: React.FC<
  React.PropsWithChildren<KBarAnimatorProps>
> = ({ children, style, className, disableCloseOnOuterClick }) => {
  const { visualState, currentRootActionId, query, options } = useKBar(
    (state) => ({
      visualState: state.visualState,
      currentRootActionId: state.currentRootActionId,
    }),
  )

  const outerRef = React.useRef<HTMLDivElement>(null)
  const innerRef = React.useRef<HTMLDivElement>(null)

  useOuterClick(outerRef, () => {
    if (disableCloseOnOuterClick) {
      return
    }
    query.setVisualState(VisualState.animatingOut)
    options.callbacks?.onClose?.()
  })

  return (
    <div
      ref={outerRef}
      style={{
        ...style,
        pointerEvents: 'auto',
      }}
      className={className}
    >
      <div ref={innerRef}>{children}</div>
    </div>
  )
}

export default DisabledAnimator
