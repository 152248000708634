import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import type { Account } from '../../common/types'
import { getOrganization } from '../../common/utils/user'
import {
  getCommonTrackingPropertiesFromAccount,
  shouldPerformTrackingEvent,
} from '../utils'

export default function trackHelpScoutBeaconEmailSent({
  user,
}: {
  user: Account
}): void {
  const canPerformTrackingEvent = shouldPerformTrackingEvent({
    user,
  })

  if (!canPerformTrackingEvent) {
    return
  }

  const organization = getOrganization(user)
  const commonTrackingProperties = getCommonTrackingPropertiesFromAccount(user)

  const eventData = {
    organizationId: organization?.id || '',
    currentUrl: window.location.href,
    ...commonTrackingProperties,
  }

  BufferTracker.helpScoutBeaconEmailSent(eventData)
}
