import React from 'react'

import { Flex, Tag } from '@buffer-mono/popcorn'

import styles from './Tags.module.css'

function Tags({ items }: { items: string[] }): JSX.Element {
  return (
    <Flex gap="2xs" wrap="wrap">
      {items.map((text: string) => (
        <Tag className={styles.tag} key={text}>
          {text.replace('_', ' ')}
        </Tag>
      ))}
    </Flex>
  )
}

export default Tags
