import React, { useContext, useEffect, useState } from 'react'
import {
  ArrowLeftIcon,
  Avatar,
  Button,
  Card,
  ChevronDownIcon,
  CriticalIcon,
  Dialog,
  Heading,
  DropdownMenu,
  Flex,
  IconButton,
  ImageIcon,
  Input,
  Label,
  Text,
  VisuallyHidden,
} from '@buffer-mono/popcorn'
import {
  FileUploadConfig,
  UploadSource,
  useUploader,
} from '@buffer-mono/uploader'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import styles from './PersonalProfile.module.css'
import footerStyles from '../Footer/Footer.module.css'

import { useMutation } from '@apollo/client'
import { CREATE_CUSTOM_CHANNELS } from '../../../../../../../common/graphql/channels'
import { trackCustomChannelSetupViewed } from '../../../../tracking'

import { UserContext } from '../../../../../../../common/context/User'
import { Service } from '../../../../types'
import { Header } from '../Header/Header'
import { Footer } from '../Footer/Footer'

import instagramSelectAccountTypeStyles from '../../InstagramSelectAccountType.module.css'

function validateUserName(name: string): string | undefined {
  if (name.length === 0) {
    return "Username can't be empty"
  }

  if (name.length > 30) {
    return "Username can't be longer than 30 characters"
  }

  if (!/^[a-zA-Z0-9._]*$/.test(name)) {
    return 'Username can only contain letters, numbers, and periods'
  }
}

const fileRestrictions = {
  maxNumberOfFiles: 1,
  allowedFileTypes: ['.jpg', '.jpeg', '.png', '.webp'],
  uploadConfig: FileUploadConfig.IMAGE,
}

function getDimensions(
  file: string,
): Promise<{ height: number; width: number }> {
  return new Promise((resolve) => {
    const img = new Image()
    img.onload = (): void => {
      resolve({
        height: img.height,
        width: img.width,
      })
    }
    img.src = file
  })
}

const MIN_WIDTH = 180
const MIN_HEIGHT = 180

const MAX_WIDTH = 1024
const MAX_HEIGHT = 1024

const MAX_SIZE = 2 * 1024 * 1024

function validateDimensions(width: number, height: number): boolean {
  return (
    width >= MIN_WIDTH &&
    height >= MIN_HEIGHT &&
    width <= MAX_WIDTH &&
    height <= MAX_HEIGHT
  )
}

export function PersonalProfile({
  onBack,
  onContinue,
}: {
  onBack: () => void
  onContinue: (channelId: string) => void
}): JSX.Element {
  const [name, setName] = useState('')
  const [avatar, setAvatar] = useState('')
  const [avatarError, setAvatarError] = useState('')
  const [avatarLoading, setAvatarLoading] = useState(false)
  const [userNameValidationError, setUserNameValidationError] =
    useState<string>()

  const user = useContext(UserContext)

  const uploader = useUploader({
    id: 'avatar-uploader',
    bucket: 'buffer-channel-avatars-bucket',
    userId:
      user?.products?.find((product) => product.name === 'publish')?.userId ||
      '',
    organizationId: user?.currentOrganization?.id || '',
    fileRestrictions,
  })

  const [createCustomChannels, { data, loading }] = useMutation(
    CREATE_CUSTOM_CHANNELS,
  )

  useEffect(() => {
    if (data) {
      const newChannelId = data.createCustomChannels?.channels?.[0]?.id

      if (newChannelId) {
        onContinue(newChannelId)
      }
    }
  }, [data, onContinue])

  useEffect(() => {
    trackCustomChannelSetupViewed({
      account: user,
      service: Service.instagram,
    })
  }, [user])

  return (
    <>
      <Header
        leftArrow={
          <IconButton
            onClick={onBack}
            label="Go back"
            variant="tertiary"
            size="small"
          >
            <ArrowLeftIcon />
          </IconButton>
        }
      />
      <Dialog.Body className={instagramSelectAccountTypeStyles.body}>
        <div className={instagramSelectAccountTypeStyles.titleSubtitle}>
          <Heading size="large">
            Add Your Instagram Personal Account to Buffer
          </Heading>
          <Text>
            You&apos;ll be able to plan your posts and set up notifications to
            publish them.
          </Text>
        </div>
        <Flex
          className={styles.wrapper}
          gap="lg"
          direction="column"
          align="stretch"
        >
          <Flex gap="2xs" direction="column" align="stretch">
            <Label>Account name (your Instagram username)</Label>
            <Input
              size="large"
              value={name}
              className={userNameValidationError ? styles.hasError : undefined}
              onChange={(event): void => {
                setName(event.target.value.replace(/^@/, ''))
                setUserNameValidationError(undefined)
              }}
            />
            {userNameValidationError ? (
              <Flex gap="2xs" align="center">
                <CriticalIcon size="xsmall" color="critical" />
                <Text size="sm" color="critical">
                  {userNameValidationError}
                </Text>
              </Flex>
            ) : (
              <Text color="subtle" size="sm">
                Find it after the dash: www.instagram.com/*username*
              </Text>
            )}
          </Flex>
          <Flex gap="2xs" direction="column">
            <Label>Avatar (optional)</Label>
            <Card className={avatarError ? styles.hasError : undefined}>
              <Flex gap="xs" align="center">
                <Avatar
                  alt="instagram personal account avatar"
                  src={avatar}
                  className={avatarLoading ? styles.avatarLoading : undefined}
                />
                <Text color="subtle" className={styles.description}>
                  At least {MIN_WIDTH}x{MIN_HEIGHT} pixels, less than 2 MB
                </Text>
                <VisuallyHidden
                  as="input"
                  type="file"
                  id="avatar-uploader"
                  accept={fileRestrictions.allowedFileTypes
                    .map((fileType) => `image/${fileType.replace('.', '')}`)
                    .join(',')}
                  multiple={false}
                  onChange={async (event): Promise<void> => {
                    setAvatar('')
                    setAvatarError('')

                    if (event.target.files?.length) {
                      const file = event.target.files[0]

                      if (file.size > MAX_SIZE) {
                        setAvatarError('Avatar images must be under 2 MB.')
                        return
                      }

                      const fileAsObjectUrl = URL.createObjectURL(file)
                      const dimensions = await getDimensions(fileAsObjectUrl)

                      if (
                        !validateDimensions(dimensions.width, dimensions.height)
                      ) {
                        setAvatarError(
                          `Avatar must be between ${MIN_WIDTH}x${MIN_HEIGHT} pixels and ${MAX_WIDTH}x${MAX_HEIGHT} pixels.`,
                        )
                        return
                      }

                      setAvatar(fileAsObjectUrl)
                      setAvatarLoading(true)
                      const result = await uploader.upload([file], {
                        source: UploadSource.filePicker(),
                      })

                      if (result.successful.length) {
                        setAvatar(result.successful[0].uploadURL)
                        setAvatarError('')
                      } else {
                        setAvatarError(
                          'Avatar upload failed. Please try again.',
                        )
                      }
                    } else {
                      setAvatarError('')
                      setAvatar('')
                    }

                    setAvatarLoading(false)
                    /**
                     * Reset the input value to allow the same file to be uploaded again in case of any errors
                     */
                    uploader.uppyInstance.reset()
                    event.target.value = ''
                  }}
                ></VisuallyHidden>
                <Button
                  variant="secondary"
                  className={styles.button}
                  as="label"
                  htmlFor="avatar-uploader"
                  disabled={avatarLoading}
                >
                  <ImageIcon />
                  {avatarLoading ? 'Uploading...' : 'Upload Image'}
                </Button>
              </Flex>
            </Card>
            {avatarError && (
              <Flex gap="2xs" align="center">
                <CriticalIcon size="xsmall" color="critical" />
                <Text size="sm" color="critical">
                  {avatarError}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Dialog.Body>
      <Footer>
        <DropdownMenu
          align="start"
          className={footerStyles.menu}
          trigger={
            <Button
              variant="tertiary"
              size="large"
              className={footerStyles.helpButton}
            >
              Help
              <ChevronDownIcon />
            </Button>
          }
        >
          <DropdownMenu.Item asChild>
            <a
              className={footerStyles.helpLink}
              href="https://support.buffer.com/article/658-using-notification-publishing?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
              target="_blank"
              rel="noopener noreferrer"
            >
              Using notification publishing
            </a>
          </DropdownMenu.Item>
        </DropdownMenu>

        <Button
          size="large"
          disabled={loading || avatarLoading}
          onClick={(): void => {
            const userNameValidationError = validateUserName(name)

            if (userNameValidationError) {
              setUserNameValidationError(userNameValidationError)
              return
            }

            BufferTracker.channelConnectionStarted({
              channel: Service.instagram,
              product: 'account',
              channelType: 'profile',
              clientName: 'core',
              organizationId: user?.currentOrganization?.id || '',
            })

            createCustomChannels({
              variables: {
                input: {
                  channels: [
                    {
                      organizationId: user?.currentOrganization?.id,
                      service: Service.instagram,
                      type: 'profile',
                      timezone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                      customChannelMetadata: {
                        instagramPersonalProfileMetadata: {
                          name,
                          avatar,
                        },
                      },
                    },
                  ],
                },
              },
            })
          }}
        >
          {loading ? 'Connecting Channel' : 'Continue'}
        </Button>
      </Footer>
    </>
  )
}
