import React from 'react'
import styled from 'styled-components'

import { Text } from '@bufferapp/ui'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'
import {
  yellowDark,
  yellowDarker,
  yellowLightest,
} from '@bufferapp/ui/style/colors'

export const StyledNoticeWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 8px;

  border: 1px solid ${yellowDark};
  color: ${yellowDarker};
  background: ${yellowLightest};
  border-radius: 4px;
  font-size: 14px;

  span {
    padding-left: 10px;
  }
`

export default function FacebookGroupWarning(): JSX.Element {
  return (
    <StyledNoticeWrapper>
      <WarningIcon />
      <Text type="span">
        As of April 22, 2024, Meta will no longer support automatic posting to
        Facebook Groups through third-party tools. We will continue to support
        posting to Facebook Groups via reminder-based notifications.
      </Text>
    </StyledNoticeWrapper>
  )
}
