import React from 'react'
import PropTypes from 'prop-types'

import Button from '@bufferapp/ui/Button'
import Text from '@bufferapp/ui/Text'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'

import { orangeDark } from '@bufferapp/ui/style/colors'

import {
  BannerStyled,
  BannerCloseButton,
  Wrapper,
  ButtonWrapper,
} from './style'

export default class Banner extends React.Component {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  constructor(props: any) {
    super(props)
    this.state = {
      isOpen: true,
    }
    this.closeBanner = this.closeBanner.bind(this)
  }

  closeBanner(): void {
    this.setState({ isOpen: false })
    // @ts-expect-error TS(2339) FIXME: Property 'onCloseBanner' does not exist on type 'R... Remove this comment to see the full error message
    const { onCloseBanner } = this.props
    if (onCloseBanner) {
      onCloseBanner()
    }
  }

  renderBannerContent(themeColor: string): JSX.Element {
    // @ts-expect-error TS(2339) FIXME: Property 'customHTML' does not exist on type 'Read... Remove this comment to see the full error message
    const { customHTML, text, actionButton, testId } = this.props
    if (customHTML) {
      return <Wrapper data-testid={testId}>{customHTML}</Wrapper>
    }
    return (
      <Wrapper data-testid={testId}>
        <Text type="paragraph" color="#FFF">
          {text}
        </Text>
        <ButtonWrapper>
          {actionButton.label && actionButton.action && (
            <Button
              type={themeColor === 'orange' ? 'orange' : 'primary'}
              onClick={actionButton.action}
              label={actionButton.label}
              size="small"
            />
          )}
        </ButtonWrapper>
      </Wrapper>
    )
  }

  render(): JSX.Element | null {
    // @ts-expect-error TS(2339) FIXME: Property 'isOpen' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { isOpen } = this.state
    // @ts-expect-error TS(2339) FIXME: Property 'themeColor' does not exist on type 'Read... Remove this comment to see the full error message
    const { themeColor, dismissible } = this.props

    if (isOpen) {
      return (
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        <BannerStyled themeColor={themeColor}>
          {this.renderBannerContent(themeColor)}
          {dismissible && (
            <BannerCloseButton>
              <Button
                type="text"
                icon={
                  <CrossIcon
                    color={themeColor === 'blue' ? '#fff' : orangeDark}
                  />
                }
                hasIconOnly
                onClick={this.closeBanner}
                label="Close"
                size="small"
              />
            </BannerCloseButton>
          )}
        </BannerStyled>
      )
    }
    return null
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Banner.propTypes = {
  /** The main text of the banner */
  text: PropTypes.string,

  /** The text of the Call To Action of the banner */
  actionButton: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
  }),

  /** Custom HTML */
  customHTML: PropTypes.shape({ __html: PropTypes.string }),

  /** Theme color. Can be `'blue'` or `'orange'` */
  themeColor: PropTypes.oneOf(['blue', 'orange']),

  /** Handler when the banner closes */
  onCloseBanner: PropTypes.func,

  /** Allow functionality to dismiss banner **/
  dismissible: PropTypes.bool,

  testId: PropTypes.string,
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
Banner.defaultProps = {
  text: '',
  actionButton: {},
  customHTML: null,
  themeColor: 'blue',
  onCloseBanner: null,
  dismissible: true,
  testId: '',
}
