import { useState } from 'react'

const useInterval = (defaultSelectedPlan: any) => {
  const initiallyMonthly = defaultSelectedPlan.planInterval === 'month'

  const [monthlyBilling, setBillingInterval] = useState(initiallyMonthly)

  return {
    monthlyBilling,
    setBillingInterval,
  }
}

export default useInterval
