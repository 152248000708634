export function getProductPriceCycleText(price: any, cycle: any) {
  const cycleAbreviation = cycle === 'month' ? 'mo' : 'yr'
  return `$${price}/${cycleAbreviation}`
}

// used to look into a plans summary details
// and find the string which references users
export function findPlanUserDetails(summaryDetails: any) {
  const detailsAboutUserLimit = summaryDetails.find((sentence: any) =>
    sentence.includes('user'),
  )

  return detailsAboutUserLimit
}
