import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Link, Text } from '@bufferapp/ui'
import { useSplitEnabled } from '@buffer-mono/features'

import { resetChannelConnectionsState } from '../../../store/channelConnectionsReducer'

import { ACCOUNT_OPTIONS_FOR_CHANNELS, HC_UTM_PARAMS } from './constants'

import type { OrchestratorRootState } from '../../../../../common/events/types'
import { ChannelAccountType, Service } from '../../types'
import { type DefaultViewProps, ViewType } from './types'

import * as Styled from './styles'
import FacebookGroupWarning from './FacebookGroupWarning'
import { getServiceName } from '../../utils'
import {
  trackChannelConnectionAborted,
  trackProfileTypeSelectionViewed,
} from '../../tracking'
import { useUser } from '../../../../../common/context/User'
import trackChannelConnectionStarted from '../../../../../tracking/trackChannelConnectionStarted'
import useAuthorizationInfo from '../../hooks/useAuthorizationInfo/useAuthorizationInfo'

export default function DefaultContent({
  onRedirect,
  onChangeView,
}: DefaultViewProps): JSX.Element | null {
  const account = useUser()
  const { selectedService, cta } = useSelector(
    (state: OrchestratorRootState) => state.channelConnections,
  )
  const dispatch = useDispatch()
  const { isEnabled: isFacebookGroupsRemindersEnabled } = useSplitEnabled(
    'facebook-groups-reminders',
  )

  const [selectedAccountType, setSelectedAccountType] =
    useState<ChannelAccountType>(ChannelAccountType.page)

  const shouldDisplayFacebookGroupFlow =
    selectedService === Service.facebook &&
    selectedAccountType === ChannelAccountType.group

  const { handleGetAuthorizationInfo, url: redirectUrl } =
    useAuthorizationInfo()

  function handleOnSubmit(): void {
    if (shouldDisplayFacebookGroupFlow) {
      onChangeView(ViewType.FacebookGroups)

      trackChannelConnectionStarted({
        payload: {
          service: selectedService,
          cta,
          channelType: selectedAccountType,
        },
        user: account,
      })
    } else {
      if (redirectUrl) {
        onRedirect({ redirectUrl })
      }
    }
  }

  function handleOnCancel(): void {
    if (selectedService) {
      trackChannelConnectionAborted({
        account,
        service: selectedService,
      })
    }
    dispatch(resetChannelConnectionsState({}))
  }

  useEffect(() => {
    if (selectedService) {
      trackProfileTypeSelectionViewed({
        account,
        service: selectedService,
      })
    }
  }, [selectedService, account])

  useEffect(() => {
    if (selectedService) {
      handleGetAuthorizationInfo({
        service: selectedService,
        isRefreshingConnection: false,
      })
    }
  }, [selectedService, handleGetAuthorizationInfo])

  if (!selectedService || !redirectUrl) return null

  return (
    <>
      <Styled.TitleSpacing>
        <Text type="h3">Connect to {getServiceName(selectedService)}</Text>
      </Styled.TitleSpacing>
      <Styled.Body>
        <Text type="p">
          Check out our{' '}
          <Link
            href={`https://support.buffer.com/article/567-supported-channels?${HC_UTM_PARAMS}`}
            newTab
          >
            guide
          </Link>{' '}
          to supported account types for more details.
        </Text>
        <Styled.Section>
          <Styled.SubSection>
            {ACCOUNT_OPTIONS_FOR_CHANNELS[
              selectedService.toLowerCase() as keyof typeof ACCOUNT_OPTIONS_FOR_CHANNELS
            ].map((option) => (
              <Styled.LabelWrapper
                key={option.accountType}
                isSelected={selectedAccountType === option.accountType}
              >
                <Styled.RadioWrapper>
                  <input
                    type="radio"
                    name={option.accountType}
                    value={option.accountType}
                    checked={selectedAccountType === option.accountType}
                    onChange={(): void => {
                      setSelectedAccountType(option.accountType)
                    }}
                  />
                </Styled.RadioWrapper>
                <Styled.TextWrapper>
                  <Text type="span">
                    {selectedService} {option.accountType} {option.icon}
                  </Text>
                  <Text type="p">{option.description}</Text>
                </Styled.TextWrapper>
              </Styled.LabelWrapper>
            ))}
            {selectedService === Service.facebook &&
              !isFacebookGroupsRemindersEnabled && <FacebookGroupWarning />}
          </Styled.SubSection>
        </Styled.Section>
      </Styled.Body>
      <Styled.CTAContainer>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error TS(2740) FIXME: Type '{ id: string; type: string; onClick: () => v... Remove this comment to see the full error message */}
        <Button
          id="cancel-account-selection"
          type="text"
          onClick={(): void => handleOnCancel()}
          label="Cancel"
        />
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error TS(2740) FIXME: Type '{ id: string; type: string; onClick: () => v... Remove this comment to see the full error message */}
        <Button
          id="start-connecting"
          type="primary"
          onClick={(): void => handleOnSubmit()}
          disabled={!redirectUrl}
          label="Start Connecting"
        />
      </Styled.CTAContainer>
    </>
  )
}
