import { useState, useEffect, type Dispatch } from 'react'

import {
  handleChannelsCountConditions,
  handleCounterErrorMesaging,
  type MessagingReturn,
} from './utils'

type UseChannelsCounterReturn = {
  channelsCount: number
  setChannelsCounterValue: (value: number | null) => void
  increaseCounter: (disableIncrease?: boolean) => void
  decreaseCounter: (disableDecrease?: boolean) => void
  lastUpdatedQuantity: number
  channelCountMessageStatus: MessagingReturn | null
  setChannelCountMessageStatus: Dispatch<MessagingReturn | null>
}

const useChannelsCounter = (
  planId: string,
  initialChannelCount = 0,
  minimumQuantity = 1,
): UseChannelsCounterReturn => {
  const [lastUpdatedQuantity, setLastUpdatedQuantity] =
    useState<number>(initialChannelCount)
  const [channelsCount, setChannelsCount] =
    useState<number>(initialChannelCount)
  const [channelCountMessageStatus, setChannelCountMessageStatus] =
    useState<MessagingReturn | null>(null)

  function setChannelsCounterValue(value: number | null): void {
    // Prevent the channel count from being set to an undefined value
    // This prevents price calculations returning Nan
    if (!value) {
      return
    }

    handleCounterErrorMesaging(planId, value, setChannelCountMessageStatus)
    setChannelsCount(value)
    setLastUpdatedQuantity(value)
  }

  function increaseCounter(disableIncrease = false): void {
    if (!disableIncrease) {
      const newChannelsCount = channelsCount + 1

      setChannelsCount(newChannelsCount)
      setLastUpdatedQuantity(newChannelsCount)
    }
  }

  function decreaseCounter(disableDecrease = false): void {
    const newChannelsCount = channelsCount - 1
    if (channelsCount > minimumQuantity && !disableDecrease) {
      setChannelsCount(newChannelsCount)
      setLastUpdatedQuantity(newChannelsCount)
    }

    handleCounterErrorMesaging(
      planId,
      newChannelsCount,
      setChannelCountMessageStatus,
    )
  }

  useEffect(() => {
    handleCounterErrorMesaging(
      planId,
      channelsCount,
      setChannelCountMessageStatus,
    )
  }, [channelsCount])

  useEffect(() => {
    handleChannelsCountConditions(
      planId,
      channelsCount,
      setChannelsCount,
      lastUpdatedQuantity,
    )
    handleCounterErrorMesaging(
      planId,
      channelsCount,
      setChannelCountMessageStatus,
    )
  }, [planId])

  return {
    channelsCount,
    setChannelsCounterValue,
    increaseCounter,
    decreaseCounter,
    lastUpdatedQuantity,
    channelCountMessageStatus,
    setChannelCountMessageStatus,
  }
}

export default useChannelsCounter
