import React from 'react'
import PropTypes from 'prop-types'
import Text from '@bufferapp/ui/Text'
import ReactPlayer from 'react-player'
import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'

import * as styles from '../styles'
import type { PaywallContentProps } from './PaywallContentTypes'
import { useUser } from '../../../../../common/context/User'
import trackCTAPaywallVideoPlayed from '../../../../../tracking/trackPaywallVideoPlayed'
import {
  ANALYZE_BULLET_POINTS,
  ANALYZE_DESCRIPTION,
  ANALYZE_HEADING,
} from '../constants'
import { PaywallCtas } from '../../shared/PaywallCtas'
import { MODALS } from '../../../../../common/types'

export const AnalyticsContent = ({
  headline = ANALYZE_HEADING,
  description = ANALYZE_DESCRIPTION,
  body = ANALYZE_BULLET_POINTS,
}: PaywallContentProps): JSX.Element => {
  const user = useUser()
  const canStartTrial = user?.currentOrganization?.billing?.canStartTrial
  const upgradePathName = canStartTrial ? 'analyze-trial' : 'analyze-upgrade'
  return (
    <styles.Content data-testid="analyze-paywall">
      <styles.Info>
        <Text type="h2">{headline}</Text>
        <Text type="p" role="paragraph">
          {description}
        </Text>
        <styles.List>
          {body.map((item: string) => (
            <li key={item}>
              <styles.Check>
                <CheckmarkIcon />
              </styles.Check>
              <Text>{item}</Text>
            </li>
          ))}
        </styles.List>
        <PaywallCtas feature="analyze" paywallType={MODALS.paywall} />
      </styles.Info>
      <styles.Video>
        <ReactPlayer
          data-testid="wistia-video-player-analyze"
          className="react-player-wrapper"
          url="https://buffer.wistia.com/medias/yqnj7k1ckt"
          width="522px"
          height="290px"
          controls={true}
          onPlay={(): void => {
            trackCTAPaywallVideoPlayed({
              payload: {
                progress: 0,
                upgradePathName,
              },
              user,
            })
          }}
          onEnded={(): void => {
            trackCTAPaywallVideoPlayed({
              payload: {
                progress: 1,
                upgradePathName,
              },
              user,
            })
          }}
        />
        <Text type="label" color="grayDark">
          {' '}
          See how you can use Buffer’s tools together to grow your brand{' '}
        </Text>
      </styles.Video>
    </styles.Content>
  )
}

AnalyticsContent.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
}
