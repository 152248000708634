import type { ApolloError } from '@apollo/client'
import { useContext } from 'react'
import { UserContext } from '../../../../../common/context/User'
import useStartTrial from '../../../../../common/hooks/useStartTrial'
import useUpdateSubscriptionQuantityByChannelIds from '../../../../../common/hooks/useUpdateSubscriptionQuantityByChannelIds'
import { MODALS } from '../../../../../common/types'
import { getSuggestedPlan } from '../../../../../common/utils/getSuggestedPlan'
import {
  isFreeUser,
  isOnActiveTrial,
  userCanStartFreeTrial,
} from '../../../../../common/utils/user'
import trackCTAClicked from '../../../../../tracking/UpgradePaths/trackCTAClicked'
import useChannels from '../../../hooks/useChannels'
import { useModalManager } from '../../../../ModalManager/hooks/useModalManager'

type UseButtonReturn = {
  handleSkipUpgrading: () => void
  handleClick: () => void
  actionError: ApolloError | Error | { message: string } | null
  isProcessing: boolean
  actionLabel: string
}

type UseButtonsProps = {
  cta: string
  upgradePathName: string
}

// Click Handlers
const useActions = ({
  cta,
  upgradePathName,
}: UseButtonsProps): UseButtonReturn => {
  const user = useContext(UserContext)
  const canStartTrial = userCanStartFreeTrial(user)
  const isFree = isFreeUser(user)
  const { openModal } = useModalManager()
  const { connectedChannelIds, lockedChannelIds } = useChannels()
  const isOnTrial = isOnActiveTrial(user)

  // Handler when skipping upgrading or starting trial
  const handleSkipUpgrading = (): void => {
    openModal({
      key: MODALS.channelConnectionSuccessModal,
      data: {
        ctaView: 'connectChannelUpgradeModal',
        ctaLocation: 'channelsPage',
        cta,
        ctaVersion: '1',
        channelIds: connectedChannelIds,
        upgradePathName,
      },
    })
  }

  // Handler when starting trial
  const suggestedPlan = getSuggestedPlan(user)

  const {
    startTrial: handleStartTrial,
    error: startTrialError,
    processing: startTrialProcessing,
  } = useStartTrial({
    user,
    plan: {
      planId: suggestedPlan.planId,
      planInterval: suggestedPlan.planInterval,
    },
    attribution: { cta, upgradePathName },
  })

  // Handler when upgrading
  const {
    updateSubscriptionQuantityByChannelIds,
    isProcessing: upgradeProcessing,
    error: upgradeError,
  } = useUpdateSubscriptionQuantityByChannelIds({
    user,
    channelIds: lockedChannelIds,
    cta: `${cta}-connectChannelUpgradeModal-upgrade-1`,
    upgradePathName,
  })

  function handleUpgrade(): void {
    trackCTAClicked({
      payload: {
        cta,
        upgradePathName,
      },
      user,
    })
    if (isOnTrial || isFree) {
      openModal({
        key: MODALS.planSelector,
        data: {
          ctaView: 'connectChannelUpgradeModal',
          ctaLocation: 'channelsPage',
          cta,
          ctaVersion: '1',
          upgradePathName,
        },
      })
    } else {
      updateSubscriptionQuantityByChannelIds()
    }
  }

  return {
    handleSkipUpgrading,
    handleClick: canStartTrial ? handleStartTrial : handleUpgrade,
    actionError: canStartTrial ? startTrialError : upgradeError,
    isProcessing: canStartTrial ? startTrialProcessing : upgradeProcessing,
    actionLabel: canStartTrial
      ? 'Start Trial'
      : isFree
      ? 'Upgrade Plan'
      : 'Confirm and Pay',
  }
}

export default useActions
