import { useQuery } from '@apollo/client'
import { graphql } from '../../../../gql'
import type { EngageChannel } from '../../../../gql/graphql'
import { useUser } from '../../../../common/context/User'
import { useCallback, useEffect, useMemo } from 'react'
import { AppshellEventKeys } from '../../../../common/events/types'
import type { Channel } from '../../../../common/types'

const GetUnreadEngageComments = graphql(/* GraphQL */ `
  query engageOrganization($organizationId: String!) {
    engageOrganization(organizationId: $organizationId) {
      channels {
        id
        totalPendingComments
      }
    }
  }
`)

export type UseFetchUnreadEngageCommentsReturn = {
  totalPendingComments: number
}

// we only show 9+ counter in the badge, so no need to poll
// very often if there are many pending comments
const getPollingFactor = (totalPendingComments: number): number => {
  if (totalPendingComments < 15) {
    return 1
  } else if (totalPendingComments < 25) {
    return 2
  } else if (totalPendingComments < 50) {
    return 3
  } else if (totalPendingComments < 75) {
    return 4
  } else {
    return 5
  }
}

export const useFetchUnreadEngageComments = (
  args: {
    pollInterval?: number
  } = {},
): UseFetchUnreadEngageCommentsReturn => {
  // zero disables polling
  const { pollInterval = 0 } = args
  const safePollInterval =
    pollInterval === 0 ? pollInterval : Math.max(120000, pollInterval)
  const account = useUser()
  const organizationId = account.currentOrganization?.id as string
  const channels = account.currentOrganization?.channels
  const serviceIds = useMemo(() => {
    // Engage channels use serviceId as the id
    const isValid = (channel: Channel): boolean =>
      channel !== null && channel.serviceId !== null && !channel.isLocked

    return channels?.filter(isValid).map((channel) => channel.serviceId) ?? []
  }, [channels])

  const { data, refetch, startPolling, stopPolling, error } = useQuery(
    GetUnreadEngageComments,
    {
      variables: {
        organizationId,
      },
      skip: !organizationId,
      errorPolicy: 'all',
    },
  )

  /* We are catching silently the error when the organization is not found */
  if (
    error &&
    error?.graphQLErrors[0]?.extensions?.name !==
      'EngageOrganizationNotFoundError'
  ) {
    throw error
  }

  const totalPendingComments = !data?.engageOrganization?.channels
    ? 0
    : data?.engageOrganization?.channels?.reduce(
        (acc: number, channel: EngageChannel | null) => {
          // Filter out channels that are not in the current account organization
          // This avoids the bug where we count comments on deleted channels that still exist
          // in the engage organization document. This should be fixed by ensuring those
          // are deleted properly, but this will patch the UI for now. -HSM 2024-07-15
          if (!serviceIds.includes(channel?.id ?? '')) return acc
          return acc + (channel?.totalPendingComments || 0)
        },
        0,
      )

  const pollingFactor = getPollingFactor(totalPendingComments)

  useEffect(() => {
    // 0 means no polling but we don't even need to call it
    if (safePollInterval === 0) return

    startPolling(safePollInterval * pollingFactor)

    return function cleanup(): void {
      stopPolling()
    }
  }, [safePollInterval, pollingFactor, startPolling, stopPolling])

  const handleRefetch = useCallback(() => {
    refetch()
  }, [refetch])

  useEffect(() => {
    window.addEventListener(
      AppshellEventKeys.ENGAGE_COMMENT_EVENT_KEY,
      handleRefetch,
    )

    return function cleanup(): void {
      window.removeEventListener(
        AppshellEventKeys.ENGAGE_COMMENT_EVENT_KEY,
        handleRefetch,
      )
    }
  }, [handleRefetch])

  return {
    totalPendingComments,
  }
}
