import React, { useEffect } from 'react'

import {
  UpgradeDialog,
  Button,
  Text,
  List,
  Image,
  Paragraph,
} from '@buffer-mono/popcorn'

import { useUser } from '../../../../common/context/User'
import { Error } from '../../../../common/components/Error'
import useStartTrial from '../../../../common/hooks/useStartTrial'
import { getSuggestedPlan } from '../../../../common/utils/getSuggestedPlan'
import { prefixWithProduct } from '../../../../common/utils/prefixWithProduct'
import { setCookie, DATES } from '../../../../common/utils/cookies'

import trackStartTrialModalViewed from '../../../../tracking/TrialPaths/trackStartTrialModalViewed'
import trackStartTrialModalMainCTAClicked from '../../../../tracking/TrialPaths/trackStartTrialModalMainCTAClicked'
import useDismissObject from '../../../../common/hooks/useDismissObject'

import { MODALS } from '../../../../common/types'
import { TrialPathsTrackingPayload } from '../../../../tracking/TrialPaths/TrialPathsTrackingTypes'
import { useModalManager } from '../../../ModalManager/hooks/useModalManager'

const trialCheckPoints = [
  'Schedule up to 2,000 posts',
  'Track audience growth with Analyze',
  'Engage with your audience directly',
  'Collaborate with unlimited users',
  'Save & manage Hashtags easily',
  'Get unlimited tags',
]

const imageUrl =
  'https://buffer-ui.s3.amazonaws.com/upgrade-paths/start-trial/trial-prompt-modal.jpg'

export const StartTrialModal = (): JSX.Element => {
  const user = useUser()
  const suggestedPlan = getSuggestedPlan(user)
  const { modalData, openModal, dismissModal: closeAction } = useModalManager()
  const {
    cta,
    ctaButton,
    ctaView,
    ctaLocation,
    upgradePathName: upgradePathName,
  } = modalData || {}

  const { startTrial, trial, error, processing } = useStartTrial({
    user,
    plan: suggestedPlan,
    attribution: {
      cta: prefixWithProduct(cta),
      upgradePathName: upgradePathName || '',
    },
  })

  const trackingPayload: TrialPathsTrackingPayload = {
    ctaButton,
    upgradePathName: upgradePathName || '',
    ctaView,
    ctaLocation,
  }

  // Mechanism to dismiss  from appearing to a customer who
  // has signed up for free and starts a trial
  const { onDismissObject } = useDismissObject({
    organizationId: user.currentOrganization?.id || '',
    dismissedObject: {
      id: 'trial_signup_welcome_modal',
    },
  })

  useEffect(() => {
    if (trial && trial.billingStartTrial.success) {
      onDismissObject()
      openModal({
        key: MODALS.success,
        data: {
          startedTrial: true,
          selectedPlan: suggestedPlan,
        },
      })
    }
  }, [trial])

  useEffect(() => {
    trackStartTrialModalViewed({ payload: trackingPayload, user })
  }, [])

  const handleOnDismissModal = (): void => {
    setCookie({
      key: 'startTrialPrompt',
      value: true,
      expires: DATES.inMonthsFromNow(12),
    })
    closeAction()
  }

  const handleStartTrial = (): void => {
    startTrial()
    trackStartTrialModalMainCTAClicked({ payload: trackingPayload, user })
  }

  return (
    <UpgradeDialog open={true} onOpenChange={handleOnDismissModal}>
      <UpgradeDialog.Content aria-describedby="dialog-title">
        <UpgradeDialog.TextContent>
          <UpgradeDialog.Title id="dialog-title">
            Try Buffer Team plan, free for 14 days
          </UpgradeDialog.Title>
          <Paragraph>With Buffer Team plan, you can:</Paragraph>
          <List>
            {trialCheckPoints.map((detail: string) => (
              <List.Item key={detail}>
                <Text as="span">{detail}</Text>
              </List.Item>
            ))}
          </List>
          <Button
            variant="primary"
            size="large"
            disabled={!suggestedPlan || processing}
            onClick={handleStartTrial}
          >
            {processing ? 'Processing ...' : 'Start Your Free 14-day Trial'}
          </Button>
          <Text as="p" color="subtle">
            No credit card required
          </Text>
          {error && <Error isInline={false} error={error} />}
        </UpgradeDialog.TextContent>
        <UpgradeDialog.Image>
          <Image alt="Start Trial" src={imageUrl} />
        </UpgradeDialog.Image>
      </UpgradeDialog.Content>
    </UpgradeDialog>
  )
}
