import React, { useEffect } from 'react'
import {
  AudienceIcon,
  Text,
  ZapFilledIcon,
  UnstyledButton,
} from '@buffer-mono/popcorn'

import {
  canHaveTeamMembers,
  getUsersCurrentPlan,
  userCanStartFreeTrial,
} from '../../../common/utils/user'

import { type Account, MODALS } from '../../../common/types'
import { isFreePlan } from '../../../common/hooks/utils/segmentTraitGetters'
import { getMigrationHubUrl } from '../../../common/utils/urls'
import { formatCTAString } from '../../../common/hooks/useSegmentTracking'
import UpgradePathsTracking from '../../../tracking/UpgradePaths'
import { useModalManager } from '../../ModalManager/hooks/useModalManager'

import styles from '../NavBar.module.css'

const UpgradeCTA = ({
  user,
  shouldShowLegacyCTA,
}: {
  user: Account
  shouldShowLegacyCTA: boolean
}): JSX.Element | null => {
  const { openModal } = useModalManager()

  const { trackCTAViewed, trackCTAClicked } = UpgradePathsTracking(user)

  const { currentOrganization } = user
  const canStartTrial = userCanStartFreeTrial(user)

  const ctaButton = canStartTrial ? 'Start-a 14-day free trial' : 'Upgrade'

  const plan = getUsersCurrentPlan(user) || ''
  let upgradePathName = 'mainNav-upgrade'
  if (canStartTrial) {
    upgradePathName = 'mainNav-trial'
  }
  if (plan && plan?.id === 'essentials') {
    upgradePathName = 'team-upgrade'
  }
  useEffect(() => {
    if (currentOrganization?.shouldDisplayInviteCTA) {
      trackCTAViewed({
        ctaButton: formatCTAString(ctaButton),
        upgradePathName,
        ctaView: 'mainNav',
        ctaLocation: 'mainNav',
      })
    }
  }, [currentOrganization?.shouldDisplayInviteCTA])

  const { isOneBufferOrganization } = currentOrganization ?? {}
  const isFree = isFreePlan(user)
  const [, envModifier] = window.location.hostname.match(
    /\w+\.(\w+\.)buffer\.com/,
  ) || [null, null]

  const inviteYourTeamHandler = canHaveTeamMembers(user)
    ? (): void => {
        window.location.assign(
          `https://${
            envModifier || ''
          }buffer.com/manage/${currentOrganization?.id}/team-members/invite`,
        )
      }
    : (): void =>
        openModal({
          key: MODALS.planSelector,
          data: {
            cta: 'navBar-navBar-inviteYourTeam-1',
            upgradePathName: 'team-upgrade',
          },
        })

  if (currentOrganization?.shouldDisplayInviteCTA) {
    return (
      <UnstyledButton
        id="inviteTeamCTA"
        className={styles.upgradeCTA}
        onClick={inviteYourTeamHandler}
      >
        {canHaveTeamMembers(user) ? <AudienceIcon /> : <ZapFilledIcon />}
        <Text as="span" size="md" weight="bold">
          Invite Your Team
        </Text>
      </UnstyledButton>
    )
  }

  if (currentOrganization?.billing && isOneBufferOrganization && isFree) {
    return (
      <UnstyledButton
        id="upgradeCTA"
        className={styles.upgradeCTA}
        data-testid="top-nav-upgrade-cta"
        onClick={(): void => {
          if (canStartTrial) {
            openModal({
              key: MODALS.startTrial,
              data: {
                ctaView: 'navBar',
                ctaLocation: 'mainNav',
                ctaButton: formatCTAString(ctaButton),
                cta: 'navBar-navBar-startTrial-1',
                upgradePathName,
              },
            })
          } else {
            openModal({
              key: MODALS.planSelector,
              data: {
                cta: 'navBar-navBar-upgradePlan-1',
                upgradePathName,
              },
            })
          }

          trackCTAClicked({
            ctaButton: formatCTAString(ctaButton),
            upgradePathName,
            ctaView: 'mainNav',
            ctaLocation: 'mainNav',
          })
        }}
      >
        <ZapFilledIcon />
        <Text as="span" size="md" weight="bold">
          {canStartTrial ? 'Start a 14-day free trial' : 'Upgrade'}
        </Text>
      </UnstyledButton>
    )
  }

  if (shouldShowLegacyCTA) {
    return (
      <UnstyledButton
        id="upgradeCTA"
        className={styles.upgradeCTA}
        onClick={(): void => {
          window.location.assign(getMigrationHubUrl('topNav-migration-cta'))
        }}
      >
        <ZapFilledIcon />
        <Text as="span" size="md" weight="bold">
          Unlock the latest features
        </Text>
      </UnstyledButton>
    )
  }
  return null
}

export default React.memo(UpgradeCTA)
