import type { RenderComponentParams } from '../../../common/events/types'

type componentReducerState = {
  showComponent: boolean
  componentParams?: RenderComponentParams
}

const initialState = {
  showComponent: false,
}

export default function componentReducer(
  state = initialState,
  action: { type: string; payload: RenderComponentParams },
): componentReducerState {
  switch (action.type) {
    case 'SHOW_COMPONENT':
      return {
        ...state,
        showComponent: true,
        componentParams: action.payload,
      }
    default:
      return state
  }
}
