/* eslint-disable n/handle-callback-err */
import React, { useEffect } from 'react'
import { StyledError, ErrorContainer } from '../Error/style'

import Text from '@bufferapp/ui/Text'
import type { ErrorState } from '../../hooks/Subscription/types'
import useEmailVerification from '../../hooks/useEmailVerification'
import { TextButton } from './style'
import { WarningIcon } from '@buffer-mono/popcorn'

type EmailVerificationErrorProps = {
  error: ErrorState
}

const EmailVerificationError = ({
  error,
}: EmailVerificationErrorProps): JSX.Element => {
  const [errorText, setErrorText] = React.useState<string>(error?.message || '')
  const [showReSendLink, setShowReSendLink] = React.useState<boolean>(true)

  const { bannerOptions } = useEmailVerification()
  const handleReSendEmailVerification = (): void => {
    if (
      bannerOptions &&
      bannerOptions.actionButton &&
      bannerOptions.actionButton.action
    ) {
      bannerOptions.actionButton.action()
    }
  }

  useEffect(() => {
    if (bannerOptions?.text) {
      setShowReSendLink(false)
      setErrorText(bannerOptions.text)
    }
  }, [bannerOptions?.text])

  return (
    <ErrorContainer>
      <StyledError isInline={false} aria-live="polite">
        <>
          <WarningIcon size="xsmall" color="critical" />
          <Text>
            {errorText}{' '}
            {showReSendLink && (
              <TextButton onClick={(): void => handleReSendEmailVerification()}>
                {bannerOptions?.actionButton?.label}
              </TextButton>
            )}
          </Text>
        </>
      </StyledError>
    </ErrorContainer>
  )
}

export default EmailVerificationError
