import React from 'react'
import clsx from 'clsx'
import { Dialog, Flex, Skeleton, SkeletonText } from '@buffer-mono/popcorn'

import channelStoreFrontStyles from './ChannelStoreFront.module.css'
import channelStoreFrontItemStyles from './ChannelStoreFrontItem.module.css'
import styles from './ChannelStorefrontSkeleton.module.css'

const SKELETON_CHANNELS = 12

function ChannelStoreFrontSkeleton(): JSX.Element {
  return (
    <Flex direction="column" align="center">
      <Dialog.Header className={channelStoreFrontStyles.header}>
        <Dialog.Title>Connect a New Channel</Dialog.Title>
      </Dialog.Header>
      <div className={clsx(channelStoreFrontStyles.wrapper, styles.wrapper)}>
        {Array.from({ length: SKELETON_CHANNELS }).map((_, index) => (
          <div
            className={channelStoreFrontItemStyles.card}
            key={`skeleton-card-${index}`}
            data-testid={`skeleton-card-${index}`}
          >
            <Skeleton
              width={44}
              height={44}
              squared
              key={`skeleton-icon-${index}`}
            />
            <div className={styles.textWrapper}>
              <SkeletonText
                lines={1}
                width={80}
                key={`skeleton-channel-title-${index}`}
              />
              <SkeletonText
                lines={1}
                width={80}
                key={`skeleton-channel-description-${index}`}
              />
            </div>
          </div>
        ))}
      </div>
    </Flex>
  )
}

export default ChannelStoreFrontSkeleton
