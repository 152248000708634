import {
  SPLITIO_LOCAL_CLIENT_KEY,
  SPLITIO_PROD_CLIENT_KEY,
  SPLITIO_STAGING_CLIENT_KEY,
} from '@buffer-mono/features/src/FeaturesWrapper'
import { SplitFactory } from '@splitsoftware/splitio'
import trackImpression from '@buffer-mono/features/src/trackImpressions'
import getEnvironmentFromURL from '@buffer-mono/features/src/getEnvironmentFromURL'

import { MODALS } from './common/types'
import {
  AppshellEventKeys,
  OrganizationActionKeys,
} from './common/events/types'
import { ORGANIZATION_ACTIONS } from './common/events/orgEvents'
import { ORCHESTRATOR_ACTIONS } from './common/events/orchestratorEvents'
import { MODAL_ACTIONS } from './common/events/modalEvents'
import { ACCOUNT_ACTIONS } from './common/events/accountEvents'

import render from './exports/Navigator'
import { COMPONENTS } from './exports/Orchestrator/index'
import { env } from './env'

const INTEGRATED_APPSHELL_FEATURE_FLAG = 'integrated-appshell'

// Exports for use in other buffer-mono projects. Once all projects use the app-shell directly,
// we can remove the above imports and stop setting window.appshell here
export {
  MODALS,
  COMPONENTS,
  AppshellEventKeys,
  OrganizationActionKeys,
  ORGANIZATION_ACTIONS,
  ORCHESTRATOR_ACTIONS,
  MODAL_ACTIONS,
  ACCOUNT_ACTIONS,
}

export { AppShell, ReduxWrappedNavigator } from './exports/Navigator'

// TODO: Remove this method once the `integrated-appshell` feature flag is removed
async function isFeatureFlagEnabled(name: string): Promise<boolean> {
  // If is not Publish, return false
  if (
    !window.location.hostname.includes('publish.') ||
    !window.location.hostname.endsWith('buffer.com')
  ) {
    return false
  }

  let organizationId
  try {
    const currentOrganization = await fetchCurrentOrganization()
    organizationId = currentOrganization.id
  } catch {
    return false
  }

  const environment = env.VITE_SPLIT_ENV || getEnvironmentFromURL()

  let authorizationKey = SPLITIO_PROD_CLIENT_KEY
  if (environment === 'staging') {
    authorizationKey = SPLITIO_STAGING_CLIENT_KEY
  }
  if (environment === 'local') {
    authorizationKey = SPLITIO_LOCAL_CLIENT_KEY
  }
  const factory: SplitIO.IBrowserSDK = SplitFactory({
    core: {
      authorizationKey,
      key: organizationId,
      trafficType: 'organization',
    },
    startup: {
      readyTimeout: 2, // 2 seconds
    },
    // Track experiments to Segment
    impressionListener: {
      logImpression: trackImpression,
    },
  })

  const client: SplitIO.IBrowserClient = factory.client()
  try {
    /**
     * Waits for the client to be ready and explicitly propagates any rejection.
     * This pattern is necessary when using async/await syntax with the ready() method,
     * as rejections must be explicitly handled to avoid unhandled Promise rejections.
     * Without this catch and rethrow, errors could be silently swallowed.
     */
    await client.ready().catch((e) => {
      throw e
    })
  } catch (error) {
    return false
  }
  const treatment = client.getTreatment(name)
  return treatment === 'on'
}

// TODO: Remove this method once the `integrated-appshell` feature flag is removed
async function fetchCurrentOrganization(): Promise<{
  id: string
}> {
  const { data } = (await fetch(
    `${env.API_GATEWAY_URL}/?_o=CurrentOrganizationQuery`,
    {
      headers: {
        'content-type': 'application/json',
        'x-buffer-client-id': 'webapp-app-shell',
      },
      body: JSON.stringify({
        operationName: 'CurrentOrganizationQuery',
        variables: {},
        query:
          'query CurrentOrganizationQuery {\n  account {\n    id\n    currentOrganization {\n      id\n      __typename\n    }\n    __typename\n  }\n}\n',
      }),
      method: 'POST',
      credentials: 'include',
    },
  ).then((res) => res.json())) as {
    data: {
      account: {
        currentOrganization: { id: string }
      }
    }
  }
  const currentOrganization = data.account.currentOrganization
  return currentOrganization
}

function injectLayoutStyle(): void {
  const navigator = document.getElementById('navigator')
  if (!navigator) return
  const style = document.createElement('style')
  style.textContent = `
  `
  document.head.append(style)
}

;(async function init(): Promise<void> {
  // TODO: Remove this check once the `integrated-appshell` feature flag is removed
  const isAppShellIntegrated = await isFeatureFlagEnabled(
    INTEGRATED_APPSHELL_FEATURE_FLAG,
  )

  if (isAppShellIntegrated) {
    return
  }

  // Inject styles and render AppShell
  injectLayoutStyle()
  render()
})()
