export const DATES = {
  inMonthsFromNow(months: any) {
    const now = new Date()
    return new Date(now.setMonth(now.getMonth() + months))
  },
  inDaysFromNow(days: any) {
    const now = new Date()
    return new Date(now.setHours(now.getHours() + days * 24))
  },
}

export function setCookie({ key, value, expires }: any) {
  document.cookie = `appshell_${key}=${value};domain=.buffer.com;path=/;expires=${expires.toUTCString()}`
}

export function getCookie({ key }: any) {
  const regex = new RegExp(`appshell_${key}=(\\w+)`, 'g')
  const cookie = document.cookie.match(regex) || [null]
  const [k, value] = cookie[0]?.split('=') || [null, false] // eslint-disable-line no-unused-vars
  return value
}
