import styled from 'styled-components'

interface UXMessagingContainerProps {
  color?: string
}

export const UXMessagingContainer = styled.div<UXMessagingContainerProps>`
  display: flex;
  padding-top: 10px;

  color: ${(props): string | undefined => props.color};
  font-size: 10px;
`
