import { useUser } from '../../../../common/context/User'
import {
  getSelectedChannelsFromAnalyzeRoute,
  getSelectedChannelsFromEngageRoute,
  getSelectedChannelsFromPublishingRoute,
  getSelectedChannelsFromStartPageRoute,
  isInPublish,
  getPublishUrl,
} from '../../../../common/utils/urls'
import { getUserUnlockedChannels } from '../../../../common/utils/user'

const PUBLISH_CTA = 'publish-navBar-globalPlus-newPost-1'

function getSelectedChannelIds(): string[] {
  const publishChannelIds = getSelectedChannelsFromPublishingRoute()
  const analyzeChannelIds = getSelectedChannelsFromAnalyzeRoute()
  const startPageChannelIds = getSelectedChannelsFromStartPageRoute()
  const engageChannelIds = getSelectedChannelsFromEngageRoute()

  if (publishChannelIds.length > 0) return publishChannelIds
  if (analyzeChannelIds.length > 0) return analyzeChannelIds
  if (startPageChannelIds.length > 0) return startPageChannelIds
  if (engageChannelIds.length > 0) return engageChannelIds

  return []
}

export default function useCreatePostRedirect(): {
  createPostRedirect: () => void
} {
  const user = useUser()

  function createPostRedirect(): void {
    const usersUnlockedChannels = getUserUnlockedChannels(user)

    const selectedChannelIdsFromURL = getSelectedChannelIds()
    const isInPublishApp = isInPublish()

    const selectedChannels = usersUnlockedChannels.filter((channel) => {
      return selectedChannelIdsFromURL?.includes(channel.id) ? channel : null
    })
    const channelsQuery = selectedChannels
      ? selectedChannels.map((channel) => `channelId[]=${channel.id}`).join('&')
      : undefined

    const newPostRoute = channelsQuery
      ? `post/new?${channelsQuery}`
      : 'post/new'

    const ids = selectedChannels.map((channel): string => channel.id)

    // If we are in publish and have access to openComposer. Open the Composer directly
    if (window.__openComposer && isInPublishApp) {
      const preSelectedChannels = ids.length > 0 ? ids : []
      window.__openComposer({
        channels: preSelectedChannels,
        cta: PUBLISH_CTA,
      })
    } else {
      const redirectUrl = getPublishUrl(newPostRoute)
      window.location.assign(redirectUrl)
    }
  }

  return {
    createPostRedirect,
  }
}
