import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { ORGANIZATION_ACTIONS } from '../../events/orgEvents'
import { UPDATE_SUBSCRIPTION_QUANTITY } from '../../graphql/billing'
import { QUERY_ACCOUNT } from '../../graphql/account'
import { prefixWithProduct } from '../../utils/prefixWithProduct'
import type { BillingUpdateSubscriptionQuantityPayload } from '../../types/graphQL/Billing'
import type {
  UseUpdateSubscriptionQuantity,
  UseUpdateSubscriptionQuantityResponse,
} from './types'

const useUpdateSubscriptionQuantity = ({
  user,
  channelsQuantity,
  cta,
  upgradePathName,
}: UseUpdateSubscriptionQuantity): UseUpdateSubscriptionQuantityResponse => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [error, setError] = useState(null)

  const [updateSubscriptionQuantityRequest, { data, error: mutationError }] =
    useMutation<BillingUpdateSubscriptionQuantityPayload>(
      UPDATE_SUBSCRIPTION_QUANTITY,
      {
        refetchQueries: [{ query: QUERY_ACCOUNT }],
        awaitRefetchQueries: true,
        onCompleted: () => {
          ORGANIZATION_ACTIONS.billingUpdated({ user })
          setIsProcessing(false)
        },
      },
    )

  function updateSubscriptionQuantity(): void {
    setIsProcessing(true)
    updateSubscriptionQuantityRequest({
      variables: {
        organizationId: user?.currentOrganization?.id,
        quantity: channelsQuantity,
        attribution: { cta: prefixWithProduct(cta), upgradePathName },
      },
    }).catch((e) => {
      throw new Error('Error: updateSubscriptionQuantity failed', e)
    })
  }

  useEffect(() => {
    if (!user || !channelsQuantity) {
      // eslint-disable-next-line no-console
      console.warn(
        'Warning: useUpdateSubscriptionQuantity: user or channelsQuantity undefined - unexpected behaviour may happen',
      )
    }
  }, [isProcessing])

  useEffect(() => {
    if (mutationError) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'ApolloError' is not assignable t... Remove this comment to see the full error message
      setError(mutationError)
      // @ts-expect-error TS(2339) FIXME: Property 'userFriendlyMessage' does not exist on t... Remove this comment to see the full error message
    } else if (data?.billingUpdateSubscriptionQuantity?.userFriendlyMessage) {
      setError({
        // @ts-expect-error TS(2345) FIXME: Argument of type '{ message: any; }' is not assign... Remove this comment to see the full error message
        message: data.billingUpdateSubscriptionQuantity.userFriendlyMessage,
      })
      setIsProcessing(false)
    }
  }, [mutationError, data])

  return {
    updateSubscriptionQuantity,
    data,
    error,
    isProcessing,
  }
}

export default useUpdateSubscriptionQuantity
