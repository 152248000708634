import { DayOfWeek } from '../../../../gql/graphql'
import type { ScheduleV2 } from '../../../../gql/graphql'
import { POSTING_TIME_FRAMES } from './constants'

export function generateRandomTime({
  hour,
  minMinutes = 0,
  maxMinutes = 59,
}: {
  hour: number
  minMinutes?: number
  maxMinutes?: number
}): string {
  // Generates a random number of minutes between minMinutes and maxMinutes
  const minutes =
    Math.floor(Math.random() * (maxMinutes - minMinutes + 1)) + minMinutes

  // make sure we have a 2 digit number - adds 0 before single digit
  const minutesFormatted = minutes.toString().padStart(2, '0')
  const hourFormatted = hour.toString().padStart(2, '0')

  return `${hourFormatted}:${minutesFormatted}`
}

export function generatePostingTimes({
  postingAmount,
}: {
  postingAmount: number
}): string[] {
  const { startHour, endHour } = POSTING_TIME_FRAMES[postingAmount]
  // Calculate the total hours in the interval: - 1 because we want to include the start hour
  const totalHours = endHour - (startHour - 1)

  // Calculate the interval between each posting time
  const interval = totalHours / postingAmount

  return Array.from({ length: postingAmount }, (_, index) => {
    // Calculate the hour for this posting time
    const timeFromInterval = startHour + interval * index
    const hour = Math.floor(timeFromInterval)

    // When we do % 1, it gives us just the decimal part - * 60 to convert to minutes
    const minutesFromHour = Math.floor((timeFromInterval % 1) * 60)

    // We want to generate the minutes closer to the hour of its interval
    const maxMinutes = minutesFromHour > 29 ? minutesFromHour : 59

    // Randomise the minutes using the calculated maxMinutes
    return generateRandomTime({
      hour,
      maxMinutes,
    })
  })
}

export function constructPostingScheduleInput({
  selectedDays,
  postingAmount,
}: {
  selectedDays: DayOfWeek[]
  postingAmount: number
}): ScheduleV2[] {
  const times = postingAmount > 0 ? generatePostingTimes({ postingAmount }) : []
  const schedule = Object.values(DayOfWeek).map((day) => {
    return {
      day,
      paused: !selectedDays.includes(day),
      times: selectedDays.includes(day) ? times : [],
    }
  })

  return schedule
}
