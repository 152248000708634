import * as React from 'react'
import clsx from 'clsx'
import * as RadixSwitch from '@radix-ui/react-switch'

import useId from '../../helpers/useId'
import { Label } from '../Label'

import styles from './Switch.module.css'
import { CheckIcon, CloseIcon } from '../icons/index'

interface SwitchProps extends RadixSwitch.SwitchProps {
  /**
   * If provided, the id of the switch
   */
  id?: string
  /**
   * If provided, the children will be rendered as a label
   */
  children?: React.ReactNode
  /**
   * If true, the switch will be disabled
   */
  disabled?: boolean
  /**
   * Additional class name
   */
  className?: string
  /**
   * Size of the switch
   * @default medium
   */
  size?: 'small' | 'medium'
}

const Switch = React.forwardRef<HTMLButtonElement, SwitchProps>(
  (
    {
      className,
      id: passedId,
      children,
      disabled,
      size = 'medium',
      ...props
    }: SwitchProps,
    forwardedRef,
  ) => {
    const id = useId(passedId)

    return (
      <div className={clsx(styles.wrapper, className)}>
        <RadixSwitch.Root
          className={clsx(styles.root, styles[size])}
          id={id}
          disabled={disabled}
          ref={forwardedRef}
          {...props}
        >
          <CheckIcon
            name="check"
            color="inverted"
            data-icon-check
            className={styles.icon}
          />
          <CloseIcon name="cross" data-icon-cross className={styles.icon} />
          <RadixSwitch.Thumb className={styles.thumb} />
        </RadixSwitch.Root>

        {children && <Label htmlFor={id}>{children}</Label>}
      </div>
    )
  },
)

Switch.displayName = 'Switch'

export { Switch }
export type { SwitchProps }
