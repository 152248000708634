export const UNSPLASH_SERVICE = 'unsplash'
export const CANVA_SERVICE = 'canva'

enum UploadTrigger {
  FilePicker = 'filePicker',
  DragAndDrop = 'dragAndDrop',
  Integration = 'integration',
  Extension = 'extension',
}

type OtherSource = {
  name: Exclude<string, typeof UNSPLASH_SERVICE | typeof CANVA_SERVICE>
  trigger: UploadTrigger
}

type CanvaSource = {
  name: typeof CANVA_SERVICE
  trigger: UploadTrigger.Integration
  id: string
}

type UnsplashSource = {
  name: typeof UNSPLASH_SERVICE
  trigger: UploadTrigger.Integration
  id: string
  author: string
}

const hasProp = <K extends string>(
  obj: unknown,
  key: K,
): obj is Record<K, string> => {
  return obj !== null && typeof obj === 'object' && key in obj
}

const hasStringProp = <K extends string>(
  obj: unknown,
  key: K,
): obj is Record<K, string> => {
  return hasProp(obj, key) && typeof obj[key] === 'string'
}

export type UploadSource = CanvaSource | UnsplashSource | OtherSource

export const UploadSource = {
  new(src: Partial<UploadSource> | undefined): UploadSource | undefined {
    if (UploadSource.isCanva(src)) {
      return {
        name: src.name,
        id: src.id,
        trigger: UploadTrigger.Integration,
      }
    }

    if (UploadSource.isUnsplash(src)) {
      return {
        name: src.name,
        id: src.id,
        author: src.author,
        trigger: UploadTrigger.Integration,
      }
    }
    if (UploadSource.isOtherSource(src)) {
      return {
        name: src.name,
        trigger: src.trigger,
      }
    }
  },

  dragAndDrop(): OtherSource {
    return {
      name: 'localFile',
      trigger: UploadTrigger.DragAndDrop,
    }
  },

  filePicker(): OtherSource {
    return {
      name: 'localFile',
      trigger: UploadTrigger.FilePicker,
    }
  },

  extension(): OtherSource {
    return {
      name: 'remoteFile',
      trigger: UploadTrigger.Extension,
    }
  },

  canva(id: string): CanvaSource {
    return {
      name: CANVA_SERVICE,
      id,
      trigger: UploadTrigger.Integration,
    }
  },

  unsplash(props: { id: string; author: string }): UnsplashSource {
    return {
      name: UNSPLASH_SERVICE,
      id: props.id,
      author: props.author,
      trigger: UploadTrigger.Integration,
    }
  },

  integration(name: string): OtherSource {
    return {
      name,
      trigger: UploadTrigger.Integration,
    }
  },

  isOtherSource(src: unknown): src is OtherSource {
    return (
      hasStringProp(src, 'name') &&
      hasStringProp(src, 'trigger') &&
      UploadSource.isValidTrigger(src.trigger) &&
      src.name !== CANVA_SERVICE &&
      src.name !== UNSPLASH_SERVICE
    )
  },

  isCanva(src?: unknown): src is CanvaSource {
    return (
      hasStringProp(src, 'name') &&
      hasStringProp(src, 'id') &&
      src.name === CANVA_SERVICE &&
      !!src.id
    )
  },

  isUnsplash(src?: unknown): src is UnsplashSource {
    return (
      hasStringProp(src, 'name') &&
      hasStringProp(src, 'id') &&
      hasStringProp(src, 'author') &&
      src.name === UNSPLASH_SERVICE &&
      !!src.id
    )
  },

  isValidTrigger(trigger: string): trigger is UploadTrigger {
    return Object.values(UploadTrigger).includes(trigger as UploadTrigger)
  },
}
