import React from 'react'
import {
  BlueskyIcon,
  FacebookIcon,
  GbpIcon,
  InstagramIcon,
  LinkedinIcon,
  MastodonIcon,
  PinterestIcon,
  ShopifyIcon,
  StartPageIcon,
  ThreadsIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
} from '@buffer-mono/popcorn'

import { ChannelTooltipItem } from '../components/AccountMenu/components/OrganizationsSwitcher'

export function getNetworkIcon(item: ChannelTooltipItem): JSX.Element | null {
  if (!item.network) return null

  switch (item.network) {
    case 'instagram':
      return <InstagramIcon data-testid="instagram-icon" size="small" />
    case 'twitter':
      return <TwitterIcon data-testid="twitter-icon" size="small" />
    case 'facebook':
      return <FacebookIcon data-testid="facebook-icon" size="small" />
    case 'pinterest':
      return <PinterestIcon data-testid="pinterest-icon" size="small" />
    case 'linkedin':
      return <LinkedinIcon data-testid="linkedin-icon" size="small" />
    case 'googlebusiness':
      return <GbpIcon data-testid="gbp-icon" size="small" />
    case 'startPage':
      return <StartPageIcon data-testid="startpage-icon" size="small" />
    case 'tiktok':
      return <TiktokIcon data-testid="tiktok-icon" size="small" />
    case 'mastodon':
      return <MastodonIcon data-testid="mastodon-icon" size="small" />
    case 'bluesky':
      return <BlueskyIcon data-testid="bluesky-icon" size="small" />
    case 'threads':
      return <ThreadsIcon data-testid="threads-icon" size="small" />
    case 'youtube':
      return <YoutubeIcon data-testid="youtube-icon" size="small" />
    case 'shopify':
      return <ShopifyIcon data-testid="shopify-icon" size="small" />
    default:
      break
  }
  return null
}
