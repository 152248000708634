import {
  grayLight,
  white,
  grayDark,
  grayDarker,
} from '@bufferapp/ui/style/colors'
import styled, { keyframes } from 'styled-components'
import {
  fontSizeSmall,
  fontWeightMedium,
  fontWeightBold,
} from '@bufferapp/ui/style/fonts'

export const UpdatedPlanInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;

  box-sizing: border-box;
  padding: 20px;
  width: 235px;

  color: ${grayDark};
  background-color: ${white};
  font-size: 14px;
  font-weight: ${fontWeightMedium};

  border: 1px ${grayLight} solid;
  border-radius: 5px;
`

export const Row = styled.div`
  display: flex;
`

export const Section = styled.div`
  display: flex;
  padding: 15px 0;
  border-bottom: 1px ${grayLight} solid;
  flex-direction: column;
`

export const PlanName = styled.div`
  padding-bottom: 14px;

  font-weight: bold;
  color: ${grayDarker};
`

export const ChannelsCount = styled.div`
  display: flex;
  margin-right: 25px;
  font-size: ${fontSizeSmall};

  span {
    padding-right: 5px;
    font-weight: bold;
    color: ${grayDarker};
  }
`

export const UsersCount = styled.div`
  display: flex;
  font-size: ${fontSizeSmall};

  span {
    padding-right: 5px;
    font-weight: bold;
    color: ${grayDarker};
  }
`

export const CurrentPaymentContainer = styled.div`
  display: flex;
  padding: 15px 0;
  line-height: 16px;
  border-bottom: 1px ${grayLight} solid;
  flex-direction: column;
  min-height: 75px;
`

export const CancellationInfo = styled.div`
  padding-top: 15px;
  font-size: ${fontSizeSmall};

  span {
    font-weight: bold;
    color: ${grayDarker};
  }
`

export const TotalAmountInfo = styled.div`
  padding-top: 8px;
`

export const FinalCost = styled.div`
  display: flex;

  p {
    font-size: 24px;
    font-weight: ${fontWeightBold};
    margin: 0 6px 0 0;
  }

  svg {
    width: 14px;
    height: 14px;
  }
`

const rotate = keyframes`
  0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`

export const LoaderWrapper = styled.div`
  line-height: 20px;
`

export const Loader = styled.span`
  width: 13px;
  height: 13px;
  border: 2px solid ${grayDarker};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${rotate} 1s linear infinite;
`

export const TotalDueLoading = styled.div`
  display: inline-block;
  width: 100px;
  height: 16px;
  border-radius: 5px;
  background-color: ${grayLight};
`
