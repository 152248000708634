import React, { useState, useEffect, type Dispatch } from 'react'
import PropTypes from 'prop-types'

import Input from '@bufferapp/ui/Input'

import { MinusIcon, PlusIcon } from '@buffer-mono/popcorn'

import { type SelectedPlan, usePlan } from '../../context/Plan'

import {
  ChannelsCounterContainer,
  ChannelsCounterButton,
  ChannelsCounterCountDisplay,
} from './Counter.style'

function handleOnBlur(
  value: string,
  setValue: Dispatch<number>,
  setChannelsCounterValue: (value: number) => void,
  planInfo: SelectedPlan,
): void {
  const parsedValue = parseInt(value, 10)

  // Checks when a user has left the input feild empty
  // we set the value to the minimum channel for plan
  if (!parsedValue) {
    // We need to also update the value here
    // because the channelCount does not change and trigger the value update
    setValue(planInfo?.currentPlanChannelQuantity || 0)
    return setChannelsCounterValue(planInfo.currentPlanChannelQuantity || 0)
  }

  setValue(parsedValue)
  return setChannelsCounterValue(parsedValue)
}

function handleOnChange(
  value: string,
  setValue: Dispatch<number>,
  setChannelsCounterValue: (value: number) => void,
): void {
  const parsedValue = parseInt(value, 10)
  if (!parsedValue) {
    setValue(0)
    return
  }

  setValue(parsedValue)
  setChannelsCounterValue(parsedValue)
}

type ChannelCounterProps = {
  channelsCount: number
  onDecreaseCounter: () => void
  onIncreaseCounter: () => void
  setChannelsCounterValue: (value: number) => void
}
const ChannelCounter = ({
  channelsCount,
  onDecreaseCounter,
  onIncreaseCounter,
  setChannelsCounterValue,
}: ChannelCounterProps): JSX.Element => {
  const [value, setValue] = useState<number>(channelsCount)

  const planInfo = usePlan()

  useEffect(() => {
    setValue(channelsCount)
  }, [channelsCount, planInfo])

  return (
    <ChannelsCounterContainer>
      <ChannelsCounterButton
        type="button"
        id="channel_counter_decrease_btn"
        aria-label="Decrease channel quantity"
        onClick={(): void => onDecreaseCounter()}
      >
        <MinusIcon size="small" color="subtle" />
      </ChannelsCounterButton>
      <ChannelsCounterCountDisplay>
        <Input
          id="channel_counter_number_input"
          type="number"
          value={`${value}`}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            handleOnChange(
              event.target.value,
              setValue,
              setChannelsCounterValue,
            )
          }}
          onBlur={(event: React.ChangeEvent<HTMLInputElement>): void =>
            handleOnBlur(
              event.target.value,
              setValue,
              setChannelsCounterValue,
              planInfo,
            )
          }
          name="counter"
          size="small"
        />
      </ChannelsCounterCountDisplay>
      <ChannelsCounterButton
        type="button"
        id="channel_counter_increase_btn"
        aria-label="Increase channel quantity"
        onClick={(): void => onIncreaseCounter()}
      >
        <PlusIcon size="small" color="subtle" />
      </ChannelsCounterButton>
    </ChannelsCounterContainer>
  )
}

ChannelCounter.propTypes = {
  channelsCount: PropTypes.number,
  onDecreaseCounter: PropTypes.func.isRequired,
  onIncreaseCounter: PropTypes.func.isRequired,
  setChannelsCounterValue: PropTypes.func.isRequired,
}

ChannelCounter.defaultProps = {
  channelsCount: 0,
}

export default ChannelCounter
