import React from 'react'
import Text from '@bufferapp/ui/Text'
import Button from '@bufferapp/ui/Button'

import useDismissBanner, {
  BannerTypes,
} from '../../../../common/hooks/useDismissBanner'

import Banner from '../../../../components/Banner'

import { ButtonWrapper } from './styles'

export function FacebookGroupsNotificationsBanner() {
  const { onDismissBanner } = useDismissBanner({
    banner: BannerTypes.facebookGroupNotifications,
  })

  return (
    <Banner
      // @ts-expect-error TS(2322) FIXME: Type '{ customHTML: Element; themeColor: string; d... Remove this comment to see the full error message
      customHTML={
        <>
          <Text type="p" color="#ffffff">
            <strong>Facebook Groups Update:</strong> As of April 22, 2024, Meta
            will no longer support direct publishing to Facebook Groups. From
            this date, all posts must be published through mobile-based
            notifications.
          </Text>
          <ButtonWrapper>
            <Button
              type="primary"
              onClick={(): void => {
                // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
                window.location =
                  'https://support.buffer.com/article/570-connecting-facebook-groups-and-troubleshooting-connections'
              }}
              label={'Learn More'}
              size="small"
            />
          </ButtonWrapper>
        </>
      }
      testId="facebook-group-notifications"
      onCloseBanner={onDismissBanner}
    />
  )
}
