export function getActiveProductFromPath(): string {
  const products = ['account', 'publish', 'analyze', 'engage', 'start-page']
  const hostname = window?.location?.origin || ''

  for (const product of products) {
    if (hostname.includes(product)) {
      return product
    }
  }

  return ''
}
