import { Button, Dialog, Flex, Heading, Text } from '@buffer-mono/popcorn'
import {
  BufferTrackerReact as BufferTracker,
  Client,
} from '@buffer-mono/tracking-plan'
import React, { useEffect } from 'react'
import { useUser } from '../../../../common/context/User'
import useDismissObject from '../../../../common/hooks/useDismissObject'
import type { ModalProps } from '../../../../common/types'
import { StyledImage } from './styles'

const TrialSignupWelcomeModalContent = ({
  closeAction: closeModal,
}: ModalProps): JSX.Element => {
  const user = useUser()

  // Mechanism to dismiss the modal from appearing again
  const { onDismissObject } = useDismissObject({
    organizationId: user.currentOrganization?.id || '',
    dismissedObject: {
      id: 'trial_signup_welcome_modal',
    },
  })

  const commonTrackingProperties =
    user.currentOrganization?.commonTrackingProperties

  useEffect(() => {
    onDismissObject()
    BufferTracker.trialSignupWelcomeModalViewed({
      organizationId: user.currentOrganization?.id || '',
      clientName: Client.PublishWeb,
      ...commonTrackingProperties,
    })
  }, [])

  return (
    <Flex justify="center" direction="column" align="center" gap="lg">
      <Dialog.Header>
        <Flex justify="center" direction="column" align="center" gap="lg">
          <StyledImage
            alt="Welcome to your trial image"
            src="https://buffer-ui.s3.amazonaws.com/welcome-trial-signup.png"
          />
        </Flex>
      </Dialog.Header>
      <Dialog.Body>
        <Flex justify="center" direction="column" align="center" gap="xs">
          <Heading as="h3">Welcome to your Buffer free trial 👋</Heading>
          <Text align="center" color="subtle">
            Enjoy improved scheduling capabilities, a full analytics dashboard,
            collaboration tools and more and more for 14 days. There is no
            commitment to upgrade afterward unless you want to keep using these
            features.
          </Text>
        </Flex>
      </Dialog.Body>
      <Dialog.Footer>
        <Button size="large" variant="primary" onClick={closeModal}>
          Let&apos;s Explore
        </Button>
      </Dialog.Footer>
    </Flex>
  )
}

const TrialSignupWelcomeModal = ({ closeAction }: ModalProps): JSX.Element => {
  return <TrialSignupWelcomeModalContent closeAction={closeAction} />
}

export default TrialSignupWelcomeModal
