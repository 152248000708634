import React from 'react'
import clsx from 'clsx'
import { Card, Heading, PlusIcon, Text } from '@buffer-mono/popcorn'

import styles from '../../ChannelStoreFrontItem.module.css'

function RequestAChannel(): JSX.Element {
  function handleRequestChannel(): void {
    window.open('https://suggestions.buffer.com/b/channel-requests', '_blank')
  }

  function onKeyDownCard(event: React.KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      handleRequestChannel()
    }
  }

  return (
    <Card
      padding="md"
      aria-label="Request a Channel"
      role="button"
      tabIndex={0}
      className={styles.card}
      onClick={(): void => {
        handleRequestChannel()
      }}
      onKeyDown={onKeyDownCard}
    >
      <PlusIcon
        className={clsx(styles.channelIcon, styles.plusIcon)}
        size="medium"
      />
      <div className={styles.wrapper}>
        <Heading size="small" align="center">
          Can&apos;t find it?
        </Heading>
        <div>
          <Text className={styles.subtitle} align="center">
            Request a channel
          </Text>
        </div>
      </div>
    </Card>
  )
}

export default RequestAChannel
