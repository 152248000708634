import React, { useState } from 'react'

import { GroupDetails } from './components/GroupDetails'
import { Explanation } from './components/Explanation'

import { setConnectionSuccessQueryParamsForCustomChannel } from '../../../utils'

export function FacebookGroups({
  onClose,
}: {
  onClose: () => void
}): React.JSX.Element {
  const [currentStep, setCurrentStep] = useState(1)
  const [newChannelId, setNewChannelId] = useState('')

  const onCloseModal = (): void => {
    if (newChannelId) {
      // Redirect to '/channels' and trigger channel connection success modal
      const newURLWithSuccessParams =
        setConnectionSuccessQueryParamsForCustomChannel(newChannelId)
      window.location.href = newURLWithSuccessParams.toString()
      return
    }

    onClose()
  }

  return (
    <>
      {currentStep === 1 && (
        <GroupDetails
          onClose={onCloseModal}
          onNext={() => setCurrentStep(2)}
          setNewChannelId={setNewChannelId}
        />
      )}
      {currentStep === 2 && <Explanation onClose={onCloseModal} />}
    </>
  )
}
