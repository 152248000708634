import React from 'react'
import {
  ArrowDownUpIcon,
  ArrowLeftIcon,
  BufferIcon,
  Button,
  Dialog,
  EmptyState,
  Flex,
  InstagramIcon,
  Notice,
  Paragraph,
  Select,
  Strong,
  WarningIcon,
} from '@buffer-mono/popcorn'

import styles from './CreatorLimitations.module.css'

export function CreatorLimitations({
  setCurrentScreen,
  closeAction,
}: {
  setCurrentScreen: (screen: string) => void
  closeAction: () => void
}): JSX.Element {
  return (
    <>
      <Dialog.Header className={styles.header}>
        <Button
          variant="tertiary"
          className={styles.backButton}
          onClick={closeAction}
        >
          <ArrowLeftIcon />
        </Button>
        <div className={styles.headerChannels}>
          <Flex gap="sm">
            <BufferIcon />
            <ArrowDownUpIcon className={styles.channelsArrows} />
            <div className={styles.instagramIconWrapper}>
              <InstagramIcon />
            </div>
          </Flex>
        </div>
        <Dialog.CloseButton />
      </Dialog.Header>
      <Dialog.Body className={styles.body}>
        <EmptyState size="xlarge" variant="warning" className={styles.content}>
          <EmptyState.Icon>
            <WarningIcon />
          </EmptyState.Icon>
          <EmptyState.Heading align="center">
            Limitations if you Continue with Your Creator Account
          </EmptyState.Heading>
          <Paragraph align="center">
            Buffer won't be able to automatically post stories from your Creator
            Instagram account once you link it to your Facebook Page. If you
            link your accounts, we can only publish stories with
            Notification-based posting due to a limitation from Meta.
          </Paragraph>
          <Notice variant="info">
            To keep automatic posting for stories, change your account type on
            Instagram to <Strong>Business</Strong>. Then come back and continue
            linking your account to Facebook.
          </Notice>
        </EmptyState>
      </Dialog.Body>
      <Dialog.Separator />
      <Dialog.Footer className={styles.footer}>
        <Select onValueChange={() => {}}>
          <Select.Trigger placeholder="Need Help" />
          <Select.Content>
            <Select.Item key={'1'} value={'addLinkToArticle1'}>
              I can't find my page
            </Select.Item>
            <Select.Item key={'2'} value={'addLinkToArticle2'}>
              Troubleshooting Guide
            </Select.Item>
            <Select.Item key={'3'} value={'addLinkToArticle3'}>
              Reach out to us
            </Select.Item>
          </Select.Content>
        </Select>
        <div className={styles.groupedActions}>
          <Flex gap="xs">
            <Button variant="tertiary" size="large" onClick={() => {}}>
              Show Me How to Switch to Business
            </Button>
            <Button
              size="large"
              onClick={() => {
                setCurrentScreen('unlockMoreFeatures')
              }}
            >
              Continue with Creator Account
            </Button>
          </Flex>
        </div>
      </Dialog.Footer>
    </>
  )
}
