import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import { getActiveProductFromPath } from '../../common/utils/getProduct'
import type { PlanSelectorCTAClickedTrackingArguments } from './PlanSelectorTrackingTypes'

import { hasPaymentDetails, getOrganization } from '../../common/utils/user'

import { shouldPerformTrackingEvent } from '../utils'

export default function trackPlanSelectorCTAClicked({
  payload,
  user,
}: PlanSelectorCTAClickedTrackingArguments): void {
  const canPerformTrackingEvent = shouldPerformTrackingEvent({
    user,
  })

  if (!canPerformTrackingEvent) {
    return
  }

  const organization = getOrganization(user)
  const commonTrackingProperties =
    organization?.commonTrackingProperties || null
  const product = getActiveProductFromPath()
  const eventData = {
    organizationId: organization?.id || null,
    ctaButtonText: payload?.ctaButtonText || null,
    cycle: payload?.cycle || null,
    selectedPlanName: payload?.selectedPlanName || null,
    channelQuantity: payload?.channelQuantity || null,
    projectedCost: payload?.projectedCost || null,
    cta: payload?.cta ? `${product}-${payload.cta}` : null,
    ...commonTrackingProperties,
    hasPaymentDetails: hasPaymentDetails(user) || null,
    isAutomaticTaxEnabled: payload?.isAutomaticTaxEnabled,
    initialChannelQuantity: payload?.initialChannelQuantity || null,
    initialSelectedPlan: payload?.initialSelectedPlan || null,
    initialCycle: payload?.initialCycle || null,
    upgradePathName: payload?.upgradePathName || null,
  }

  BufferTracker.planSelectorMainCtaClicked(eventData)
}
