import type { Account } from '../../../common/types'
import {
  isObjectDismissed,
  isOneBufferOrganization,
  isPaymentPastDue,
} from '../../../common/utils/user'

export const shouldShowPaymentPastDueBanner = (user: Account): boolean => {
  return (
    isPaymentPastDue(user) &&
    !isObjectDismissed(user, 'payment-past-due-banner') &&
    isOneBufferOrganization(user)
  )
}
