import styled from 'styled-components'
import {
  blue,
  gray,
  grayDarker,
  grayLighter,
  grayLight,
  grayDark,
  white,
} from '@bufferapp/ui/style/colors'

export const Container = styled.div`
  width: 100vw;
  max-width: 512px;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2,
  p {
    text-align: left;
  }

  > * {
    box-sizing: border-box;
  }
`

export const TitleSpacing = styled.div`
  margin: 0;
  padding: 0 16px;
  width: 100%;
  text-align: left;
  font-weight: 500;

  h3 {
    margin: 16px 0;
  }
`

export const Body = styled.div`
  justify-content: center;
  padding: 16px;
  width: 100%;
  background: ${grayLighter};
  border-top: 1px solid ${grayLight};
  border-bottom: 1px solid ${grayLight};

  p {
    margin: 0 0 16px;
    color: ${grayDark};

    strong {
      color: ${grayDarker};
    }
  }
`

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  width: 100%;
`

export const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  gap: 10px;

  p {
    margin: 0 0 16px;
  }

  .underline {
    text-decoration: underline;
  }
`

export const LabelWrapper = styled.label<{ isSelected: boolean }>`
  display: flex;
  gap: 8px;
  padding: 16px;
  width: 100%;

  background-color: ${white};
  border: ${(props): string =>
    props.isSelected ? '1px solid blue' : `1px solid ${gray}`};
  border-radius: 4px;
  cursor: pointer;

  span {
    display: flex;
    gap: 10px;

    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
  }

  p {
    margin: 0;
  }

  box-sizing: border-box;
`

export const RadioWrapper = styled.span`
  display: flex;

  input {
    appearance: none;
    font: inherit;
    color: ${gray};
    width: 16px;
    height: 16px;
    border: 0.15em solid ${gray};
    border-radius: 100px;
    display: grid;
    place-content: center;

    &::before {
      box-sizing: border-box;
      margin: 0;
      padding: 0;

      content: '';
      width: 16px;
      height: 16px;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      border: 6px solid ${blue};
    }

    :checked::before {
      transform: scale(1);
    }
  }
`

export const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.2rem;
  flex-direction: column;
  justify-content: right;
`

export const CTAContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  gap: 1rem;
  flex-direction: row;
  justify-content: right;
`

export const Wrapper = styled.div`
  width: 535px;
`

export const Header = styled.div`
  width: 100%;
  padding: 16px;
`

export const FormGroup = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`

export const Footer = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  align-items: center;
  justify-content: flex-end;

  .custom-channel-connection-action {
    &:not(:last-of-type) {
      margin-right: 8px;
    }
  }
`
