import React from 'react'
import styled from 'styled-components'
import {
  redDark,
  grayLight,
  grayLighter,
  grayDarker,
} from '@bufferapp/ui/style/colors'
import { fontWeightMedium } from '@bufferapp/ui/style/fonts'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'
import Text from '@bufferapp/ui/Text'

export const ModalBody = styled.div`
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  background-color: ${grayLighter};
  border-top: 1px solid ${grayLight};
  border-bottom: 1px solid ${grayLight};

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
    font-weight: 700;
  }
`

export const ModalTitle = ({
  text,
  color,
}: {
  text: string
  color?: string
}): JSX.Element => {
  return <ModalTitleStyled color={color}>{text}</ModalTitleStyled>
}

const ModalTitleStyled = styled.h3`
  color: ${(props): string | undefined => props.color};
  font-size: 18px !important;
  font-weight: ${fontWeightMedium};

  margin: 0;
`

export const ModalHeader = styled.div`
  width: 100%;
  padding: 1rem;
  color: ${grayDarker};
`

export const StyledError = styled.p<{
  isInline?: boolean
}>`
  margin: 0px;
  display: inline-block;
  align-items: center;
  width: 100%;
  overflow: ${({ isInline }): string | undefined => (isInline ? 'hidden' : '')};
  text-overflow: ellipsis;
  font-size: 14px;

  span {
    color: ${redDark};
  }

  svg {
    color: ${redDark};
    height: 12px;
    margin-right: 4px;
    position: relative;
    top: 1px;
  }
`

export const Error = ({
  isInline,
  error,
}: {
  isInline?: boolean
  error: string
}): JSX.Element => (
  <StyledError isInline={isInline} aria-live="polite">
    {error && (
      <>
        <WarningIcon />
        <Text>{error}</Text>
      </>
    )}
  </StyledError>
)

export const PageWrapper = styled.div`
  width: 100%;
  padding: 16px;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 512px;
`

export const ModalFooter = styled.footer`
  width: 100%;
  box-sizing: border-box;
  background: white;
  padding: 0 16px;
  margin-top: 16px;
  margin-bottom: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > span,
  & > p {
    margin: 0 auto 0 0;
  }

  & + * {
    display: none;
  }
`
