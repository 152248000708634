const getTrialBannerCopy = ({
  daysRemaining,
}: {
  daysRemaining: number
}): string => {
  // Trial expired
  if (daysRemaining === 0) {
    return `Your trial has ended. Start your subscription to unlock full access to Buffer.`
  } else {
    return `You have ${daysRemaining} ${
      daysRemaining === 1 ? 'day' : 'days'
    } left on your free trial.`
  }
}

export default getTrialBannerCopy
