import { graphql } from '../../../../../gql'

export const GetConnectableServices = graphql(/* GraphQL */ `
  query GetConnectableServices($input: ConnectableServicesInput!) {
    connectableServices(input: $input) {
      ... on ConnectableServicesResponse {
        __typename
        services {
          service
          serviceStatus {
            label
            details {
              message
              upvoteId
            }
          }
          types
          products
          isNew
          canConnect
          status {
            status
          }
          title
          subtitle
          serviceIconUrl
          description
          links {
            text
            url
          }
          contentTypes
          audience
          socialProof
          similarNetworks
          tag
          categories
        }
      }
      ... on ConnectableServicesError {
        __typename
        userFriendlyMessage
        cause
      }
    }
  }
`)

export const MOCK_GET_CONNECTABLE_SERVICES_SUCCESS = {
  connectableServices: {
    __typename: 'ConnectableServicesResponse',
    services: [
      {
        service: 'bluesky',
        serviceStatus: {
          label: 'new',
          details: {
            message:
              "Thanks for your interest in supporting Bluesky. Your interest counts as an upvote for this feature. We'll notify you when it's available.",
            upvoteId: 200517,
            __typename: 'UpvoteDetails',
          },
          __typename: 'ServiceStatus',
        },
        types: ['profile'],
        products: ['publish'],
        isNew: true,
        canConnect: true,
        status: {
          status: 'available',
          __typename: 'ChannelConnectionStatus',
        },
        title: 'Bluesky',
        subtitle: 'Profile',
        serviceIconUrl:
          'https://buffer-account.s3.amazonaws.com/logo/large/bluesky.svg',
        description: [
          'Connect with a growing community of 5.9 million users ',
          'Establish thought leadership in a less saturated environment',
          'Craft your own algorithm',
        ],
        links: [
          {
            text: "What Is Bluesky? Here's What You Should Know",
            url: 'https://buffer.com/resources/bluesky-social/',
            __typename: 'HelpCenterLink',
          },
          {
            text: 'Create an account on Bluesky',
            url: 'https://bsky.app/',
            __typename: 'HelpCenterLink',
          },
        ],
        contentTypes: ['text', 'image'],
        audience: null,
        socialProof:
          'Hey! Did you know Bluesky lets brands use custom domains for their profiles, boosting brand recognition and credibility?',
        similarNetworks: ['twitter', 'threads', 'mastodon'],
        tag: null,
        categories: ['small_business', 'creator'],
        __typename: 'ConnectableService',
      },
      {
        service: 'threads',
        serviceStatus: {
          label: 'new',
          details: {
            message:
              "Thanks for your interest in supporting Meta Threads. Your interest counts as an upvote for this feature. We'll notify you when it's available.",
            upvoteId: 204598,
            __typename: 'UpvoteDetails',
          },
          __typename: 'ServiceStatus',
        },
        types: ['profile'],
        products: ['publish'],
        isNew: true,
        canConnect: true,
        status: {
          status: 'available',
          __typename: 'ChannelConnectionStatus',
        },
        title: 'Threads',
        subtitle: 'Profile',
        serviceIconUrl:
          'https://buffer-account.s3.amazonaws.com/logo/large/threads.svg',
        description: [
          'Reach an ever-growing user base of more than 150 million people',
          'Build your community and nurture authentic relationships with your audience',
          'Engage in news and tap into trends on this conversation-first platform',
        ],
        links: [
          {
            text: 'Connecting your channels to Buffer',
            url: 'https://support.buffer.com/article/564-connecting-your-channels-to-buffer?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more',
            __typename: 'HelpCenterLink',
          },
        ],
        contentTypes: ['text', 'image', 'video', 'analytics'],
        audience:
          'Wide range, particularly 18-29, with real-time, text-based content',
        socialProof:
          'Hey! Did you know that when you sign up for Threads, your Instagram followers who are already on Threads will be notified (and probably follow you on Threads, too).',
        similarNetworks: ['mastodon', 'linkedin', 'twitter', 'instagram'],
        tag: null,
        categories: ['small_business', 'creator'],
        __typename: 'ConnectableService',
      },
      {
        service: 'facebook',
        serviceStatus: {
          label: 'none',
          details: null,
          __typename: 'ServiceStatus',
        },
        types: ['profile', 'group'],
        products: ['publish', 'analyze', 'engage'],
        isNew: false,
        canConnect: true,
        status: {
          status: 'available',
          __typename: 'ChannelConnectionStatus',
        },
        title: 'Facebook',
        subtitle: 'Page or Group',
        serviceIconUrl:
          'https://buffer-account.s3.amazonaws.com/logo/large/facebook.svg',
        description: [
          'Engage with a diverse 2.8 billion user base.',
          'Build a loyal community; over 1.8 billion users are part of a Facebook group.',
          'Get great insights in Buffer’s Analyze feature.',
        ],
        links: [
          {
            text: 'Connecting your Facebook Page to Buffer',
            url: 'https://support.buffer.com/article/569-connecting-your-facebook-page-to-buffer?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more',
            __typename: 'HelpCenterLink',
          },
        ],
        contentTypes: [
          'text',
          'image',
          'video',
          'post_analytics',
          'engagement',
          'audience_insights',
        ],
        audience:
          'Wide range, particularly 18-49, with a variety of content types',
        socialProof:
          'Hey! Did you know Facebook is the third most visited website globally, after Google and YouTube?',
        similarNetworks: ['linkedin', 'instagram', 'youtube'],
        tag: 'U+2728 Most Popular',
        categories: ['ecommerce'],
        __typename: 'ConnectableService',
      },
      {
        service: 'instagram',
        serviceStatus: {
          label: 'none',
          details: null,
          __typename: 'ServiceStatus',
        },
        types: ['business', 'account'],
        products: ['publish', 'engage', 'analyze'],
        isNew: false,
        canConnect: true,
        status: {
          status: 'available',
          __typename: 'ChannelConnectionStatus',
        },
        title: 'Instagram',
        subtitle: 'Business or Creator accounts',
        serviceIconUrl:
          'https://buffer-account.s3.amazonaws.com/logo/large/instagram.svg',
        description: [
          'Connect with a billion-strong monthly active user base.',
          'Drive sales with Buffer Shop Grid; 130 million users tap on shopping posts monthly.',
          'Create stories, posts, and reels.',
        ],
        links: [
          {
            text: 'Connecting your Instagram business or creator account to Buffer',
            url: 'https://support.buffer.com/article/568-connecting-your-instagram-business-or-creator-account-to-buffer?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more',
            __typename: 'HelpCenterLink',
          },
        ],
        contentTypes: [
          'image',
          'reels',
          'post_analytics',
          'engagement',
          'stories',
          'audience_insights',
        ],
        audience: 'Primarily 18-35, with visual content',
        socialProof:
          'Hey! Did you know Instagram users spend an average of 53 minutes per day on the platform, making it a great place for businesses to engage with their audience?',
        similarNetworks: ['pinterest', 'tiktok', 'youtube'],
        tag: null,
        categories: ['small_business', 'creator', 'ecommerce'],
        __typename: 'ConnectableService',
      },
      {
        service: 'twitter',
        serviceStatus: {
          label: 'none',
          details: null,
          __typename: 'ServiceStatus',
        },
        types: ['profile'],
        products: ['publish'],
        isNew: false,
        canConnect: true,
        status: {
          status: 'available',
          __typename: 'ChannelConnectionStatus',
        },
        title: 'Twitter / X',
        subtitle: 'Profile',
        serviceIconUrl:
          'https://buffer-account.s3.amazonaws.com/logo/large/x.svg',
        description: [
          'Tweet your way to 330 million active users.',
          'Engage in real-time conversations and trending topics.',
          'Tap into the news and information-seeking behavior.',
        ],
        links: [
          {
            text: 'Connecting your channels to Buffer',
            url: 'https://support.buffer.com/article/564-connecting-your-channels-to-buffer?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more',
            __typename: 'HelpCenterLink',
          },
        ],
        contentTypes: ['text', 'post_analytics', 'threads', 'video', 'image'],
        audience:
          'Wide range, particularly 18-29, with real-time, text-based content',
        socialProof:
          'Hey! Did you know that Buffer\'s paid plans include Twitter / X Threads, which <a href="https://buffer.com/resources/twitter-thread-experiment/" target="_blank">drive higher impressions and engagement</a>?',
        similarNetworks: ['mastodon', 'linkedin'],
        tag: null,
        categories: ['small_business', 'creator'],
        __typename: 'ConnectableService',
      },
      {
        service: 'linkedin',
        serviceStatus: {
          label: 'none',
          details: null,
          __typename: 'ServiceStatus',
        },
        types: ['page', 'profile'],
        products: ['publish'],
        isNew: false,
        canConnect: true,
        status: {
          status: 'available',
          __typename: 'ChannelConnectionStatus',
        },
        title: 'LinkedIn',
        subtitle: 'Page or Profile',
        serviceIconUrl:
          'https://buffer-account.s3.amazonaws.com/logo/large/linkedin.svg',
        description: [
          'Network with a professional community of over 774 million.',
          'Drive B2B leads and build professional connections.',
          'Share industry insights and company updates.',
        ],
        links: [
          {
            text: 'Connecting your LinkedIn profile or page to Buffer',
            url: 'https://support.buffer.com/article/560-using-linkedin-with-buffer?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more',
            __typename: 'HelpCenterLink',
          },
        ],
        contentTypes: ['text', 'pdf', 'image', 'video', 'page_analytics'],
        audience: 'Professionals, B2B, with professional content',
        socialProof:
          'Hey! Did you know LinkedIn is the number one platform for B2B lead generation, with 80% of B2B leads coming from LinkedIn vs. 13% on Twitter and 7% on Facebook?',
        similarNetworks: ['twitter', 'facebook'],
        tag: null,
        categories: ['creator'],
        __typename: 'ConnectableService',
      },
      {
        service: 'tiktok',
        serviceStatus: {
          label: 'none',
          details: null,
          __typename: 'ServiceStatus',
        },
        types: ['business'],
        products: ['publish'],
        isNew: false,
        canConnect: true,
        status: {
          status: 'available',
          __typename: 'ChannelConnectionStatus',
        },
        title: 'TikTok',
        subtitle: 'Business or Creator accounts',
        serviceIconUrl:
          'https://buffer-account.s3.amazonaws.com/logo/large/tiktok.svg',
        description: [
          'Dive into a pool of 1 billion active users.',
          'Ride the wave of a 6.92% average engagement rate.',
          'Create content for TikTok’s highly engaged audience, who use the app for 52 minutes a day on average.',
        ],
        links: [
          {
            text: 'Using TikTok with Buffer',
            url: 'https://support.buffer.com/article/559-using-tiktok-with-buffer?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more#01H85RV55S1DNACQ1PEY9Y71WG',
            __typename: 'HelpCenterLink',
          },
        ],
        contentTypes: ['video', 'post_analytics'],
        audience: 'Primarily Gen Z, with short, creative video content',
        socialProof:
          'Hey! Did you know TikTok was the most downloaded app worldwide in 2020, surpassing even Facebook and WhatsApp?',
        similarNetworks: ['youtube', 'instagram'],
        tag: null,
        categories: ['small_business', 'creator'],
        __typename: 'ConnectableService',
      },
      {
        service: 'youtube',
        serviceStatus: {
          label: 'none',
          details: null,
          __typename: 'ServiceStatus',
        },
        types: ['channel'],
        products: ['publish'],
        isNew: false,
        canConnect: true,
        status: {
          status: 'available',
          __typename: 'ChannelConnectionStatus',
        },
        title: 'YouTube',
        subtitle: 'Channel',
        serviceIconUrl:
          'https://buffer-account.s3.amazonaws.com/logo/large/youtube.svg',
        description: [
          'Engage with a 2 billion-strong audience.',
          "Harness the power of the world's #2 search engine.",
          'Tap into a billion hours of daily video views.',
        ],
        links: [
          {
            text: 'Using YouTube Shorts with Buffer',
            url: 'https://support.buffer.com/article/562-using-youtube-shorts-with-buffer?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more',
            __typename: 'HelpCenterLink',
          },
        ],
        contentTypes: ['shorts', 'post_analytics'],
        audience: 'All ages, particularly 18-34, with video content',
        socialProof: null,
        similarNetworks: ['tiktok', 'instagram', 'facebook'],
        tag: 'U+1F525 Trending',
        categories: ['small_business', 'ecommerce', 'creator'],
        __typename: 'ConnectableService',
      },
      {
        service: 'startPage',
        serviceStatus: {
          label: 'none',
          details: null,
          __typename: 'ServiceStatus',
        },
        types: ['page'],
        products: ['publish'],
        isNew: false,
        canConnect: true,
        status: {
          status: 'available',
          __typename: 'ChannelConnectionStatus',
        },
        title: 'Start Page',
        subtitle: 'Simple, powerful link-in-bio',
        serviceIconUrl:
          'https://buffer-account.s3.amazonaws.com/logo/large/start-page.svg',
        description: [
          'Showcase all your content in one place.',
          'Customize your landing page to reflect your brand.',
          'Track link clicks and gain performance insights.',
        ],
        links: [
          {
            text: "A guide to using Buffer's Start Page",
            url: 'https://support.buffer.com/article/664-using-start-page-with-buffer?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more',
            __typename: 'HelpCenterLink',
          },
        ],
        contentTypes: [
          'image',
          'video',
          'text',
          'link_tracking',
          'post_analytics',
        ],
        audience: null,
        socialProof:
          'Hey! Did you know Start Page can function as a great link-in-bio? You can add all your links, pick a personalized domain, and share it in any social media bio.',
        similarNetworks: [
          'instagram',
          'youtube',
          'linkedin',
          'tiktok',
          'twitter',
          'googlebusiness',
          'facebook',
          'mastodon',
          'shopify',
          'pinterest',
        ],
        tag: 'by Buffer',
        categories: ['small_business', 'creator', 'ecommerce'],
        __typename: 'ConnectableService',
      },
      {
        service: 'googlebusiness',
        serviceStatus: {
          label: 'none',
          details: null,
          __typename: 'ServiceStatus',
        },
        types: ['account'],
        products: ['publish'],
        isNew: false,
        canConnect: true,
        status: {
          status: 'available',
          __typename: 'ChannelConnectionStatus',
        },
        title: 'Google Business',
        subtitle: 'Account',
        serviceIconUrl:
          'https://buffer-account.s3.amazonaws.com/logo/large/google-business.svg',
        description: [
          'Boost local visibility; businesses with a profile are 2.7x more reputable.',
          'Engage customers through interactive reviews and Q&A.',
          'Share updates and offers, right on your profile.',
        ],
        links: [
          {
            text: 'Connecting your Google Business Profile to Buffer',
            url: 'https://support.buffer.com/article/557-using-google-business-profiles-with-buffer?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more',
            __typename: 'HelpCenterLink',
          },
        ],
        contentTypes: ['text', 'image'],
        audience:
          'All demographics, local businesses, with business information',
        socialProof: null,
        similarNetworks: ['linkedin', 'facebook'],
        tag: null,
        categories: ['small_business'],
        __typename: 'ConnectableService',
      },
      {
        service: 'pinterest',
        serviceStatus: {
          label: 'none',
          details: null,
          __typename: 'ServiceStatus',
        },
        types: ['business', 'profile'],
        products: ['publish'],
        isNew: false,
        canConnect: true,
        status: {
          status: 'available',
          __typename: 'ChannelConnectionStatus',
        },
        title: 'Pinterest',
        subtitle: 'Business or Profile',
        serviceIconUrl:
          'https://buffer-account.s3.amazonaws.com/logo/large/pinterest.svg',
        description: [
          'Inspire and be seen by 482 million active users.',
          'Drive high referral traffic, straight to your site.',
          'Convert users with high purchase intent.',
        ],
        links: [
          {
            text: 'Creating or converting to a Pinterest business account',
            url: 'https://support.buffer.com/article/558-using-pinterest-with-buffer?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more#01H8EYH47RDVMJWSBKKFWFM7FA',
            __typename: 'HelpCenterLink',
          },
        ],
        contentTypes: ['image'],
        audience:
          'Primarily women, particularly 18-49, with image-based content',
        socialProof:
          'Hey! Did you know that 80% of weekly Pinners have discovered a new brand or product on Pinterest?',
        similarNetworks: ['instagram', 'shopify'],
        tag: null,
        categories: ['ecommerce'],
        __typename: 'ConnectableService',
      },
      {
        service: 'mastodon',
        serviceStatus: {
          label: 'none',
          details: null,
          __typename: 'ServiceStatus',
        },
        types: ['profile'],
        products: ['publish'],
        isNew: false,
        canConnect: true,
        status: {
          status: 'available',
          __typename: 'ChannelConnectionStatus',
        },
        title: 'Mastodon',
        subtitle: 'Profile',
        serviceIconUrl:
          'https://buffer-account.s3.amazonaws.com/logo/large/mastodon.svg',
        description: [
          'Enjoy an ad-free, algorithm-free social experience.',
          'Benefit from a chronological timeline, no hidden posts.',
          'Cross-post to other networks with ease.',
        ],
        links: [
          {
            text: 'Using Mastodon with Buffer',
            url: 'https://support.buffer.com/article/563-using-mastodon-with-buffer?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more',
            __typename: 'HelpCenterLink',
          },
        ],
        contentTypes: ['text', 'analytics', 'image', 'video'],
        audience:
          'Tech-savvy users, privacy advocates, with text-based content',
        socialProof:
          "Hey! Did you know Mastodon is not owned by a single corporation. It's a decentralized social network, meaning anyone can run their own server?",
        similarNetworks: ['twitter', 'linkedin'],
        tag: null,
        categories: ['creator'],
        __typename: 'ConnectableService',
      },
      {
        service: 'shopify',
        serviceStatus: {
          label: 'none',
          details: null,
          __typename: 'ServiceStatus',
        },
        types: ['account'],
        products: ['analyze'],
        isNew: false,
        canConnect: true,
        status: {
          status: 'available',
          __typename: 'ChannelConnectionStatus',
        },
        title: 'Shopify',
        subtitle: 'Store',
        serviceIconUrl:
          'https://buffer-account.s3.amazonaws.com/logo/large/shopify.svg',
        description: [
          'Monitor customers, sales, and lifetime value in one place.',
          'Categorize sales, customers, and order value by social referrals.',
          'Understand product performance and customer origins.',
        ],
        links: [
          {
            text: 'Connecting your Shopify store to Buffer',
            url: 'https://buffer.com/resources/buffer-shopify-integration?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more',
            __typename: 'HelpCenterLink',
          },
        ],
        contentTypes: ['analytics'],
        audience:
          'E-commerce businesses, all demographics, with product images',
        socialProof:
          'Hey! Did you know Shopify supports over 1 million businesses in 175 countries, making it one of the most popular e-commerce platforms in the world?',
        similarNetworks: ['pinterest', 'facebook'],
        tag: null,
        categories: ['ecommerce'],
        __typename: 'ConnectableService',
      },
    ],
  },
}
