import { formatCTAString } from '../../../common/hooks/useSegmentTracking'
import type { Account } from '../../../common/types'
import UpgradePathsTracking from '../../../tracking/UpgradePaths'

export type UpgradePathConditionals = {
  shouldTrackUpgradeCTAViewed: boolean
  shouldTrackStartTrialViewed: boolean
  shouldShowTeamMemberUpgradePath: boolean
}

export function trackCTAsViewedInDropdown(
  user: Account,
  upgradePathConditionals: UpgradePathConditionals,
): void {
  const { trackCTAViewed } = UpgradePathsTracking(user)
  const {
    shouldTrackUpgradeCTAViewed,
    shouldTrackStartTrialViewed,
    shouldShowTeamMemberUpgradePath,
  } = upgradePathConditionals

  if (shouldTrackUpgradeCTAViewed) {
    trackCTAViewed({
      ctaButton: 'upgrade',
      upgradePathName: 'mainNavMenu-upgrade',
      ctaView: 'mainNavMenuDropdown',
      ctaLocation: 'mainNavMenuDropdown',
    })
  }

  if (shouldTrackStartTrialViewed) {
    trackCTAViewed({
      ctaButton: formatCTAString('Start a free trial'),
      upgradePathName: 'mainNavMenu-trial',
      ctaView: 'mainNavMenuDropdown',
      ctaLocation: 'mainNavMenuDropdown',
    })
  }

  if (shouldShowTeamMemberUpgradePath) {
    trackCTAViewed({
      ctaButton: 'team',
      upgradePathName: 'team-upgrade',
      ctaView: 'mainNavMenuDropdown',
      ctaLocation: 'mainNavMenuDropdown',
    })
  }
}
