import React from 'react'

import { AlertDialog, Flex } from '@buffer-mono/popcorn'
import { constructTitleWithGrammer } from '../ChannelConnectionOnboarding/utils'
import ChannelsList from './components/ChannelsList'
import { ConnectChannelUpgradeActions } from './components/ConnectChannelUpgradeActions'
import { ConnectChannelUpgradeMessage } from './components/ConnectChannelUpgradeMessage'
import useChannels from './../../hooks/useChannels'

import styles from './ConnectChannelUpgrade.module.css'
import { useUser } from '../../../../common/context/User'
import { isOnNonAgencyTieredPricing } from '../../../../common/utils/billing'

const ConnectChannelUpgrade = (): JSX.Element => {
  const { channelIds, unlockedChannels, lockedChannels, lockedChannelIds } =
    useChannels()
  const totalConnectedChannels = unlockedChannels.concat(lockedChannels)
  const title = constructTitleWithGrammer(channelIds.length)

  const account = useUser()
  const isOnTieredPricing = isOnNonAgencyTieredPricing(
    account?.currentOrganization?.billing,
  )

  return (
    <AlertDialog.Content data-testid={`channel-connection-update-modal`}>
      <AlertDialog.Title>{title}</AlertDialog.Title>
      <Flex
        direction="column"
        gap="md"
        data-testid={`channel-connection-update-modal-body`}
        className={styles.body}
      >
        <ConnectChannelUpgradeMessage
          totalChannelsCount={totalConnectedChannels.length}
          lockedChannelIds={lockedChannelIds}
          isOnTieredPricing={isOnTieredPricing}
        />
        <ChannelsList channels={totalConnectedChannels} />
      </Flex>
      <Flex direction="column" align="center" gap="md">
        <ConnectChannelUpgradeActions />
      </Flex>
    </AlertDialog.Content>
  )
}

export default ConnectChannelUpgrade
