import React from 'react'
import PropTypes from 'prop-types'

import { red, yellowDark } from '@bufferapp/ui/style/colors'

import { UXMessagingContainer } from './UXMessaging.style'

const messageStatusColor = {
  error: red,
  warning: yellowDark,
}

export type MessageStatus = {
  messageStatus: 'error' | 'warning'
  message: string
}

const UXMessaging = ({
  messageStatus,
  message,
}: MessageStatus): JSX.Element => {
  const messageTest = message
  const color = messageStatusColor[messageStatus]

  return (
    <UXMessagingContainer id="UXMessagingContainer" color={color}>
      {messageTest}
    </UXMessagingContainer>
  )
}

UXMessaging.propTypes = {
  messageStatus: PropTypes.oneOf(['error', 'warning']),
  message: PropTypes.string.isRequired,
}

UXMessaging.defaultProps = {
  messageStatus: 'warning',
}

export default UXMessaging
