import React from 'react'
import Loader from '@bufferapp/ui/Loader'
import { getUserBillingData } from '../../../../common/utils/user'
import {
  getSubscriptionInterval,
  getSubscriptionPlanData,
  getBillingChannelSlotDetails,
  getPricingTiers,
} from '../../../../common/utils/billing'

import CardBody from './components/CardBody'

import { LoadingContainer, Container } from './style'
import { useUser } from '../../../../common/context/User'

const loadingContainer = (
  <LoadingContainer>
    <Loader />
  </LoadingContainer>
)

const QuantityUpdateTieredPricing = (): JSX.Element => {
  const user = useUser()

  if (!user.currentOrganization?.billing) {
    return loadingContainer
  }

  // Get billing Data from user and early return if it doesn't exist
  const billingData = getUserBillingData(user)
  if (!billingData) return loadingContainer

  // Get current plan early return if it doesn't exist
  const currentPlan = getSubscriptionPlanData(billingData)
  if (!currentPlan) return loadingContainer

  // Get pricing details early return if it doesn't exist
  const pricingTiers = getPricingTiers(billingData)
  if (!pricingTiers) return loadingContainer

  // Get channel slot details early return if it doesn't exist
  const channelSlotDetails = getBillingChannelSlotDetails(billingData)
  if (!channelSlotDetails) return loadingContainer

  // Get plan interval early return if it doesn't exist
  const planInterval = getSubscriptionInterval(billingData)
  if (!planInterval) return loadingContainer

  return (
    <Container id="quantity_update_tiered_pricing_container">
      <CardBody
        planName={currentPlan.name}
        planId={currentPlan.id}
        planCycle={planInterval}
        initialQuantity={channelSlotDetails.currentQuantity}
        pricingTiers={pricingTiers}
        user={user}
        trialInfo={user.currentOrganization.billing.subscription?.trial}
      />
    </Container>
  )
}

export default QuantityUpdateTieredPricing
