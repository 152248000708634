import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'
import Text from '@bufferapp/ui/Text'
import React from 'react'

import { MODALS } from '../../../../../common/types'
import { useFetchUnreadEngageComments } from '../../../../NavBar/components/UnreadEngageCommentsCounter/useFetchUnreadEngageComments'
import { PaywallCtas } from '../../shared/PaywallCtas'
import {
  ENGAGE_BULLET_POINTS,
  ENGAGE_DESCRIPTION,
  ENGAGE_HEADING_EMPTY,
  ENGAGE_HEADING_PLURAL,
  ENGAGE_HEADING_SINGULAR,
} from '../constants'
import * as styles from '../styles'
import type { PaywallContentProps } from './PaywallContentTypes'

export const EngagementContent = ({
  description = ENGAGE_DESCRIPTION,
  body = ENGAGE_BULLET_POINTS,
}: PaywallContentProps): JSX.Element => {
  const { totalPendingComments = 0 } = useFetchUnreadEngageComments()
  const headline =
    totalPendingComments < 1
      ? ENGAGE_HEADING_EMPTY
      : totalPendingComments > 1
      ? ENGAGE_HEADING_PLURAL.replace(
          '%UNREAD%',
          totalPendingComments.toString(),
        )
      : ENGAGE_HEADING_SINGULAR
  return (
    <styles.Content data-testid="engage-paywall">
      <styles.Info>
        <Text type="h2">{headline}</Text>
        <Text type="p" role="paragraph">
          {description}
        </Text>
        <styles.List>
          {body.map((item: string) => (
            <li key={item}>
              <styles.Check>
                <CheckmarkIcon />
              </styles.Check>
              <Text>{item}</Text>
            </li>
          ))}
        </styles.List>
        <PaywallCtas feature="engage" paywallType={MODALS.paywall} />
      </styles.Info>
      <styles.Image>
        <img
          src={
            'https://static.buffer.com/images/engage/engage-paywall-growth-563.jpg'
          }
          alt="Engage"
        />
      </styles.Image>
    </styles.Content>
  )
}
