import { useState, useLayoutEffect } from 'react'

type UseMediaQueryOptions = {
  defaultValue?: boolean
  initializeWithValue?: boolean
}

const IS_SERVER = typeof window === 'undefined'

/**
 * Custom hook that tracks the state of a media query using the Match Media API.
 *
 * @example
 * ```tsx
 * const matches = useMediaQuery('(min-width: 768px)')
 * ```
 */
export function useMediaQuery(
  query: string,
  {
    defaultValue = false,
    initializeWithValue = true,
  }: UseMediaQueryOptions = {},
): boolean {
  const getMatches = (query: string): boolean => {
    if (IS_SERVER) {
      return defaultValue
    }

    // Check if matches media query
    return window.matchMedia(query)?.matches ?? defaultValue
  }

  const [matches, setMatches] = useState<boolean>(() => {
    if (initializeWithValue) {
      return getMatches(query)
    }
    return defaultValue
  })

  // Handles the change event of the media query.
  function handleChange() {
    setMatches(getMatches(query))
  }

  useLayoutEffect(() => {
    const matchMedia = window.matchMedia(query)

    if (!matchMedia) {
      return
    }

    // Triggered at the first client-side load and if query changes
    handleChange()

    // Watch for changes
    matchMedia.addEventListener('change', handleChange)

    return () => {
      // Cleanup
      matchMedia.removeEventListener('change', handleChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return matches
}
