import {
  BufferTrackerReact as BufferTracker,
  Client,
} from '@buffer-mono/tracking-plan'
import type { Account } from '../common/types'
import { getActiveProductFromPath } from '../common/utils/getProduct'
import { getOrganization } from '../common/utils/user'
import type { Service } from '../exports/Orchestrator/channelConnections/types'
import {
  getCommonTrackingPropertiesFromAccount,
  shouldPerformTrackingEvent,
} from './utils'

export interface ChannelCTAViewedTrackingPayload {
  service: Service
  cta?: string
}

export interface ChannelCTAViewedTrackingArguments {
  payload: ChannelCTAViewedTrackingPayload
  user: Account
}

export default function trackChannelCTAViewed({
  payload,
  user,
}: ChannelCTAViewedTrackingArguments): void {
  const canPerformTrackingEvent = shouldPerformTrackingEvent({
    user,
  })

  if (!canPerformTrackingEvent) {
    return
  }

  const { service, cta } = payload

  const organization = getOrganization(user)
  const product = getActiveProductFromPath()

  const commonTrackingProperties = getCommonTrackingPropertiesFromAccount(user)

  if (organization?.id && cta) {
    const eventData = {
      organizationId: organization?.id,
      cta,
      clientName: Client.PublishWeb,
      product,
      channel: service,
      upgradePathName: null,
      ...commonTrackingProperties,
    }
    BufferTracker.channelCTAClicked(eventData)
  }
}
