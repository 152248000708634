import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import { shouldPerformTrackingEvent } from '../utils'
import type { TrackTrialPathsArguments } from './TrialPathsTrackingTypes'
import { createEventDataStructureForTrialPath } from './utils'

export default function trackStartTrialModalViewed({
  payload,
  user,
}: TrackTrialPathsArguments): void {
  const canPerformTrackingEvent = shouldPerformTrackingEvent({
    user,
  })

  if (!canPerformTrackingEvent) {
    return
  }

  const eventData = createEventDataStructureForTrialPath({
    payload,
    user,
    defaultUpgradePathName: 'startTrialModal',
  })

  BufferTracker.startTrialModalViewed(eventData)
}
