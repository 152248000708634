import {
  isPayingAnalyzeOrganization,
  isPayingPublishOrganization,
} from '../../../common/hooks/utils/segmentTraitGetters'
import type { Account } from '../../../common/types'
import {
  isPayingStripeCustomer,
  isCustomerTaxExempt,
  isUnrecognizedTaxLocationValid,
  canManageBilling,
} from '../../../common/utils/user'

export const shouldShowMissingBillingAddressBanner = (
  user: Account,
): boolean => {
  return (
    (isPayingStripeCustomer(user) ||
      isPayingPublishOrganization(user) ||
      isPayingAnalyzeOrganization(user)) &&
    !isCustomerTaxExempt(user) &&
    isUnrecognizedTaxLocationValid(user) &&
    canManageBilling(user)
  )
}
