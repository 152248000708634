import React from 'react'
import { getActiveProductFromPath } from '../../../../common/utils/getProduct'
import { EngagementContent } from './components/EngagementContent'
import { AnalyticsContent } from './components/AnalyticsContent'

export const Paywall = (): JSX.Element | null => {
  let product = getActiveProductFromPath()

  // for local development
  if (product === 'appshell') {
    product = 'engage'
  }

  if (product === 'engage') {
    return <EngagementContent />
  }

  if (product === 'analyze') {
    return <AnalyticsContent />
  }

  return null
}
