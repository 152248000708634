import gql from 'graphql-tag'

export const CREATE_SETUP_INTENT = gql`
  mutation billingCreateSetupIntent($organizationId: String) {
    billingCreateSetupIntent(organizationId: $organizationId) {
      ... on BillingCreateSetupIntentResponse {
        success
        clientSecret
      }
      ... on BillingError {
        userFriendlyMessage
      }
    }
  }
`

export const UPDATE_PAYMENT_METHOD = gql`
  mutation updatePaymentMethod(
    $organizationId: String
    $paymentMethodId: String
  ) {
    billingUpdateCustomerPaymentMethod(
      organizationId: $organizationId
      paymentMethodId: $paymentMethodId
    ) {
      ... on BillingResponse {
        success
      }
      ... on BillingError {
        userFriendlyMessage
      }
    }
  }
`

export const UPDATE_SUBSCRIPTION_PLAN = gql`
  mutation updatePlan(
    $organizationId: String
    $plan: OBPlanId
    $interval: BillingInterval
    $attribution: AttributionInput
    $quantity: Int
  ) {
    billingUpdateSubscriptionPlan(
      organizationId: $organizationId
      plan: $plan
      interval: $interval
      quantity: $quantity
      attribution: $attribution
    ) {
      ... on BillingUpdateSubscriptionPlanResponse {
        success
        scheduledUpdate {
          newPlan
          newInterval
          newQuantity
          effectiveDate
        }
      }
      ... on BillingError {
        userFriendlyMessage
      }
    }
  }
`

export const UPDATE_SUBSCRIPTION_PLAN_TO_FREE = gql`
  mutation updatePlanToFree(
    $organizationId: String
    $plan: OBPlanId
    $interval: BillingInterval
  ) {
    billingUpdateSubscriptionPlan(
      organizationId: $organizationId
      plan: $plan
      interval: $interval
    ) {
      ... on BillingUpdateSubscriptionPlanResponse {
        success
      }
      ... on BillingError {
        userFriendlyMessage
      }
    }
  }
`

export const UPDATE_SUBSCRIPTION_QUANTITY = gql`
  mutation updateQuantity(
    $organizationId: String
    $quantity: Int
    $attribution: AttributionInput
  ) {
    billingUpdateSubscriptionQuantity(
      organizationId: $organizationId
      quantity: $quantity
      attribution: $attribution
    ) {
      ... on BillingUpdateSubscriptionPlanResponse {
        success
        scheduledUpdate {
          newPlan
          newInterval
          newQuantity
          effectiveDate
        }
      }
      ... on BillingError {
        userFriendlyMessage
      }
    }
  }
`

export const UPDATE_SUBSCRIPTION_QUANTITY_BY_CHANNEL_IDS = gql`
  mutation updateQuantityByChannelIds(
    $organizationId: String!
    $channelIds: [String]!
    $attribution: AttributionInput
  ) {
    billingIncreaseSubscriptionQuantityByChannelIds(
      organizationId: $organizationId
      channelIds: $channelIds
      attribution: $attribution
    ) {
      ... on BillingResponse {
        success
      }
      ... on BillingError {
        userFriendlyMessage
      }
    }
  }
`

export const START_TRIAL = gql`
  mutation startTrial(
    $organizationId: String
    $plan: OBPlanId
    $interval: BillingInterval
    $attribution: AttributionInput
  ) {
    billingStartTrial(
      organizationId: $organizationId
      plan: $plan
      interval: $interval
      attribution: $attribution
    ) {
      ... on BillingResponse {
        success
      }
      ... on BillingError {
        userFriendlyMessage
      }
    }
  }
`

export const PREVIEW_CUSTOM_UPCOMING_INVOICE = gql`
  query GetInvoicePreview(
    $organizationId: String!
    $plan: OBPlanId
    $interval: BillingInterval
    $quantity: Int
  ) {
    previewCustomUpcomingInvoice(
      organizationId: $organizationId
      plan: $plan
      interval: $interval
      quantity: $quantity
    ) {
      chargeAmount
      creditAmount
      isScheduledChange
      nextBillingDate
    }
  }
`

export const CREATE_CHECKOUT_SESSION = gql`
  mutation billingCreateCheckoutSession(
    $organizationId: String!
    $plan: OBPlanId!
    $interval: BillingInterval!
    $quantity: Int
    $currentURL: String
    $shouldCollectAutomaticTax: Boolean
    $attribution: AttributionInput
  ) {
    billingCreateCheckoutSession(
      organizationId: $organizationId
      plan: $plan
      interval: $interval
      quantity: $quantity
      currentURL: $currentURL
      shouldCollectAutomaticTax: $shouldCollectAutomaticTax
      attribution: $attribution
    ) {
      ... on BillingCreateCheckoutSessionResponse {
        redirectUrl
      }
      ... on BillingError {
        userFriendlyMessage
      }
    }
  }
`
