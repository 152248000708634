import styled from 'styled-components'

export const SummaryContainer = styled.div`
  width: 280px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 100%;
  padding: 60px 0 24px;
  box-sizing: border-box;
  position: relative;

  h2 {
    margin-left: 8px;
    margin-bottom: 16px;
  }
`

export const Body = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
`

export const Price = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  sup {
    font-family: 'Roboto', sans-serif;
    font-weight: 800;
    font-size: 18px;
  }

  sup:first-child {
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
  }

  p {
    color: black;
    margin-left: 2px;
    margin-right: 2px;
  }
`

export const BoldPrice = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0px;
`
