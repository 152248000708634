import React, { useState } from 'react'
import { Button, ClockIcon, Dialog, Notice } from '@buffer-mono/popcorn'
import {
  DayOfWeek,
  type Service,
  type ScheduleV2Input,
  type ChannelType,
} from '../../../../gql/graphql'

import ChipInputGroup from '../../../../common/components/ChipInputGroup'
import styles from './PostingPlanConfiguration.module.css'
import { useMutation } from '@apollo/client'
import { CREATE_POSTING_PLAN_MUTATION } from './mutations'
import { POSTING_TIME_FRAMES } from './constants'
import { constructPostingScheduleInput } from './utils'
import { getChannelFromAccount } from '../../../../common/utils/channels'
import { useUser } from '../../../../common/context/User'
import { getChannelsQueueUrl } from '../../../../common/utils/urls'
import trackPostingPlanCreated from '../../../../tracking/PostingPlanConfiguration/trackPostingPlanCreated'
const daysOfWeek: DayOfWeek[] = [
  DayOfWeek.Mon,
  DayOfWeek.Tue,
  DayOfWeek.Wed,
  DayOfWeek.Thu,
  DayOfWeek.Fri,
  DayOfWeek.Sat,
  DayOfWeek.Sun,
]

function PostingPlanConfiguration({
  channelId,
}: {
  channelId: string
}): JSX.Element {
  const account = useUser()
  const defaultSelectedAmount = '0'
  const [selectedDays, setSelectedDays] = useState<DayOfWeek[]>([])
  const [postingAmount, setPostingAmount] = useState<string>(
    defaultSelectedAmount,
  )

  const channel = getChannelFromAccount(account, channelId)
  const channelName = channel?.name

  const title = channelName
    ? `Posting Plan for ${channelName}`
    : 'Create Posting Plan'

  const schedule: ScheduleV2Input[] = constructPostingScheduleInput({
    selectedDays,
    postingAmount: parseInt(postingAmount, 10),
  })

  const redirectUrl = getChannelsQueueUrl({ channelId })

  const [createPostingPlan, { error, loading }] = useMutation(
    CREATE_POSTING_PLAN_MUTATION,
    {
      onCompleted: (postingSchedule) => {
        if (postingSchedule) {
          trackPostingPlanCreated({
            user: account,
            payload: {
              channel: channel?.service as Service,
              channelType: channel?.type as ChannelType,
              channelUsername: channelName,
              days: selectedDays,
              timesPerDay: parseInt(postingAmount, 10),
            },
          })
          window.location.href = `${redirectUrl}&postingPlanConfigHelper=true`
        }
      },
    },
  )

  const handleCreatePostingPlan = (): void => {
    createPostingPlan({
      variables: {
        input: {
          channelId,
          postingSchedule: schedule,
        },
      },
    })
  }

  const handleSelectPostingAmount = (amount: string[]): void => {
    const value = amount[0]
    setPostingAmount(value)
  }

  const isDisabled =
    selectedDays.length === 0 ||
    !postingAmount ||
    postingAmount === '0' ||
    loading

  return (
    <Dialog open>
      <Dialog.Content className={styles.content}>
        <Dialog.Header className={styles.header}>
          <Dialog.Title className={styles.title}>{title}</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <div className={styles.inputWrapper}>
            <ChipInputGroup
              name="days"
              legend="Which days you would like to post?"
              options={daysOfWeek.map((day) => ({
                label: day.substring(0, 2),
                value: day,
              }))}
              onChange={(selectedOptions: string[]): void =>
                setSelectedDays(selectedOptions as DayOfWeek[])
              }
            />
            <ChipInputGroup
              inputType="radio"
              legend="Number of post per day"
              name="amount"
              options={Object.keys(POSTING_TIME_FRAMES).map((amount) => ({
                label: amount.toString(),
                value: amount.toString(),
              }))}
              onChange={(selectedAmount: string[]): void =>
                handleSelectPostingAmount(selectedAmount)
              }
            />
            {!error && (
              <Notice icon={<ClockIcon />}>
                We’ll automatically select time slots spread throughout the day,
                making it easy to 1-click schedule content to meet your goal.
              </Notice>
            )}
            {error && (
              <Notice variant="error">
                There was an error creating your posting plan. Please try again.
              </Notice>
            )}
          </div>
        </Dialog.Body>
        <Dialog.Separator />
        <Dialog.Footer>
          <Button
            onClick={handleCreatePostingPlan}
            size="large"
            disabled={isDisabled}
            loading={loading}
          >
            Create Posting Plan
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}

export default PostingPlanConfiguration
