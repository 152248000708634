import React, { useState } from 'react'

import * as Styled from './styles'
import DefaultContent from './DefaultContent'
import { FacebookGroups } from './FacebookGroups'
import { type AccountTypeSelectionProps, ViewType } from './types'

export default function AccountTypeSelection({
  onRedirect,
  closeAction,
}: AccountTypeSelectionProps): JSX.Element | null {
  const [currentView, setCurrentView] = useState<ViewType>(
    ViewType.accountSelection,
  )

  return (
    <>
      <Styled.Container>
        {currentView === ViewType.accountSelection && (
          <DefaultContent
            onRedirect={onRedirect}
            onChangeView={setCurrentView}
          />
        )}
        {currentView === ViewType.FacebookGroups && (
          <FacebookGroups onClose={closeAction} />
        )}
      </Styled.Container>
    </>
  )
}
