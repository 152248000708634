import React, { useEffect, type ReactElement, useMemo } from 'react'
import Button from '@bufferapp/ui/Button'
import Text from '@bufferapp/ui/Text'

import { useUser } from '../../../../common/context/User'
import { MODALS } from '../../../../common/types'

import { trackUpgradePathViewed } from '../../../../common/hooks/useSegmentTracking'
import useStartTrial from '../../../../common/hooks/useStartTrial'
import { getSuggestedPlan } from '../../../../common/utils/getSuggestedPlan'
import { Error } from '../../../../common/components/Error'
import UpgradePathsTracking from '../../../../tracking/UpgradePaths'
import { prefixWithProduct } from '../../../../common/utils/prefixWithProduct'
import { useModalManager } from '../../../ModalManager/hooks/useModalManager'

import * as styles from '../shared/PaywallStyles'

type CtaProp = {
  feature: string
  paywallType: MODALS.featurePaywall | MODALS.paywall
}

export const PaywallCtas = ({
  feature,
  paywallType,
}: CtaProp): ReactElement => {
  const user = useUser()
  const { openModal, dismissModal } = useModalManager()
  const suggestedPlan = getSuggestedPlan(user)
  const canStartTrial = user?.currentOrganization?.billing?.canStartTrial

  const ctaAttributes = useMemo(() => {
    const ctaButton = canStartTrial ? 'startTrial' : 'upgradePlan'
    const ctaView = 'paywallModal'
    const ctaLocation = 'paywallModal'

    return {
      ctaView,
      ctaLocation,
      cta: `${ctaView}-${ctaLocation}-${ctaButton}-1`,
      upgradePathName: canStartTrial
        ? `${feature}-trial`
        : `${feature}-upgrade`,
      ctaButton,
    }
  }, [canStartTrial, feature])

  const { startTrial, trial, error, processing } = useStartTrial({
    user,
    plan: suggestedPlan,
    attribution: {
      cta: prefixWithProduct(ctaAttributes.cta),
      upgradePathName:
        ctaAttributes.upgradePathName /* This attribution are sent in the Start Trial event from backend */,
    },
  })
  const { trackCTAClicked, trackCTAViewed } = UpgradePathsTracking(user)

  useEffect(() => {
    trackUpgradePathViewed({
      payload: { upgradePathName: ctaAttributes.upgradePathName },
      user,
    })

    /* Track CTA Viewed event */
    trackCTAViewed({
      ctaView: ctaAttributes.ctaView,
      ctaLocation: ctaAttributes.ctaLocation,
      ctaButton: ctaAttributes.ctaButton,
      upgradePathName: ctaAttributes.upgradePathName,
    })
  }, [user, ctaAttributes.upgradePathName])

  useEffect(() => {
    if (trial && trial.billingStartTrial.success) {
      /* close first this modal */
      dismissModal()
      openModal({
        key: MODALS.success,
        data: {
          startedTrial: true,
          selectedPlan: suggestedPlan,
        },
      })
    }
  }, [openModal, suggestedPlan, trial])

  const handleMainCTAClick = (): void => {
    /* Track CTA Clicked event */
    trackCTAClicked({
      ctaView: ctaAttributes.ctaView,
      ctaLocation: ctaAttributes.ctaLocation,
      ctaButton: ctaAttributes.ctaButton,
      upgradePathName: ctaAttributes.upgradePathName,
    })
    /*
     * If the user can start a trial, the trial will start inmediately
     * In other case, the Pricing Modal will be opened to upgrade the subscription
     */
    if (canStartTrial) {
      startTrial()
    } else {
      /* close first this modal */
      dismissModal()
      openModal({
        key: MODALS.planSelector,
        data: {
          cta: ctaAttributes.cta,
          source: paywallType,
          upgradePathName: ctaAttributes.upgradePathName,
        },
      })
    }
  }

  const getButtonLabel = (): string => {
    const label = canStartTrial ? 'Try it free for 14 days' : 'Upgrade Plan'
    if (canStartTrial && processing) {
      return 'Processing ...'
    }
    return label
  }

  return (
    <>
      <styles.CTAs>
        <Button
          type="primary"
          data-testid={`${feature}-paywall-cta-button`}
          disabled={canStartTrial && (!suggestedPlan || processing)}
          onClick={handleMainCTAClick}
          label={getButtonLabel()}
        />
        {error && <Error isInline={false} error={error} />}
        {canStartTrial && <Text>No credit card required</Text>}
      </styles.CTAs>
    </>
  )
}
