import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import {
  getCommonTrackingPropertiesFromAccount,
  shouldPerformTrackingEvent,
} from '../utils'
import { getActiveProductFromPath } from '../../common/utils/getProduct'
import type { Account } from '../../common/types'
import { isAtPlanChannelLimit } from '../../common/utils/user'

export type TrackGlobalPlusButtonItemClickedPayload = {
  ctaButton: string
  ctaView: string
  ctaLocation: string
  ctaVersion?: string
  upgradePathName: string
}

type TrackGlobalPlusButtonItemClickedArguments = {
  payload: TrackGlobalPlusButtonItemClickedPayload
  user: Account
}

export default function trackGlobalPlusButtonItemClicked({
  payload,
  user,
}: TrackGlobalPlusButtonItemClickedArguments): void {
  const canPerformTrackingEvent = shouldPerformTrackingEvent({
    user,
  })

  if (!canPerformTrackingEvent) {
    return
  }

  const { ctaView, ctaLocation, ctaButton, ctaVersion, upgradePathName } =
    payload

  const version = ctaVersion || '1'

  const organization = user.currentOrganization
  const product = getActiveProductFromPath()
  const cta = `${product}-${ctaView}-${ctaLocation}-${ctaButton}-${version}`
  const currentChannelQuantity = user?.channels?.length
  const isAtPlanLimit = isAtPlanChannelLimit(user)

  const commonTrackingProperties = getCommonTrackingPropertiesFromAccount(user)

  const eventData = {
    organizationId: organization?.id || '',
    cta,
    clientName: 'publishWeb',
    product,
    currentChannelQuantity,
    upgradePathName,
    isAtPlanLimit,
    ...commonTrackingProperties,
  }

  BufferTracker.globalPlusButtonItemClicked(eventData)
}
