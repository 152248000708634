import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Text } from '@bufferapp/ui'

import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import { useUser } from '../../../../common/context/User'
import { UPDATE_SUBSCRIPTION_PLAN_TO_FREE } from '../../../../common/graphql/billing'
import { MODALS, PlanInterval } from '../../../../common/types'
import { QUERY_ACCOUNT } from '../../../../common/graphql/account'
import { ORGANIZATION_ACTIONS } from '../../../../common/events/orgEvents'
import { useModalManager } from '../../../ModalManager/hooks/useModalManager'

import {
  ModalTitle,
  ModalBody,
  ModalHeader,
  Error,
  ModalFooter,
  ContentWrapper,
} from './styles'

const ConfirmCancellationModal = (): JSX.Element => {
  const [error, setError] = useState<string | null>(null)

  const account = useUser()
  const {
    openModal,
    modalData: data,
    dismissModal: closeAction,
  } = useModalManager()

  const subscriptionType = data?.subscriptionType || 'plan'

  const [updateSubscription, { loading }] = useMutation(
    UPDATE_SUBSCRIPTION_PLAN_TO_FREE,
    {
      refetchQueries: [{ query: QUERY_ACCOUNT }],
      awaitRefetchQueries: true,
      onCompleted(data) {
        if (data?.billingUpdateSubscriptionPlan.userFriendlyMessage) {
          setError(data?.billingUpdateSubscriptionPlan.userFriendlyMessage)
        } else {
          ORGANIZATION_ACTIONS.billingUpdated({ account })
          closeAction()
          openModal({
            key: MODALS.success,
            data: {
              selectedPlan: { planName: 'free' },
              isDowngradingToFreePlan: true,
            },
          })
        }
      },
      onError() {
        setError(
          `Whoops! Looks like there was a problem canceling your ${subscriptionType}. Please try again. If you continue experiencing this problem, please contact us.`,
        )
      },
    },
  )

  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null
  const planName =
    account?.currentOrganization?.billing?.subscription?.plan?.name || ''
  const renewalDate =
    account?.currentOrganization?.billing?.subscription?.periodEnd || ''
  const organizationId = account?.currentOrganization?.id

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } as Intl.DateTimeFormatOptions
  const formattedRenewalDate = new Date(renewalDate).toLocaleDateString(
    'en-US',
    dateOptions,
  )

  const { currentOrganization } = account
  const connectedChannelsCount =
    currentOrganization && currentOrganization.channels
      ? account?.channels?.filter(
          (channel) =>
            channel.organizationId === account?.currentOrganization?.id &&
            !channel.isLocked,
        ).length || 0
      : 0

  const channelsCopy = (
    <Text type="p">
      You currently have {connectedChannelsCount} channels connected to your
      Buffer account.{' '}
      <strong>
        On your downgrade date, all but 3 of these channels will be locked and
        you will not be able to post to them.
      </strong>{' '}
      You can choose which channels are unlocked by visiting the “Channels” page
      within your Account.
    </Text>
  )

  const planCopy = (
    <div data-testid="confirm-cancellation-modal-message">
      <Text type="p">
        You’ve already paid for the <strong>{planName}</strong> plan until{' '}
        <strong>{formattedRenewalDate}</strong>. By confirming, your downgrade
        will take effect on <strong>{formattedRenewalDate}</strong>.{' '}
      </Text>
      {connectedChannelsCount > 3 && channelsCopy}
    </div>
  )

  const trialCopy = (
    <Text data-testid="confirm-cancellation-modal-message" type="p">
      Please confirm you’d like to cancel your trial.
    </Text>
  )

  useEffect(() => {
    if (organizationId) {
      BufferTracker.confirmCancellationViewed({
        organizationId,
        ...commonTrackingProperties,
      })
    }
  }, [organizationId, commonTrackingProperties])

  return (
    <ContentWrapper>
      <ModalHeader>
        <ModalTitle text={`Confirm ${subscriptionType} cancellation`} />
      </ModalHeader>
      <ModalBody>
        {subscriptionType === 'plan' ? planCopy : trialCopy}
        {error && <Error error={error} />}
      </ModalBody>
      <ModalFooter>
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
        <Button
          type="text"
          label="No, Do Not Cancel"
          disabled={loading}
          onClick={(): void => {
            organizationId &&
              BufferTracker.confirmCancellationCTAClicked({
                organizationId,
                ctaName: 'doNotCancel_cancelConfirm',
                ...commonTrackingProperties,
              })
            closeAction()
          }}
        />
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
        <Button
          type="primary"
          label={
            loading ? 'Cancelling...' : `Yes, Cancel My ${subscriptionType}`
          }
          disabled={loading}
          onClick={(): void => {
            if (error) {
              setError(null)
            }

            updateSubscription({
              variables: {
                interval: PlanInterval.month,
                organizationId: account?.currentOrganization?.id,
                plan: 'free',
              },
            })

            organizationId &&
              BufferTracker.confirmCancellationCTAClicked({
                organizationId,
                ctaName: 'yesCancel_cancelConfirm',
                ...commonTrackingProperties,
              })
          }}
        />
      </ModalFooter>
    </ContentWrapper>
  )
}

export default ConfirmCancellationModal
