import {
  type Account,
  type ChangePlanOption,
  PlanInterval,
  type SuggestedPlanInfo,
} from '../types'

export function getSuggestedPlan(user: Account): SuggestedPlanInfo {
  const plan = {
    planId: 'team',
    planInterval: PlanInterval.month,
  }

  if (user) {
    const suggestedPlan =
      user.currentOrganization?.billing?.changePlanOptions?.find(
        (p: ChangePlanOption) => p.isRecommended,
      )
    if (suggestedPlan) {
      return {
        planId: suggestedPlan.planId,
        planInterval: suggestedPlan.planInterval,
      }
    }
  }
  return plan
}
