import gql from 'graphql-tag'
import { graphql } from '../../../../gql'

// This requests the BE to start our authentication process for the users selected network service.
export const GET_CHANNEL_AUTHORIZATION_INFO = gql`
  query ChannelAuthorizationInfo($input: ChannelAuthorizationInfoInput!) {
    channelAuthorizationInfo(input: $input) {
      ... on ChannelAuthorizationInfoResponse {
        url
        state
        redirectUri
      }
    }
  }
`

// TODO: This should eventually replace the above query
// Needs dedicated time to fix incorrect types after switch
export const GetChannelAutorizationInfo = graphql(/* GraphQL */ `
  query GetChannelAutorizationInfo($input: ChannelAuthorizationInfoInput!) {
    channelAuthorizationInfo(input: $input) {
      ... on ChannelAuthorizationInfoResponse {
        url
        state
        redirectUri
      }
    }
  }
`)
