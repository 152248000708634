import trackPlanSelectorViewed from '../../tracking/PlanSelector/trackPlanSelectorViewed'
import trackPlanSelectorPlanClicked from '../../tracking/PlanSelector/trackPlanSelectorPlanClicked'
import trackPlanSelectorPlanCycleClicked from '../../tracking/PlanSelector/trackPlanSelectorPlanCycleClicked'
import trackPlanSelectorChannelQuantityClicked from './trackPlanSelectorChannelQuantityClicked'
import trackPlanSelectorCTAClicked from './trackPlanSelectorCTAClicked'

import type { Account } from '../../common/types'
import type {
  PlanSelectorTrackingEvents,
  PlanSelectorViewedTrackingPayload,
  PlanSelectorCTAClickedTrackingPayload,
  PlanSelectorClickedTrackingPayload,
  PlanSelectorPlanCycleClickedTrackingPayload,
} from '../../tracking/PlanSelector/PlanSelectorTrackingTypes'

export default function PlanSelectorTracking(
  user: Account,
): PlanSelectorTrackingEvents {
  function trackViewed(payload: PlanSelectorViewedTrackingPayload): void {
    trackPlanSelectorViewed({ payload, user })
  }

  function trackPlanClicked(payload: PlanSelectorClickedTrackingPayload): void {
    trackPlanSelectorPlanClicked({ payload, user })
  }

  function trackPlanCycleClicked(
    payload: PlanSelectorPlanCycleClickedTrackingPayload,
  ): void {
    trackPlanSelectorPlanCycleClicked({ payload, user })
  }

  function trackChannelCountChanged(channelQuantity: number): void {
    const payload = {
      channelQuantity,
    }

    trackPlanSelectorChannelQuantityClicked({ payload, user })
  }

  function trackCTAClicked(
    payload: PlanSelectorCTAClickedTrackingPayload,
  ): void {
    trackPlanSelectorCTAClicked({ payload, user })
  }

  return {
    trackViewed,
    trackPlanClicked,
    trackPlanCycleClicked,
    trackChannelCountChanged,
    trackCTAClicked,
  }
}
