import React, { type ReactElement } from 'react'
import Text from '@bufferapp/ui/Text'
import ReactPlayer from 'react-player'
import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'

import { PaywallCtas } from '../shared/PaywallCtas'
import { useUser } from '../../../../common/context/User'
import trackCTAPaywallVideoPlayed from '../../../../tracking/trackPaywallVideoPlayed'

import { MODALS } from '../../../../common/types'
import { useModalManager } from '../../../ModalManager/hooks/useModalManager'

import * as styles from '../shared/PaywallStyles'

export const FeaturePaywall = (): ReactElement | null => {
  const user = useUser()
  const { modalData: data } = useModalManager()

  if (!data || !data.paywalledFeature || !data.featurePaywallProperties)
    return null

  const {
    paywalledFeature: feature,
    featurePaywallProperties: { headline, body, description },
  } = data
  const canStartTrial = user?.currentOrganization?.billing?.canStartTrial
  const upgradePathName = canStartTrial
    ? `${feature}-trial`
    : `${feature}-upgrade`

  return (
    <styles.Content data-testid={`${feature}-content`}>
      <styles.Info>
        <Text type="h2">{headline}</Text>
        <Text type="p">{description}</Text>
        <styles.List>
          {body.map((item: string) => (
            <li key={item}>
              <styles.Check>
                <CheckmarkIcon />
              </styles.Check>
              <Text type="span">{item}</Text>
            </li>
          ))}
        </styles.List>
        <PaywallCtas feature={feature} paywallType={MODALS.featurePaywall} />
      </styles.Info>
      <styles.Video>
        <ReactPlayer
          data-testid="wistia-video-player-analyze"
          className="react-player-wrapper"
          url="https://buffer.wistia.com/medias/yqnj7k1ckt"
          width="522px"
          height="290px"
          controls={true}
          onPlay={(): void => {
            trackCTAPaywallVideoPlayed({
              payload: {
                progress: 0,
                upgradePathName,
              },
              user,
            })
          }}
          onEnded={(): void => {
            trackCTAPaywallVideoPlayed({
              payload: {
                progress: 1,
                upgradePathName,
              },
              user,
            })
          }}
        />
        <Text type="label" color="grayDark">
          {' '}
          See how you can use Buffer’s tools together to grow your brand{' '}
        </Text>
      </styles.Video>
    </styles.Content>
  )
}
