import React from 'react'

import { Flex, Text, Avatar } from '@buffer-mono/popcorn'

import styles from './SocialProofCallout.module.css'

function SocialProofCallout({ text }: { text: string }): JSX.Element {
  return (
    <Flex className={styles.socialProofCallout} direction="column" gap="sm">
      <Text>{text}</Text>
      <Flex direction="row" gap="xs" align="center">
        <Avatar
          alt="Joel from Buffer avatar"
          size="small"
          src="https://buffer.com/resources/content/images/size/w300/2020/06/Joel-Gascoigne.png"
        />{' '}
        <Text as="label" weight="medium">
          Joel from Buffer
        </Text>
      </Flex>
    </Flex>
  )
}

export default SocialProofCallout
