import React, { Children, useEffect, useState } from 'react'
import clsx from 'clsx'

import { Flex } from '../Flex'
import { Badge } from '../Badge'
import { VisuallyHidden } from '../VisuallyHidden'

import styles from './Avatar.module.css'

// TODO: replace with SVG
const DEFAULT_AVATAR = `https://s3.amazonaws.com/buffer-ui/Default+Avatar.png`

type AvatarSize = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge'

interface AvatarProps
  extends Omit<React.HTMLProps<HTMLImageElement>, 'size' | 'src' | 'alt'> {
  /**
   * Size of the avatar
   * @default medium
   */
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge'
  /**
   * Source of the avatar image
   */
  src?: string
  /**
   * Alt text for the avatar image, pass `""` if the avatar is purely decorative
   */
  alt: string
}

const AvatarRoot = React.forwardRef<HTMLImageElement, AvatarProps>(
  (
    { size = 'medium', className, src, alt, ...props }: AvatarProps,
    forwardedRef,
  ) => {
    const [innerSrc, setInnerSrc] = useState(src)

    useEffect(() => {
      setInnerSrc(src)
    }, [src])

    return (
      <picture className={clsx(styles.picture, styles[size])}>
        <img
          ref={forwardedRef}
          className={clsx(styles.avatar, className)}
          src={innerSrc ?? DEFAULT_AVATAR}
          alt={alt}
          onError={() => setInnerSrc(DEFAULT_AVATAR)}
          {...props}
        />
      </picture>
    )
  },
)
AvatarRoot.displayName = 'Avatar'

export interface AvatarStackProps
  extends React.ComponentPropsWithoutRef<'div'> {
  /**
   * Whether the avatar should be on top or on bottom of the previous avatar
   * @default firstOnTop
   */
  stacking?: 'lastOnTop' | 'firstOnTop'
  /**
   * Number of max avatars to show in the stack
   */
  max?: number
  /**
   * Size of the avatar stack
   * @default medium
   */
  size?: AvatarSize
}

/**
 * AvatarStack is a component used to display avatar groups, by stacking avatars
 * on top of each other. It also displays a badge with the count of remaining
 * avatars that are not visible.
 *
 * You can customize the size of the avatar stack with the following CSS variables:
 *
 * `--avatar-stack-border-width` to change the border width of the avatars
 * `--avatar-stack-margin-left` to change the margin left of the avatars
 * `--avatar-stack-border-color` to change the border color of the avatars
 */
const AvatarStack = React.forwardRef<HTMLDivElement, AvatarStackProps>(
  (
    {
      children: _children,
      stacking = 'firstOnTop',
      max,
      size = 'medium',
      style,
      className,
      ...props
    }: AvatarStackProps,
    forwardedRef,
  ) => {
    const childrenArray = Children.toArray(_children)
    const remaining = Math.max(max ? childrenArray.length - max : 0, 0)

    const children = childrenArray.slice(0, max)

    const calculateZIndex = (index: number) => {
      if (stacking === 'firstOnTop') {
        return (max ?? childrenArray.length) + 1 - index
      }
      return index
    }

    return (
      <Flex
        align="center"
        className={clsx(styles[size], styles.stack, className)}
        style={style}
        ref={forwardedRef}
        {...props}
      >
        {children.map((child, index) => (
          <div
            key={index}
            className={styles.avatarWrapper}
            style={
              {
                '--avatar-stack-z-index': calculateZIndex(index),
              } as React.CSSProperties
            }
          >
            {child}
          </div>
        ))}
        {remaining > 0 && (
          <Badge
            className={styles.badge}
            style={
              {
                '--avatar-stack-z-index': calculateZIndex(children.length),
              } as React.CSSProperties
            }
            size={size === 'xlarge' ? 'large' : size}
          >
            +{remaining}
            <VisuallyHidden>more avatars</VisuallyHidden>
          </Badge>
        )}
      </Flex>
    )
  },
)
AvatarStack.displayName = 'AvatarStack'

const Avatar = Object.assign(AvatarRoot, {
  Stack: AvatarStack,
})
export { Avatar, AvatarProps, AvatarSize }
