import { maxChannelsAllowedPerPurchaseViaStripe } from '../../utils/validation'
import type { Dispatch } from 'react'

// IMPROVEMENT: this object should get constructed in the Provider
// Goal is to remove these hard coded values
export const plansChannelQuantityInfo: Array<PlansChannelQuantity> = [
  {
    id: 'free',
    min: 3,
  },
  {
    id: 'essentials',
    min: 1,
  },
  {
    id: 'team',
    min: 1,
  },
  {
    id: 'agency',
    min: 10,
  },
]

type PlansChannelQuantity = {
  id: string
  min: number
}

export type MessagingReturn = {
  messageStatus: 'error' | 'warning'
  message: string
}

export function getAgencyPlanMinimumChannelInputMessaging(): MessagingReturn {
  return {
    messageStatus: 'warning',
    message: 'Oops! The Agency Plan has a minimum of 10 channels. ',
  }
}

export function getMaxChannelQuantityInputMessaging(): MessagingReturn {
  return {
    messageStatus: 'error',
    message:
      'Oops! The quantity of channels you entered is too large for us to process right now. Please reach out to our team.',
  }
}

const agencyPlanLimitMessageStatus = getAgencyPlanMinimumChannelInputMessaging()

const maxChannelQuantityMessageStatus = getMaxChannelQuantityInputMessaging()

export function getPlanChannelLimits(
  planId: string,
): PlansChannelQuantity | undefined {
  return plansChannelQuantityInfo.find((planLimit) => planLimit.id === planId)
}

export function handleAgencyConditions(
  channelsCount: number,
  setChannelsCounterValue: Dispatch<number>,
): void | null {
  const planChannelQuantityInfo = getPlanChannelLimits('agency')
  const minimumQuantity = planChannelQuantityInfo?.min || 0

  return channelsCount < minimumQuantity
    ? setChannelsCounterValue(minimumQuantity)
    : null
}

// Whenever the Free plan is selected we set it to it's minium quantity
export function handleFreeConditions(
  channelsCount: number,
  setChannelsCounterValue: Dispatch<number>,
): void {
  const planChannelQuantityInfo = getPlanChannelLimits('free')
  const defaultQuantity = planChannelQuantityInfo?.min || 0

  return setChannelsCounterValue(defaultQuantity)
}

export function handleChannelsCountConditions(
  planId: string,
  channelsCount: number,
  setChannelsCounterValue: Dispatch<number>,
  lastUpdatedQuantity: number,
): void | null {
  switch (planId) {
    case 'agency':
      return handleAgencyConditions(channelsCount, setChannelsCounterValue)

    case 'free':
      return handleFreeConditions(channelsCount, setChannelsCounterValue)

    default:
      // change the counter to the last updated quantity number before choosing the agency plan
      return setChannelsCounterValue(lastUpdatedQuantity)
  }
}

export function isValidAgencyChannelValue(channelsCount?: number): boolean {
  const planChannelQuantityInfo = getPlanChannelLimits('agency')
  const minimumQuantity = planChannelQuantityInfo?.min || 0

  if (!channelsCount || !minimumQuantity) {
    console.warn(
      'Warning - isLessThanAgencyChannelLimit: channelsCount or minimumQuantity is not defined. This may cause errors in the application.',
    )
    return false
  }

  return channelsCount >= minimumQuantity
}

export function handleCounterErrorMesaging(
  planId: string,
  channelCount: number,
  setChannelCountMessageStatus: Dispatch<MessagingReturn | null>,
): void {
  if (channelCount >= maxChannelsAllowedPerPurchaseViaStripe) {
    return setChannelCountMessageStatus(maxChannelQuantityMessageStatus)
  }

  switch (planId) {
    case 'agency':
      return isValidAgencyChannelValue(channelCount)
        ? setChannelCountMessageStatus(null)
        : setChannelCountMessageStatus(agencyPlanLimitMessageStatus)

    default:
      return setChannelCountMessageStatus(null)
  }
}
