import React, { useMemo } from 'react'
import clsx from 'clsx'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { Slottable } from '@radix-ui/react-slot'
import { CheckedState } from '@radix-ui/react-checkbox'

import { Button } from '../Button'
import { ChevronRightIcon, CheckIcon, LoadingIcon } from '../icons'
import useId from '../../helpers/useId'
import usePortalContainer from '../../helpers/usePortalContainer'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { Drawer, useIsInDrawer } from '../Drawer'
import { useControllableState } from '../../hooks/useControllableState'
import { mapKeyboardKey } from '../../helpers/mapKeyboardKey'

import styles from './DropdownMenu.module.css'

/**
 * Dropdown menu root component
 */
type DropdownMenuProps = {
  /**
   * The content of the dropdown menu
   */
  children?: React.ReactNode
  /**
   * Trigger element for the dropdown menu, usually, a button
   * @deprecated Use `DropdownMenu.Trigger` instead
   */
  trigger?: React.ReactNode
  /**
   * When true, keyboard navigation will loop from last
   * item to first, and vice versa.
   * @default false
   * @deprecated Use `DropdownMenu.Content` instead
   */
  loop?: boolean
  /**
   * The side of the trigger where the menu should be opened
   * @default 'bottom'
   * @deprecated Use `DropdownMenu.Content` instead
   */
  side?: 'top' | 'right' | 'bottom' | 'left'
  /**
   * The align of the menu relative to the trigger
   * @default 'end'
   * @deprecated Use `DropdownMenu.Content` instead
   */
  align?: 'start' | 'center' | 'end'
  /**
   * Controlled opened state
   */
  open?: boolean
  /**
   * Uncontrolled opened state
   * @default false
   */
  defaultOpen?: boolean
  /**
   * The modality of the dropdown menu. When `true`, interaction with outside elements
   * will be disabled and only menu content will be visible to screen readers.
   * @default true
   */
  modal?: boolean
  /**
   * Event handler called when the open state changes
   */
  onOpenChange?(open: boolean): void
  /**
   * Classname of the item
   */
  className?: string
}

const useShouldBeDrawer = () => {
  const isMobile = useMediaQuery('(width < 640px)')

  // HACK: in tests, we want to force the dropdown menu to render as menu
  // this simplifies testing as we can focus on most common usecases
  if (process.env.NODE_ENV === 'test') {
    return false
  }

  return isMobile
}

const DropdownMenuContext = React.createContext<{
  open: boolean
  setOpen: (open: boolean) => void
  close: () => void
} | null>(null)

const useDropdownMenu = () => {
  const context = React.useContext(DropdownMenuContext)
  if (!context) {
    throw new Error('useDropdownMenu must be used within a DropdownMenu')
  }
  return context
}

const DropdownMenu = ({
  children,
  open: openProp,
  defaultOpen: defaultOpenProp,
  loop = false,
  side = 'bottom',
  align = 'end',
  modal = true,
  trigger,
  className,
  onOpenChange,
}: DropdownMenuProps) => {
  const shouldBeDrawer = useShouldBeDrawer()
  const [open = false, setOpen] = useControllableState<boolean>({
    prop: openProp,
    defaultProp: defaultOpenProp,
    onChange: onOpenChange,
  })

  const contextValue = useMemo(
    () => ({
      open,
      setOpen,
      close: () => setOpen(false),
    }),
    [open, setOpen],
  )

  if (shouldBeDrawer) {
    return (
      <DropdownMenuContext.Provider value={contextValue}>
        <DropdownMenuPrimitive.Root open={open} onOpenChange={setOpen}>
          <Drawer direction="bottom" open={open} onOpenChange={setOpen}>
            {/* TODO: once trigger property is unused, remove it and render children directly */}
            {trigger ? (
              <DropdownMenuTrigger asChild className={styles.trigger}>
                {trigger}
              </DropdownMenuTrigger>
            ) : null}
            {trigger ? (
              <DropdownMenuContent
                loop={loop}
                side={side}
                align={align}
                className={className}
              >
                {children}
              </DropdownMenuContent>
            ) : (
              children
            )}
          </Drawer>
        </DropdownMenuPrimitive.Root>
      </DropdownMenuContext.Provider>
    )
  }

  return (
    <DropdownMenuContext.Provider value={contextValue}>
      <DropdownMenuPrimitive.Root
        modal={modal}
        open={open}
        onOpenChange={setOpen}
      >
        {/* TODO: once trigger property is unused, remove it and render children directly */}
        {trigger ? (
          <DropdownMenuTrigger asChild className={styles.trigger}>
            {trigger}
          </DropdownMenuTrigger>
        ) : null}
        {trigger ? (
          <DropdownMenuContent
            loop={loop}
            side={side}
            align={align}
            className={className}
          >
            {children}
          </DropdownMenuContent>
        ) : (
          children
        )}
      </DropdownMenuPrimitive.Root>
    </DropdownMenuContext.Provider>
  )
}

DropdownMenu.displayName = 'DropdownMenu'

type DropdownMenuContentProps = {
  /**
   * When true, keyboard navigation will loop from last
   * item to first, and vice versa.
   * @default false
   */
  loop?: boolean
  /**
   * The side of the trigger where the menu should be opened
   * @default 'bottom'
   */
  side?: 'top' | 'right' | 'bottom' | 'left'
  /**
   * The align of the menu relative to the trigger
   * @default 'end'
   */
  align?: 'start' | 'center' | 'end'
  /**
   * Classname of the content
   */
  className?: string
  /**
   * The content of the dropdown menu
   */
  children?: React.ReactNode
}

type DropdownMenuContentElement = React.ElementRef<
  typeof DropdownMenuPrimitive.Content
>

const DropdownMenuContent = React.forwardRef<
  DropdownMenuContentElement,
  DropdownMenuContentProps
>(
  (
    { children, loop = false, side = 'bottom', align = 'end', className },
    forwardedRef,
  ) => {
    const container = usePortalContainer()
    const isInDrawer = useIsInDrawer()

    if (isInDrawer) {
      return (
        <DropdownMenuPrimitive.Content asChild>
          <Drawer.Content
            // HACK: This is a workaround to prevent radix menu animation
            // (defined in inline styles) interfering with drawer animation
            style={{ animation: 'none !important' }}
            className={styles.drawer}
          >
            <Drawer.Body className={clsx(styles.drawerMenu, className)}>
              {children}
            </Drawer.Body>
          </Drawer.Content>
        </DropdownMenuPrimitive.Content>
      )
    }

    return (
      <DropdownMenuPrimitive.Portal container={container}>
        <DropdownMenuPrimitive.Content
          ref={forwardedRef}
          loop={loop}
          side={side}
          align={align}
          sideOffset={4}
          collisionPadding={16}
          className={clsx(styles.menu, className)}
        >
          {children}
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Portal>
    )
  },
)

DropdownMenuContent.displayName = 'DropdownMenu.Content'

const DropdownMenuTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Trigger>
>(({ children, ...props }, forwardedRef) => {
  const isInDrawer = useIsInDrawer()

  if (isInDrawer) {
    return (
      <DropdownMenuPrimitive.Trigger asChild>
        <Drawer.Trigger asChild {...props} ref={forwardedRef}>
          {typeof children === 'string' ? (
            <Button>{children}</Button>
          ) : (
            children
          )}
        </Drawer.Trigger>
      </DropdownMenuPrimitive.Trigger>
    )
  }

  return (
    <DropdownMenuPrimitive.Trigger asChild {...props} ref={forwardedRef}>
      {typeof children === 'string' ? <Button>{children}</Button> : children}
    </DropdownMenuPrimitive.Trigger>
  )
})

DropdownMenuTrigger.displayName = 'DropdownMenu.Trigger'

/**
 * Dropdown menu label
 */
type DropdownMenuLabelProps = React.ComponentPropsWithoutRef<
  typeof DropdownMenuPrimitive.Label
> & {
  /**
   * Classname of the label
   */
  className?: string
  /**
   * Content of the label
   */
  children?: React.ReactNode
}

type DropdownMenuLabelElement = React.ElementRef<
  typeof DropdownMenuPrimitive.Label
>

const DropdownMenuLabel = React.forwardRef<
  DropdownMenuLabelElement,
  DropdownMenuLabelProps
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.Label
      {...props}
      ref={forwardedRef}
      className={clsx(styles.label, className)}
    >
      {children}
    </DropdownMenuPrimitive.Label>
  )
})

DropdownMenuLabel.displayName = 'DropdownMenu.Label'

/**
 * Dropdown menu separator
 */
type DropdownMenuSeparatorProps = React.ComponentPropsWithoutRef<
  typeof DropdownMenuPrimitive.Separator
> & {
  /**
   * Classname of the label
   */
  className?: string
  /**
   * Content of the label
   */
  children?: never
}

type DropdownMenuSeparatorElement = React.ElementRef<
  typeof DropdownMenuPrimitive.Separator
>

const DropdownMenuSeparator = React.forwardRef<
  DropdownMenuSeparatorElement,
  DropdownMenuSeparatorProps
>(({ className, ...props }, forwardedRef) => {
  const isInDrawer = useIsInDrawer()

  if (isInDrawer) {
    return <Drawer.Separator {...props} inset={false} ref={forwardedRef} />
  }

  return (
    <DropdownMenuPrimitive.Separator
      {...props}
      ref={forwardedRef}
      className={clsx(styles.separator, className)}
    />
  )
})

DropdownMenuSeparator.displayName = 'DropdownMenu.Separator'

/**
 * Dropdown menu item
 */
type DropdownMenuItemProps = React.ComponentPropsWithoutRef<
  typeof DropdownMenuPrimitive.Item
> & {
  /**
   * The variant of the menu item
   * @default default
   */
  variant?: 'default' | 'critical'
  /**
   * The shortcut of the menu item
   */
  shortcut?: string
  /**
   * Classname of the item
   */
  className?: string
  /**
   * Content of the menu item
   */
  children?: React.ReactNode
  /**
   * When true, prevents the user from interacting with the item.
   * @default false
   */
  disabled?: boolean
  /**
   * When true, displays a spinner and prevents interaction
   * @default false
   */
  loading?: boolean
  /**
   * When true, the item will be rendered as a child element
   * @default false
   */
  asChild?: boolean
}

type DropdownMenuItemElement = React.ElementRef<
  typeof DropdownMenuPrimitive.Item
>

const DropdownMenuItem = React.forwardRef<
  DropdownMenuItemElement,
  DropdownMenuItemProps
>(
  (
    {
      children,
      className,
      disabled,
      loading = false,
      variant = 'default',
      shortcut,
      onSelect,
      asChild,
      ...props
    },
    forwardedRef,
  ) => {
    let innerChildren = children

    if (!asChild) {
      innerChildren = (
        <>
          {loading && <LoadingIcon />}
          {children}
          {shortcut && (
            <span className={styles.shortcut} aria-hidden>
              {shortcut.split('+').map((key, index) => (
                <kbd key={`${key}-${index}`}>{mapKeyboardKey(key)}</kbd>
              ))}
            </span>
          )}
        </>
      )
    }

    const computedClassName = clsx(
      styles.item,
      variant === 'critical' && styles.critical,
      loading && styles.loading,
      className,
    )

    return (
      <DropdownMenuPrimitive.Item
        {...props}
        disabled={disabled || loading}
        className={computedClassName}
        ref={forwardedRef}
        onSelect={onSelect}
        asChild={asChild}
      >
        {innerChildren}
      </DropdownMenuPrimitive.Item>
    )
  },
)

DropdownMenuItem.displayName = 'DropdownMenu.Item'

/**
 * Dropdown menu checkbox item
 */
type DropdownMenuCheckboxItemProps = {
  /**
   * Classname of the item
   */
  className?: string
  /**
   * Content of the menu item
   */
  children?: React.ReactNode
  /**
   * When true, prevents the user from interacting with the item.
   * @default false
   */
  disabled?: boolean
  /**
   * Controlled state of the checkbox
   */
  checked?: CheckedState
  /**
   * Uncontrolled state of the checkbox
   */
  defaultChecked?: CheckedState
  /**
   * Event handler called when the checked state changes.
   */
  onCheckedChange?: (checked: CheckedState) => void
  /**
   * Event handler called when the user selects an item (via mouse or keyboard)
   */
  onSelect?: () => void
}

type DropdownMenuCheckboxItemElement = React.ElementRef<
  typeof DropdownMenuPrimitive.CheckboxItem
>

const DropdownMenuCheckboxItem = React.forwardRef<
  DropdownMenuCheckboxItemElement,
  DropdownMenuCheckboxItemProps
>(
  (
    {
      children,
      className,
      disabled,
      checked: checkedProp,
      defaultChecked: defaultCheckedProp,
      onCheckedChange,
      onSelect,
      ...props
    },
    forwardedRef,
  ) => {
    const [checked, setChecked] = useControllableState<CheckedState>({
      prop: checkedProp,
      defaultProp: defaultCheckedProp,
      onChange: onCheckedChange,
    })

    return (
      <DropdownMenuPrimitive.CheckboxItem
        {...props}
        disabled={disabled}
        className={clsx(styles.checkboxItem, className)}
        checked={checked}
        onCheckedChange={setChecked}
        ref={forwardedRef}
        onSelect={onSelect}
      >
        <DropdownMenuPrimitive.ItemIndicator className={styles.postfix}>
          <CheckIcon />
        </DropdownMenuPrimitive.ItemIndicator>
        <Slottable>{children}</Slottable>
      </DropdownMenuPrimitive.CheckboxItem>
    )
  },
)

DropdownMenuCheckboxItem.displayName = 'DropdownMenu.CheckboxItem'

/**
 * Dropdown menu sub menu root component
 */
type DropdownMenuSubProps = {
  /**
   * Content of the menu item
   */
  children?: React.ReactNode
  /**
   * Controlled is submenu opened
   */
  open?: boolean
  /**
   * Uncontrolled is submenu opened
   * @default false
   */
  defaultOpen?: boolean
  /**
   * Event handler called when the open state changes
   * @param open
   */
  onOpenChange?(open: boolean): void
}

const DropdownMenuSub = ({
  children,
  open: openProp,
  defaultOpen: defaultOpenProp,
  onOpenChange,
  ...props
}: DropdownMenuSubProps) => {
  const isInDrawer = useIsInDrawer()
  const { open: parentOpen } = useDropdownMenu()
  const [open, setOpen] = useControllableState<boolean>({
    prop: openProp,
    defaultProp: defaultOpenProp,
    onChange: onOpenChange,
  })

  if (isInDrawer) {
    return (
      <DropdownMenuPrimitive.Sub {...props} open={open} onOpenChange={setOpen}>
        <Drawer {...props} open={parentOpen && open} onOpenChange={setOpen}>
          {children}
        </Drawer>
      </DropdownMenuPrimitive.Sub>
    )
  }

  return (
    <DropdownMenuPrimitive.Sub {...props} open={open} onOpenChange={setOpen}>
      {children}
    </DropdownMenuPrimitive.Sub>
  )
}

DropdownMenuSub.displayName = 'DropdownMenu.Sub'

/**
 * Dropdown menu sub menu trigger item
 */
type DropdownMenuSubTriggerProps = {
  /**
   * Content of the menu item
   */
  children?: React.ReactNode
  /**
   * If disabled, submenu won't open
   * @default false
   */
  disabled?: boolean
  /**
   * Classname to set on the trigger
   */
  className?: string
}

type DropdownMenuSubTriggerElement = React.ElementRef<
  typeof DropdownMenuPrimitive.SubTrigger
>

const DropdownMenuSubTrigger = React.forwardRef<
  DropdownMenuSubTriggerElement,
  DropdownMenuSubTriggerProps
>(({ children, className, disabled, ...props }, forwardedRef) => {
  const isInDrawer = useIsInDrawer()

  if (isInDrawer) {
    return (
      <Drawer.Trigger asChild>
        <DropdownMenuItem
          disabled={disabled}
          onSelect={(e) => {
            e.preventDefault()
          }}
        >
          {children}
          <span className={styles.postfix}>
            <ChevronRightIcon />
          </span>
        </DropdownMenuItem>
      </Drawer.Trigger>
    )
  }

  return (
    <DropdownMenuPrimitive.SubTrigger
      className={clsx(styles.subTrigger, className)}
      disabled={disabled}
      {...props}
      ref={forwardedRef}
    >
      {children}
      <span className={styles.postfix}>
        <ChevronRightIcon />
      </span>
    </DropdownMenuPrimitive.SubTrigger>
  )
})

DropdownMenuSubTrigger.displayName = 'DropdownMenu.SubTrigger'

/**
 * Dropdown sub menu
 */
type DropdownMenuSubMenuProps = {
  /**
   * Content of the menu item
   */
  children?: React.ReactNode
  /**
   * Classname to set on the menu
   */
  className?: string
}

type DropdownMenuSubMenuElement = React.ElementRef<
  typeof DropdownMenuPrimitive.SubContent
>

const DropdownMenuSubMenu = React.forwardRef<
  DropdownMenuSubMenuElement,
  DropdownMenuSubMenuProps
>(({ children, className, ...props }, forwardedRef) => {
  const isInDrawer = useIsInDrawer()

  if (isInDrawer) {
    return (
      <Drawer.Content {...props} ref={forwardedRef}>
        <div className={styles.drawerMenu}>{children}</div>
      </Drawer.Content>
    )
  }

  return (
    <DropdownMenuPrimitive.SubContent
      className={clsx(styles.subMenu, className)}
      sideOffset={4}
      collisionPadding={16}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </DropdownMenuPrimitive.SubContent>
  )
})

DropdownMenuSubMenu.displayName = 'DropdownMenu.SubMenu'

/**
 * Dropdown menu group of items
 */
export type DropdownMenuGroupProps = {
  /**
   * Content of the menu item
   */
  children?: React.ReactNode
  /**
   * Visible label of the group
   */
  label?: string
  /**
   * Accessible label of the group
   * Use, if the group does not have a visible label
   */
  'aria-label'?: string
  /**
   * Classname to set on the menu
   */
  className?: string
}

type DropdownMenuGroupElement = React.ElementRef<
  typeof DropdownMenuPrimitive.Group
>

const DropdownMenuGroup = React.forwardRef<
  DropdownMenuGroupElement,
  DropdownMenuGroupProps
>(({ children, className, label, ...props }, forwardedRef) => {
  const labelId = useId()

  // if no items, group does not render
  if (!children) {
    return null
  }

  return (
    <DropdownMenuPrimitive.Group
      className={clsx(styles.group, className)}
      aria-labelledby={label ? labelId : undefined}
      {...props}
      ref={forwardedRef}
    >
      {label && <DropdownMenuLabel id={labelId}>{label}</DropdownMenuLabel>}
      {children}
    </DropdownMenuPrimitive.Group>
  )
})

DropdownMenuGroup.displayName = 'DropdownMenu.Group'

/**
 * Dropdown radio group of items
 */
type DropdownMenuRadioGroupProps = {
  /**
   * Content of the menu item
   */
  children?: React.ReactNode
  /**
   * Visible label of the group
   */
  label?: string
  /**
   * The value of the selected item in the group.
   */
  value?: string
  /**
   * Event handler called when the value changes.
   */
  onChange?: (value: string) => void
  /**
   * Classname to set on the menu
   */
  className?: string
}

type DropdownMenuRadioGroupElement = React.ElementRef<
  typeof DropdownMenuPrimitive.RadioGroup
>

const DropdownMenuRadioGroup = React.forwardRef<
  DropdownMenuRadioGroupElement,
  DropdownMenuRadioGroupProps
>(({ children, className, label, value, onChange, ...props }, forwardedRef) => {
  const labelId = useId()

  return (
    <DropdownMenuPrimitive.RadioGroup
      className={clsx(styles.radioGroup, className)}
      aria-labelledby={label ? labelId : undefined}
      value={value}
      onValueChange={onChange}
      {...props}
      ref={forwardedRef}
    >
      {label && <DropdownMenuLabel id={labelId}>{label}</DropdownMenuLabel>}
      {children}
    </DropdownMenuPrimitive.RadioGroup>
  )
})

DropdownMenuRadioGroup.displayName = 'DropdownMenu.RadioGroup'

/**
 * Dropdown radio item
 */
type DropdownMenuRadioItemProps = {
  /**
   * Label of the radio item
   */
  children?: React.ReactNode
  /**
   * Classname to set on the menu
   */
  className?: string
  /**
   * Unique value associated with an item
   */
  value: string
  /**
   * When true, prevents the user from selecting an item
   * @default false
   */
  disabled?: boolean
  /**
   * Fires when the user selects an item (via mouse or keyboard)
   */
  onSelect?: () => void
}

type DropdownMenuRadioItemElement = React.ElementRef<
  typeof DropdownMenuPrimitive.RadioItem
>

const DropdownMenuRadioItem = React.forwardRef<
  DropdownMenuRadioItemElement,
  DropdownMenuRadioItemProps
>(({ children, className, value, onSelect, ...props }, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.RadioItem
      className={clsx(styles.radioItem, className)}
      value={value}
      onSelect={onSelect}
      {...props}
      ref={forwardedRef}
    >
      <DropdownMenuPrimitive.ItemIndicator className={styles.postfix}>
        <CheckIcon />
      </DropdownMenuPrimitive.ItemIndicator>
      <Slottable>{children}</Slottable>
    </DropdownMenuPrimitive.RadioItem>
  )
})

DropdownMenuRadioItem.displayName = 'DropdownMenu.RadioItem'

const DropdownMenuObject = Object.assign(DropdownMenu, {
  Trigger: DropdownMenuTrigger,
  Content: DropdownMenuContent,
  Item: DropdownMenuItem,
  CheckboxItem: DropdownMenuCheckboxItem,
  Label: DropdownMenuLabel,
  Separator: DropdownMenuSeparator,
  Sub: DropdownMenuSub,
  SubTrigger: DropdownMenuSubTrigger,
  SubMenu: DropdownMenuSubMenu,
  Group: DropdownMenuGroup,
  RadioGroup: DropdownMenuRadioGroup,
  RadioItem: DropdownMenuRadioItem,
})

export { DropdownMenuObject as DropdownMenu, useDropdownMenu }
export type { DropdownMenuProps, DropdownMenuItemProps }
