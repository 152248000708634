import React, { useEffect, useState } from 'react'
import Button from '@bufferapp/ui/Button'

import useStartTrial from '../../../common/hooks/useStartTrial'
import { getSuggestedPlan } from '../../../common/utils/getSuggestedPlan'
import { useUser } from '../../../common/context/User'
import { MODALS } from '../../../common/types'
import { Error } from '../../../common/components/Error'
import { prefixWithProduct } from '../../../common/utils/prefixWithProduct'
import UpgradePathsTracking from '../../../tracking/UpgradePaths'
import { useModalManager } from '../../../components/ModalManager/hooks/useModalManager'

type Options = {
  cta: string
  buttonType?: string
  label?: string
  upgradePathName: string
}

export const StartTrialButton = React.memo(
  ({ options }: { options: Options }) => {
    const [modalOpened, setModalOpened] = useState(false)
    const user = useUser()
    const suggestedPlan = getSuggestedPlan(user)
    const { cta, buttonType, upgradePathName, label } = options || {}

    const buttonTypeSetting = buttonType || 'primary'
    const buttonText = label || 'Start a 14-day Free Trial'

    const { startTrial, trial, error, processing } = useStartTrial({
      user,
      plan: suggestedPlan,
      attribution: { cta: prefixWithProduct(cta), upgradePathName },
    })

    const { trackCTAViewed } = UpgradePathsTracking(user)
    const { openModal } = useModalManager()

    useEffect(() => {
      /* track the button being displayed */
      trackCTAViewed({
        ctaView: 'orchestrator',
        ctaLocation: 'orchestrator',
        ctaButton: 'startTrial',
        upgradePathName,
      })
    }, [trackCTAViewed, upgradePathName])

    useEffect(() => {
      if (trial && trial.billingStartTrial.success && !modalOpened) {
        openModal({
          key: MODALS.success,
          data: {
            startedTrial: true,
            selectedPlan: suggestedPlan,
          },
        })
        setModalOpened(true)
      }
    }, [trial])

    return (
      <>
        <Button
          type={buttonTypeSetting}
          disabled={!suggestedPlan || processing}
          onClick={(): void => {
            startTrial()
          }}
          label={processing ? 'Processing ...' : buttonText}
        />
        {error && <Error error={error} />}
      </>
    )
  },
)
