import styled from 'styled-components'
import Button from '@bufferapp/ui/Button'
import { orangeDark, orangeDarker } from '@bufferapp/ui/style/colors'

export const StyledButton = styled(Button)`
  color: ${orangeDark};
  text-decoration: underline;

  &:hover {
    color: ${orangeDarker};
  }
`
