import React from 'react'

import Text from '@bufferapp/ui/Text'
import Button from '@bufferapp/ui/Button'

import { Container } from './FreePlanSection.style'

const FreePlanSection = (props: any) => {
  const { ctaAction } = props

  return (
    <Container id="free_plan_section">
      <Text htmlFor="foo" type="help">
        Looking for basic publishing tools?{''}{' '}
        <Button
          id="try_free_plan"
          type="text"
          onClick={() => ctaAction()}
          aria-label="Downgrade to our Free plan"
          label="Downgrade to our Free plan"
        />
      </Text>
    </Container>
  )
}

export default FreePlanSection
