import { useUser } from '../../../common/context/User'
import type { Channel } from '../../../common/types'
import {
  getUserLockedChannels,
  getUserUnlockedChannels,
} from '../../../common/utils/user'
import { useModalManager } from '../../ModalManager/hooks/useModalManager'

type UseChannelsReturn = {
  channelIds: string[]
  lockedChannelIds: string[]
  connectedChannelIds: string[]
  lockedChannels: Channel[]
  unlockedChannels: Channel[]
  connectedChannels: Channel[]
}

const useChannels = (): UseChannelsReturn => {
  // Gather data
  const user = useUser()
  const { modalData: data } = useModalManager()
  const channelIds = data?.channelIds || []

  // Calculate information:
  // Get the list of channelIds that are blocked after connecting new channels
  // You can have available slots but the new channels will be blocked if you have reached the max limit
  // We need to increase the subscriptio by the remaining channels that are blocked
  const lockedChannels = getUserLockedChannels(user)
  const unlockedChannels = getUserUnlockedChannels(user).filter((channel) =>
    channelIds.includes(channel.id),
  )
  const lockedChannelIds = lockedChannels
    .map((channel) => channel.id)
    .filter((channelId) => channelIds.includes(channelId))
  const connectedChannelIds = channelIds.filter(
    (channelId) => !lockedChannelIds.includes(channelId),
  )
  const connectedChannels = unlockedChannels.filter((channel) =>
    connectedChannelIds.includes(channel.id),
  )

  return {
    channelIds,
    lockedChannelIds,
    connectedChannelIds,
    lockedChannels,
    unlockedChannels,
    connectedChannels,
  }
}

export default useChannels
