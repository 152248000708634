import eventDispatcher from '../utils/eventDispatcher'
import { AppshellEventKeys, OrganizationActionKeys } from './types'

export const ORGANIZATION_ACTIONS = {
  setCurrentOrganization(organizationId: string, options = {}): void {
    eventDispatcher(AppshellEventKeys.ORGANIZATION_EVENT_KEY, {
      action: OrganizationActionKeys.setCurrentOrganization,
      organizationId,
      options,
    })
  },
  billingUpdated(options = {}): void {
    eventDispatcher(AppshellEventKeys.ORGANIZATION_EVENT_KEY, {
      action: OrganizationActionKeys.billingUpdated,
      options,
    })
  },
}

export type ORGANIZATION_ACTIONS_TYPE = typeof ORGANIZATION_ACTIONS
