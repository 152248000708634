import React from 'react'
import {
  Button,
  Dialog,
  Flex,
  ChannelIcon,
  Text,
  CheckIcon,
  ScrollArea,
} from '@buffer-mono/popcorn'
import type { ConnectableService, Service } from '../../../../../gql/graphql'

import { Footer } from './components/Footer/Footer'

import styles from './ServiceDetails.module.css'
import Tags from './components/Tags/Tags'
import SocialProofCallout from './components/SocialProofCallout/SocialProofCallout'

function ServiceDetails({
  connectableService,
  handleConnectService,
}: {
  connectableService: ConnectableService
  handleConnectService: (service: Service) => void
}): JSX.Element {
  return (
    <>
      <div className={styles.container}>
        <ScrollArea>
          <div className={styles.wrapper}>
            <Dialog.Header
              className={styles.header}
              // ref={(newRef: HTMLDivElement): void => setHeaderElement(newRef)}
              // className={styles.header}
            >
              <Dialog.Title className={styles.title}>
                <ChannelIcon
                  className={styles.channelIcon}
                  color="inverted"
                  type={connectableService.service}
                  size="medium"
                />
                <Flex direction="column">
                  <Text as="h1" size="md" weight="bold">
                    {connectableService.title}
                  </Text>
                  <Text weight="regular" color="subtle">
                    {connectableService.subtitle}
                  </Text>
                </Flex>
              </Dialog.Title>
            </Dialog.Header>
            <Flex direction="column" gap="sm" className={styles.why}>
              <Text weight="medium">Why {connectableService.title}</Text>
              {connectableService.description.map(
                (text: string, index: number) => {
                  return (
                    <Flex
                      className={styles.listItem}
                      key={`description-${index}`}
                    >
                      <CheckIcon color="brand" size="small" />{' '}
                      <Text>{text}</Text>
                    </Flex>
                  )
                },
              )}
            </Flex>
            {connectableService.socialProof && (
              <SocialProofCallout text={connectableService.socialProof} />
            )}
            <div className={styles.tags}>
              <Flex direction="column" gap="xs">
                <Text as="label" weight="medium">
                  Supported Features
                </Text>
                <Tags items={connectableService.contentTypes} />
              </Flex>
              {connectableService.similarNetworks && (
                <Flex direction="column" gap="xs">
                  <Text as="label" weight="medium">
                    {connectableService.title} works well with
                  </Text>
                  <Flex gap="2xs" wrap="wrap">
                    <Tags items={connectableService.similarNetworks} />
                  </Flex>
                </Flex>
              )}
            </div>
          </div>
        </ScrollArea>
      </div>
      <Footer links={connectableService.links || []}>
        <Button
          size="large"
          onClick={(): void => handleConnectService(connectableService.service)}
        >
          Connect {connectableService.title}
        </Button>
      </Footer>
    </>
  )
}

export default ServiceDetails
