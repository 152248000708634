import React from 'react'

import {
  Button,
  ChevronDownIcon,
  Dialog,
  DropdownMenu,
  Flex,
} from '@buffer-mono/popcorn'

import type { HelpCenterLink } from '../../../../../../../gql/graphql'

import styles from './Footer.module.css'

export function Footer({
  children,
  links,
}: {
  children?: React.ReactNode
  links: HelpCenterLink[]
}): JSX.Element {
  return (
    <Flex direction="column" align="baseline" className={styles.wrapper}>
      <Dialog.Separator />
      <Dialog.Footer className={styles.footer}>
        {links.length > 0 && (
          <DropdownMenu
            align="start"
            className={styles.menu}
            trigger={
              <Button
                variant="tertiary"
                size="large"
                className={styles.helpButton}
              >
                Help
                <ChevronDownIcon />
              </Button>
            }
          >
            {links.map((link) => (
              <DropdownMenu.Item asChild key={link.text}>
                <a href={link.url}>{link.text}</a>
              </DropdownMenu.Item>
            ))}
          </DropdownMenu>
        )}
        {children}
      </Dialog.Footer>
    </Flex>
  )
}
