import React from 'react'

export const PlanContextDefaultValue = {
  selectedPlanId: null,
  selectedPlanName: null,
  selectedPlanMinimumQuantity: null,
  currentPlanChannelQuantity: null,
} as SelectedPlan

export type SelectedPlan = {
  selectedPlanId: string | null
  selectedPlanName: string | null
  selectedPlanMinimumQuantity: number | null
  currentPlanChannelQuantity: number | null
}

export const PlanContext = React.createContext(PlanContextDefaultValue)

export function usePlan(): SelectedPlan {
  const plan = React.useContext(PlanContext)
  if (plan === undefined) {
    throw new Error('usePlan must be used within a PlanContext.Provider')
  }

  return plan
}
