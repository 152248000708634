import { useEffect, useMemo } from 'react'
import { Uploader, type UploaderEvents } from './Uploader'

import {
  defaultRestrictions,
  type UploaderRestrictions,
} from './values/UploaderRestrictions'

import { KnownBuckets, useS3Signature } from './getS3Signature'
import { createUppy } from './createUppy'
import { addUploaderTracking } from './addUploaderTracking'

/**
 * Returns an uploader instance for the given id with
 * uploader events bound to the redux store.
 * It also returns the current state of the uploader
 * and some callbacks.
 */
export const useUploader = ({
  id,
  bucket,
  fileRestrictions = defaultRestrictions,
  eventHandlers,
  getCustomCount,
  userId,
  organizationId,
}: {
  id: string
  bucket?: KnownBuckets
  fileRestrictions?: UploaderRestrictions
  eventHandlers?: Partial<UploaderEvents>
  getCustomCount?: () => number
  userId: string
  organizationId: string
}): Uploader => {
  const getS3Signature = useS3Signature(bucket)

  const uploader = useMemo(() => {
    const uppyInstance = createUppy({
      id,
      userId,
      getS3Signature,
    })

    const uploaderInstance = new Uploader(
      id,
      organizationId,
      uppyInstance,
      fileRestrictions,
      getCustomCount,
    )

    if (eventHandlers) {
      Object.keys(eventHandlers).forEach((eventName) => {
        const handler = eventHandlers[eventName as keyof UploaderEvents]
        if (handler) {
          uploaderInstance.on(eventName as keyof UploaderEvents, handler)
        }
      })
    }

    addUploaderTracking(uploaderInstance)

    return uploaderInstance
  }, [
    id,
    userId,
    organizationId,
    fileRestrictions,
    getS3Signature,
    getCustomCount,
    eventHandlers,
  ])

  useEffect(() => {
    return () => {
      uploader.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- close uploader on unmount
  }, [])

  return uploader
}
