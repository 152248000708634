import React from 'react'
import clsx from 'clsx'

import { Flex, NewBadge, Text, UnstyledButton } from '@buffer-mono/popcorn'

import { StartPageLink } from './StartPageLink'
import {
  getUrlEnvModifier,
  getPortForProduct,
} from '../../../../common/utils/urls'

import type { Account, Organization } from '../../../../common/types'
import { shouldTrackNavCTA } from './utils/shouldTrackNavCTA'
import UpgradePathsTracking from '../../../../tracking/UpgradePaths'
import { useProductHistory } from '../../../../exports/Navigator/utils/useProductHistory'
import UnreadEngageCommentsCounter from '../UnreadEngageCommentsCounter/UnreadEngageCommentsCounter'

import styles from '../..//NavBar.module.css'

export enum ProductNames {
  PUBLISH = 'publish',
  ANALYZE = 'analyze',
  ENGAGE = 'engage',
  START_PAGE = 'start-page',
  CREATE = 'create',
  ACCOUNT = 'account',
  NEWSETTINGS = 'new-settings',
}

type ProductType = {
  id: ProductNames
  label: string
  isNew: boolean
  subdomain: string
  pathname?: string
  activeOnMatch?: RegExp
}

export const Products: Record<ProductNames, ProductType> = {
  [ProductNames.CREATE]: {
    id: ProductNames.CREATE,
    label: 'Create',
    isNew: false,
    subdomain: 'publish',
    pathname: 'create',
    activeOnMatch: /(content|create)/,
  },
  [ProductNames.PUBLISH]: {
    id: ProductNames.PUBLISH,
    label: 'Publish',
    isNew: false,
    subdomain: 'publish',
    pathname: '',
  },
  [ProductNames.ANALYZE]: {
    id: ProductNames.ANALYZE,
    label: 'Analyze',
    isNew: false,
    subdomain: 'analyze',
    pathname: '',
  },
  [ProductNames.ENGAGE]: {
    id: ProductNames.ENGAGE,
    label: 'Engage',
    isNew: false,
    subdomain: 'engage',
    pathname: '',
  },
  [ProductNames.START_PAGE]: {
    id: ProductNames.START_PAGE,
    label: 'Start Page',
    isNew: false,
    subdomain: 'start-page',
    pathname: '',
  },
  [ProductNames.ACCOUNT]: {
    id: ProductNames.ACCOUNT,
    label: 'Account',
    isNew: false,
    subdomain: 'account',
    pathname: '',
  },
  [ProductNames.NEWSETTINGS]: {
    id: ProductNames.NEWSETTINGS,
    label: 'Settings',
    isNew: false,
    subdomain: 'publish',
    pathname: 'settings',
  },
}

type NavBarProductsProps = {
  activeProduct: ProductNames | null
  currentOrganization: Organization | undefined
  user: Account
  canStartTrial: boolean
  isFreeUser: boolean
}

const NavBarProducts = ({
  activeProduct,
  currentOrganization,
  user,
  canStartTrial,
  isFreeUser,
}: NavBarProductsProps): JSX.Element => {
  const { trackCTAClicked } = UpgradePathsTracking(user)
  const { history } = useProductHistory()
  const showStartPage = currentOrganization?.role === 'admin'
  const environmentModifier = getUrlEnvModifier()

  // Wait until we have currentOrganization?.id to avoid flickering links while loading
  const products = currentOrganization?.id
    ? [
        Products[ProductNames.CREATE],
        Products[ProductNames.PUBLISH],
        Products[ProductNames.ANALYZE],
        Products[ProductNames.ENGAGE],
        ...(showStartPage ? [Products[ProductNames.START_PAGE]] : []),
      ]
    : []

  const navigate = (href: string): void => {
    const url = new URL(href)
    const origin = url.origin
    const pathname = url.pathname

    if (history && window.location.origin === origin) {
      try {
        return history?.push(pathname)
      } catch (error) {
        // Do nothing, will fallback to window.location.assign
      }
    }
    window.location.assign(href)
  }

  const handleTrackProductClick = (id: ProductNames): void => {
    if (
      shouldTrackNavCTA({
        isFreeUser,
        activeProductId: activeProduct ?? undefined,
        currentProductId: id,
      })
    ) {
      trackCTAClicked({
        ctaView: 'mainNav',
        ctaLocation: 'mainNav',
        ctaButton: id,
        upgradePathName: `${id}-${canStartTrial ? 'trial' : 'upgrade'}`,
      })
    }
  }

  return (
    <Flex align="center" className={styles.navBarLeft}>
      {products.map(({ id, label, isNew, subdomain, pathname }) => {
        const origin = `https://${subdomain}.${environmentModifier}buffer.com${getPortForProduct(
          id,
        )}`
        const url = `${origin}/${pathname ?? ''}`
        const isActiveProduct = activeProduct === id
        return (
          <UnstyledButton
            as="button"
            className={clsx(
              styles.productLink,
              isActiveProduct && styles.activeProduct,
            )}
            key={id}
            id={`product-${id}`}
            onClick={(): void => {
              handleTrackProductClick(id)
              navigate(url)
            }}
          >
            {id === ProductNames.START_PAGE ? (
              <StartPageLink />
            ) : (
              <Text as="span" size="md" weight="bold">
                {label}
              </Text>
            )}
            {isNew && <NewBadge className={styles.newBadge} />}
            {id === ProductNames.ENGAGE && <UnreadEngageCommentsCounter />}
          </UnstyledButton>
        )
      })}
    </Flex>
  )
}

export default NavBarProducts
