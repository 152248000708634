import { useState } from 'react'
import { freePlan } from '../../../../../common/mocks/freePlan'

const useSelectedPlan = (planOptions: any, defaultSelectedPlan: any) => {
  const [selectedPlan, setSelectedPlan] = useState(defaultSelectedPlan)

  const updateSelectedPlan = (planString: string) => {
    const [newSelectedPlanId, newSelectedPlanInterval] = planString.split('_')
    const newPlan = planOptions.find(
      (option: any) =>
        newSelectedPlanId === option.planId &&
        newSelectedPlanInterval === option.planInterval,
    )
    if (!newPlan) {
      setSelectedPlan(freePlan)
    } else setSelectedPlan(newPlan)
  }

  return {
    selectedPlan,
    updateSelectedPlan,
  }
}

export default useSelectedPlan
