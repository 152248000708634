import {
  BufferTrackerReact as BufferTracker,
  Client,
} from '@buffer-mono/tracking-plan'

import { shouldPerformTrackingEvent } from '../utils'
import { getActiveProductFromPath } from '../../common/utils/getProduct'
import type { Account } from '../../common/types'
import type { Service } from '../../gql/graphql'
import { getUsersCurrentPlan } from '../../common/utils/user'

export type TrackChannelStorefrontMoreDetailsClickedPayload = {
  cta?: string
  service: Service
}

type TrackChannelStorefrontMoreDetailsClickedArguments = {
  payload: TrackChannelStorefrontMoreDetailsClickedPayload
  user: Account
}

export default function trackChannelStorefrontMoreDetailsClicked({
  payload,
  user,
}: TrackChannelStorefrontMoreDetailsClickedArguments): void {
  const canPerformTrackingEvent = shouldPerformTrackingEvent({
    user,
  })

  if (!canPerformTrackingEvent) {
    return
  }

  const { cta, service } = payload

  const organization = user.currentOrganization
  const currentPlanData = getUsersCurrentPlan(user)
  const product = getActiveProductFromPath()

  const eventData = {
    organizationId: organization?.id || '',
    currentPlan: currentPlanData?.id || '',
    cta,
    clientName: Client.PublishWeb,
    product,
    service,
  }

  BufferTracker.channelStorefrontMoreDetailsClicked(eventData)
}
