import React, { useEffect, useState } from 'react'

import { Input, Text, Button } from '@bufferapp/ui'

import {
  Wrapper,
  ModalContent,
  ModalFooter,
  Title,
  Form,
  StyledReason,
  StyledRadio,
  CheckMark,
} from './styles'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { useUser } from '../../../../common/context/User'
import { MODALS } from '../../../../common/types'
import { useModalManager } from '../../../ModalManager/hooks/useModalManager'

interface ReasonProps {
  children: React.ReactNode
  value: string
  onChange: (ev: any) => void
}

const Reason = ({ children, value, onChange }: ReasonProps): JSX.Element => (
  <StyledReason>
    <Text type="label" htmlFor={`churnOption${value}`}>
      <StyledRadio
        onChange={onChange}
        id={`churnOption${value}`}
        name="churnOptions"
        value={value}
      />
      <CheckMark /> {children}
    </Text>
  </StyledReason>
)

const ChurnSurveyModal = (): JSX.Element => {
  const [reason, setReason] = useState('')
  const [details, setDetails] = useState('')
  const [isValid, setIsValid] = useState(false)

  const {
    modalData: data,
    openModal,
    dismissModal: closeAction,
  } = useModalManager()

  const isOnTrial = data?.isOnTrial

  const onReasonChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    setReason(ev.target.value)
    setIsValid(true)
  }

  const onDetailsChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    setDetails(ev.target.value)
  }

  const account = useUser()
  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null
  const organizationId = account?.currentOrganization?.id || ''
  useEffect(() => {
    if (account?.currentOrganization) {
      BufferTracker.churnSurveyViewed({
        organizationId,
        ...commonTrackingProperties,
      })
    }
  }, [account?.currentOrganization, organizationId, commonTrackingProperties])

  return (
    <Wrapper>
      <Title>
        <h3>We are sad to see you go!</h3>
      </Title>
      <ModalContent>
        <Text type="p">
          We would love to understand why so we can make Buffer better.
          <br />
          <b>Would you mind sharing the reason with us?</b>
          <br />
          <br />
          <Text type="label">I’m leaving because:</Text>
        </Text>
        <Form>
          <fieldset>
            <Reason
              onChange={(ev): void => {
                onReasonChange(ev)
              }}
              value={'extenuating-circumstances'}
            >
              Extenuating Circumstances (e.g. COVID-19, illness, budget cuts,
              etc.) <br />{' '}
            </Reason>
            <Reason
              onChange={(ev): void => {
                onReasonChange(ev)
              }}
              value={'not-using-anymore'}
            >
              I don&apos;t use the product anymore <br />{' '}
            </Reason>
            <Reason
              onChange={(ev): void => {
                onReasonChange(ev)
              }}
              value={'too-expensive'}
            >
              Too expensive <br />
            </Reason>
            <Reason
              onChange={(ev): void => {
                onReasonChange(ev)
              }}
              value={'missing-features'}
            >
              Missing features <br />
            </Reason>
            <Reason
              onChange={(ev): void => {
                onReasonChange(ev)
              }}
              value={'bugs'}
            >
              Bugs or usability issues <br />
            </Reason>
            <Reason
              onChange={(ev): void => {
                onReasonChange(ev)
              }}
              value={'others'}
            >
              Other <br />
            </Reason>
          </fieldset>
          <Input
            // @ts-expect-error TS(2322) FIXME: Type '{ onChange: (ev: any) => void; disabled: boo... Remove this comment to see the full error message
            onChange={(ev): void => {
              onDetailsChange(ev)
            }}
            disabled={!isValid}
            name="details"
            label="Any other details:"
          />
        </Form>
      </ModalContent>
      <ModalFooter>
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
        <Button
          type="text"
          label="No, Do Not Cancel"
          onClick={(): void => {
            BufferTracker.churnSurveyCTAClicked({
              organizationId,
              ctaName: 'doNotCancel_churnSurvey',
              ...commonTrackingProperties,
            })
            closeAction()
          }}
        />
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
        <Button
          type="primary"
          label="Continue"
          onClick={(): void => {
            closeAction()
            openModal({
              key: MODALS.confirmCancellation,
              data: {
                subscriptionType: isOnTrial ? 'trial' : 'plan',
              },
            })
            BufferTracker.churnSurveyCTAClicked({
              organizationId,
              ctaName: 'continue_churnSurvey',
              ...commonTrackingProperties,
            })
            BufferTracker.churnSurveyCompleted({
              organizationId,
              product: 'buffer',
              reason: `${reason}`,
              wasOnTrial: isOnTrial,
              details,
              ...commonTrackingProperties,
            })
          }}
          disabled={!isValid}
        />
      </ModalFooter>
    </Wrapper>
  )
}

export default ChurnSurveyModal
