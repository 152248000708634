import gql from 'graphql-tag'

export const QUERY_START_PAGES = gql`
  query startPages($organizationId: String!) {
    startPages(organizationId: $organizationId) {
      ... on StartPages {
        startPages {
          id
          domain
          organizationId
          isPublished
          title
          migrationAsAChannel {
            success
            wasPublished
            wasBilling
            wasMobile
            wasTrialist
            wasAtLimit
          }
        }
      }
      ... on CommonError {
        code
        success
      }
    }
  }
`
