import React from 'react'

import Banner from '../../../../components/Banner'

const TwitterIsDownBanner = (): JSX.Element => {
  return (
    <Banner
      // @ts-expect-error TS(2322) FIXME: Type '{ themeColor: string; text: string; dismissi... Remove this comment to see the full error message
      themeColor="orange"
      text="Due to ongoing changes at Twitter, some of Buffer’s Twitter features (including scheduled posts) may not work."
      dismissible={false}
      actionButton={{
        label: 'Learn More',
        action: (): void => {
          // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
          window.location = 'https://buffer.com/resources/twitter-api-changes/'
        },
      }}
    />
  )
}

export default TwitterIsDownBanner
