import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import type { PlanSelectorViewedTrackingArguments } from './PlanSelectorTrackingTypes'

import { formatCTAString } from '../../common/hooks/useSegmentTracking'
import {
  hasPaymentDetails,
  getOrganization,
  getOrganizationId,
} from '../../common/utils/user'
import { getActiveProductFromPath } from '../../common/utils/getProduct'
import { prefixWithProduct } from '../../common/utils/prefixWithProduct'
import { shouldPerformTrackingEvent } from '../utils'

/**
 * Tracking event fired when a user opens and views the PlanSelector
 */
export default function trackPlanSelectorViewed({
  payload,
  user,
}: PlanSelectorViewedTrackingArguments): void {
  const canPerformTrackingEvent = shouldPerformTrackingEvent({
    user,
  })

  if (!canPerformTrackingEvent) {
    return
  }

  const organization = getOrganization(user)
  const commonTrackingProperties =
    organization?.commonTrackingProperties || null
  const currentPlanFormatted: string = formatCTAString(
    `${payload.currentPlanId} ${payload.currentPlanInterval}`,
  )

  const product = getActiveProductFromPath()
  const eventData = {
    organizationId: getOrganizationId(user) || null,
    ctaApp: product,
    ctaVersion: '1',
    currentPlan: currentPlanFormatted,
    ctaLocation: 'appShell',
    screenName: payload.screenName, // Human readable name of the section of the plan selector viewed (e.g., ""plans"", ""billing_info"", etc.)
    ctaButton: payload.ctaButton, // What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`,
    initialChannelQuantity: payload.initialChannelQuantity, // The number of channels the user has selected when they first view the plan selector
    initialCycle: payload.initialCycle, // The billing cycle the user has selected when they first view the plan selector
    initialSelectedPlan: payload.initialSelectedPlan, // The plan the user has selected when they first view the plan selector
    ...commonTrackingProperties,
    hasPaymentDetails: hasPaymentDetails(user) || null,
    cta: prefixWithProduct(payload.cta || ''),
    isStripeCheckout: payload.isStripeCheckout,
    isAutomaticTaxEnabled: payload.isAutomaticTaxEnabled,
    upgradePathName: payload.upgradePathName || null,
  }

  BufferTracker.planSelectorViewed(eventData)
}
