import React from 'react'
import Text from '@bufferapp/ui/Text'
import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'
import Tag from '@bufferapp/ui/Tag'

import {
  Wrapper,
  CardContainer,
  CardHeader,
  CardFooter,
  Price,
  BenefitList,
  Benefit,
  Check,
  TopSection,
} from '../style'

const ENTER_KEY = 13
const SPACE_KEY = 32

const PlanName = ({ planName, planId }: any) => {
  if (planId === 'agency') {
    return (
      <>
        <Text type="h3">{planName}</Text>
        <Tag id="new-tag" color="green">
          New
        </Tag>
      </>
    )
  }
  return <Text type="h3">{planName}</Text>
}

const Card = ({
  planId,
  planName,
  planInterval,
  description,
  highlightsHeading,
  highlights,
  currency,
  basePrice,
  priceNote,
  updateSelectedPlan,
  selectedPlan,
  isMobileBillingUser,
  trackPlanClicked,
  channelsCount,
}: any) => {
  const isSelectedPlan = selectedPlan === planId
  const shouldShowSelectionUX = isMobileBillingUser ? isSelectedPlan : true
  return (
    <Wrapper
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      tabIndex="0"
      onClick={(e) => {
        if (!isMobileBillingUser) {
          updateSelectedPlan(e.currentTarget.id)
          trackPlanClicked({
            selectedPlanName: planName,
            channelQuantity: channelsCount,
          })
        }
      }}
      onKeyDown={(e) => {
        if (e.keyCode === ENTER_KEY || e.keyCode === SPACE_KEY)
          if (!isMobileBillingUser) {
            updateSelectedPlan(e.currentTarget.id)
            trackPlanClicked({
              selectedPlanName: planName,
              channelQuantity: channelsCount,
            })
          }
      }}
      id={`${planId}_${planInterval}`}
      isSelectedPlan={isSelectedPlan}
      aria-label={isSelectedPlan ? 'checked' : 'unchecked'}
      isMobileBillingUser={isMobileBillingUser}
    >
      <TopSection>
        <CardHeader>
          <PlanName planName={planName} planId={planId} />
          {shouldShowSelectionUX && (
            <Check
              isSelectedPlan={isSelectedPlan}
              role="radio"
              aria-label={planName}
            >
              <CheckmarkIcon size="medium" />
            </Check>
          )}
        </CardHeader>
        <Text type="p">{description}</Text>
      </TopSection>
      <CardFooter>
        <Price>
          <sup>{currency}</sup>
          <Text type="h2" as="p">
            {basePrice}
          </Text>
        </Price>
        <Text type="label" color="grayDark">
          {priceNote}
        </Text>
        <BenefitList>
          <Text type="h3">{highlightsHeading}</Text>
          <ul>
            {highlights.map((benefit: any) => (
              <Benefit key={benefit}>
                <CheckmarkIcon size="smedium" />
                <Text type="p">{benefit}</Text>
              </Benefit>
            ))}
          </ul>
        </BenefitList>
      </CardFooter>
    </Wrapper>
  )
}

export const SelectionScreen = ({
  planOptions,
  selectedPlan,
  updateSelectedPlan,
  monthlyBilling,
  isMobileBillingUser,
  trackPlanClicked,
  channelsCount,
}: any) => (
  <CardContainer>
    {planOptions
      .filter((option: any) => {
        if (monthlyBilling) {
          return option.planInterval === 'month'
        }
        return option.planInterval === 'year'
      })
      .map((option: any) => (
        <Card
          planId={option.planId}
          planName={option.planName}
          planInterval={option.planInterval}
          description={option.description}
          highlightsHeading={option.highlightsHeading}
          highlights={option.highlights}
          currency={option.currency}
          basePrice={option.basePrice}
          priceNote={option.priceNote}
          summary={option.summary}
          isCurrentPlan={option.isCurrentPlan}
          key={`${option.planId}_${option.planInterval}`}
          updateSelectedPlan={updateSelectedPlan}
          selectedPlan={selectedPlan.planId}
          recommended={false}
          isMobileBillingUser={isMobileBillingUser}
          trackPlanClicked={trackPlanClicked}
          channelsCount={channelsCount}
        />
      ))}
  </CardContainer>
)
