import {
  type Account,
  type Organization,
  type MPSubscription,
  BillingState,
} from '../../types'

export function isMultiProductCustomer({
  currentOrganization,
}: {
  currentOrganization?: Organization
}): boolean {
  if (!currentOrganization?.isOneBufferOrganization) {
    const subscriptions = currentOrganization?.billing?.subscriptions
    if (subscriptions && subscriptions.length > 1) {
      const activeSubscriptions = subscriptions.filter(
        (subscription: MPSubscription) => !subscription?.trial?.isActive,
      )
      return activeSubscriptions.length > 1
    }
  }

  return false
}

export function isOnBufferTrial({
  currentOrganization,
}: {
  currentOrganization?: Organization
}): boolean {
  if (isMultiProductCustomer({ currentOrganization })) {
    return false
  }

  return currentOrganization?.billing?.subscription?.trial?.isActive || false
}

function getMPSubscription(
  currentOrganization: Organization,
  product: string,
): MPSubscription | null {
  const subscriptions = currentOrganization?.billing?.subscriptions
  if (subscriptions && subscriptions.length > 0) {
    return subscriptions.find((s) => s.product === product) || null
  }

  return null
}

function getPublishSubscription(
  currentOrganization: Organization,
): MPSubscription | null {
  return getMPSubscription(currentOrganization, 'publish')
}

function getAnalyzeSubscription(
  currentOrganization: Organization,
): MPSubscription | null {
  return getMPSubscription(currentOrganization, 'analyze')
}

export function isOnAnalyzeTrial({
  currentOrganization,
}: {
  currentOrganization: Organization
}): boolean {
  const subscription = getAnalyzeSubscription(currentOrganization)
  return subscription?.trial?.isActive || false
}

export function isOnPublishTrial({
  currentOrganization,
}: {
  currentOrganization: Organization
}): boolean {
  const subscription = getPublishSubscription(currentOrganization)
  return subscription?.trial?.isActive || false
}

export function isFreePlan(user: Account): boolean {
  const { currentOrganization } = user
  const subscriptions = currentOrganization?.billing?.subscriptions
  if (subscriptions && subscriptions.length > 0) {
    return !!currentOrganization?.billing?.subscriptions?.find(
      (sub: MPSubscription) => sub.product === 'publish' && sub.plan === 'free',
    )
  }

  return (
    !isOnBufferTrial({ currentOrganization }) &&
    currentOrganization?.billing?.subscription?.plan.id === 'free'
  )
}

export function isPayingPublishOrganization(user: Account): boolean {
  const { currentOrganization } = user
  const subscriptions = currentOrganization?.billing?.subscriptions
  if (subscriptions && subscriptions.length > 0) {
    return !!subscriptions.find(
      (s) =>
        s.product === 'publish' && !s?.trial?.isActive && !isFreePlan(user),
    )
  }

  return false
}

export function isPayingAnalyzeOrganization(user: Account): boolean {
  const { currentOrganization } = user
  const subscriptions = currentOrganization?.billing?.subscriptions
  if (subscriptions && subscriptions.length > 0) {
    return !!subscriptions.find(
      (s) => s.product === 'analyze' && !s?.trial?.isActive,
    )
  }

  return false
}

export function isPayingBufferOrganization({
  currentOrganization,
}: {
  currentOrganization: Organization
}): boolean {
  if (currentOrganization?.isOneBufferOrganization) {
    if (currentOrganization?.billing?.subscription?.trial?.isActive) {
      return false
    }
    return currentOrganization?.billing?.subscription?.plan.id !== 'free'
  }

  return false
}

export function currentAnalyzePlan({
  currentOrganization,
}: {
  currentOrganization: Organization
}): string {
  if (!isOnAnalyzeTrial({ currentOrganization })) {
    const subscription = getAnalyzeSubscription(currentOrganization)
    return subscription?.plan || 'free'
  }

  return 'free'
}

export function currentAnalyzeTrialPlan({
  currentOrganization,
}: {
  currentOrganization: Organization
}): string | null {
  if (isOnAnalyzeTrial({ currentOrganization })) {
    const subscription = getAnalyzeSubscription(currentOrganization)
    return subscription?.plan || null
  }

  return null
}

export function currentPublishPlan({
  currentOrganization,
}: {
  currentOrganization: Organization
}): string {
  if (!isOnPublishTrial({ currentOrganization })) {
    const subscription = getPublishSubscription(currentOrganization)
    return subscription?.plan || 'free'
  }

  return 'free'
}

export function currentPublishTrialPlan({
  currentOrganization,
}: {
  currentOrganization: Organization
}): string | null {
  if (isOnPublishTrial({ currentOrganization })) {
    const subscription = getPublishSubscription(currentOrganization)
    return subscription?.plan || null
  }

  return null
}

export function billingCycle({
  currentOrganization,
}: {
  currentOrganization: Organization
}): string | null {
  if (currentOrganization?.isOneBufferOrganization) {
    return currentOrganization?.billing?.subscription?.interval || null
  }

  const subscriptions = currentOrganization?.billing?.subscriptions || []
  const plan = subscriptions.slice(-1)[0]
  return plan?.interval || null
}

export function trialBillingCycle({
  currentOrganization,
}: {
  currentOrganization: Organization
}): string | null {
  if (
    currentOrganization?.isOneBufferOrganization &&
    isOnBufferTrial({ currentOrganization })
  ) {
    return currentOrganization?.billing?.subscription?.interval || null
  }

  if (
    isOnPublishTrial({ currentOrganization }) ||
    isOnAnalyzeTrial({ currentOrganization })
  ) {
    const subscriptions = currentOrganization?.billing?.subscriptions || []
    const plan = subscriptions.find((s) => s?.trial?.isActive)
    return plan?.interval || null
  }

  return null
}

function bufferPlan({
  currentOrganization,
}: {
  currentOrganization?: Organization
}): string | null {
  return currentOrganization?.billing?.subscription?.plan?.id || null
}

function bufferPlanHumanReadableName({
  currentOrganization,
}: {
  currentOrganization?: Organization
}): string | null {
  return currentOrganization?.billing?.subscription?.plan?.name || null
}

export function currentBufferPlan(user: Account): string | null {
  if (isOnBufferTrial({ currentOrganization: user.currentOrganization })) {
    return 'free'
  }

  return bufferPlan(user)
}

export function currentBufferTrialPlan(user: Account): string | null {
  if (!isOnBufferTrial({ currentOrganization: user.currentOrganization })) {
    return null
  }

  return bufferPlan(user)
}

export function getHumanReadableBufferPlanName(user: Account): string | null {
  if (isOnBufferTrial({ currentOrganization: user.currentOrganization })) {
    return 'Free'
  }

  return bufferPlanHumanReadableName(user)
}

export function organizationUserRole({
  currentOrganization,
}: {
  currentOrganization: Organization
}): string | null {
  return currentOrganization?.role || null
}

export function paidSubscriptionAutoRenewEnabled({
  currentOrganization,
}: {
  currentOrganization: Organization
}): boolean | null {
  if (currentOrganization?.isOneBufferOrganization) {
    return Object.prototype.hasOwnProperty.call(
      currentOrganization?.billing?.subscription,
      'isCanceledAtPeriodEnd',
    )
      ? !currentOrganization?.billing?.subscription?.isCanceledAtPeriodEnd
      : null
  }
  return null
}

export function getCurrentBillingState(user: Account): string {
  const currentOrganization = user?.currentOrganization
  if (isFreePlan(user)) {
    return BillingState.free
  }
  if (isOnBufferTrial({ currentOrganization })) {
    return BillingState.trial
  }
  return BillingState.paid
}
