import React from 'react'
import { Button, Text } from '@bufferapp/ui'

import * as Styles from '../../../styles'
import * as ExplanationStyles from './styles'

export function Explanation({ onClose }: { onClose: () => void }): JSX.Element {
  return (
    <>
      <Styles.Header>
        <Text type="h3">Set up your Facebook Group</Text>
      </Styles.Header>
      <Styles.Body>
        <ExplanationStyles.ImagePlaceholder />
        <Text type="p">
          Meta no longer supports automatic posting to Facebook Groups through
          third-party tools like Buffer.{' '}
        </Text>

        <Text type="p">
          We support publishing your content to Facebook Groups via mobile
          notification-based posting.
        </Text>

        <Text type="p">
          <strong>How does this work?</strong>
          <br /> Create and schedule your post on Buffer. We’ll send you a
          notification to the Buffer mobile app when it’s time to post, so you
          can finish the post yourself on Facebook.
        </Text>
      </Styles.Body>
      <Styles.Footer>
        {/* @ts-ignore */}
        <Button
          type="text"
          className="custom-channel-connection-action"
          onClick={(): void => {
            onClose()
          }}
          label="Skip"
        />
        {/* @ts-ignore */}
        <Button
          type="primary"
          className="custom-channel-connection-action"
          onClick={(): void => {
            onClose()
          }}
          label="Got It"
        />
      </Styles.Footer>
    </>
  )
}
