import React, { type ChangeEvent, useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Input, Text, Tooltip } from '@bufferapp/ui'
import HelpIcon from '@bufferapp/ui/Icon/Icons/Help'

import { UserContext } from '../../../../../../../../common/context/User'
import { CREATE_CUSTOM_CHANNELS } from '../../../../../../../../common/graphql/channels'

import * as Styles from '../../../styles'
import * as GroupDetailsStyles from './styles'
import { trackCustomChannelSetupViewed } from '../../../../../tracking'
import { useSelector } from 'react-redux'
import type { OrchestratorRootState } from '../../../../../../../../common/events/types'

export function GroupDetails({
  onNext,
  onClose,
  setNewChannelId,
}: {
  onNext: () => void
  onClose: () => void
  setNewChannelId: (id: string) => void
}): React.JSX.Element {
  const { selectedService } = useSelector(
    (state: OrchestratorRootState) => state.channelConnections,
  )
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')

  const [url, setUrl] = useState('')
  const [urlError, setUrlError] = useState('')

  const user = useContext(UserContext)

  const [createCustomChannels, { data, loading }] = useMutation(
    CREATE_CUSTOM_CHANNELS,
  )

  // If successfully connects, set new channel Id and go to next step in flow.
  const newChannelId = data?.createCustomChannels?.channels[0]?.id
  if (newChannelId) {
    setNewChannelId(newChannelId)
    onNext()
  }

  useEffect(() => {
    if (selectedService) {
      trackCustomChannelSetupViewed({ account: user, service: selectedService })
    }
  }, [selectedService, user])

  return (
    <>
      <Styles.Header>
        <Text type="h3">Set up your Facebook Group</Text>
      </Styles.Header>
      <Styles.Body>
        <Styles.FormGroup>
          <Text htmlFor="name" type="label">
            Name
            <Input
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error TS(2322) FIXME: Type '{ onChange: (ev: any) => void; disabled: boo... Remove this comment to see the full error message
              type="input"
              value={name}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                setNameError('')
                setName(e.target.value)
              }}
              id="name"
              name="name"
              placeholder="Enter your Facebook Group Name"
              hasError={nameError}
              help={nameError}
            />
          </Text>
        </Styles.FormGroup>
        <Styles.FormGroup>
          <Text htmlFor="url" type="label">
            <GroupDetailsStyles.LabelIconWrapper>
              <span className="label-name">Group URL</span>
              {/* @ts-expect-error */}
              <Tooltip
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                /* @ts-ignore */
                label="Find your Facebook group URL by logging into Facebook, going to your group, and clicking the '...' icon next to the search button. Select 'Share', then 'Copy Link', and paste the URL here."
                position="right"
              >
                <div>
                  <HelpIcon />
                </div>
              </Tooltip>
            </GroupDetailsStyles.LabelIconWrapper>
            <Input
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error TS(2322) FIXME: Type '{ onChange: (ev: any) => void; disabled: boo... Remove this comment to see the full error message
              type="input"
              value={url}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                setUrlError('')
                setUrl(e.target.value)
              }}
              id="url"
              name="url"
              placeholder="Looks like this: facebook.com/groups/xxxxxxxxx"
              hasError={urlError}
              help={urlError}
            />
          </Text>
        </Styles.FormGroup>
      </Styles.Body>
      <Styles.Footer>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error TS(2740) FIXME: Type '{ label: string; type: string; size: string... Remove this comment to see the full error message */}
        <Button type="text" onClick={onClose} label="Cancel" />
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error TS(2740) FIXME: Type '{ label: string; type: string; size: string... Remove this comment to see the full error message */}
        <Button
          type="primary"
          onClick={(): void => {
            // Validation that name and url are required
            if (name.trim() === '' || url.trim() === '') {
              if (name.trim() === '') {
                setNameError('Facebook Group name is required.')
              }

              if (url.trim() === '') {
                setUrlError('Facebook Group url is required.')
              }

              return
            }

            // Max length validation for group name
            if (name.length > 75) {
              setNameError('Facebook Group name must be under 75 characters')
              return
            }

            // Correct Facebook Group URL validation
            if (!url.toLowerCase().includes('facebook.com/groups/')) {
              setUrlError(
                'Facebook Group url should look like this: facebook.com/groups/xxxxxxxxx',
              )
              return
            }

            createCustomChannels({
              variables: {
                input: {
                  channels: [
                    {
                      organizationId: user?.currentOrganization?.id,
                      service: 'facebook',
                      type: 'group',
                      timezone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                      customChannelMetadata: {
                        facebookGroupMetadata: {
                          avatar: '',
                          name,
                          url,
                        },
                      },
                    },
                  ],
                },
              },
            })
          }}
          label={loading ? 'Connecting Channel' : 'Next'}
          disabled={loading}
        />
      </Styles.Footer>
    </>
  )
}
