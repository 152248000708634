import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import { getOrganization } from '../../common/utils/user'
import { getActiveProductFromPath } from '../../common/utils/getProduct'

import {
  getCommonTrackingPropertiesFromAccount,
  shouldPerformTrackingEvent,
} from '../utils'

import type { TrackCTACLickedArguments } from './UpgradePathsTrackingTypes'
import { prefixWithProduct } from '../../common/utils/prefixWithProduct'

export default function trackCTAClicked({
  payload,
  user,
}: TrackCTACLickedArguments): void {
  const { cta, ctaView, ctaLocation, ctaButton, ctaVersion, upgradePathName } =
    payload

  const canPerformTrackingEvent = shouldPerformTrackingEvent({
    user,
  })

  if (!canPerformTrackingEvent) {
    return
  }

  const version = ctaVersion || '1'

  const organization = getOrganization(user)
  const product = getActiveProductFromPath()
  const compoundCta = cta
    ? prefixWithProduct(cta)
    : `${product}-${ctaView}-${ctaLocation}-${ctaButton}-${version}`

  const commonTrackingProperties = getCommonTrackingPropertiesFromAccount(user)

  const eventData = {
    organizationId: organization?.id,
    cta: compoundCta,
    upgradePathName,
    clientName: 'publishWeb',
    product,
    ...commonTrackingProperties,
  }

  BufferTracker.cTAClicked(eventData)
}
