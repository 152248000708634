import { useState, useEffect } from 'react'

interface ScrollPositionHookProps {
  element: HTMLElement | null
  threshold: number
}

const useScrollPositionThreshold = ({
  element,
  threshold,
}: ScrollPositionHookProps): boolean => {
  const [isPastThreshold, setIsPastThreshold] = useState(false)

  useEffect(() => {
    const handleScroll = (): void => {
      if (element) {
        const scrollPosition = element.scrollTop
        setIsPastThreshold(scrollPosition > threshold)
      }
    }

    const targetElement = element
    if (targetElement instanceof HTMLElement) {
      targetElement.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (targetElement instanceof HTMLElement) {
        targetElement.removeEventListener('scroll', handleScroll)
      }
    }
  }, [element, threshold])

  return isPastThreshold
}

export default useScrollPositionThreshold
