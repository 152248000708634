// This are the extensions we support, no need to have any other for now
//
// ___Where to take new extensions from?
//    https://github.com/jshttp/mime-db/blob/master/db.json

const SUPPORTED_TYPES = [
  'image/gif',
  'image/heic',
  'image/heif',
  'image/jpeg',
  'image/jpeg',
  'image/png',
  'video/avi',
  'video/x-msvideo',
  'image/webp',
  'video/x-matroska',
  'video/x-matroska',
  'video/quicktime',
  'video/mp4',
  'video/mpeg',
  'video/ogg',
  'video/webm',
  'application/pdf',
]

export const isSupportedMimeType = (mimeType: string | undefined): boolean => {
  return !!mimeType && SUPPORTED_TYPES.includes(mimeType)
}
